import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/dashboards/hrdashboard/subhr.css";
import Pagination from "@mui/material/Pagination";
import threedot from "../../../utils/landingpage/images/three-dots-svgrepo-com.svg";
import { IconButton, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

export default function SubUserList() {
  const ITEM_HEIGHT = 48;
  const navigate = useNavigate();
  const [anchorEls, setAnchorEls] = useState({});
  const [loading, setLoading] = useState(true);
  const [subUsers, setSubUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(3);
  const token = sessionStorage.getItem("accessToken");
  const base_Url = process.env.REACT_APP_BASE_URL;

  const handleClick = (event, userId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [userId]: event.currentTarget, // Use userId as the key
    }));
  };

  const viewDetails = (id) => {
    navigate(`/dashboard/sidebar-page/hr-manager/view-sub-hr-details/${id}`);
  };

  const handleClose = (userId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [userId]: null, // Close the menu for a specific user
    }));
  };

  const fetchSubUsers = async () => {
    try {
      const response = await fetch(`${base_Url}/api/show_subuser/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setSubUsers(data);
    } catch (error) {
      console.error("Error fetching sub-users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubUsers();
  }, [base_Url, token]);

  // Logic for pagination
  const indexOfLastUser = currentPage * jobsPerPage;
  const indexOfFirstUser = indexOfLastUser - jobsPerPage;
  const currentUsers = subUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const activeDeActive = async (subUserId) => {
    try {
      console.log("Sub user", subUserId);

      const response = await axios.post(
        `${base_Url}/api/active_deactive_subuser/${subUserId}/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        fetchSubUsers();
      }
    } catch (error) {
      console.error("Error toggling subuser status:", error.message);
    }
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="sub_hr_container">
            <h6>
              {" "}
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <path
                  d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                  fill="#222222"
                />
                <path
                  d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                  fill="#222222"
                />
              </svg>{" "}
              &nbsp; Sub User List
            </h6>

            <div>
              <div className="sub_hr_userlist_job_listing_table">
                {loading ? (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100vw",
                      height: "100vh",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 9999,
                    }}
                  >
                    <ClipLoader color="#FFA500" size={60} />
                  </div>
                ) : subUsers.length === 0 ? (
                  <div className="no_job_post_yet">No sub-users found.</div>
                ) : (
                  <table>
                    <thead className="sub_hr_userlist_job_listing_table_head">
                      <tr>
                        <th>Full Name</th>
                        <th>Contact Number</th>
                        <th>Email Address</th>
                        <th>Branch Name</th>
                        <th>Active/Inactive</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentUsers.map((user) => (
                        <tr key={user.id}>
                          <td>
                            {capitalizeFirstLetter(user.name)}{" "}
                            {capitalizeFirstLetter(user.subuser_lastname)}
                          </td>
                          <td>{user.all_mobno}</td>
                          <td>{user.email}</td>
                          <td>{user.subuser_branchname}</td>
                          <td className="active">
                            <div className="hr_notification_one checkbox-wrapper">
                              <input
                                className="tgl tgl-light"
                                id={`hr_toggle_${user.id}`}
                                type="checkbox"
                                checked={user.issubuser_active}
                                onClick={() => activeDeActive(user.id)}
                              />
                              <label
                                className="tgl-btn"
                                htmlFor={`hr_toggle_${user.id}`}
                              ></label>
                            </div>
                          </td>

                          <td>
                            {" "}
                            <div>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={
                                  anchorEls[user.id] ? "long-menu" : undefined
                                }
                                aria-expanded={
                                  anchorEls[user.id] ? "true" : undefined
                                }
                                aria-haspopup="true"
                                onClick={(event) => handleClick(event, user.id)}
                              >
                                <img
                                  src={threedot}
                                  height={18}
                                  width={18}
                                  alt=".."
                                />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  "aria-labelledby": "long-button",
                                }}
                                anchorEl={anchorEls[user.id]} // Now specific to the user
                                open={Boolean(anchorEls[user.id])}
                                onClose={() => handleClose(user.id)}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: "8ch",
                                  },
                                }}
                              >
                                <MenuItem onClick={() => viewDetails(user.id)}>
                                  View
                                </MenuItem>
                              </Menu>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>

              {/* Pagination */}
              {subUsers.length > jobsPerPage && (
                <div className="d-flex justify-content-center pagination_custom">
                  <Pagination
                    count={Math.ceil(subUsers.length / jobsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
