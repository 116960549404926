import axios from "axios";
import React, { useEffect, useState } from "react";
import arrowImg from "../../../utils/landingpage/images/navigate.svg";
import searchLogo from "../../../utils/dashboards/searchIconDash.svg";
import { useNavigate, useParams } from "react-router-dom";
import "../../../styles/admin/companydetails.css";
import threeDot from "../../../utils/landingpage/images/three-dots-svgrepo-com.svg";
import "../../../styles/dashboards/hrdashboard/subhr.css";
import Pagination from "@mui/material/Pagination";
import { Badge, IconButton, Menu, MenuItem } from "@mui/material";
import { toast } from "react-toastify";

function ViewCompanyDetails() {
  const { id } = useParams();
  const [companyDetails, setCompanyDetails] = useState(null);
  const [companyLoading, setCompanyLoading] = useState(true);
  const [subUsers, setSubUsers] = useState([]);
  const [isCompanyActive, setIsCompanyActive] = useState(false); // Initial state: inactive
  const [anchorEls, setAnchorEls] = useState([null, null]); // For menu dropdown
  const token = sessionStorage.getItem("accessToken"); // Fetch token
  const [subUsersLoading, setSubUsersLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(3);

  const navigate = useNavigate();
  const ITEM_HEIGHT = 36;

  const handleStatusToggle = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/active_deactive_company/${id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Toggle the state based on API response
        setIsCompanyActive((prevStatus) => !prevStatus);
      }
    } catch (error) {
      console.error("Error toggling company status:", error);
    }
  };

  const handleClick = (event, panId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [panId]: event.currentTarget,
    }));
  };

  const getCompanyDetails = async () => {
    setCompanyLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/details_company/${id}/`
      );
      setCompanyDetails(response.data);
    } catch (error) {
      console.error("Error fetching company details:", error);
    } finally {
      setCompanyLoading(false);
    }
  };

  const fetchSubUsers = async () => {
    setSubUsersLoading(true);
    try {
      const token = sessionStorage.getItem("accessToken");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/show_subuser_by_admin/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            company_id: id,
          },
        }
      );

      if (response.status === 200) {
        const result = response.data;
        setSubUsers(result);
      }
    } catch (error) {
      console.error("Error fetching sub-users:", error);
    } finally {
      setSubUsersLoading(false);
    }
  };

  const handleClose = (panId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [panId]: null,
    }));
  };

  const viewDetails = (id) => {
    navigate(`/dashboard/sidebar-page/hr-manager/view-sub-hr-details/${id}`);
  };

  const activeDeActive = async (subUserId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/active_deactive_subuser/${subUserId}/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        fetchSubUsers();
      }
    } catch (error) {
      console.error("Error toggling subuser status:", error.message);
    }
  };

  const goToBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getCompanyDetails();
    fetchSubUsers();
  }, [id]);

  const indexOfLastUser = currentPage * jobsPerPage;
  const indexOfFirstUser = indexOfLastUser - jobsPerPage;
  const currentUsers = subUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  console.log("setSubUsers", subUsers);
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="admin_plan_header">
          <h6 className="d-flex align-items-center justify-content-center">
            <img
              className="arrow_icon"
              onClick={goToBack}
              src={arrowImg}
              alt=".."
            />
            &nbsp; Company Details
          </h6>
          <div className="d-flex gap-3">
            <div className="campuse_student_request_search_bar">
              <img src={searchLogo} alt="search" />
              <input type="text" placeholder="Search" />
            </div>
          </div>
        </div>

        {companyLoading ? (
          <div>Loading company details...</div>
        ) : (
          <div className="company_details">
            <div className="company_logo_container">
              <div className="company_logo">
                <img
                  src={
                    companyDetails?.company_profile?.company_logo_url ||
                    "https://via.placeholder.com/100"
                  }
                  alt="company logo"
                />
              </div>
              <div className="company_title">
                <p className="company_title_heading">
                  {companyDetails?.company_name}
                </p>
                <p className="company_title_sub_heading">Gurugram, Haryana</p>
              </div>
            </div>
            <div className="company_others_details">
              <div className="company_others_details_con">
                <p className="company_title_sub_heading">Name</p>
                <p className="company_title_heading">
                  {companyDetails?.name} {companyDetails?.company_last_name}
                </p>
              </div>
              <div className="company_others_details_con">
                <p className="company_title_sub_heading">Business Email</p>
                <p className="company_title_heading">
                  {companyDetails?.company_profile?.company_email}
                </p>
              </div>
              <div className="company_others_details_con">
                <p className="company_title_sub_heading">Phone Number</p>
                <p className="company_title_heading">
                  {companyDetails?.all_mobno}
                </p>
              </div>
              <div className="company_others_details_con">
                <p className="company_title_sub_heading">City</p>
                <p className="company_title_heading">
                  {companyDetails?.company_city}
                </p>
              </div>
            </div>
            <div className="three_icon_btn">
              {/* Badge for Active/Inactive Status */}
              <Badge
                badgeContent=""
                color={isCompanyActive ? "success" : "error"} // Green for active, red for inactive
                variant="dot"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={anchorEls[1] ? "long-menu" : undefined}
                  aria-expanded={anchorEls[1] ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => handleClick(event, 1)}
                >
                  <img src={threeDot} height={18} width={18} alt=".." />
                </IconButton>
              </Badge>

              {/* Dropdown Menu */}
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEls[1]}
                open={Boolean(anchorEls[1])}
                onClose={() => handleClose(1)}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 3.5,
                    width: "12ch",
                  },
                }}
              >
                {/* Toggle Active/Deactivate */}
                <MenuItem onClick={() => handleStatusToggle(id)}>
                  {isCompanyActive ? "Deactivate" : "Activate"}
                </MenuItem>
              </Menu>
            </div>
          </div>
        )}

        <h6>Sub-User Management</h6>
        <div className="sub_hr_userlist_job_listing_table">
          {Array.isArray(subUsers) && subUsers.length > 0 ? (
            <table>
              <thead className="sub_hr_userlist_job_listing_table_head">
                <tr>
                  <th>Full Name</th>
                  <th>Contact Number</th>
                  <th>Email Address</th>
                  <th>Branch Name</th>
                  <th>Active/Inactive</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {subUsers.map((user) => (
                  <tr key={user.id}>
                    <td>
                      {capitalizeFirstLetter(user.name)}{" "}
                      {capitalizeFirstLetter(user.subuser_lastname)}
                    </td>
                    <td>{user.all_mobno}</td>
                    <td>{user.email}</td>
                    <td>{user.subuser_branchname}</td>
                    <td className="active">
                      <div className="hr_notification_one checkbox-wrapper">
                        <input
                          className="tgl tgl-light"
                          id={`hr_toggle_${user.id}`}
                          type="checkbox"
                          checked={user.issubuser_active}
                          onClick={() => activeDeActive(user.id)}
                        />
                        <label
                          className="tgl-btn"
                          htmlFor={`hr_toggle_${user.id}`}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <div>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={
                            anchorEls[user.id] ? "long-menu" : undefined
                          }
                          aria-expanded={
                            anchorEls[user.id] ? "true" : undefined
                          }
                          aria-haspopup="true"
                          onClick={(event) => handleClick(event, user.id)}
                        >
                          <img src={threeDot} height={18} width={18} alt=".." />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEls[user.id]}
                          open={Boolean(anchorEls[user.id])}
                          onClose={() => handleClose(user.id)}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 3.5,
                              width: "12ch",
                            },
                          }}
                        >
                          <MenuItem onClick={() => viewDetails(user.id)}>
                            View Details
                          </MenuItem>
                        </Menu>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="no_job_post_yet">No sub-users found.</div>
          )}
        </div>
        <div className="sub_hr_userlist_job_listing_table_pagination">
          {subUsers.length > jobsPerPage && (
            <Pagination
              count={Math.ceil((subUsers.length || 1) / jobsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewCompanyDetails;
