import { createSlice } from "@reduxjs/toolkit";

const CollageDashbaordSlice = createSlice({
    name: "CollageDashboard",
    initialState: {
        showCurrentStudentList: false,
        showEntrolledStudentList: false,
    },
    reducers: {
        setShowCurrentStudentList: (state, action) => {
            state.showCurrentStudentList = action.payload;
        },
        setShowEntrolledStudentList: (state, action) => {
            state.showEntrolledStudentList = action.payload;
        }
    }
});

export const {setShowCurrentStudentList, setShowEntrolledStudentList} = CollageDashbaordSlice.actions;

export default CollageDashbaordSlice.reducer;