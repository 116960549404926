import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../../styles/dashboards/hrdashboard/campuseinterview.css";
import axios from "axios";
import Loader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import Swal from "sweetalert2";

function CandidateOfSelectedCollege() {
  const { jobId, clgId } = useParams();

  const navigate = useNavigate();
  const [allCandidates, setAllCandidates] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roundsCount, setRoundsCount] = useState(0);
  const [activeRound, setActiveRound] = useState(1);
  const token = sessionStorage.getItem("accessToken");
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedCandidatesId, setSelectedCandidatesId] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [isDeclineLoading, setIsDeclineLoading] = useState(false);
  const [isMoveToNextLoading, setIsMoveToNextLoading] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [interviewType, setInterviewType] = useState("physical");
  const [interviewDateTime, setInterviewDateTime] = useState("");
  const [location, setLocation] = useState("");
  const [isScheduling, setIsScheduling] = useState(false);
  const [isMakeOfferLoading, setIsMakeOfferLoading] = useState(false);
  const [interviewDetails, setInterviewDetails] = useState({});

  const getJobRoundsData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/campus-jobs/${jobId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setRoundsCount(response.data.no_of_interview_round);
      }
    } catch (error) {
      console.error("Error fetching job rounds data:", error);
      toast.error("Failed to fetch job rounds data");
    }
  }, [jobId, token]);

  const fetchInterviewDetails = useCallback(
    async (applicationIds) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/schedule_interview/retrieve_interview_details/`,
          { application_ids: applicationIds },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const details = response.data.interview_details.reduce(
          (acc, detail) => {
            acc[detail.application_id] = detail;
            return acc;
          },
          {}
        );
        setInterviewDetails(details);
        console.log("----interview details", details)
      } catch (error) {
        console.error("Error fetching interview details:", error);
      }
    },
    [token]
  );

  const getCandidatesData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/hr/jobs/colleges/applications/?college_id=${clgId}&job_id=${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setAllCandidates(response.data.students);
        const roundCandidates = response.data.students.filter(
          (candidate) => candidate.interview_round === activeRound.toString()
        );
        setFilteredCandidates(roundCandidates);

        const applicationIds = roundCandidates.map((candidate) => candidate.id);
        if (applicationIds.length > 0) {
          await fetchInterviewDetails(applicationIds);
        }
      }
    } catch (error) {
      console.error("Error fetching candidates:", error);
      toast.error("Failed to fetch candidates");
    } finally {
      setLoading(false);
    }
  }, [clgId, jobId, token, activeRound, fetchInterviewDetails]);

  useEffect(() => {
    const roundCandidates = allCandidates.filter(
      (candidate) => candidate.interview_round === activeRound.toString()
    );
    setFilteredCandidates(roundCandidates);
  }, [activeRound, allCandidates]);

  useEffect(() => {
    getJobRoundsData();
    getCandidatesData();
  }, [getJobRoundsData, getCandidatesData]);

  const handleCheckboxChange = (candidateId) => {
    setSelectedCandidatesId(candidateId);
    setSelectedCandidates((prev) => {
      if (prev.includes(candidateId)) {
        return prev.filter((id) => id !== candidateId);
      } else {
        return [...prev, candidateId];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedCandidates(
        filteredCandidates.map((candidate) => candidate.id)
      );
    } else {
      setSelectedCandidates([]);
    }
  };

  const handleUpdateStatus = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to decline these candidates?",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "custom-swal-confirm-button",
      },
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, decline them!",
    });

    if (result.isConfirmed) {
      try {
        setIsDeclineLoading(true);
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/update_application_status/`,
          {
            application_ids: selectedCandidates,
            status: "discarded",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success("Status updated successfully.");
        getCandidatesData();
        setSelectedCandidates([]);
      } catch (error) {
        console.error("Error updating status:", error);
        toast.error("Failed to update status.");
      } finally {
        setIsDeclineLoading(false);
      }
    }
  };

  const handleMoveToNextRound = async () => {
    try {
      setIsMoveToNextLoading(true);
      const nextRound = activeRound + 1;

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/student_campus_schedule_next_round/`,
        {
          application_ids: selectedCandidates,
          interview_round: nextRound,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Candidates moved to next round successfully");
      setActiveRound(nextRound);
      getCandidatesData();
      setInterviewDetails("");
      setSelectedCandidates([]);
      setSelectAll(false);
    } catch (error) {
      console.error("Error moving candidates to next round:", error);
      toast.error("Failed to move candidates to next round");
    } finally {
      setIsMoveToNextLoading(false);
    }
  };

  const handleMakeOffer = async () => {
    try {
      setIsMakeOfferLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/update_application_status/`,
        {
          application_ids: selectedCandidates,
          status: "offered",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Candidates marked as offered successfully");
      getCandidatesData();
      setSelectedCandidates([]);
    } catch (error) {
      console.error("Error updating status to offered:", error);
      toast.error("Failed to update status to offered");
    } finally {
      setIsMakeOfferLoading(false);
    }
  };

  const handleScheduleInterview = async () => {
    try {
      if (!interviewDateTime) {
        toast.error("Please select date and time");
        return;
      }

      setIsScheduling(true);
      const formattedDateTime = interviewDateTime.replace("T", " ");

      const payload = {
        application_ids: selectedCandidates,
        interview_round: activeRound,
        interview_type: interviewType,
        interview_date_time: formattedDateTime,
      };

      if (interviewType === "physical") {
        if (!location.trim()) {
          toast.error("Please enter location");
          return;
        }
        payload.location = location;
      }

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/student_campus_schedule_interview/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Interview scheduled successfully");
      setShowScheduleModal(false);
      getCandidatesData();
      setSelectedCandidates([]);
      setInterviewDateTime("");
      setLocation("");
      setInterviewType("physical");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorDetail = error.response.data.detail;
        if (errorDetail.includes("Interview time must be in the future")) {
          toast.error(
            "Please select a future date and time for the interview."
          );
        } else {
          toast.error("Failed to schedule interview: " + errorDetail);
        }
      } else {
        console.error("Error scheduling interview:", error);
        toast.error("Failed to schedule interview.");
      }
    } finally {
      setIsScheduling(false);
    }
  };

  const handleScheduleButtonClick = async (candidateId) => {
    setSelectedCandidates([candidateId]);
    setShowScheduleModal(true);

    const candidate = filteredCandidates.find((c) => c.id === candidateId);
    if (candidate?.interview_scheduled) {
      const details = interviewDetails[candidateId];
      if (details) {
        setInterviewType(details.interview_type || "physical");
        const formattedDateTime = details.interview_date?.slice(0, 16);
        setInterviewDateTime(formattedDateTime || "");
        setLocation(details.location || "");
      }
    } else {
      setInterviewType("physical");
      setInterviewDateTime("");
      setLocation("");
    }
  };

  const handleBulkScheduleClick = async () => {
    setShowScheduleModal(true);
    if (
      selectedCandidates.some(
        (id) => filteredCandidates.find((c) => c.id === id)?.interview_scheduled
      )
    ) {
      await fetchInterviewDetails(selectedCandidates);
    } else {
      setInterviewType("physical");
      setInterviewDateTime("");
      setLocation("");
    }
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 16);
  };

  const scheduleDialog = (
    <Dialog
      open={showScheduleModal}
      onClose={() => setShowScheduleModal(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {selectedCandidates.some(
          (id) =>
            filteredCandidates.find((c) => c.id === id)?.interview_scheduled
        )
          ? "Re-Schedule Interview"
          : "Schedule Interview"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Interview Type</InputLabel>
            <Select
              value={interviewType}
              label="Interview Type"
              onChange={(e) => setInterviewType(e.target.value)}
            >
              <MenuItem value="physical">Physical</MenuItem>
              <MenuItem value="virtual">Virtual</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Interview Date & Time"
            type="datetime-local"
            value={interviewDateTime}
            onChange={(e) => setInterviewDateTime(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: getCurrentDateTime(),
            }}
          />

          {interviewType === "physical" ? (
            <TextField
              label="Location"
              fullWidth
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Enter interview location "
            />
          ) : (
            null
            // <TextField
            //   label="Zoom Meeting Link"
            //   fullWidth
            //   value={location}
            //   onChange={(e) => setLocation(e.target.value)}
            //   placeholder="Enter zoom meeting link Optional"
            // />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowScheduleModal(false)} color="inherit">
          Cancel
        </Button>
        <button
          onClick={handleScheduleInterview}
          className="action-btn"
          disabled={isScheduling}
          variant="contained"
          color="primary"
        >
          {isScheduling ? "Scheduling..." : "Schedule Interview"}
        </button>
      </DialogActions>
    </Dialog>
  );

  const isInterviewCompleted = (interviewDateTime) => {
    if (!interviewDateTime) return false;
    const interview = new Date(interviewDateTime);
    const current = new Date();
    return current > interview;
  };

  if (loading) {
    return (
      <div className="_main_content_inner_screen">
        <div className="custom_loader">
          <Loader color="#F38D00" size={50} />
        </div>
      </div>
    );
  }

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="capuse_interview_tabs-container">
          <h6>
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <path
                d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                fill="#222222"
              />
            </svg>
            &nbsp; Selected Candidates
          </h6>

          <div className="capuse_interview_tabs">
            {Array.from({ length: roundsCount }, (_, index) => (
              <button
                key={index + 1}
                className={`capuse_interview_tab-button ${activeRound === index + 1 ? "capuse_interview_active" : ""
                  }`}
                onClick={() => setActiveRound(index + 1)}
              >
                {`Round ${index + 1}`}
              </button>
            ))}
          </div>

          <div className="student_campus_table_div">
            <table className="student_campus_styled-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />{" "}
                    &nbsp; All Select
                  </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Interview Status</th>
                  <th>Interview Date & Time</th>
                  <th>Actions</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredCandidates?.length > 0 ? (
                  filteredCandidates?.map((candidate) => (
                    <tr key={candidate.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedCandidates.includes(candidate.id)}
                          onChange={() => handleCheckboxChange(candidate.id)}
                        />
                      </td>
                      <td>
                        {`${candidate.student_details.studentcampus_profile.first_name} ${candidate.student_details.studentcampus_profile.last_name}`}
                      </td>
                      <td>{candidate.student_details.email}</td>
                      <td>{candidate.status}</td>
                      <td>
                        {interviewDetails[candidate.id]?.interview_date ? (
                          <span
                            style={{
                              backgroundColor: isInterviewCompleted(
                                interviewDetails[candidate.id].interview_date
                              )
                                ? "#ff9800"
                                : "green",
                              color: "white",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              width: "auto",
                              fontWeight: "bold",
                            }}
                          >
                            {isInterviewCompleted(
                              interviewDetails[candidate.id].interview_date
                            )
                              ? "In Process"
                              : "Scheduled"}
                          </span>
                        ) : (
                          <span
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              width: "auto",
                              fontWeight: "bold",
                            }}
                          >
                            Not Scheduled
                          </span>
                        )}
                      </td>
                      <td>
                        {interviewDetails[candidate.id]?.interview_date
                          ? new Date(
                            interviewDetails[candidate.id].interview_date
                          ).toLocaleString()
                          : "Not scheduled"}
                      </td>
                      <td>
                        <button
                          className="view_application_btn"
                          onClick={() => {
                            navigate(
                              `/dashboard/sidebar-page/hr-manager/campus/view-candidate-application/${candidate.student_details.id}/${candidate.id}`
                            );
                          }}
                        >
                          View Application
                        </button>
                      </td>
                      <td>
                        <button
                          className="view_application_btn"
                          onClick={() =>
                            handleScheduleButtonClick(candidate.id)
                          }
                        >
                          {candidate.interview_scheduled
                            ? "Re-Schedule"
                            : "Schedule"}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="no-data">
                      No candidates found for Round {activeRound}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {selectedCandidates?.length > 0 && (
              <div
                className="action_buttons_container d-flex justify-content-between"
                style={{ marginTop: "20px", textAlign: "right" }}
              >
                <div className="d-flex gap-2">
                  <button
                    className="action-btn"
                    onClick={handleUpdateStatus}
                    disabled={isDeclineLoading}
                  >
                    {isDeclineLoading ? (
                      <div className="btn-loader loader"></div>
                    ) : (
                      "Decline Selected Candidates"
                    )}
                  </button>

                  {activeRound >= roundsCount &&
                    selectedCandidates.every((id) => {
                      const candidate = filteredCandidates.find((c) => c.id === id);
                      return (
                        candidate?.interview_scheduled &&
                        interviewDetails[id]?.interview_date &&
                        isInterviewCompleted(interviewDetails[id].interview_date)
                      );
                    }) ? (
                    <button
                      className="action-btn"
                      onClick={handleMakeOffer}
                      disabled={isMakeOfferLoading}
                    >
                      {isMakeOfferLoading ? (
                        <div className="btn-loader loader"></div>
                      ) : (
                        "Make Offer"
                      )}
                    </button>
                  ) : null}

                  {selectedCandidates.every((id) =>
                    filteredCandidates.find((candidate) => candidate.id === id)?.interview_scheduled
                  ) && (
                      <button
                        className="action-btn"
                        onClick={handleMoveToNextRound}
                        disabled={
                          isMoveToNextLoading ||
                          activeRound >= roundsCount ||
                          interviewDetails == ""
                        }
                      >
                        {isMoveToNextLoading ? (
                          <div className="btn-loader loader"></div>
                        ) : (
                          "Move to Next Round"
                        )}
                      </button>
                    )}
                </div>

                {activeRound >= roundsCount ? (
                  ""
                ) : (
                  <button
                    className="action-btn"
                    onClick={handleBulkScheduleClick}
                    disabled={isDeclineLoading || activeRound >= roundsCount}
                  >
                    {isDeclineLoading ? (
                      <div className="btn-loader loader"></div>
                    ) : selectedCandidates.some((id) =>
                      filteredCandidates.find((candidate) => candidate.id === id)
                        ?.interview_scheduled
                    ) ? (
                      "Re-Schedule Interview"
                    ) : (
                      "Schedule Interview"
                    )}
                  </button>
                )}
              </div>
            )}

            {/* {selectedCandidates?.length > 0 && (
              <div
                className="action_buttons_container d-flex justify-content-between"
                style={{ marginTop: "20px", textAlign: "right" }}
              >
                <div className="d-flex gap-2">
                  <button
                    className="action-btn"
                    onClick={handleUpdateStatus}
                    disabled={isDeclineLoading}
                  >
                    {isDeclineLoading ? (
                      <div className="btn-loader loader"></div>
                    ) : (
                      "Decline Selected Candidates"
                    )}
                  </button>

                  {((activeRound >= roundsCount) && (interviewDetails[selectedCandidatesId]?.interview_date)) ? (
                    <button
                      className="action-btn"
                      onClick={handleMakeOffer}
                      disabled={
                        isMakeOfferLoading ||
                        interviewDetails == "" ||
                        !Object.values(interviewDetails).every((detail) =>
                          isInterviewCompleted(detail.interview_date)
                        )
                      }
                    >
                      {isMakeOfferLoading ? (
                        <div className="btn-loader loader"></div>
                      ) : (
                        "Make Offer"
                      )}
                    </button>
                  ) : (
                    selectedCandidates.every(
                      (id) =>
                        filteredCandidates.find(
                          (candidate) => candidate.id === id
                        )?.interview_scheduled
                    ) && (
                      <button
                        className="action-btn"
                        onClick={handleMoveToNextRound}
                        disabled={
                          isMoveToNextLoading ||
                          activeRound >= roundsCount ||
                          interviewDetails == ""
                        }
                      >
                        {isMoveToNextLoading ? (
                          <div className="btn-loader loader"></div>
                        ) : (
                          "Move to Next Round"
                        )}
                      </button>
                    )
                  )}
                </div>
                {activeRound >= roundsCount ? (
                  ""
                ) : (
                  <button
                    className="action-btn"
                    onClick={handleBulkScheduleClick}
                    disabled={isDeclineLoading || activeRound >= roundsCount}
                  >
                    {isDeclineLoading ? (
                      <div className="btn-loader loader"></div>
                    ) : selectedCandidates.some(
                        (id) =>
                          filteredCandidates.find(
                            (candidate) => candidate.id === id
                          )?.interview_scheduled
                      ) ? (
                      "Re-Schedule Interview"
                    ) : (
                      "Schedule Interview"
                    )}
                  </button>
                )}
              </div>
            )} */}

          </div>
        </div>
      </div>
      {scheduleDialog}
    </div>
  );
}

export default CandidateOfSelectedCollege;
