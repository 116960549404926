import React, { useEffect } from "react";
import Navbar from "../common_component/navigation/navbar";
import Footer from "../common_component/footer/footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useOnlineStatus } from "../reusablecomponents/CheckOnline";
import { toast, ToastContainer } from "react-toastify";
import DashboardSideBar from "../dashboards/dashboard-sider-bar";

const Layout = ({ children }) => {
  const location = useLocation();
  const isOnline = useOnlineStatus();

  useEffect(() => {
    if (!isOnline) {
      toast.error("Please connect to the internet");
    }
  }, [isOnline]);
  return (
    <div>
      <ToastContainer />
      {(location.pathname !== "/sidebar-page" &&
        (location.pathname == "/404" ||
          location.pathname == "/login" ||
          location.pathname == "/register" ||
          location.pathname.includes("dashboard"))) ||
      location.pathname.includes("lateral/register") ||
      location.pathname.includes("auth/userMessage") ? (
        children
      ) : (
        <>
          <Navbar />
          {children}
          <Footer />
        </>
      )}

      {location.pathname.includes("/sidebar-page") && (
        <>
          <DashboardSideBar>{children}</DashboardSideBar>
        </>
      )}
    </div>
  );
};

export default Layout;
