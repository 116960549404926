import axios from "axios";
import { setGetStudentProfileData } from "../../reducers/student_profile_reducer";
import { setIsSubmitted } from "../../reducers/student_profile_reducer";
import { setIsSubmittedSkill } from "../../reducers/student_profile_reducer";
import { setIsAlreadySocialDataAvailable } from "../../reducers/student_profile_reducer";
import { setShowSocialMediaRedux } from "../../reducers/student_profile_reducer";
import { setEditSocialMediaDataRedux } from "../../reducers/student_profile_reducer";
import { setGetStudentApplicationData } from "../../reducers/student_profile_reducer";
import { setIsLoadingMyApplication } from "../../reducers/student_profile_reducer";
import { setIsLoadingHome } from "../../reducers/student_profile_reducer";
import { toast } from "react-toastify";

export const fetchStudentProfileData = () => async (dispatch) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const userSessionData = sessionStorage.getItem("userDetails");

  if (accessToken && userSessionData) {
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData?.id;

    if (!userId) return;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const url = `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`;

    try {
      const response = await axios.get(url, config);

      dispatch(setGetStudentProfileData(response.data));

      // if (response.data.certificates?.length > 0) {
      //   dispatch(setIsSubmittedCertification(true));
      // }
      if (response.data.skill_name?.length > 0) {
        dispatch(setIsSubmittedSkill(true));
      }
      //   if (response.data.employments?.length > 0) {
      //     dispatch(setIsSubmittedEmployment(true));
      //   }
      if (response.data.dob !== null && response.data.phone_no !== null) {
        dispatch(setIsSubmitted(true))
      }
      if (response.data?.social_media_links.length > 0) {
        dispatch(setIsAlreadySocialDataAvailable(true));
        dispatch(setShowSocialMediaRedux(true));

        const socialMediaData = {
          instagram: response.data?.social_media_links.find(link => link.platform === 'instagram')?.url || '',
          twitter: response.data?.social_media_links.find(link => link.platform === 'twitter')?.url || '',
          linkedin: response.data?.social_media_links.find(link => link.platform === 'linkedin')?.url || '',
        };

        dispatch(setEditSocialMediaDataRedux(socialMediaData));
      }
      //   if (response.data.qualifications?.length > 0) {
      //     dispatch(setIsSubmittedQualification(true));
      //   }

    } catch (error) {
      toast.error("Error fetching data:", error);
    }
  } else {
    toast.warn("User session data is expired Please Login again.");
  }
};

export const fetchStudentApplicationData = () => async (dispatch) => {
  const accessToken = sessionStorage.getItem("accessToken");

  if (accessToken) {

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const url = `${process.env.REACT_APP_BASE_URL}/api/jobs/student_applied_jobs/`;

    try {
      dispatch(setIsLoadingMyApplication(true))
      const response = await axios.get(url, config);

      dispatch(setGetStudentApplicationData(response.data));

    } catch (error) { 
      console.error("Error fetching data:", error);
    } finally {
      dispatch(setIsLoadingMyApplication(false))
    }
  } else {
    console.warn("User session data or access token is missing.");
  }
};