import React, { useState, useCallback, useEffect } from "react";
import { motion } from "framer-motion";
import "../../../styles/landing_page/landingpage.css";
import ArrowDown from "../../../utils/landingpage/images/arrow-down.svg";
import ArrowUp from "../../../utils/landingpage/images/arrow-up.svg";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const Faq = () => {
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const location = useLocation();

  const toggleAnswer = useCallback(
    (faqIndex) => {
      setExpandedFaq(expandedFaq === faqIndex ? null : faqIndex);
    },
    [expandedFaq]
  ); 

  const FaqData = useSelector((state) => state.letralHiring?.faqsRedux);
  const DigitalFaqData = useSelector((state) => state.digitalCampusHiring?.digitalFAQdata);

  useEffect(() => {
    if ((location.pathname.includes("lateral") || location.pathname === "/") && FaqData) {
      setFaqs(FaqData);
    } else {
      setFaqs(DigitalFaqData);
    }
  }, [location.pathname, FaqData, DigitalFaqData]);

  return (
    <>
      <section className="faq-secion">
        <motion.div
          className="faq-header"
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1>Frequently Asked Questions</h1>
          <p>
            Find quick answers about StartNaukri's account setup, job search tools, employer,
            <br /> interactions, privacy policies, and more
          </p>
        </motion.div>

        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {faqs?.length > 0 && faqs?.map((faq, index) => (
            <div key={faq.id} className={`faq-item ${expandedFaq === index + 1 ? "d-grid" : ""}`}>
              <label
                htmlFor={`faq${index + 1}`}
                className="faq-question"
                onClick={() => toggleAnswer(index + 1)}
              >
                <div>
                  <span className="number">{String(index + 1).padStart(2, '0')}</span>
                  <span className="faq-text">{faq.question || "No questions provided."}</span>
                </div>
                <img
                  className="arrow"
                  src={expandedFaq === index + 1 ? ArrowUp : ArrowDown}
                  alt="Arrow"
                />
              </label>
              <div
                className="faq-answer"
                style={{ display: expandedFaq === index + 1 ? "block" : "none" }}
              >
                <p>{faq.answer || "No answer provided."}</p>
              </div>
            </div>
          ))}
          {faqs?.length < 1 && (
            <div style={{ display: "grid", placeItems: "center", paddingTop: "5rem" }}>
              <p>No Questions Available</p>
            </div>
          )}
        </motion.div>
      </section>
    </>
  );
};

export default Faq;