import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination"; // Import MUI Pagination
import "../../../styles/dashboards/hrdashboard/hrjoblisting.css";

function JobApplications() {
  let { name, filterType } = useParams();
  const changeFilter = filterType === "null" ? null : filterType;
  const [filter, setFilter] = useState(changeFilter || "");
  const [applications, setApplications] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 10; // Items per page

  const handleFilterChange = (e) => {
    const newFilter = e.target.value;
    setFilter(newFilter);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const fetchApplications = async () => {
      setLoading(true);
      const token = sessionStorage.getItem("accessToken");

      try {
        const params = { student_type: name };
        if (filter) {
          params.status = filter;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/admin_home_page_applicants/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params,
          }
        );

        setApplications(response.data.detailed_applications);
        setError(null);
      } catch (err) {
        console.error("Error fetching applications:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, [name, filter]);

  // Pagination logic
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = applications.slice(indexOfFirstJob, indexOfLastJob); // Paginated data

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        {error && <p className="error_message">{error}</p>}

        {/* Filter dropdown */}
        <div className="filter-container">
          <label htmlFor="filter" className="filter-label">
            Filter by Status:
          </label>
          <select
            id="filter"
            value={filter}
            onChange={handleFilterChange}
            className="application_filter_dropdown"
          >
            <option value="">All</option>
            <option value="discarded">Discarded</option>
            <option value="shortlisted">Shortlisted</option>
            <option value="in_review">In Review</option>
            <option value="offered">Offered</option>
            <option value="interviewed">Interviewed</option>
          </select>
        </div>

        {/* Applications Table */}
        <div className="w-100 d-flex align-items-center justify-content-center flex-column">
          <div className="hr_job_listing_table">
            {loading ? (
              <div className="loading_message">Loading applications...</div>
            ) : currentJobs.length === 0 ? (
              <div className="no_job_post_yet">No applications yet.</div>
            ) : (
              <table className="applications_table">
                <thead className="hr_job_listing_table_head">
                  <tr>
                    <th>S.No</th>
                    <th style={{ textAlign: "start" }}>Application ID</th>
                    <th style={{ textAlign: "start" }}>Student Name</th>
                    <th style={{ textAlign: "start" }}>Email</th>
                    <th style={{ textAlign: "start" }}>Job Title</th>
                    <th style={{ textAlign: "start" }}>Status</th>
                    <th style={{ textAlign: "start" }}>Applied At</th>
                    {filter == "offered" ? <th>Offer Letter</th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {currentJobs.map((application, index) => (
                    <tr key={application.application_id}>
                      <td>{(currentPage - 1) * jobsPerPage + index + 1}</td>
                      <td style={{ textAlign: "start" }}>
                        {application.application_id}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {application.student.name}{" "}
                        {application.student.student_last_name}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {application.student.email}
                      </td>
                      <td style={{ textAlign: "start" }}>{application.job}</td>
                      <td style={{ textAlign: "start" }}>
                        {application.status}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {new Date(application.applied_at).toLocaleDateString(
                          "en-GB"
                        )}
                      </td>

                      {filter == "offered" ? (
                        <td>
                          <button className="offer_letter_btn">
                            Offer Letter
                          </button>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {/* Pagination Component */}
          {applications.length > jobsPerPage && (
            <div className="d-flex justify-content-center pagination_custom">
              <Pagination
                count={Math.ceil(applications.length / jobsPerPage)}
                page={currentPage}
                color="primary"
                className="mt-3"
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobApplications;
