import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Pagination,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../../styles/admin/adminhome.css";

const CalendlySchedule = () => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(
    "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzM3MDMyMDI0LCJqdGkiOiJiY2VjMTZiOS1kNGViLTQyMTMtYTZmZC0yNTU3MjVjZjRlMWEiLCJ1c2VyX3V1aWQiOiJjZjE2MDVkYi1hN2RjLTRlNTYtYjZkNi1mYmFlMTBkNDlhNmYifQ.MkgEQMUBQiXd5IcDOSH9HozlfxGzRYvpNqioT8LMcSZRi7cXHd4011-xP5vIwUMcsdytlgiiMQBBC42k_8dwsQ"
  );
  const [userData, setUserData] = useState(null);
  const [scheduledEvents, setScheduledEvents] = useState([]);
  const [selectedEventInvitees, setSelectedEventInvitees] = useState([]);
  const [openInviteesDialog, setOpenInviteesDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const eventPerPage = 8;

  const handleTokenSubmit = async () => {
    try {
      // Fetch user data
      const userResponse = await axios.get(
        "https://api.calendly.com/users/me",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setUserData(userResponse.data.resource);

      // Fetch scheduled events
      const eventsResponse = await axios.get(
        `https://api.calendly.com/scheduled_events?user=${userResponse.data.resource.uri}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const sortedEvents = eventsResponse.data.collection.sort((a, b) => {
        const dateA = new Date(a.start_time);
        const dateB = new Date(b.start_time);
        return dateB - dateA;
      });
      setScheduledEvents(sortedEvents);
    } catch (error) {
      console.error("Error fetching Calendly data:", error);
      alert("Failed to fetch Calendly data. Check your access token.");
    }
  };

  const handleViewInvitees = async (eventUri) => {
    try {
      const inviteesResponse = await axios.get(`${eventUri}/invitees`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      setSelectedEventInvitees(inviteesResponse.data.collection);
      setOpenInviteesDialog(true);
    } catch (error) {
      console.error("Error fetching event invitees:", error);
      alert("Failed to fetch event invitees.");
    }
  };

  useEffect(() => {
    handleTokenSubmit();
  }, [accessToken]);

  // Pagination logic
  const indexOfLastEvent = currentPage * eventPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventPerPage;
  const currentEvents = scheduledEvents.slice(
    indexOfFirstEvent,
    indexOfLastEvent
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <h6>
          {" "}
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <path
              d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
              fill="#222222"
            />
            <path
              d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
              fill="#222222"
            />
          </svg>{" "}
          &nbsp; My Schedule
        </h6>
        <Container sx={{ maxWidth: "1400px !important" }}>
          <div className="welcome-message">
            {userData && (
              <div className="welcome-container">
                <div className="welcome-icon">🎉</div>
                <div className="welcome-text">
                  <h1>Welcome, {userData.name}!</h1>
                  <p>({userData.email})</p>
                </div>
              </div>
            )}
          </div>

          {scheduledEvents.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 3 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#011e39", color: "#fff" }}>
                    {[
                      "S No",
                      "Event Name",
                      "Start Time",
                      "End Time",
                      "Meeting URL",
                      "Actions",
                    ].map((header, index) => (
                      <TableCell key={index} sx={{ color: "#fff" }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentEvents.map((event, index) => (
                    <TableRow key={event.uri}>
                      <TableCell>
                        {" "}
                        <td>{(currentPage - 1) * eventPerPage + index + 1}</td>
                      </TableCell>
                      <TableCell>{event.name}</TableCell>
                      <TableCell>
                        {new Date(event.start_time).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {new Date(event.end_time).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {event?.location?.join_url ? (
                          <a
                            href={event?.location?.join_url}
                            target="_blank"
                            className="admin_join_meeting"
                            rel="noopener noreferrer"
                          >
                            Join Meeting
                          </a>
                        ) : (
                          "Not Found"
                        )}
                      </TableCell>

                      <TableCell>
                        <buttion
                          className="add_faq_btn"
                          onClick={() => handleViewInvitees(event.uri)}
                        >
                          View Invitees
                        </buttion>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Dialog
            open={openInviteesDialog}
            onClose={() => setOpenInviteesDialog(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>Event Invitees</DialogTitle>
            <DialogContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>

                      <TableCell>Phone</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedEventInvitees.map((invitee) => (
                      <TableRow key={invitee.uri}>
                        <TableCell>{invitee.name || "N/A"}</TableCell>
                        <TableCell>{invitee.email}</TableCell>
                        <TableCell>
                          {invitee.questions_and_answers?.find(
                            (q) => q.question === "Phone No"
                          )?.answer || "N/A"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          </Dialog>
        </Container>

        <div className="d-flex justify-content-center pagination_custom">
          <Pagination
            count={Math.ceil(scheduledEvents.length / eventPerPage)}
            page={currentPage}
            color="primary"
            className="mt-3"
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendlySchedule;
