import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import dummy_logo from '../../../../utils/landingpage/images/dummy_logo.png'
import Swal from "sweetalert2";
import { fetchStudentProfileData } from "../../../../redux/actions/student_profile_action";
import { fetchStudentProfileCompletion } from "../../../../redux/actions/student_profilePercentage";

const StudentCertificationInfo = () => {
    const [isSubmittedCertification, setIsSubmittedCertification] =
        useState(false);
    const [isEditModelOpenCertification, setIsEditModelOpenCertification] =
        useState(false);

    const [updatedData, setUpdatedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [editingValue, setEditingValue] = useState([]);

    const NewupdatedData = useSelector((state) => state.studentProfile.getStudentProfileData);
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        certificateCourseName: "",
        certificateInstitute: "",
        certificateType: "",
        specialization: "",
        certificateStartingYear: "",
        certificateEndingYear: "",
        certificateValidity: "",
        skills: "",
        certificates: [],
    });

    const [initialValuesSet, setInitialValuesSet] = useState({
        certificateCourseName: false,
        certificateInstitute: false,
        certificateStartingYear: false,
        certificateEndingYear: false,
        certificateValidity: false,
    });

    const [certificates, setCertificates] = useState(
        NewupdatedData?.certificates || []
    );

    const formatYearToDate = (year) => (year ? `${year}-01-01` : "");

    const submitCertification = (data) => {


        setCertificates(data.certificates);
        const accessToken = sessionStorage.getItem("accessToken");
        const userSessionData = sessionStorage.getItem("userDetails");

        const parsedData = JSON.parse(userSessionData);
        const userId = parsedData.id;
        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        setLoading(true)

        const url = `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`;

        axios
            .put(url, data, config)
            .then((response) => {
                toast.success("Added Successfully");
                setLoading(false)
                setUpdatedData(response.data);
                dispatch(fetchStudentProfileCompletion())
                dispatch(fetchStudentProfileData())

                setFormData({
                    certificateCourseName: "",
                    certificateInstitute: "",
                    certificateType: "",
                    specialization: "",
                    certificateStartingYear: "",
                    certificateEndingYear: "",
                    certificateValidity: "",
                });

            })
            .catch((error) => {
                toast.error(error);
                setLoading(false)
            });
    };

    const validateEditQualification = () => {
        const newErrors = {};

        const currentYear = new Date().getFullYear();

        const certificateStartingYear = formData.certificateStartingYear
            ? Number(formData.certificateStartingYear)
            : editingValue.course_from?.slice(0, 4)
                ? Number(editingValue.course_from?.slice(0, 4))
                : null;

        const certificateEndingYear = formData.certificateEndingYear
            ? Number(formData.certificateEndingYear)
            : editingValue.course_to?.slice(0, 4)
                ? Number(editingValue.course_to?.slice(0, 4))
                : null;

        // const certificateValidity = formData.certificateValidity
        //     ? Number(formData.certificateValidity)
        //     : null;

        const certificateValidity = formData.certificateValidity
            ? Number(formData.certificateValidity)
            : editingValue.valid_till?.slice(0, 4)
                ? Number(editingValue.valid_till?.slice(0, 4))
                : null;

        if (certificateStartingYear && certificateStartingYear > currentYear) {
            newErrors.certificateStartingYear = "Starting Year cannot be greater than the current year.";
        }

        if (certificateEndingYear && certificateEndingYear > currentYear) {
            newErrors.certificateEndingYear = "Ending Year cannot be greater than the current year.";
        }

        if (certificateStartingYear && certificateEndingYear && certificateStartingYear >= certificateEndingYear) {
            newErrors.certificateStartingYear = "Starting Year should not exceed Ending Year.";
        }

        if (certificateValidity && certificateValidity < currentYear) {
            newErrors.certificateValidity = "Certificate Validity should not be less than the current year.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleEditCertificationDeatils = (value, event) => {
        event.preventDefault();

        const validate = validateEditQualification(value)
        if (!validate) {
            return;
        }

        let editedCertificate = {};
        let newErrors = {};

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        if (formData.certificateCourseName) {
            editedCertificate.course_name = formData.certificateCourseName;
        }

        if (formData.certificateInstitute) {
            editedCertificate.course_institute = formData.certificateInstitute;
        }

        if (formData.certificateType) {
            editedCertificate.course_type = formData.certificateType;
        }

        if (formData.certificateStartingYear) {
            editedCertificate.course_from = formatYearToDate(
                formData.certificateStartingYear
            );
        }

        if (formData.certificateEndingYear) {
            editedCertificate.course_to = formatYearToDate(
                formData.certificateEndingYear
            );
        }

        if (formData.certificateValidity) {
            editedCertificate.valid_till = formatYearToDate(
                formData.certificateValidity
            );
        }

        editedCertificate.is_expired = false;

        if (Object.keys(editedCertificate).length === 0) {
            toast.info("No data to update");
            return;
        }

        const accessToken = sessionStorage.getItem("accessToken");

        setLoading(true)

        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/certification/${value}/`;

        axios
            .put(url, editedCertificate, config)
            .then((response) => {
                toast.success("Added Successfully");
                // setUpdatedData(response.data);
                // fetchData();
                setLoading(false)
                dispatch(fetchStudentProfileData())
                dispatch(fetchStudentProfileCompletion())
                setIsEditModelOpenCertification(false);
                setIsSubmittedCertification(true);

                setFormData({
                    certificateCourseName: "",
                    certificateInstitute: "",
                    certificateType: "",
                    specialization: "",
                    certificateStartingYear: "",
                    certificateEndingYear: "",
                    certificateValidity: "",
                });

                setInitialValuesSet({
                    certificateCourseName: false,
                    certificateInstitute: false,
                    certificateStartingYear: false,
                    certificateEndingYear: false,
                    certificateValidity: false,
                });

            })
            .catch((error) => {
                toast.error(error);
                setLoading(false)
            });
    };

    const handleAddCertificationDetails = (event) => {
        event.preventDefault();

        let newErrors = {};

        const currentYear = new Date().getFullYear();

        const {
            certificateCourseName = "",
            certificateInstitute = "",
            certificateType,
            certificateStartingYear,
            certificateEndingYear,
            certificateValidity,
        } = formData || {};

        if (!certificateCourseName.trim()) {
            newErrors.certificateCourseName = "Course name is required";
        }

        if (!certificateInstitute.trim()) {
            newErrors.certificateInstitute = "Institute name is required";
        }

        if (!certificateType) {
            newErrors.certificateType = "Course type is required";
        }

        if (!certificateStartingYear || certificateStartingYear.length !== 4) {
            newErrors.certificateStartingYear = "Valid starting year is required";
        } else if (parseInt(certificateStartingYear) > currentYear) {
            newErrors.certificateStartingYear = "Starting year cannot be greater than the current year";
        }

        if (!certificateEndingYear || certificateEndingYear.length !== 4) {
            newErrors.certificateEndingYear = "Valid ending year is required";
        } else if (parseInt(certificateEndingYear) < parseInt(certificateStartingYear)) {
            newErrors.certificateEndingYear = "Ending year cannot be before starting year";
        } else if (parseInt(certificateEndingYear) > currentYear) {
            newErrors.certificateEndingYear = "Ending year cannot be greater than the current year";
        }

        if (certificateValidity) {
            if (!/^\d{4}$/.test(certificateValidity)) {
                newErrors.certificateValidity = "Valid year is required (4 digits).";
            } else if (certificateValidity < currentYear) {
                newErrors.certificateValidity = "Certificate Validity should not be less than the current year.";
            }
        } else {
            newErrors.certificateValidity = "Certificate Validity is required.";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setIsSubmittedCertification(false);
            return;
        }

        setIsSubmittedCertification(true);
        setIsEditModelOpenCertification(false);

        const newCertificate = {
            course_name: certificateCourseName,
            course_institute: certificateInstitute,
            course_type: certificateType,
            specialization: formData.specialization,
            course_from: formatYearToDate(certificateStartingYear),
            course_to: formatYearToDate(certificateEndingYear),
            valid_till: formatYearToDate(certificateValidity),
            is_expired: false,
        };

        const previousCertificates = NewupdatedData?.certificates || [];
        const mergedCertificates = [...previousCertificates, newCertificate];

        const payload = {
            certificates: mergedCertificates,
        };

        submitCertification(payload);
    };

    const handleEditCertificationCancel = () => {
        setIsEditModelOpenCertification(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({ ...errors, [name]: "" });
    };

    const handleInputEditChange = (field, value) => {
        if (!initialValuesSet[field]) {
            setInitialValuesSet((prev) => ({ ...prev, [field]: true }));
        }
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const handleInputDateEditChange = (field, value) => {
        if (!initialValuesSet[field]) {
            setInitialValuesSet((prev) => ({ ...prev, [field]: true }));
        }
        const formattedValue = value.slice(0, 4);
        // placementOfficerEditFormik.setFieldValue(field, value);
        setFormData({
            ...formData,
            [field]: formattedValue,
        });
    };

    const handleDateInputChange = (e) => {
        const { name, value } = e.target;

        if (Number(value) < 0) {
            setErrors({ ...errors, [name]: "Year cannot be negative" });
            return;
        }

        const formattedValue = value.slice(0, 4);

        setFormData({
            ...formData,
            [name]: formattedValue,
        });
        setErrors({ ...errors, [name]: "" });
    };

    const handleEditCertification = (value) => {
        // setIsSubmittedCertification(false);
        setIsEditModelOpenCertification(!isEditModelOpenCertification);
        setEditingValue(value);
        setFormData({
            certificateCourseName: "",
            certificateInstitute: "",
            certificateType: "",
            specialization: "",
            certificateStartingYear: "",
            certificateEndingYear: "",
            certificateValidity: "",
        });
    };

    const handleDeleteCertification = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this Certification?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ffa500',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/certification/${id}/`;
                const accessToken = sessionStorage.getItem('accessToken');

                axios.delete(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                    .then(response => {
                        toast.success("Certification deleted successfully");
                        dispatch(fetchStudentProfileData())
                    })
                    .catch(error => {
                        toast.error("Something Went Wrong");
                    });
            }
        });
    }

    return (<>
        <div>
            <div className="pb-4">
                <p>{isEditModelOpenCertification ? `Edit Certifications Details` : `Add Certifications Details`}
                </p>
            </div>
            {isEditModelOpenCertification ? (
                <form
                    onSubmit={(event) =>
                        handleEditCertificationDeatils(editingValue.id, event)
                    }
                    className="epf-form-layout"
                >
                    <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                            <input
                                type="text"
                                id="certificateCourseName"
                                name="certificateCourseName"
                                placeholder="Course Name"
                                // value={
                                //     formData.certificateCourseName ||
                                //     editingValue.course_name
                                // }
                                // onChange={handleInputChange}
                                value={
                                    !initialValuesSet.certificateCourseName
                                        ? editingValue.course_name || ""
                                        : formData.certificateCourseName
                                }
                                onChange={(e) => handleInputEditChange("certificateCourseName", e.target.value)}
                                className="epf-input-field"
                            />

                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                            <select
                                id="certificateType"
                                name="certificateType"
                                value={
                                    formData.certificateType || editingValue.course_type
                                }
                                onChange={handleInputChange}
                                className="epf-input-field"
                            >
                                <option value="">Course Type</option>
                                <option value="fulltime">Full Time</option>
                                <option value="parttime">Part Time</option>
                                <option value="correspondence/distance">
                                    Correspondence/Distance learning
                                </option>
                            </select>
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                            <input
                                type="text"
                                id="certificateValidity"
                                name="certificateValidity"
                                placeholder="Valid Till"
                                // value={
                                //     formData.certificateValidity ||
                                //     editingValue.valid_till.slice(0, 4)
                                // }
                                // onChange={handleDateInputChange}
                                value={
                                    !initialValuesSet.certificateValidity
                                        ? editingValue.valid_till.slice(0, 4) || ""
                                        : formData.certificateValidity
                                }
                                onChange={(e) => handleInputDateEditChange("certificateValidity", e.target.value)}
                                className="epf-input-field"
                            />
                            {errors.certificateValidity && (
                                <span className="error-text">{errors.certificateValidity}</span>
                            )}
                        </div>
                    </div>

                    <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                            <input
                                type="text"
                                id="certificateInstitute"
                                name="certificateInstitute"
                                placeholder="Institute Name"
                                // value={
                                //     formData.certificateInstitute ||
                                //     editingValue.course_institute
                                // }
                                // onChange={handleInputChange}
                                value={
                                    !initialValuesSet.certificateInstitute
                                        ? editingValue.course_institute.slice(0, 4) || ""
                                        : formData.certificateInstitute
                                }
                                onChange={(e) => handleInputEditChange("certificateInstitute", e.target.value)}
                                className="epf-input-field"
                            />
                        </div>

                        <div className="d-flex gap-4">
                            <div className="epf-input-wrapper epf-input-gap">
                                <input
                                    type="number"
                                    id="certificateStartingYear"
                                    name="certificateStartingYear"
                                    placeholder="Starting Year"
                                    // value={
                                    //     formData.certificateStartingYear ||
                                    //     editingValue.course_from.slice(0, 4)
                                    // }
                                    // onChange={handleDateInputChange}
                                    value={
                                        !initialValuesSet.certificateStartingYear
                                            ? editingValue.course_from.slice(0, 4) || ""
                                            : formData.certificateStartingYear
                                    }
                                    onChange={(e) => handleInputDateEditChange("certificateStartingYear", e.target.value)}
                                    className="epf-input-field"
                                />
                                {errors.certificateStartingYear && (
                                    <span className="error-text">{errors.certificateStartingYear}</span>
                                )}
                            </div>

                            <div className="epf-input-wrapper epf-input-gap">
                                <input
                                    type="number"
                                    id="certificateEndingYear"
                                    name="certificateEndingYear"
                                    placeholder="Ending Year"
                                    // value={
                                    //     formData.certificateEndingYear ||
                                    //     editingValue.course_to.slice(0, 4)
                                    // }
                                    // onChange={handleDateInputChange}
                                    value={
                                        !initialValuesSet.certificateEndingYear
                                            ? editingValue.course_to.slice(0, 4) || ""
                                            : formData.certificateEndingYear
                                    }
                                    onChange={(e) => handleInputDateEditChange("certificateEndingYear", e.target.value)}
                                    className="epf-input-field"
                                />
                                {errors.certificateEndingYear && (
                                    <span className="error-text">{errors.certificateEndingYear}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex gap-4">
                        <button type="submit" className="epf-submit-btn mt-0">
                            {loading ? <div className="spinner"></div> : `Save`}
                        </button>
                        <button
                            onClick={handleEditCertificationCancel}
                            className="epf-submit-btn mt-0"
                            style={{
                                background: "transparent",
                                border: "1px solid rgba(243, 141, 0, 1)",
                                color: "rgba(243, 141, 0, 1)",
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            ) :
                (<form
                    onSubmit={handleAddCertificationDetails}
                    className="epf-form-layout"
                >
                    <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                            <input
                                type="text"
                                id="certificateCourseName"
                                name="certificateCourseName"
                                placeholder="Course Name"
                                value={formData.certificateCourseName}
                                onChange={handleInputChange}
                                className={`epf-input-field ${errors.certificateCourseName ? "input-error" : ""
                                    }`}
                            />
                            {errors.certificateCourseName && (
                                <span className="error-text">
                                    {errors.certificateCourseName}
                                </span>
                            )}
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                            <select
                                id="certificateType"
                                name="certificateType"
                                value={formData.certificateType}
                                onChange={handleInputChange}
                                className={`epf-input-field ${errors.certificateType ? "input-error" : ""
                                    }`}
                            >
                                <option value="">Course Type</option>
                                <option value="fulltime">Full Time</option>
                                <option value="parttime">Part Time</option>
                                <option value="correspondence/distance">
                                    Correspondence/Distance learning
                                </option>
                            </select>
                            {errors.certificateType && (
                                <span className="error-text">
                                    {errors.certificateType}
                                </span>
                            )}
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                            <input
                                type="text"
                                id="certificateValidity"
                                name="certificateValidity"
                                placeholder="Valid Till"
                                value={formData.certificateValidity}
                                onChange={handleDateInputChange}
                                className={`epf-input-field ${errors.certificateValidity ? "input-error" : ""
                                    }`}
                            />
                            {errors.certificateValidity && (
                                <span className="error-text">
                                    {errors.certificateValidity}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                            <input
                                type="text"
                                id="certificateInstitute"
                                name="certificateInstitute"
                                placeholder="Institute Name"
                                value={formData.certificateInstitute}
                                onChange={handleInputChange}
                                className={`epf-input-field ${errors.certificateInstitute ? "input-error" : ""
                                    }`}
                            />
                            {errors.certificateInstitute && (
                                <span className="error-text">
                                    {errors.certificateInstitute}
                                </span>
                            )}
                        </div>

                        <div className="d-flex gap-4">
                            <div className="epf-input-wrapper epf-input-gap">
                                <input
                                    type="number"
                                    id="certificateStartingYear"
                                    name="certificateStartingYear"
                                    placeholder="Starting Year"
                                    value={formData.certificateStartingYear}
                                    onChange={handleDateInputChange}
                                    className={`epf-input-field ${errors.certificateStartingYear ? "input-error" : ""
                                        }`}
                                />
                                {errors.certificateStartingYear && (
                                    <span className="error-text">
                                        {errors.certificateStartingYear}
                                    </span>
                                )}
                            </div>

                            <div className="epf-input-wrapper epf-input-gap">
                                <input
                                    type="number"
                                    id="certificateEndingYear"
                                    name="certificateEndingYear"
                                    placeholder="Ending Year"
                                    value={formData.certificateEndingYear}
                                    onChange={handleDateInputChange}
                                    className={`epf-input-field ${errors.certificateEndingYear ? "input-error" : ""
                                        }`}
                                />
                                {errors.certificateEndingYear && (
                                    <span className="error-text">
                                        {errors.certificateEndingYear}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="epf-submit-btn mt-0">
                        {loading ? <div className="spinner"></div> : `Save`}
                    </button>
                </form>)}
        </div>

        {NewupdatedData?.certificates?.length > 0 && (<div>
            <div className="pb-4 pt-4">
                <p>My Certifications Details
                </p>
            </div>
            {NewupdatedData?.certificates && NewupdatedData?.certificates?.map((value, index) => (
                (<div key={index} className="company-info student_company_info">
                    <div className="d-flex align-items-center gap-2">
                        <img src={dummy_logo} alt={`logo`} className="company-logo" />
                        <div>
                            <h2>{value.course_institute}</h2>
                            <h7>
                                {value.course_name}{' '}
                                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                    {`(${value.course_type === 'fulltime' ? 'full time' : value.course_type === 'parttime' ? 'part time' : value.course_type})`}
                                </span>
                            </h7>

                            <div className="d-flex gap-2 jdrc_class">
                                <div className="company-name">
                                    {value.course_from?.slice(0, 4)}-{value.course_to?.slice(0, 4)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                        <div onClick={() => handleDeleteCertification(value.id)} style={{ cursor: "pointer" }}>
                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z" fill="#F38D00" />
                                <path d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z" fill="#F38D00" />
                                <path d="M9.64788 16H4.87022C2.27302 16 2.16884 14.5637 2.08698 13.4028L1.60326 5.90889C1.58093 5.60378 1.81907 5.33587 2.12419 5.31355C2.43674 5.29866 2.69721 5.52936 2.71953 5.83448L3.20325 13.3284C3.28511 14.4596 3.31488 14.8837 4.87022 14.8837H9.64788C11.2107 14.8837 11.2404 14.4596 11.3149 13.3284L11.7986 5.83448C11.8209 5.52936 12.0888 5.29866 12.3939 5.31355C12.699 5.33587 12.9372 5.59634 12.9148 5.90889L12.4311 13.4028C12.3493 14.5637 12.2451 16 9.64788 16Z" fill="#F38D00" />
                                <path d="M8.49403 11.9069H6.0159C5.71079 11.9069 5.45776 11.6539 5.45776 11.3488C5.45776 11.0437 5.71079 10.7906 6.0159 10.7906H8.49403C8.79915 10.7906 9.05217 11.0437 9.05217 11.3488C9.05217 11.6539 8.79915 11.9069 8.49403 11.9069Z" fill="#F38D00" />
                                <path d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z" fill="#F38D00" />
                            </svg>
                        </div>

                        <div onClick={() => handleEditCertification(value)} style={{ cursor: "pointer" }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.1138 16H5.70051C1.70648 16 0 14.2935 0 10.2995V5.88618C0 1.89214 1.70648 0.185669 5.70051 0.185669H7.17161C7.47318 0.185669 7.72327 0.435756 7.72327 0.737331C7.72327 1.03891 7.47318 1.28899 7.17161 1.28899H5.70051C2.30963 1.28899 1.10332 2.49529 1.10332 5.88618V10.2995C1.10332 13.6904 2.30963 14.8967 5.70051 14.8967H10.1138C13.5047 14.8967 14.711 13.6904 14.711 10.2995V8.82838C14.711 8.5268 14.9611 8.27672 15.2627 8.27672C15.5642 8.27672 15.8143 8.5268 15.8143 8.82838V10.2995C15.8143 14.2935 14.1078 16 10.1138 16Z" fill="#222222" />
                                <path d="M5.33224 12.2782C4.88356 12.2782 4.47165 12.1163 4.17007 11.8221C3.80965 11.4617 3.65519 10.9395 3.7361 10.3878L4.05239 8.17379C4.11123 7.74718 4.39074 7.19551 4.69231 6.89394L10.4884 1.09781C11.9522 -0.365936 13.438 -0.365936 14.9017 1.09781C15.7035 1.89956 16.0639 2.71602 15.9904 3.53248C15.9242 4.19447 15.5711 4.84176 14.9017 5.50375L9.10561 11.2999C8.80404 11.6015 8.25237 11.881 7.82576 11.9398L5.61175 12.2561C5.51613 12.2782 5.42051 12.2782 5.33224 12.2782ZM11.2681 1.87749L5.472 7.67362C5.33224 7.81338 5.17042 8.13702 5.141 8.32826L4.82471 10.5423C4.79529 10.7556 4.83942 10.9321 4.94976 11.0424C5.06009 11.1528 5.23662 11.1969 5.44993 11.1675L7.66393 10.8512C7.85518 10.8218 8.18618 10.66 8.31857 10.5202L14.1147 4.72407C14.5928 4.24596 14.8429 3.81934 14.8797 3.42214C14.9238 2.94404 14.6737 2.43651 14.1147 1.87013C12.9378 0.693256 12.1287 1.02425 11.2681 1.87749Z" fill="#222222" />
                                <path d="M13.681 6.49694C13.6295 6.49694 13.578 6.48959 13.5339 6.47488C11.5994 5.93057 10.0621 4.39327 9.51776 2.45877C9.43685 2.16455 9.60602 1.86298 9.90024 1.77471C10.1945 1.6938 10.496 1.86298 10.5769 2.1572C11.0183 3.72392 12.2614 4.967 13.8281 5.40833C14.1223 5.48924 14.2915 5.79817 14.2106 6.09239C14.1444 6.34248 13.9237 6.49694 13.681 6.49694Z" fill="#222222" />
                            </svg>
                        </div>
                    </div>
                </div>
                )))}
        </div>)}

    </>)
};

export default StudentCertificationInfo;