import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import "../../../../styles/dashboards/hrdashboard/zoomguid.css";
import zoomguid1 from "../../../../utils/dashboards/zoom.png"; // Placeholder image
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHrProfileData,
  updateHrProfileData,
} from "../../../../redux/actions/hr_profile_action";

export default function ZoomMeeting() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    zoom_client_id: "",
    zoom_client_secret: "",
    zoom_account_id: "",
  });

  const profileData = useSelector((state) => state.hrProfile);
  const reduxProfileData = useSelector(
    (state) => state.hrProfile.getHrProfileData
  );

  const [openImageDialog, setOpenImageDialog] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleValidation = () => {
    if (
      !formData.zoom_client_id ||
      !formData.zoom_client_secret ||
      !formData.zoom_account_id
    ) {
      alert("Please fill in all the Zoom credentials.");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const isValid = handleValidation();
    if (isValid) {
      dispatch(updateHrProfileData(formData));
    }
  };

  const handleImageClick = () => {
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  useEffect(() => {
    dispatch(fetchHrProfileData());
  }, [dispatch]);

  useEffect(() => {
    if (reduxProfileData) {
      setFormData({
        zoom_client_id: reduxProfileData.zoom_client_id || "",
        zoom_client_secret: reduxProfileData.zoom_client_secret || "",
        zoom_account_id: reduxProfileData.zoom_account_id || "",
      });
    }
  }, [reduxProfileData]);

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="zoom_meeting_guide">
          <Typography variant="h6" gutterBottom>
            Zoom Meeting Credentials Setup
          </Typography>
          <Typography variant="body1" paragraph>
            To host a Zoom meeting, you need to provide the following
            credentials. Follow the steps below to obtain them from your Zoom
            Developer account.
          </Typography>

          <Typography variant="body2" paragraph>
            To learn how to create these credentials, refer to the official Zoom
            Developer documentation:
            <br />
            <a
              href="https://developers.zoom.us/docs/integrations/create/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Create Zoom API Keys
            </a>
          </Typography>

          <Box mb={3}>
            <Typography variant="body2" paragraph>
              1. <strong>Zoom Client ID</strong>: This is your API Client ID,
              available in your Zoom Developer account. It is used for
              authenticating the API requests you make to Zoom.
            </Typography>
            <Typography variant="body2" paragraph>
              2. <strong>Zoom Client Secret</strong>: This is your API Client
              Secret, also found in your Zoom Developer account. This secret
              helps secure your API requests.
            </Typography>
            <Typography variant="body2" paragraph>
              3. <strong>Zoom Account ID</strong>: This is your Zoom account ID,
              which can be found in your Zoom account settings under the
              "Profile" section.
            </Typography>
          </Box>

          <Grid container spacing={3}>
            {/* Zoom Client ID Section */}
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6">Zoom Client ID</Typography>
                <Typography variant="body2" paragraph>
                  Find this in your Zoom Developer account under the "App
                  Credentials" section.
                </Typography>
                <img
                  src={zoomguid1}
                  alt="Zoom Client ID"
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={handleImageClick}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="zoom_client_id"
                  label="Zoom Client ID"
                  variant="outlined"
                  value={formData.zoom_client_id}
                  onChange={handleChange}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6">Zoom Client Secret</Typography>
                <Typography variant="body2" paragraph>
                  Find this in your Zoom Developer account under the "App
                  Credentials" section.
                </Typography>
                <img
                  src={zoomguid1}
                  alt="Zoom Client Secret"
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={handleImageClick}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="zoom_client_secret"
                  label="Zoom Client Secret"
                  variant="outlined"
                  value={formData.zoom_client_secret}
                  onChange={handleChange}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6">Zoom Account ID</Typography>
                <Typography variant="body2" paragraph>
                  Find this in your Zoom Account Settings under "Profile".
                </Typography>
                <img
                  src={zoomguid1}
                  alt="Zoom Account ID"
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={handleImageClick}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="zoom_account_id"
                  label="Zoom Account ID"
                  variant="outlined"
                  value={formData.zoom_account_id}
                  onChange={handleChange}
                />
              </Paper>
            </Grid>
          </Grid>

          <Box mt={3}>
            <button
              className="submit_credential"
              onClick={handleSubmit}
              disabled={
                !formData.zoom_client_id ||
                !formData.zoom_client_secret ||
                !formData.zoom_account_id
              }
            >
              Submit Credentials
            </button>
          </Box>

          <Dialog
            open={openImageDialog}
            onClose={handleCloseImageDialog}
            fullWidth
            maxWidth="xl"
          >
            <DialogTitle>Zoom Image</DialogTitle>
            <DialogContent>
              <img
                src={zoomguid1}
                alt="Zoom Guide"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseImageDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Box mt={3}>
            <Typography variant="h6">Video Tutorial</Typography>
            <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/VyCVAKQC8NI"
              title="Zoom API Tutorial"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </div>
      </div>
    </div>
  );
}
