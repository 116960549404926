import React, { useState, useEffect } from "react";
import arrowImg from "../../../../../utils/landingpage/images/navigate.svg";
import { useNavigate } from "react-router-dom";
import TestimonialCard from "./TestimonialCard";
import TestimonialForm from "./TestimonialForm";
import axios from "axios";
import { toast } from "react-toastify";

function AllTestimonials() {
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingTestimonial, setEditingTestimonial] = useState(null);

  const fetchTestimonials = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/testimonials/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTestimonials(response.data);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
      toast.error("Failed to load testimonials");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const handleTestimonialAdded = () => {
    fetchTestimonials();
    setEditingTestimonial(null);
  };

  const handleEdit = (testimonial) => {
    setEditingTestimonial(testimonial);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const goToBack = () => {
    navigate(-1);
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="clientTestimonial_header">
          <h6>
            <img
              onClick={goToBack}
              className="arrow_icon"
              src={arrowImg}
              alt=".."
            />
            &nbsp; {editingTestimonial ? 'Edit Testimonial' : 'All Client Testimonials'}
          </h6>
        </div>
        {editingTestimonial && (
          <TestimonialForm 
            onSuccess={handleTestimonialAdded}
            editData={editingTestimonial}
          />
        )}
        <div className="client_testimonial_card_containers">
          {loading ? (
            <div>Loading testimonials...</div>
          ) : (
            <TestimonialCard 
              testimonials={testimonials}
              onDelete={handleTestimonialAdded}
              onEdit={handleEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AllTestimonials;
