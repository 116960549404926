import React from "react";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "../../../styles/landing_page/landingpage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightarrow from "../../../utils/landingpage/images/rightarrow.svg";
import leftarrow from "../../../utils/landingpage/images/leftarrow.svg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={leftarrow} style={{ height: "31px" }} />
    </div>
  );
};

export const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={rightarrow} style={{ height: "31px" }} />
    </div>
  );
};

const Testimonial = () => {
  const [allTestimonials, setAllTestimonials] = useState([]);
  const location = useLocation();

  const TestimonialData = useSelector((state) => state.letralHiring?.alltestimonialsRedux);
  const DigitalTestimonialData = useSelector((state) => state.digitalCampusHiring?.digitalTestimonialData)

  useEffect(() => {
    if (location.pathname.includes("lateral") && TestimonialData) {
      setAllTestimonials(TestimonialData);
    } else {
      setAllTestimonials(DigitalTestimonialData);
    }
  }, [location.pathname, TestimonialData, DigitalTestimonialData]);

  const isSingleSlide = allTestimonials.length === 1;

  const testimonial = {
    dots: false,
    infinite: !isSingleSlide,
    arrows: true,
    slidesToShow: isSingleSlide ? 1 : 2,
    slidesToScroll: isSingleSlide ? 1 : 2,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: !isSingleSlide,
        },
      },
    ],
  };

  // const testimonial = {
  //   dots: true,
  //   infinite: true,
  //   arrows: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   pauseOnHover: true,
  //   nextArrow: <CustomNextArrow />,
  //   prevArrow: <CustomPrevArrow />,
  // };

  return (
    <>
      <section className="testimonial_main">
        <div className="testimonial_container">
          <motion.div
            className="testimonial_heading"
            initial={{ y: -60, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <h3>What our client say about us.</h3>
            <h4>See what our user are saying. </h4>
          </motion.div>
          <motion.div
            className="slider-container"
            initial={{ y: 60, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Slider {...testimonial}>
              {allTestimonials && allTestimonials?.map((testimonial) => (
                <div
                  key={testimonial.id}
                  className="testimonial_slider_container"
                >
                  <div className="testimonial_hero_section">
                    <div className="testimonial_image">
                      <div className="testimonial_img_background"></div>
                      <div className="testimonial_user_image">
                        <img
                          src={testimonial?.client_photo ||testimonial?.student_photo_url}
                          alt='user image'
                        />
                      </div>
                    </div>

                    <div className="testimonial_content">
                      <p>{testimonial?.testimonial_description || testimonial.review_description}</p>
                      <h5>{testimonial?.client_name || testimonial.first_name} {testimonial.last_name || ""}</h5>
                      <h6>{testimonial?.position}</h6>
                      <h6>Rating: {`${testimonial?.rating}/5`}</h6>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;