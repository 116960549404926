import React, { useState } from "react";
import arrowImg from "../../../../utils/landingpage/images/navigate.svg";
import "../../../../styles/admin/adminplan.css";
import threeDot from "../../../../utils/landingpage/images/three-dots-svgrepo-com.svg";
import { IconButton, Menu, MenuItem } from "@mui/material";
import "../../../../styles/dashboards/hrdashboard/transection.css";
import { useNavigate } from "react-router-dom";

function AdminPlans() {
  const [activeCard, setActiveCard] = useState(0);
  const navigate = useNavigate();
  const ITEM_HEIGHT = 36;
  const [anchorEls, setAnchorEls] = useState({});
  const handleClick = (event, panId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [panId]: event.currentTarget,
    }));
  };

  const handleClose = (panId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [panId]: null,
    }));
  };

  const goToBack = () => {
    navigate(-1);
  };

  const plans = [
    {
      id: 1,
      title: "Premium Plan",
      price: "₹ 1599 /month",
      features: [
        "50,000 Visitors",
        "Create Unlimited Widgets",
        "CMS Integration",
        "All Widget Types",
        "Integrations",
      ],
    },
    {
      id: 2,
      title: "Standard Plan",
      price: "₹ 999 /month",
      features: [
        "50,000 Visitors",
        "Create Unlimited Widgets",
        "CMS Integration",
        "All Widget Types",
        "Integrations",
      ],
    },
    {
      id: 3,
      title: "Basic Plan",
      price: "₹ 599 /month",
      features: [
        "50,000 Visitors",
        "Create Unlimited Widgets",
        "CMS Integration",
        "All Widget Types",
        "Integrations",
      ],
    },
  ];

  const handleCardClick = (id) => {
    setActiveCard(id);
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="admin_plan_header">
          <h6>
            <img
              className="arrow_icon"
              onClick={goToBack}
              src={arrowImg}
              alt=".."
            />
            &nbsp; Subscription Plans
          </h6>
          <button className="add_plan_btn">Add Plan</button>
        </div>
        <div className="admin_plan_card_container">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`card ${activeCard === plan.id ? "active" : ""}`}
              onClick={() => handleCardClick(plan.id)}
            >
              <div className="card_header">
                <div>{plan.title}</div>
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={anchorEls[plan.id] ? "long-menu" : undefined}
                    aria-expanded={anchorEls[plan.id] ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, plan.id)}
                  >
                    <img src={threeDot} height={18} width={18} alt=".." />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEls[plan.id]} // Now specific to the user
                    open={Boolean(anchorEls[plan.id])}
                    onClose={() => handleClose(plan.id)}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 3.5,
                        width: "12ch",
                      },
                    }}
                  >
                    <MenuItem>Edit</MenuItem>
                    <MenuItem>Delete</MenuItem>
                  </Menu>
                </div>
              </div>
              <div className="card_price">{plan.price}</div>
              <ul className="card_features">
                {plan.features.map((feature, idx) => (
                  <li key={idx}>{feature}</li>
                ))}
              </ul>
              <div className="card_footer">
                <button className="save_btn">Save</button>
              </div>
            </div>
          ))}
        </div>

        {/* --------------------- */}

        <div>
          <div className="admin_plan_header mt-4">
            <h6>Recent Transaction</h6>
          </div>

          <div className="sub_hr_userlist_job_listing_table">
            <table>
              <thead className="sub_hr_userlist_job_listing_table_head">
                <tr>
                  <th>Date</th>
                  <th>Transaction ID</th>
                  <th>Plan Purchased</th>
                  <th>Amount Paid</th>
                  <th>Payment Method</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <td>31-07-24</td>
                <td>123456789</td>
                <td>Basic Plan</td>
                <td>499</td>
                <td>UPI</td>
                <td>Completed</td>
                <td style={{ color: "orange", cursor: "pointer" }}>view</td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPlans;
