import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Overall statistics
  totalCompanies: 0,
  totalColleges: 0,
  totalStudents: 0,
  totalJobs: 0,
  totalApplications: 0,

  // Application status counts
  applicationStatus: {
    inReview: 0,
    interviewed: 0,
    shortlisted: 0,
    declined: 0,
    offered: 0,
  },

  // Lateral hiring data
  lateralHiring: {
    studentsCount: 0,
    jobsCount: 0,
    applicationsCount: 0,
    inReviewCount: 0,
    interviewedCount: 0,
    shortlistedCount: 0,
    declinedCount: 0,
    offeredCount: 0,
  },

  // Campus hiring data
  campusHiring: {
    studentsCount: 0,
    jobsCount: 0,
    applicationsCount: 0,
    inReviewCount: 0,
    interviewedCount: 0,
    shortlistedCount: 0,
    declinedCount: 0,
    offeredCount: 0,
  },

  // Upcoming drives
  upcomingDrives: [],
  upcomingDrivesCount: 0,

  activeApplication: "",

  loading: false,
  error: null,
};

const superAdminSlice = createSlice({
  name: "superAdmin",
  initialState,
  reducers: {
    fetchDashboardStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDashboardSuccess: (state, action) => {
      const data = action.payload;

      // Update overall statistics

      state.defaultCountStatus = {
        totalCompanies: data.total_companies_count,
        totalColleges: data.total_colleges_count,
        totalStudents: data.total_students_count,
        totalJobs: data.total_jobs_count,
        totalApplications: data.total_applications_count,
      };

      // Update application status counts
      state.applicationStatus = {
        inReview: data.total_inreview_applications_count,
        interviewed: data.total_interviewed_applications_count,
        shortlisted: data.total_shortlisted_applications_count,
        declined: data.total_declined_applications_count,
        offered: data.total_offered_applications_count,
      };

      // Update lateral hiring data
      state.lateralHiring = {
        studentsCount: data?.lateral_data?.students_count,
        jobsCount: data?.lateral_data?.jobs_count,
        applicationsCount: data.lateral_data.applications_count,
        inReviewCount: data.lateral_data.inreview_count,
        interviewedCount: data.lateral_data.interviewed_count,
        shortlistedCount: data.lateral_data.shortlisted_count,
        declinedCount: data.lateral_data.declined_count,
        offeredCount: data.lateral_data.offered_count,
      };

      // Update campus hiring data
      state.campusHiring = {
        studentsCount: data.campus_data.students_count,
        jobsCount: data.campus_data.jobs_count,
        applicationsCount: data.campus_data.applications_count,
        inReviewCount: data.campus_data.inreview_count,
        interviewedCount: data.campus_data.interviewed_count,
        shortlistedCount: data.campus_data.shortlisted_count,
        declinedCount: data.campus_data.declined_count,
        offeredCount: data.campus_data.offered_count,
      };

      // Update upcoming drives
      state.upcomingDrives = data.upcoming_drives;
      state.upcomingDrivesCount = data.total_upcoming_drives_count;
      state.recentJobs = data.recent_lateral_jobs;
      state.loading = false;
    },
    fetchDashboardFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setActiveApplication: (state, action) => {
      state.activeApplication = action.payload;
    }
  },
});

export const {
  fetchDashboardStart,
  fetchDashboardSuccess,
  fetchDashboardFailure,
  setActiveApplication
} = superAdminSlice.actions;

export default superAdminSlice.reducer;