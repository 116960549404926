import React from "react";
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        textAlign: "center",
        padding: "50px",
        height: "90vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <button
        onClick={() => {
          navigate(-1);
        }}
        style={{
          background: "#F38D00",
          border: "none",
          padding: "9px",
          marginTop: "15px",
          fontWeight: "700",
          borderRadius: "12px",
          textDecoration: "none",
          color: "#ffff",
        }}
      >
        Go Back
      </button>
    </div>
  );
};

export default NotFound;
