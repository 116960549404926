import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/landing_page/lateralhiring.css";
import potentialimg from "../../../utils/landingpage/images/potentialimg.svg";
import searchIcon from "../../../utils/landingpage/images/search-icon.svg";
import Mphasis from "../../../utils/landingpage/images/MPHASIS-logo.svg";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png";
import dolorIocn from "../../../utils/landingpage/images/dollerIcon.png";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import Faq from "../../common_component/faq/faq.jsx";
import Counter from "../../common_component/counter/counter.jsx";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import Thinkabout from "../../common_component/thinkabout/thinkabout.jsx";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLetralHiringJobsData } from "../../../redux/reducers/letralHiringReducer";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { fetchLetralHiringLandingPage } from "../../../redux/actions/letralHiringAction/index.js";

const LateralHiring = () => {
  const [startCounting, setStartCounting] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [newData, setNewData] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [alltestimonials, setAllTestimonials] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [popularJobs, setPopularJobs] = useState([]);
  const [topcompany, setTopCompany] = useState([]);
  const [countersData, setCountersData] = useState([]);
  const [searchTitle, setsearchTitle] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [selectedExperienceRange, setSelectedExperienceRange] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const TopcompanyData = useSelector(
    (state) => state.letralHiring.topcompanyRedux
  );
  const PopularJobsData = useSelector(
    (state) => state.letralHiring.popularJobsRedux
  );
  const ReduxLoading = useSelector(
    (state) => state.letralHiring.loadingLetralLanding
  );

  const handleSearch = async () => {
    let min_experience = null;
    let max_experience = null;

    if (selectedExperienceRange) {
      const [min, max] = selectedExperienceRange.split("-").map(Number);
      min_experience = min;
      max_experience = max;
    }

    const url = `${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/?min_experience=${min_experience || ""
      }&max_experience=${max_experience || ""
      }&job_location=${searchLocation}&title=${searchTitle}`;

    try {
      const response = await axios.get(url);

      dispatch(
        setLetralHiringJobsData(response.data?.length > 0 ? response.data : [])
      );

      navigate("/lateral/alljobs");
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const jobData = {
    jobs: [
      {
        image: Mphasis,
        company: "Mphasis",
        title: "Junior Frontend Developer",
        type: "(Full Time)",
        location: "Indore, Madhya Pradesh",
        salary: "Upto 5lpa",
      },
    ],
  };

  useEffect(() => {
    setStartCounting(true);
    setJobs(jobData.jobs);
  }, []);

  const formatNumber = useCallback((num) => {
    if (num >= 1000) {
      return `${(num / 1000).toFixed(0)}k +`;
    }
    return num;
  }, []);

  const itemsPerPage = 6;

  const totalPages = Math.ceil(topcompany?.length / itemsPerPage);

  const currentItems = topcompany?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (TopcompanyData) {
      setTopCompany(TopcompanyData);
    } else {
      console.warn(
        "Top Companies data is missing in the response:",
        TopcompanyData
      );
    }
    if (PopularJobsData) {
      setPopularJobs(PopularJobsData);
    } else {
      console.warn(
        "Popular Jobs data is missing in the response:",
        PopularJobsData
      );
    }
  }, [TopcompanyData, PopularJobsData]);

  // useEffect(() => {

  //   const fetchData = async () => {
  //     try {
  //       setLoading(true)
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}/api/landing_page/`
  //       );

  //     } catch (error) {
  //       toast.error("Error fetching data:", error.message);
  //     }
  //     finally {
  //       setLoading(false)
  //     }
  //   };

  //   fetchData();

  // }, []);

  return (
    <>
      {ReduxLoading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ClipLoader color="#FFA500" size={60} />
        </div>
      ) : (
        <>
          <section className="lateral_main">
            <div className="hero_container">
              <div className="hero_row letral-hiring-row">
                <div className="letral_content">
                  <h1>
                    Unlock Your Potential
                    <br />
                    With <span>Start Naukri</span>
                  </h1>
                  <p>
                    Discover your next career move with StartNaukri, the go-to
                    job
                    <br />
                    marketplace for job seekers and employers for life time.
                  </p>
                  {/* <div className="hero_content_btn">
                <button>Book a Demo</button>
              </div> */}
                  <div className="box">
                    <div className="search-box">
                      <img src={searchIcon} alt="search icon" />
                      <input
                        type="text"
                        className="search-input"
                        placeholder="Search Job"
                        value={searchTitle}
                        onChange={(e) => setsearchTitle(e.target.value)}
                      />
                      <select
                        className="search-select"
                        onChange={(e) =>
                          setSelectedExperienceRange(e.target.value)
                        }
                      >
                        <option value="">Experience</option>
                        <option value="0-1">0-1 years</option>
                        <option value="1-3">1-3 years</option>
                        <option value="3-5">3-5 years</option>
                        <option value="5-7">5-7 years</option>
                        <option value="7-30">7 +</option>
                      </select>
                      <input
                        type="text"
                        className="search-location"
                        placeholder="Location"
                        value={searchLocation}
                        onChange={(e) => setSearchLocation(e.target.value)}
                      />
                      <button className="search-button" onClick={handleSearch}>
                        Search
                      </button>
                    </div>
                  </div>

                  <div className="Popular-Search">
                    <span>Explore Opportunities: </span>
                    <span className="alljobs_popular_search">
                      Job Title, Experience Level, Location
                    </span>
                  </div>

                  {/* <div className="Popular-Search">
                    <span>Popular Search :</span> Frontend Developer, UI/UX
                    Designer, Backend Developer
                  </div> */}
                </div>
                <div className="letral-image-hero">
                  <img src={potentialimg} alt="Potential_Image" />
                </div>
              </div>
            </div>
          </section>
          {/* Hero Section Ends */}
          <Counter />
          {/* Counter Section Ends */}

          <section className="popular-jobs-main">
            <header className="text-center mb-5">
              <h1>Popular Jobs</h1>
              <p>
                Find the best paying jobs based on the job category, 100% safe
                recruitment process
                <br /> with transparent progress and real-time assessment.
              </p>
            </header>
            <div className="container">
              <div className="row ">
                {popularJobs?.slice(0, 8)?.map((job, index) => (
                  <div
                    key={index}
                    className="lateral-hiring-card-container col-lg-4 col-xl-3 col-md-6 gap-2 mt-3 col-sm-6  col-12 "
                  >
                    <div className="job-card">
                      <div className="company-header">
                        <img
                          src={
                            job.company_profile?.company_logo_url || dummy_logo
                          }
                          alt={`${job.company} logo`}
                          className="company-logo"
                          id="company-logo-id"
                        />
                        <h5 className="card-company-name">
                          {job?.company?.length > 10
                            ? `${job?.company.slice(0, 10)}..`
                            : job?.company || "Not Mentioned"}
                        </h5>
                      </div>
                      <p className="card-job-title">
                        {job.title?.length > 25
                          ? `${job.title.slice(0, 22)}..`
                          : job.title || "Not Mentioned"}
                      </p>
                      <p className="card-job-type">
                        <small>{`(${job.type})` || "Not Mentioned"}</small>
                      </p>
                      <div className="job-info">
                        <p className="card-text">
                          <span>
                            <img
                              src={locationIcon}
                              width="9px"
                              height="13px"
                              alt="..."
                            />
                          </span>
                          <small className="small">
                            <i className="bi bi-geo-alt"></i>{" "}
                            {job.job_location || "Not Mentioned"}
                          </small>
                        </p>
                        <p className="card-text">
                          <span>
                            <img
                              src={dolorIocn}
                              width="11px"
                              height="12px"
                              alt="..."
                            />
                          </span>
                          <small>
                            <i className="bi bi-currency-dollar"></i>{" "}
                            {job.min_salary
                              ? `${job.min_salary} lpa`
                              : "Not Mentioned"}
                            {" - "}
                            {job.max_salary ? `${job.max_salary} lpa` : ""}
                          </small>
                        </p>

                        <Link
                          to={`/lateral/jobDetails/${job.id}`}
                          className="more-details"
                        >
                          More Details
                        </Link>

                        <Link to={`/lateral/jobDetails/${job.id}`}>
                          <button className="job-apply-btn w-100 rounded-3 mt-2">
                            Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate("/lateral/alljobs")}
                className="rounded-3 btn btn_view_all_jobs"
              >
                View all jobs
              </button>
            </div>
          </section>

          <section className="job_seekers_main">
            <div className="job_seekers_overlay"></div>
            <div className="job_seekers_container">
              <div className="job_seekers_container_semi">
                <motion.div
                  className="job_seekers_container_content"
                  initial={{ y: -60, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <h2>Ready to take your career to the next level?</h2>
                </motion.div>
                <motion.div
                  className="job_seekers_container_description"
                  initial={{ y: 0, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 2 }}
                >
                  <b>
                    Find the best paying jobs based on the job category, 100%
                    safe recruitment process with transparent progress and
                    real-time assessment.
                  </b>
                </motion.div>
              </div>
            </div>
          </section>

          <section className="companies-main">
            <header>
              <h1>Top Companies</h1>
              <p>
                Find the top companies, 100% safe recruitment process with
                transparent progress <br />
                and real-time assessment.
              </p>
            </header>
            <div className="companies-container">
              <div className="row min-height-container">
                {currentItems?.length > 0 ? (
                  currentItems?.map((req, index) => (
                    <div
                      key={index}
                      className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4"
                    >
                      <div className="company-card">
                        <div className="compay">
                          <img
                            src={req.company_logo_url || dummy_logo}
                            alt="logo"
                            className="company-logo"
                            id="top-company-logo-id"
                            style={{ borderRadius: "15px" }}
                          />
                          <div className="card-body company-info">
                            <h5 className="card-title">
                              {" "}
                              {req.company_name?.length > 30
                                ? `${req.company_name.slice(0, 30)}..`
                                : req.company_name || "Not Mentioned"}
                            </h5>
                          </div>
                        </div>
                        <b className="position">
                          {req.open_positions} Open Positions
                        </b>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center w-100 py-5">
                    <p>No companies to display</p>
                  </div>
                )}
              </div>
              {topcompany?.length > itemsPerPage && (
                <div className="pagination-controls">
                  <Stack spacing={2} alignItems="center">
                    <Pagination
                      className="custom-pagination"
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      siblingCount={1}
                      boundaryCount={1}
                      renderItem={(item) => (
                        <PaginationItem
                          components={{
                            previous: () => (
                              <img src={leftArrowIcon} alt="prev" />
                            ),
                            next: () => <img src={rightIconArrow} alt="next" />,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Stack>
                </div>
              )}
            </div>
          </section>

          <Thinkabout />

          <Faq />
        </>
      )}
    </>
  );
};

export default LateralHiring;
