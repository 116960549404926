import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Loader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDispatch } from "react-redux";
import { fetchStudentProfileCompletion } from "../../../redux/actions/student_profilePercentage";

const CampusJobDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [jobId, setJobId] = useState(null);
  const [token, setToken] = useState(null);
  const [jobDetails, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [studentProfileCompletion, setStudentProfileCompletion] = useState(0);
  const [error, setError] = useState("");
  const [isJobValid, setIsJobValid] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);

  const [skills, setSkills] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const dispatch = useDispatch()

  // const handleSend = () => {
  //   setEmail("");
  //   setUrl("");
  //   closeModal();
  // };

  const handleSend = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Enter a valid email");
      return;
    }

    setError("");

    const token = sessionStorage.getItem("accessToken");
    if (!token) {
      toast.error("Authentication failed. Please log in again.");
      return;
    }

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/share_job/`;

    const requestBody = {
      'email': email,
      'job_url': url
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    try {
      setIsEmailLoading(true);

      const response = await axios.post(apiUrl, requestBody, config);

      if (response.status === 200) {
        toast.success("Job shared successfully!");
        setEmail(""); 
      } else {
        toast.error("Failed to share job. Please try again.");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong. Please try again.");
    } finally {
      setIsEmailLoading(false);
      closeModal();
    }
  };

  useEffect(() => {
    const currentOrigin = window.location.origin;
    const currentPath = window.location.pathname;

    const pathSegments = currentPath.split("/").filter(segment => segment !== "");

    const jobId = pathSegments[pathSegments?.length - 1];

    const publicUrl = `${currentOrigin}/public/job-details/${jobId}`;
    setUrl(publicUrl);

  }, []);

  useEffect(() => {
    dispatch(fetchStudentProfileCompletion())
  }, [dispatch]);

  const UserPercenatageValue = useSelector((state) => state.studentProfilePercentage.studentProfilePercentageValue);

  useEffect(() => {
    setJobId(id);

    const newSession = sessionStorage.getItem("accessToken");
    setToken(newSession);
  }, [id]);

  const DeletedJobDetails = async () => {
    if (jobId) {
      setGlobalLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/`
        );

        if (response.status === 200) {
          setDetails(response.data)
          setSkills(response.data.job_skills);
        } else {
          toast.error("Failed to retrieve job details.");
        }
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          if (status === 404) {
            toast.error("Job not found.");
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        } else {
          toast.error("Network error. Please check your connection.");
        }
      } finally {
        setGlobalLoading(false)
      }
    }
    else {
      toast.error("Job Id Not Found!")
    }
  };

  useEffect(() => {
    if (jobId || token) {
      const userToken = token;

      setGlobalLoading(true)

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/campus-jobs/${jobId}/`,

          {
            headers: {
              Authorization: userToken && `Bearer ${userToken}`,
            },
          }
        )
        .then((response) => {
          setDetails(response.data);
          setSkills(response.data.job_skills);
          setGlobalLoading(false)
        })
        .catch((error) => {
          setGlobalLoading(false)
          if (error.response && error.response.status === 404) {
            setIsJobValid(true)
            toast.warn("Job has been expired!");
            DeletedJobDetails();
          }
        });
    } else {
      console.warn("Job has been expired!");
    }
  }, [jobId, token]);

  const handleApplyJob = async (jobId) => {

    if (UserPercenatageValue < 91) {
      Swal.fire({
        title: 'Profile Incomplete',
        text: 'Complete your profile up to 90% before applying.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Complete Now',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'custom-swal-confirm-button',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/dashboard/sidebar-page/student/student-profile');
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.close();
        }
      });
      return;
    }

    if (jobId) {
      try {
        setLoading(true)

        const accessToken = sessionStorage.getItem('accessToken');

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/apply/`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
        setLoading(false)
        if(response.data?.detail === "You have already applied for this job."){
          toast.error("You have already applied for this job.")
        }
        else{
          toast.success(response.data?.detail || 'Application submitted successfully!');
        }
      } catch (error) {
        setLoading(false)
        const errorMessage = error.response
          ? error.response.data.detail || 'Application failed. Please try again.'
          : error.message;

        toast.error(errorMessage);
      }
    } else {
      toast.info('Job ID is missing.');
      setLoading(false)
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date).toUpperCase();
  };

  const handleDownloadPDF = () => {
    if (!jobDetails) {
      toast.error("No job details available to download.");
      return;
    }

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text(`Job Details ( ${jobDetails.title} )`, 14, 20);

    const details = [
      ["Title", jobDetails.title || "Not Mentioned"],
      ["Company Name", jobDetails.company_name || "Not Mentioned"],
      ["Description", jobDetails.description || "Not Mentioned"],
      ["Role", jobDetails.title || "Not Mentioned"],
      ["Status", jobDetails.status || "Not Mentioned"],
      ["Industry Type", jobDetails.job_category || "Not Mentioned"],
      ["Employment Type", jobDetails.type || "Not Mentioned"],
      ["Education", jobDetails.qualification || "Not Mentioned"],
      ["Vacancy", jobDetails.vacancy || "Not Mentioned"],
      ["Job Posted", formatDate(jobDetails.created_at) || "Not Mentioned"],
      ["Job Expiry", formatDate(jobDetails.end_date) || "Not Mentioned"],
      [
        "Salary Range",
        jobDetails.min_salary && jobDetails.max_salary
          ? `${parseFloat(jobDetails.min_salary)} - ${parseFloat(jobDetails.max_salary)} LPA`
          : "Not Mentioned",
      ],
      [
        "Experience",
        jobDetails.min_experience && jobDetails.max_experience
          ? `${parseFloat(jobDetails.min_experience)} - ${parseFloat(jobDetails.max_experience)} Years`
          : "Not Mentioned",
      ],
      ["Location", jobDetails.job_location || "Not Mentioned"],
      ["Key Skills", skills?.length > 0 ? skills?.map((s) => s.skill_name)?.join(", ") : "Not Mentioned"],
      ["Job Responsibility", jobDetails.rensponsibility || "Not Mentioned"],
      ["HR Email", jobDetails.company || "Not Mentioned"],
    ];

    doc.autoTable({
      startY: 30,
      head: [["Field", "Details"]],
      body: details,
    });

    doc.save("job-details.pdf");
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        {globalLoading ? (
          <div className="custom_loader">
            <Loader color="#F38D00" size={50} />
          </div>
        ) : (<div className="_main_content_inner_screen_container">
          <div className="job-detail-page">
            <div className="job-detail-header">
              <button
                className="job-detail-back-button"
                onClick={() => window.history.back()}
              >
                <img src={leftArrowIcon} alt="Back" />
              </button>
              <h5>Job Details</h5>
              {!location.pathname.includes('student/job-details') && (<div className="job-detail-Download-Pdf">
                <button onClick={handleDownloadPDF}>Download Pdf</button>
              </div>)}
            </div>
            <div className="job-detail-summary">
              <div className="job-left">
                <img
                  src={jobDetails.company_profile?.company_logo_url || dummy_logo}
                  alt='logo'
                  className="company-logo"
                  style={{ marginRight: "13px" }}
                />
                <div className="job-info">
                  <div className="d-flex align-items-center gap-4">
                    <h6>{jobDetails.title || 'Not Mentioned'}</h6>
                    {jobDetails?.status === "LIVE" ? (<button className="job_list_job_active">Live</button>) : (<button className="job_list_job_active job_list_job_closed">Expired</button>)}
                  </div>
                  <p>{jobDetails.company_name || 'Not Mentioned'}</p>
                </div>
              </div>
              <div className="job-detail-right">
                <span>
                  <h6>Posted date</h6>{jobDetails?.created_at ? formatDate(jobDetails.created_at) : "Not Mentioned"}
                </span>
              </div>
              {location.pathname.includes('student/job-details') && !isJobValid && (<div>
                <div className="job-detail-Download-Pdf">
                  <button disabled={jobDetails?.status !== "LIVE"} className={jobDetails?.status !== "LIVE" ? 'job_closed_button' : ""} onClick={() => handleApplyJob(jobDetails.id)}> {loading ? <div className="spinner"></div> : "Apply Job"} </button>
                </div>
              </div>
              )}

              {isJobValid && (<div>
                <div className="job-detail-Download-Pdf">
                  <button className="jobDetails_button disabled_button" disabled > Job Closed </button>
                </div>
              </div>
              )}

            </div>
            <div className="job-detail-description">
              <h5>Job Description</h5>
              <p>{jobDetails.description || "Not Mentioned"}</p>
              <h6>
                Role: <p>{jobDetails.title || "Not Mentioned"}</p>
              </h6>
              <h6>
                Status: <p>{jobDetails.status || "Not Mentioned"}</p>
              </h6>
              <h6>
                Job Category: <p>{jobDetails.job_category_name || jobDetails.job_category || "Not Mentioned"}</p>
              </h6>
              <h6>
                Employment Type: <p>{jobDetails.type || "Not Mentioned"}</p>
              </h6>
              <h6>
                Education: <p>{jobDetails.qualification || "Not Mentioned"}</p>
              </h6>
              <h6>
                Salary Range:
                <p>
                  {jobDetails.min_salary
                    ? `${parseFloat(jobDetails.min_salary)}`
                    : "Not Mentioned"}{" "}
                  -{" "}
                  {jobDetails.max_salary
                    ? `${parseFloat(jobDetails.max_salary)}`
                    : "Not Mentioned"}{" "}
                  LPA
                </p>
              </h6>
              <h6>
                Experience:
                <p>
                  {jobDetails.min_salary
                    ? `${parseFloat(jobDetails.min_experience)}`
                    : "Not Mentioned"}{" "}
                  -{" "}
                  {jobDetails.max_salary
                    ? `${parseFloat(jobDetails.max_experience)}`
                    : "Not Mentioned"}{" "}
                  Year's
                </p>
              </h6>
              <h6>
                Vacancy: <p>{jobDetails.vacancy || "Not Mentioned"}</p>
              </h6>
              <h6>
                Job Start Date: <p>{jobDetails?.start_date ? formatDate(jobDetails.start_date) : "Not Mentioned"}</p>
              </h6>
              <h6>
                Job Expiry Date: <p>{jobDetails?.end_date ? formatDate(jobDetails.end_date) : "Not Mentioned"}</p>
              </h6>
              <h6>
                Location: <p>{jobDetails.job_location || "Not Mentioned"}</p>
              </h6>
              <h6>
                Key Skills:
                {skills != "" ? skills?.map((skill) => (
                  <p key={skill.id}>{skill.skill_name || "Not Mentioned"},</p>
                )) : <p > Not Mentioned </p>}
              </h6>
              <h6>
                Job Responsibilities: <p>{jobDetails.responsibility || "Not Mentioned"}</p>
              </h6>
            </div>
            <div className="job-detail-company">
              <h5>About Company</h5>
              <p>
                {jobDetails.company_name || "Not Mentioned"}
              </p>
              <p>HR Email - {jobDetails?.company} </p>
            </div>
            {!location.pathname.includes('student/job-details') && (<div className="job-detail-share-button mb-4">
              <button onClick={openModal}>Share Job</button>
            </div>)}
          </div>
          {isModalOpen && (
            <div className="job-share-modal-overlay">
              <div className="job-share-modal-content">
                <h5>Job Sharing</h5>
                {/* <div className="job-sharing-icon">
                  <img src={jobsharingw} alt="sharing" />
                  <img src={jobsharingm} alt="sharing" />
                  <img src={jobsharinge} alt="sharing" />
                </div> */}
                <p>Share Job Opportunity</p>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                  placeholder="Email"
                />
                {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                <input
                  type="text"
                  value={url}
                  placeholder="Job URL"
                  disabled
                  style={{ cursor: "not-allowed" }}
                />
                <div>
                  <button onClick={handleSend}>{isEmailLoading ? <span className="spinner"></span> : `Send Email`}</button>
                </div>
                <div onClick={() => setIsModalOpen(false)} className="job_sharing_cross_button">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9999 14.9999L1 1M15 1L1 15" stroke="#F38D00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>)}
      </div>
    </>
  );
};

export default CampusJobDetails;