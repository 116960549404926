import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, IconButton, Menu, MenuItem, Pagination } from "@mui/material";
import threedot from "../../../../utils/landingpage/images/three-dots-svgrepo-com.svg";
import "../../../../styles/dashboards/hrdashboard/hrjoblisting.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import filterIcon from "../../../../utils/dashboards/filterIcon.svg";
import filterIcondrop from "../../../../utils/dashboards/filterarrowdropdown.svg";

export default function LateralJobList() {
  const roleName = JSON.parse(sessionStorage.getItem("userRoleName"));
  const ITEM_HEIGHT = 48;
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(true);
  const [jobsData, setJobsData] = useState([]);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("accessToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const jobsPerPage = 8;
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("All");
  const [selectedDateOption, setSelectedDateOption] = useState("Today");
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);

  const options = [
    { value: "all", label: "All" },
    { value: "live", label: "Live" },
    { value: "closed", label: "Closed" },
  ];

  const dateFilterOptions = [
    { value: "all", label: "All" },
    { value: "today", label: "Today" },
    { value: "week", label: "This Week" },
    { value: "month", label: "This Month" },
    { value: "sixMonths", label: "Last 6 Months" },
  ];

  const filterJobsByDate = (jobs, filterType) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const getDateRange = (filterType) => {
      switch (filterType.toLowerCase()) {
        case "all":
          return today;
        case "today":
          return today;
        case "week": {
          const weekAgo = new Date(today);
          weekAgo.setDate(today.getDate() - 7);
          return weekAgo;
        }
        case "month": {
          const monthAgo = new Date(today);
          monthAgo.setMonth(today.getMonth() - 1);
          return monthAgo;
        }
        case "sixmonths": {
          const sixMonthsAgo = new Date(today);
          sixMonthsAgo.setMonth(today.getMonth() - 6);
          return sixMonthsAgo;
        }
        default:
          return null;
      }
    };

    const startDate = getDateRange(filterType);
    if (!startDate) return jobs;

    return jobs.filter((job) => {
      const jobDate = new Date(job.created_at);
      jobDate.setHours(0, 0, 0, 0);
      return jobDate >= startDate && jobDate <= today;
    });
  };

  const handleDateFilterClick = (option) => {
    setSelectedDateOption(option.label);
    setIsDateFilterOpen(false);
    setCurrentPage(1);
  };

  const handleViewJob = (jobId) => {
    navigate(
      `/dashboard/sidebar-page/hr-manager/view-job-details/${
        selectedJob.id
      }/${"lateral"}`
    );
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    setCurrentPage(1);
  };

  const filteredJobs = jobsData.filter((job) => {
    const matchesOption =
      selectedOption === "All" ||
      job.status.toLowerCase() === selectedOption.toLowerCase();
    const matchesSearch = job.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesOption && matchesSearch;
  });

  const handleClick = (event, job) => {
    setAnchorEl(event.currentTarget);
    setSelectedJob(job); // Set the selected job
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewApplicants = () => {
    const jobNameForUrl = selectedJob.title.replace(/ /g, "-");
    navigate(
      `/dashboard/sidebar-page/hr-manager/view-applicants/${selectedJob.id}/${jobNameForUrl}`
    );
  };

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()]; // Get the month's abbreviation
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDeleteJob = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this job?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff8800",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        setLoading(true);
        const response = await axios.delete(
          `${baseUrl}/api/jobs/company_lateral_jobs/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: { id: selectedJob.id },
          }
        );

        setJobsData((prevJobs) => {
          return prevJobs.filter((job) => job.id !== selectedJob.id);
        });

        Swal.fire({
          title: "Deleted!",
          text: "The job has been deleted.",
          icon: "success",
          confirmButtonColor: "#ff8800",
        });
      } catch (error) {
        console.error("Error deleting the job:", error);
      } finally {
        setLoading(false);
        handleClose();
      }
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchJobsData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/api/jobs/lateral-jobs/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setJobsData(response.data);
      } catch (error) {
        console.error("Error fetching the data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobsData();
  }, [baseUrl, token]);

  if (loading) {
    return (
      <div className="_main_content_inner_screen">
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="d-flex align-items-center justify-content-between">
          <h6>
            {" "}
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <path
                d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                fill="#222222"
              />
              <path
                d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                fill="#222222"
              />
            </svg>{" "}
            &nbsp; Job-List
          </h6>
          <div className="d-flex align-items-center">
            <input
              type="text"
              placeholder="Search by job name"
              value={searchTerm}
              onChange={handleSearchInputChange}
              className="job_search_input_admin"
            />
            {/* <div className="filter_container_job_listing">
              <div
                className="custom-select"
                onClick={() => setIsDateFilterOpen(!isDateFilterOpen)}
              >
                <div className="select-selected">
                  <img
                    src={filterIcon}
                    alt="Filter Icon"
                    className="filter-icon"
                  />
                  {selectedDateOption}
                  <img
                    className="filterdropdown"
                    src={filterIcondrop}
                    alt="Dropdown Icon"
                  />
                </div>
                {isDateFilterOpen && (
                  <div className="select-items">
                    {dateFilterOptions.map((option) => (
                      <div
                        key={option.value}
                        onClick={() => handleDateFilterClick(option)}
                        className="option"
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div> */}
            <div className="filter_container_job_listing">
              <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
                <div className="select-selected">
                  <img
                    src={filterIcon}
                    alt="Filter Icon"
                    className="filter-icon"
                  />{" "}
                  {selectedOption}{" "}
                  <img
                    className="filterdropdown"
                    src={filterIcondrop}
                    alt="..."
                  />
                </div>
                {isOpen && (
                  <div className="select-items">
                    {options.map((option) => (
                      <div
                        key={option.value}
                        onClick={() => handleOptionClick(option)}
                        className="option"
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 d-flex align-items-center justify-content-center flex-column">
          <div className="hr_job_listing_table">
            {currentJobs.length === 0 ? (
              <div className="no_job_post_yet">No job posts yet.</div>
            ) : (
              <table>
                <thead className="hr_job_listing_table_head">
                  <tr>
                    <th>S No</th>
                    <th style={{ textAlign: "left" }}>Job Title</th>
                    <th>Status</th>
                    <th>Date Posted</th>
                    <th>Due Date</th>
                    <th>Job Type</th>
                    <th style={{ textAlign: "left" }}>Degree Type</th>
                    <th>Salary Range</th>
                    <th>Experience Range</th>
                    <th>Vacancy</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentJobs.map((job, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td style={{ textAlign: "left" }}>
                        {job.title.length > 13
                          ? `${job.title
                              .charAt(0)
                              .toUpperCase()}${job.title.slice(1, 13)}...`
                          : `${job.title
                              .charAt(0)
                              .toUpperCase()}${job.title.slice(1)}`}
                      </td>
                      <td>
                        <span className={`status ${job.status.toLowerCase()}`}>
                          {job.status}
                        </span>
                      </td>
                      <td>{formatDate(job.created_at)}</td>
                      <td>{job.end_date ? formatDate(job.end_date) : "N/A"}</td>
                      <td>{job.type}</td>
                      <td style={{ textAlign: "left" }}>{job.degree_type}</td>
                      <td>
                        {job.min_salary} to {job.max_salary}
                      </td>
                      <td>
                        {job.min_experience} to {job.max_experience} Years
                      </td>
                      <td>{job.vacancy}</td>
                      <td>
                        <div>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, job)}
                          >
                            <img
                              src={threedot}
                              height={18}
                              width={18}
                              alt=".."
                            />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "15ch",
                              },
                            }}
                          >
                            <MenuItem
                              sx={{ fontSize: "12px" }}
                              onClick={() => handleViewJob(job.id)}
                            >
                              View Job
                            </MenuItem>
                            <MenuItem
                              sx={{ fontSize: "12px" }}
                              onClick={() => handleDeleteJob(job.id)}
                            >
                              Delete
                            </MenuItem>
                            <MenuItem
                              sx={{ fontSize: "12px" }}
                              onClick={handleViewApplicants}
                            >
                              View Applicants
                            </MenuItem>
                          </Menu>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {filteredJobs.length > jobsPerPage && (
            <div className="d-flex justify-content-center pagination_custom">
              <Pagination
                count={Math.ceil(jobsData.length / jobsPerPage)}
                page={currentPage}
                color="primary"
                className="mt-3"
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
