import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

const localizer = momentLocalizer(moment);

export default function CampusManagerMySchedule() {
  const navigate = useNavigate();
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [cardPosition, setCardPosition] = useState({ top: 0, left: 0 });
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("accessToken");
  const base_Url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${base_Url}/api/myschedule_interview/retrieve_interview_details/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const formattedEvents = response.data.interview_details
          ?.filter((item) => item.interview_scheduled_date)
          .map((item) => ({
            title: `${item.company_name}`,
            start: new Date(item.interview_scheduled_date),
            end: new Date(item.interview_scheduled_date),
            allDay: false,
            interview_type: `${item?.interview_type}`,
            interview_round_scheduled: `${item?.interview_round_scheduled}`,
            interview_scheduled_location: `${item?.interview_scheduled_location}`,
            zoom_meeting_link_scheduled: `${item.zoom_meeting_link_scheduled}`
          }));

        console.log('----formattedEvents--', formattedEvents);
        setEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, [base_Url, token]);

  const handleEventHover = (event, e) => {
    setHoveredEvent(event);
    console.log("---eventss", event)
    const boundingRect = e.target.getBoundingClientRect();
    setCardPosition({
      top: boundingRect.top + window.scrollY + 20,
      left: boundingRect.left + window.scrollX + 20,
    });
  };

  const handleMouseLeave = () => {
    setHoveredEvent(null);
  };

  console.log("+++ events ---", events);


  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <h6>
            &nbsp; &nbsp; &nbsp; My Schedule
          </h6>
          {loading ? (
            <div className="custom_loader">
              <ClipLoader color="#F38D00" size={50} />
            </div>
          ) : (
            <div>
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{
                  height: 500,
                  margin: "20px",
                  background: "#fff",
                  padding: "1rem",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
                  fontFamily: "Poppins, sans-serif",
                }}
                components={{
                  // {console.log}
                  event: ({ event }) => (
                    <span
                      onMouseEnter={(e) => handleEventHover(event, e)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {event.title}
                    </span>
                  ),
                }}
              />
            </div>
          )}
          {hoveredEvent && (
            <div
              style={{
                position: "absolute",
                top: `${cardPosition.top}px`,
                left: `${cardPosition.left}px`,
                background: "#f8f9fa",
                border: "1px solid #ddd",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px",
                zIndex: 1000,
              }}
            >
              <p>
                <strong>Title:</strong> {hoveredEvent.title}
              </p>
              <p>
                <b>Start:</b>{" "}
                {moment(hoveredEvent.start).format("MMMM Do YYYY, h:mm a")}
              </p>
              <p>{hoveredEvent.allDay ? "All Day Event" : "Timed Event"}</p>
              <p>
                <strong>Current Interview Round:</strong> {hoveredEvent.interview_round_scheduled}
              </p>
              <p>
                <strong>Interview Mode:</strong> {hoveredEvent.interview_type}
              </p>
              {hoveredEvent.interview_type === "physical" && (<p>
                <strong>Interview Location:</strong> {hoveredEvent.interview_scheduled_location}
              </p>)}
              {hoveredEvent.interview_type === "virtual" && (<p>
                <strong>Zoom Meeting URL:</strong> <spa><a target="." href={`${hoveredEvent.zoom_meeting_link_scheduled}`}>{hoveredEvent.zoom_meeting_link_scheduled}</a></spa>
              </p>)}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
