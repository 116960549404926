import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FaChevronDown } from "react-icons/fa";
import dummy_logo from '../../../../utils/landingpage/images/dummy_logo.png'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setGetStudentProfileData } from "../../../../redux/reducers/student_profile_reducer";
import { fetchStudentProfileData } from "../../../../redux/actions/student_profile_action";
import Swal from 'sweetalert2';
import { fetchStudentProfileCompletion } from "../../../../redux/actions/student_profilePercentage";

const degreeOptions = {
  bachelors: {
    courses: [
      {
        name: "B.Tech",
        specializations: [
          "Computer Science",
          "IT",
          "Electrical",
          "Electronics",
          "Mechanical",
          "Civil",
          "Chemical",
          "Aerospace",
          "1",
          "Automobile",
          "Mechatronics",
        ],
      },
      {
        name: "BBA",
        specializations: [
          "Marketing",
          "Finance",
          "HR",
          "Operations",
          "Entrepreneurship",
          "Supply Chain",
        ],
      },
      {
        name: "MBBS",
        specializations: ["General Medicine"],
      },
      {
        name: "B.Sc.",
        specializations: [
          "Physics",
          "Chemistry",
          "Mathematics",
          "Biology",
          "Biotechnology",
          "Environmental Science",
          "Statistics",
          "Computer Science",
        ],
      },
      {
        name: "B.Com",
        specializations: [
          "General",
          "Accounting",
          "Finance",
          "Banking",
          "Management",
          "Business Administration",
        ],
      },
      {
        name: "BCA",
        specializations: [
          "Computer Applications",
          "Software Engineering",
          "Networking",
          "Database Management",
        ],
      },
      {
        name: "B.Design",
        specializations: [
          "Fashion Design",
          "Interior Design",
          "Graphic Design",
          "Textile Design",
        ],
      },
      {
        name: "B.Pharma",
        specializations: ["Pharmacy", "Pharmaceutical Sciences"],
      },
      {
        name: "B.Arch",
        specializations: ["Architecture", "Urban Planning"],
      },
      {
        name: "LLB",
        specializations: ["Law", "Corporate Law", "Criminal Law"],
      },
    ],
  },
  masters: {
    courses: [
      {
        name: "M.Tech",
        specializations: [
          "Computer Science",
          "IT",
          "Mechanical",
          "Civil",
          "Electrical",
          "Electronics",
          "Chemical",
          "Aerospace",
          "Biotechnology",
          "Data Science",
          "Software Engineering",
          "Information Security",
        ],
      },
      {
        name: "MBA",
        specializations: [
          "Marketing",
          "Finance",
          "HR",
          "Operations",
          "International Business",
          "Entrepreneurship",
          "Supply Chain",
          "Business Analytics",
          "Digital Marketing",
        ],
      },
      {
        name: "M.A.",
        specializations: [
          "English Literature",
          "History",
          "Sociology",
          "Political Science",
          "Economics",
          "Psychology",
          "Journalism and Mass Communication",
          "Public Administration",
          "Cultural Studies",
          "Linguistics",
        ],
      },
      {
        name: "M.Sc.",
        specializations: [
          "Physics",
          "Chemistry",
          "Mathematics",
          "Biology",
          "Biotechnology",
          "Environmental Science",
          "Statistics",
          "Computer Science",
          "Data Science",
        ],
      },
      {
        name: "M.Com",
        specializations: ["Accountancy", "Finance", "Business Management"],
      },
      {
        name: "MCA",
        specializations: [
          "Software Development",
          "Networking",
          "Database Management",
          "Cloud Computing",
        ],
      },
      {
        name: "M.Design",
        specializations: ["Industrial Design", "Graphic Design"],
      },
      {
        name: "M.Pharma",
        specializations: ["Pharmacy", "Pharmaceutical Sciences"],
      },
      {
        name: "M.Arch",
        specializations: ["Architecture", "Urban Design"],
      },
      {
        name: "LLM",
        specializations: ["Corporate Law", "International Law", "Criminal Law"],
      },
    ],
  },
  doctorate: {
    courses: [
      {
        name: "PhD in Engineering",
        specializations: [
          "Computer Science",
          "Mechanical",
          "Civil",
          "Electrical",
          "Electronics",
          "Chemical",
          "Aerospace",
          "Biotechnology",
          "Data Science",
        ],
      },
      {
        name: "PhD in Business and Management",
        specializations: [
          "Marketing",
          "Finance",
          "HR Management",
          "Organizational Behavior",
          "Supply Chain Management",
        ],
      },
      {
        name: "PhD in Humanities",
        specializations: [
          "English Literature",
          "History",
          "Sociology",
          "Political Science",
          "Economics",
          "Cultural Studies",
        ],
      },
      {
        name: "PhD in Sciences",
        specializations: [
          "Physics",
          "Chemistry",
          "Biology",
          "Mathematics",
          "Environmental Science",
        ],
      },
      {
        name: "PhD in Law",
        specializations: ["Corporate Law", "International Law", "Criminal Law"],
      },
    ],
  },
};

const StudentQualification = () => {

  const [profileCompletion, setProfileCompletion] = useState("");
  const [selectedDegree, setSelectedDegree] = useState("");
  const [availableCourses, setAvailableCourses] = useState([]);
  const [availableSpecializations, setAvailableSpecializations] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [institutes, setInstitutes] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [editingValue, setEditingValue] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmittedQualification, setIsSubmittedQualification] =
    useState(false);
  const [isEditModelOpenQualification, setIsEditModelOpenQualification] =
    useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [loading, setLoading] = useState(false)

  const NewupdatedData = useSelector((state) => state.studentProfile.getStudentProfileData);
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    degree: "",
    institute: "",
    courseName: "",
    courseType: "",
    startingYear: "",
    endingYear: "",
    school: "",
    board: "",
    passingYear: "",
    qualifications: [],
  });

  const [initialValuesSet, setInitialValuesSet] = useState({
    institute: "",
    startingYear: "",
    endingYear: "",
    school: "",
    passingYear: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (Number(value) < 0) {
      setErrors({ ...errors, [name]: "Year cannot be negative" });
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleInputEditChange = (field, value) => {
    if (!initialValuesSet[field]) {
      setInitialValuesSet((prev) => ({ ...prev, [field]: true }));
    }
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const formatYearToDate = (year) => (year ? `${year}-01-01` : "");

  const validateEditQualification = (editingValue) => {
    const newErrors = {};

    const currentYear = new Date().getFullYear();

    const startingYear = formData.startingYear
      ? Number(formData.startingYear)
      : editingValue.from_date?.slice(0, 4)
        ? Number(editingValue.from_date?.slice(0, 4))
        : null;

    const endingYear = formData.endingYear
      ? Number(formData.endingYear)
      : editingValue.to_date?.slice(0, 4)
        ? Number(editingValue.to_date?.slice(0, 4))
        : null;

    const passingYear = formData.passingYear
      ? Number(formData.passingYear)
      : null;

    if (startingYear && startingYear > currentYear) {
      newErrors.startingYear = "Starting Year cannot be greater than the current year.";
    }

    if (endingYear && endingYear > currentYear) {
      newErrors.endingYear = "Ending Year cannot be greater than the current year.";
    }

    if (startingYear && endingYear && startingYear >= endingYear) {
      newErrors.startingYear = "Starting Year should be less than Ending Year.";
    }

    if (startingYear && endingYear && startingYear >= endingYear) {
      newErrors.startingYear = "Starting Year should not exceed Ending Year.";
    }

    if (passingYear && passingYear > currentYear) {
      newErrors.passingYear = "Passing Year cannot be greater than the current year.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleEditNewQualification = (value, event) => {
    event.preventDefault();

    const validate = validateEditQualification(editingValue)
    if (!validate) {
      return;
    }

    let editedQualification = {};

    if (formData.degree) {
      editedQualification.degree = formData.degree;
    }

    if (formData.institute) {
      editedQualification.institute = formData.institute;
    }

    if (formData.courseType) {
      editedQualification.course_type = formData.courseType;
    }

    if (formData.courseName) {
      editedQualification.course_name = formData.courseName;
    }

    if (formData.specialization) {
      editedQualification.course_specification = formData.specialization;
    }

    if (formData.board) {
      editedQualification.board = formData.board;
    }

    if (formData.specialization) {
      editedQualification.course_specification = formData.specialization;
    }

    if (formData.school) {
      editedQualification.school_name = formData.school;
    }

    if (formData.startingYear) {
      editedQualification.from_date = formatYearToDate(formData.startingYear);
    }

    if (formData.passingYear) {
      editedQualification.passing_year = formatYearToDate(formData.passingYear);
    }

    if (formData.endingYear) {
      editedQualification.to_date = formatYearToDate(formData.endingYear);
    }

    if (Object.keys(editedQualification).length === 0) {
      toast.info("No data to update");
      return;
    }

    setLoading(true)

    const accessToken = sessionStorage.getItem("accessToken");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/qualification/${value}/`;

    axios
      .put(url, editedQualification, config)
      .then((response) => {
        toast.success("Added Successfully");
        dispatch(fetchStudentProfileData())
        setLoading(false)
        dispatch(fetchStudentProfileCompletion())
        setIsEditModelOpenQualification(false);
        setFormData({
          degree: "",
          institute: "",
          courseName: "",
          courseType: "",
          startingYear: "",
          endingYear: "",
          school: "",
          board: "",
          passingYear: "",
        });
        setEditingValue([])

        setInitialValuesSet({
          institute: null,
          startingYear: null,
          endingYear: null,
          school: null,
          passingYear: null,
        });

      })
      .catch((error) => {
        toast.error(error);
        setLoading(false)
      });
  };

  useEffect(() => {
    if (selectedDegree) {
      setAvailableCourses(degreeOptions[selectedDegree]?.courses || []);
    }
  }, [selectedDegree]);

  useEffect(() => {
    const selectedCourse = availableCourses.find(
      (course) => course.name === formData.courseName
    );
    setAvailableSpecializations(
      selectedCourse ? selectedCourse.specializations : []
    );
  }, [formData.courseName, availableCourses]);

  useEffect(() => {
    dispatch(fetchStudentProfileCompletion())
  }, [dispatch]);

  const validateQualification = () => {
    const newErrors = {};

    const currentYear = new Date().getFullYear();

    if (!formData.degree) {
      newErrors.degree = "Degree is required";
    }
    if (
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate") &&
      !formData.institute
    ) {
      newErrors.institute = "Institute is required";
    }
    if (
      !formData.courseName &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.courseName = "Course Name is required";
    }
    if (
      !formData.courseType &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.courseType = "Course Type is required";
    }
    if (
      !formData.specialization &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.specialization = "Specialization is required";
    }
    if (
      !formData.startingYear &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.startingYear = "Starting Year is required";
    }
    if (
      !formData.endingYear &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.endingYear = "Ending Year is required";
    }

    // if (
    //   formData.startingYear &&
    //   Number(formData.startingYear) > currentYear
    // ) {
    //   newErrors.startingYear = "Starting Year cannot be greater than the current year.";
    // }

    // if (
    //   formData.endingYear &&
    //   Number(formData.endingYear) > currentYear
    // ) {
    //   newErrors.endingYear = "Ending Year cannot be greater than the current year.";
    // }

    // if (
    //   formData.passingYear &&
    //   Number(formData.passingYear) > currentYear
    // ) {
    //   newErrors.passingYear = "Passing Year cannot be greater than the current year.";
    // }

    // if (
    //   formData.startingYear &&
    //   formData.endingYear &&
    //   Number(formData.endingYear) <= Number(formData.startingYear)
    // ) {
    //   newErrors.endingYear = "Ending Year should be greater than Starting Year";
    // }

    if (
      !formData.startingYear &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.startingYear = "Starting Year is required";
    } else if (
      formData.startingYear &&
      (formData.startingYear.length !== 4 || isNaN(formData.startingYear))
    ) {
      newErrors.startingYear = "Starting Year must be a 4-digit number.";
    } else if (Number(formData.startingYear) > currentYear) {
      newErrors.startingYear = "Starting Year cannot be greater than the current year.";
    }

    if (
      !formData.endingYear &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.endingYear = "Ending Year is required";
    } else if (
      formData.endingYear &&
      (formData.endingYear.length !== 4 || isNaN(formData.endingYear))
    ) {
      newErrors.endingYear = "Ending Year must be a 4-digit number.";
    } else if (Number(formData.endingYear) > currentYear) {
      newErrors.endingYear = "Ending Year cannot be greater than the current year.";
    } else if (
      formData.startingYear &&
      formData.endingYear &&
      Number(formData.endingYear) <= Number(formData.startingYear)
    ) {
      newErrors.endingYear = "Ending Year should be greater than Starting Year";
    }

    if (
      !formData.passingYear &&
      (formData.degree === "10" || formData.degree === "12")
    ) {
      newErrors.passingYear = "Passing Year is required";
    } else if (
      formData.passingYear &&
      (formData.passingYear.length !== 4 || isNaN(formData.passingYear))
    ) {
      newErrors.passingYear = "Passing Year must be a 4-digit number.";
    } else if (Number(formData.passingYear) > currentYear) {
      newErrors.passingYear = "Passing Year cannot be greater than the current year.";
    }

    if (
      !formData.school &&
      (formData.degree === "10" || formData.degree === "12")
    ) {
      newErrors.school = "School Name is required";
    }
    if (
      !formData.board &&
      (formData.degree === "10" || formData.degree === "12")
    ) {
      newErrors.board = "Board is required";
    }
    if (
      !formData.passingYear &&
      (formData.degree === "10" || formData.degree === "12")
    ) {
      newErrors.passingYear = "Passing Year is required";
    }
    if (
      formData.passingYear &&
      Number(formData.passingYear) > currentYear
    ) {
      newErrors.passingYear = "Passing Year cannot be greater than the current year.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleEditQualificationCancel = () => {
    setIsSubmittedQualification(true);
    setIsEditModelOpenQualification(false);

    setFormData({
      degree: "",
      institute: "",
      courseName: "",
      courseType: "",
      startingYear: "",
      endingYear: "",
      school: "",
      board: "",
      passingYear: "",
    });
    setEditingValue([])
  };

  const handleDeleteQualification = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this qualification?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff9800',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/qualification/${id}/`;
        const accessToken = sessionStorage.getItem('accessToken');

        axios.delete(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
          .then(response => {
            toast.success("Qualification deleted successfully");
            dispatch(fetchStudentProfileData())
          })
          .catch(error => {
            toast.error("Something Went Wrong");
          });
      }
    });
  };

  const handleEditQualification = (value) => {
    // setIsSubmittedQualification(false);
    setIsEditModelOpenQualification(!isEditModelOpenQualification);
    setEditingValue(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      degree: value.degree,
      courseName: value.course_name,
      specialization: value.course_specification,
    }));
    setSelectedDegree(value.degree);
    let newvalue = degreeOptions[value.degree]?.courses.find(
      (item) => item.name === value.course_name
    );
    setAvailableSpecializations(newvalue?.specializations);

    if (!value) {
      setFormData({
        certificateCourseName: "",
        certificateInstitute: "",
        certificateType: "",
        specialization: "",
        certificateStartingYear: "",
        certificateEndingYear: "",
        certificateValidity: "",
      });
    }
  };

  useEffect(() => {
    const fetchInstitutes = async () => {
      try {
        const queryParam = searchTerm ? searchTerm : "";
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/college-data/?search=${queryParam}`
        );
        if (response.data && response.data.results) {
          setInstitutes(response.data.results);
        }
      } catch (error) {
        setInstitutes([]);
      }
    };

    fetchInstitutes();
  }, [searchTerm]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const hanldeAddQualification = async (e) => {
    e.preventDefault();

    const isValid = validateQualification();

    if (!isValid) {
      return;
    }

    let instituteValue = formData.institute;
    if (formData.degree === "12" || formData.degree === "10") {
      instituteValue = formData.school;
    }

    let fromDate = null;
    let toDate = null;
    let passingYear = null;
    let boardValue = null;

    if (formData.degree === "12" || formData.degree === "10") {
      passingYear = formatYearToDate(formData.passingYear);
      boardValue = formData.board;
    } else {
      fromDate = formatYearToDate(formData.startingYear);
      toDate = formatYearToDate(formData.endingYear);
      boardValue = null;
      passingYear = null;
    }

    const newQualification = {
      institute: instituteValue,
      degree: formData.degree,
      course_type: formData.courseType || "fulltime",
      course_name: formData.courseName || null,
      course_specification: formData.specialization || null,
      board: boardValue,
      passing_year: passingYear,
      from_date: fromDate,
      to_date: toDate,
    };

    const previousQualification = NewupdatedData?.qualifications || [];
    const mergedQualification = [...previousQualification, newQualification];

    const payload = {
      qualifications: mergedQualification,
    };

    const accessToken = sessionStorage.getItem("accessToken");

    const userSessionData = sessionStorage.getItem("userDetails");

    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {

      setLoading(true)

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsSubmittedQualification(true);
        setUpdatedData(response.data);
        setLoading(false)
        dispatch(setGetStudentProfileData(response.data))
        toast.success("Added Successfully");
        dispatch(fetchStudentProfileCompletion())
        setFormData({
          degree: "",
          institute: "",
          courseName: "",
          courseType: "",
          specialization: "",
          startingYear: "",
          endingYear: "",
          school: "",
          board: "",
          passingYear: "",
        });
      }
    } catch (error) {
      toast.error(error);
      setLoading(false)
    }
  };

  return (<>
    <div>
      <div className="pb-4">
        <p>{isEditModelOpenQualification ? `Edit Qualifications Details` : `Add Qualifications Details`}
        </p>
      </div>
      {isEditModelOpenQualification ? (
        <form
          onSubmit={(event) =>
            handleEditNewQualification(editingValue.id, event)
          }
          className="epf-form-layout"
        >
          <div className="epf-input-row">
            <div className="epf-input-wrapper epf-input-gap">
              <select
                id="degree"
                name="degree"
                value={formData.degree || editingValue.degree}
                onChange={(e) => {
                  handleInputChange(e);
                  setSelectedDegree(e.target.value);
                }}
                className={`epf-input-field ${errors.degree ? "input-error" : ""
                  }`}
              >
                <option value="">Degree</option>
                <option value="bachelors">
                  Bachelor's Degree/Diploma
                </option>
                <option value="masters">Master's Degree</option>
                <option value="doctorate">Doctorate/PHD</option>
                <option value="12">12th</option>
                <option value="10">10th</option>
              </select>
            </div>

            {(formData.degree === "bachelors" ||
              formData.degree === "masters" ||
              formData.degree === "doctorate" ||
              formData.degree === "") && (
                <div
                  className="epf-input-wrapper epf-input-gap"
                  style={{ position: "relative" }}
                >
                  <div
                    className="search-dropdown-wrapper"
                    style={{ position: "relative" }}
                  >
                    <input
                      type="text"
                      placeholder="Search institute"
                      value={
                        searchTerm ||
                        (isEditModelOpenQualification && !initialValuesSet.institute
                          ? editingValue.institute || ""
                          : formData.institute)
                      }
                      onChange={(e) => { setSearchTerm(e.target.value); handleInputEditChange("institute", e.target.value) }}
                      //   value={
                      //     !initialValuesSet.phone
                      //         ? updatedData?.phone_no || ""
                      //         : formData.phone
                      // }
                      // onChange={(e) => handleInputEditChange("phone", e.target.value)}
                      className="epf-input-field"
                      onClick={toggleDropdown}
                    />
                    <FaChevronDown
                      className="dropdown-icon"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        width: "15px",
                        height: "15px",
                      }}
                      onClick={toggleDropdown}
                    />
                  </div>

                  {showDropdown && (
                    <ul
                      className="dropdown-list"
                      style={{
                        position: "absolute",
                        width: "100%",
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        zIndex: "1",
                        maxHeight: "200px",
                        overflowY: "auto",
                        listStyle: "none",
                        padding: "0",
                        margin: "0",
                      }}
                    >
                      {institutes.length > 0 ? (
                        institutes.map((institute) => (
                          <li
                            key={institute.id}
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ccc",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                institute: institute.university_name,
                              });
                              setSearchTerm(institute.university_name);
                              setShowDropdown(false);
                            }}
                          >
                            {institute.university_name}
                          </li>
                        ))
                      ) : (
                        <li style={{ padding: "10px", color: "#888" }}>
                          No institutes found
                        </li>
                      )}
                    </ul>
                  )}

                  {errors.institute && (
                    <span className="error-text">{errors.institute}</span>
                  )}
                </div>
              )}

            {formData.degree === "10" && (
              <div className="epf-input-wrapper epf-input-gap">
                <input
                  type="text"
                  id="school"
                  name="school"
                  placeholder="School Name"
                  // value={formData.school || editingValue.institute}
                  // onChange={handleInputChange}
                  value={
                    !initialValuesSet.school
                      ? editingValue.institute || ""
                      : formData.school
                  }
                  onChange={(e) => handleInputEditChange("school", e.target.value)}
                  className={`epf-input-field ${errors.school ? "input-error" : ""
                    }`}
                />
              </div>
            )}

            {formData.degree === "12" && (
              <div className="epf-input-wrapper epf-input-gap">
                <input
                  type="text"
                  id="school"
                  name="school"
                  placeholder="School Name"
                  // value={formData.school || editingValue.institute}
                  // onChange={handleInputChange}
                  value={
                    !initialValuesSet.school
                      ? editingValue.institute || ""
                      : formData.school
                  }
                  onChange={(e) => handleInputEditChange("school", e.target.value)}
                  className={`epf-input-field ${errors.school ? "input-error" : ""
                    }`}
                />
              </div>
            )}

            {(formData.degree === "bachelors" ||
              formData.degree === "masters" ||
              formData.degree === "doctorate" ||
              formData.degree === "") && (
                <div className="epf-input-wrapper epf-input-gap">
                  <select
                    id="courseName"
                    name="courseName"
                    value={
                      formData.courseName || editingValue.course_name
                    }
                    onChange={handleInputChange}
                    className={`epf-input-field ${errors.courseName ? "input-error" : ""
                      }`}
                  >
                    <option value="">Course Name</option>
                    {availableCourses.map((course) => (
                      <option key={course.name} value={course.name}>
                        {course.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
          </div>

          {(formData.degree === "bachelors" ||
            formData.degree === "masters" ||
            formData.degree === "doctorate" ||
            formData.degree === "") && (
              <div className="epf-input-row">
                <div className="epf-input-wrapper epf-input-gap">
                  <select
                    id="courseType"
                    name="courseType"
                    value={
                      formData.courseType || editingValue.course_type
                    }
                    onChange={handleInputChange}
                    className={`epf-input-field ${errors.courseType ? "input-error" : ""
                      }`}
                  >
                    <option value="">Course Type</option>
                    <option value="fulltime">Full Time</option>
                    <option value="parttime">Part Time</option>
                    <option value="correspondence/distance">
                      Correspondence/Distance learning
                    </option>
                  </select>
                </div>

                <div className="epf-input-wrapper epf-input-gap">
                  <select
                    id="specialization"
                    name="specialization"
                    value={
                      formData.specialization ||
                      editingValue.course_specification
                    }
                    onChange={handleInputChange}
                    className={`epf-input-field ${errors.specialization ? "input-error" : ""
                      }`}
                  >
                    <option value="">Specialization</option>
                    {availableSpecializations.map((spec) => (
                      <option key={spec} value={spec}>
                        {spec}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="d-flex gap-4">
                  <div className="epf-input-wrapper epf-input-gap">
                    <input
                      type="number"
                      id="startingYear"
                      name="startingYear"
                      placeholder="Starting Year"
                      // value={
                      //   formData.startingYear ||
                      //   editingValue.from_date?.slice(0, 4)
                      // }
                      // onChange={handleInputChange}
                      value={
                        !initialValuesSet.startingYear
                          ? editingValue.from_date?.slice(0, 4) || ""
                          : formData.startingYear
                      }
                      onChange={(e) => handleInputEditChange("startingYear", e.target.value)}
                      className={`epf-input-field ${errors.startingYear ? "input-error" : ""
                        }`}
                    />
                    {errors.startingYear && (
                      <span className="error-text">
                        {errors.startingYear}
                      </span>
                    )}
                  </div>

                  <div className="epf-input-wrapper epf-input-gap">
                    <input
                      type="number"
                      id="endingYear"
                      name="endingYear"
                      placeholder="Ending Year"
                      // value={
                      //   formData.endingYear ||
                      //   editingValue.to_date?.slice(0, 4)
                      // }
                      // onChange={handleInputChange}
                      value={
                        !initialValuesSet.endingYear
                          ? editingValue.to_date?.slice(0, 4) || ""
                          : formData.endingYear
                      }
                      onChange={(e) => handleInputEditChange("endingYear", e.target.value)}
                      className={`epf-input-field ${errors.endingYear ? "input-error" : ""
                        }`}
                    />
                    {errors.endingYear && (
                      <span className="error-text">
                        {errors.endingYear}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}

          {formData.degree === "10" && (
            <div className="epf-input-row">
              <div className="epf-input-wrapper epf-input-gap">
                <select
                  id="board"
                  name="board"
                  value={formData.board || editingValue.board}
                  onChange={handleInputChange}
                  className={`epf-input-field ${errors.board ? "input-error" : ""
                    }`}
                >
                  <option value="">Board</option>
                  <option value="cbse">CBSE</option>
                  <option value="icse">ICSE</option>
                  <option value="nios">NIOS</option>
                  <option value="stateBoard">State Board</option>
                </select>
                {errors.board && (
                  <span className="error-text">{errors.board}</span>
                )}
              </div>

              <div className="d-flex gap-4">
                <div className="epf-input-wrapper epf-input-gap">
                  <input
                    type="number"
                    id="passingYear"
                    name="passingYear"
                    placeholder="Passing Year"
                    // value={
                    //   formData.passingYear ||
                    //   editingValue.passing_year?.slice(0, 4)
                    // }
                    // onChange={handleInputChange}
                    value={
                      !initialValuesSet.passingYear
                        ? editingValue.passing_year?.slice(0, 4) || ""
                        : formData.passingYear
                    }
                    onChange={(e) => handleInputEditChange("passingYear", e.target.value)}
                    className={`epf-input-field ${errors.passingYear ? "input-error" : ""
                      }`}
                  />
                  {errors.passingYear && (
                    <span className="error-text">
                      {errors.passingYear}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          {formData.degree === "12" && (
            <div className="epf-input-row">
              <div className="epf-input-wrapper epf-input-gap">
                <select
                  id="board"
                  name="board"
                  value={formData.board || editingValue.board}
                  onChange={handleInputChange}
                  className={`epf-input-field ${errors.board ? "input-error" : ""
                    }`}
                >
                  <option value="">Board</option>
                  <option value="cbse">CBSE</option>
                  <option value="icse">ICSE</option>
                  <option value="nios">NIOS</option>
                  <option value="stateBoard">State Board</option>
                </select>
                {errors.board && (
                  <span className="error-text">{errors.board}</span>
                )}
              </div>

              <div className="d-flex gap-4">
                <div className="epf-input-wrapper epf-input-gap">
                  <input
                    type="number"
                    id="passingYear"
                    name="passingYear"
                    placeholder="Passing Year"
                    // value={
                    //   formData.passingYear ||
                    //   editingValue.passing_year?.slice(0, 4)
                    // }
                    // onChange={handleInputChange}
                    value={
                      !initialValuesSet.passingYear
                        ? editingValue.passing_year?.slice(0, 4) || ""
                        : formData.passingYear
                    }
                    onChange={(e) => handleInputEditChange("passingYear", e.target.value)}
                    className={`epf-input-field ${errors.passingYear ? "input-error" : ""
                      }`}
                  />
                  {errors.passingYear && (
                    <span className="error-text">
                      {errors.passingYear}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="d-flex gap-4">
            <button type="submit" className="epf-submit-btn mt-0">
              {loading ? <div className="spinner"></div> : `Save`}
            </button>
            <button
              onClick={handleEditQualificationCancel}
              className="epf-submit-btn mt-0"
              style={{
                background: "transparent",
                border: "1px solid rgba(243, 141, 0, 1)",
                color: "rgba(243, 141, 0, 1)",
              }}
            >
              Cancel
            </button>
          </div>
        </form>) :
        (<form
          onSubmit={hanldeAddQualification}
          className="epf-form-layout"
        >
          <div className="epf-input-row">
            <div className="epf-input-wrapper epf-input-gap">
              <select
                id="degree"
                name="degree"
                value={formData.degree}
                onChange={(e) => {
                  handleInputChange(e);
                  setSelectedDegree(e.target.value);
                }}
                className={`epf-input-field ${errors.degree ? "input-error" : ""
                  }`}
              >
                <option value="">Degree</option>
                <option value="bachelors">
                  Bachelor's Degree/Diploma
                </option>
                <option value="masters">Master's Degree</option>
                <option value="doctorate">Doctorate/PHD</option>
                <option value="12">12th</option>
                <option value="10">10th</option>
              </select>
              {errors.degree && (
                <span className="error-text">{errors.degree}</span>
              )}
            </div>

            {(formData.degree === "bachelors" ||
              formData.degree === "masters" ||
              formData.degree === "doctorate" ||
              formData.degree === "") && (
                <div
                  className="epf-input-wrapper epf-input-gap"
                  style={{ position: "relative" }}
                >
                  <div
                    className="search-dropdown-wrapper"
                    style={{ position: "relative" }}
                  >
                    <input
                      type="text"
                      placeholder="Search institute"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="epf-input-field"
                      onClick={toggleDropdown}
                    />
                    <FaChevronDown
                      className="dropdown-icon"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        width: "15px",
                        height: "15px",
                      }}
                      onClick={toggleDropdown}
                    />
                  </div>

                  {showDropdown && (
                    <ul
                      className="dropdown-list"
                      style={{
                        position: "absolute",
                        width: "100%",
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        zIndex: "1",
                        maxHeight: "200px",
                        overflowY: "auto",
                        listStyle: "none",
                        padding: "0",
                        margin: "0",
                      }}
                    >
                      {institutes.length > 0 ? (
                        institutes.map((institute) => (
                          <li
                            key={institute.id}
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ccc",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                institute: institute.college_name,
                              });
                              setSearchTerm(institute.college_name);
                              setShowDropdown(false);
                            }}
                          >
                            {institute.college_name}
                          </li>
                        ))
                      ) : (
                        <li style={{ padding: "10px", color: "#888" }}>
                          No institutes found
                        </li>
                      )}
                    </ul>
                  )}

                  {errors.institute && (
                    <span className="error-text">{errors.institute}</span>
                  )}
                </div>
              )}

            {(formData.degree === "12" || formData.degree === "10") && (
              <div className="epf-input-wrapper epf-input-gap">
                <input
                  type="text"
                  id="school"
                  name="school"
                  placeholder="School Name"
                  value={formData.school}
                  onChange={handleInputChange}
                  className={`epf-input-field ${errors.school ? "input-error" : ""
                    }`}
                />
                {errors.school && (
                  <span className="error-text">{errors.school}</span>
                )}
              </div>
            )}

            {(formData.degree === "bachelors" ||
              formData.degree === "masters" ||
              formData.degree === "doctorate" ||
              formData.degree === "") && (
                <div className="epf-input-wrapper epf-input-gap">
                  <select
                    id="courseName"
                    name="courseName"
                    value={formData.courseName}
                    onChange={handleInputChange}
                    className={`epf-input-field ${errors.courseName ? "input-error" : ""
                      }`}
                  >
                    <option value="">Course Name</option>
                    {availableCourses.map((course) => (
                      <option key={course.name} value={course.name}>
                        {course.name}
                      </option>
                    ))}
                  </select>
                  {errors.courseName && (
                    <span className="error-text">
                      {errors.courseName}
                    </span>
                  )}
                </div>
              )}
          </div>
          {(formData.degree === "bachelors" ||
            formData.degree === "masters" ||
            formData.degree === "doctorate" ||
            formData.degree === "") && (
              <div className="epf-input-row">
                <div className="epf-input-wrapper epf-input-gap">
                  <select
                    id="courseType"
                    name="courseType"
                    value={formData.courseType}
                    onChange={handleInputChange}
                    className={`epf-input-field ${errors.courseType ? "input-error" : ""
                      }`}
                  >
                    <option value="">Course Type</option>
                    <option value="fulltime">Full Time</option>
                    <option value="parttime">Part Time</option>
                    <option value="correspondence/distance">
                      Correspondence/Distance learning
                    </option>
                  </select>
                  {errors.courseType && (
                    <span className="error-text">
                      {errors.courseType}
                    </span>
                  )}
                </div>

                <div className="epf-input-wrapper epf-input-gap">
                  <select
                    id="specialization"
                    name="specialization"
                    value={formData.specialization}
                    onChange={handleInputChange}
                    className={`epf-input-field ${errors.specialization ? "input-error" : ""
                      }`}
                  >
                    <option value="">Specialization</option>
                    {availableSpecializations.map((spec) => (
                      <option key={spec} value={spec}>
                        {spec}
                      </option>
                    ))}
                  </select>
                  {errors.specialization && (
                    <span className="error-text">
                      {errors.specialization}
                    </span>
                  )}
                </div>

                <div className="d-flex gap-4">
                  <div className="epf-input-wrapper epf-input-gap">
                    <input
                      type="number"
                      id="startingYear"
                      name="startingYear"
                      placeholder="Starting Year"
                      value={formData.startingYear}
                      onChange={handleInputChange}
                      className={`epf-input-field ${errors.startingYear ? "input-error" : ""
                        }`}
                    />
                    {errors.startingYear && (
                      <span className="error-text">
                        {errors.startingYear}
                      </span>
                    )}
                  </div>

                  <div className="epf-input-wrapper epf-input-gap">
                    <input
                      type="number"
                      id="endingYear"
                      name="endingYear"
                      placeholder="Ending Year"
                      value={formData.endingYear}
                      onChange={handleInputChange}
                      className={`epf-input-field ${errors.endingYear ? "input-error" : ""
                        }`}
                    />
                    {errors.endingYear && (
                      <span className="error-text">
                        {errors.endingYear}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}

          {(formData.degree === "12" || formData.degree === "10") && (
            <div className="epf-input-row">
              <div className="epf-input-wrapper epf-input-gap">
                <select
                  id="board"
                  name="board"
                  value={formData.board}
                  onChange={handleInputChange}
                  className={`epf-input-field ${errors.board ? "input-error" : ""
                    }`}
                >
                  <option value="">Board</option>
                  <option value="cbse">CBSE</option>
                  <option value="icse">ICSE</option>
                  <option value="nios">NIOS</option>
                  <option value="stateBoard">State Board</option>
                </select>
                {errors.board && (
                  <span className="error-text">{errors.board}</span>
                )}
              </div>

              <div className="d-flex gap-4">
                <div className="epf-input-wrapper epf-input-gap">
                  <input
                    type="number"
                    id="passingYear"
                    name="passingYear"
                    placeholder="Passing Year"
                    value={formData.passingYear}
                    onChange={handleInputChange}
                    className={`epf-input-field ${errors.passingYear ? "input-error" : ""
                      }`}
                  />
                  {errors.passingYear && (
                    <span className="error-text">
                      {errors.passingYear}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          <button type="submit" className="epf-submit-btn mt-0">
            {loading ? <div className="spinner"></div> : `Save`}
          </button>
        </form>)
      }
    </div>

    <br />

    {NewupdatedData?.qualifications?.length > 0 && (<div>
      <div className="pb-4 pt-4">
        <p>My Qualifications Details
        </p>
      </div>
      {NewupdatedData?.qualifications && NewupdatedData?.qualifications?.map((value, index) => (
        (<div key={index} className="company-info student_company_info">
          <div className="d-flex align-items-center gap-2">
            <img src={dummy_logo} alt={`logo`} className="company-logo" />
            <div>
              <h2>{value.institute}</h2>
              {value.degree !== '12' && value.degree !== '10' ? (
                <h7>
                  {value.course_name}{' '}
                  <span style={{ fontWeight: '400', fontSize: '14px' }}>
                    {`(${value.course_type === 'fulltime' ? 'full time' : value.course_type === 'parttime' ? 'part time' : value.course_type})`}
                  </span>
                </h7>
              ) : (
                <h7>
                  Class {value.degree}{'th'}
                  <span style={{ fontWeight: '400', fontSize: '14px' }}>{`(${value.board})`}</span>
                </h7>
              )}
              <div className="d-flex gap-2 jdrc_class">
                {value.degree !== '12' && value.degree !== '10' ? (
                  <div className="company-name">
                    {value.from_date?.slice(0, 4)}-{value.to_date?.slice(0, 4)}
                  </div>
                ) : (
                  <div className="company-name">{value.passing_year?.slice(0, 4)}</div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-3">
            <div onClick={() => handleDeleteQualification(value.id)} style={{ cursor: "pointer" }}>
              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z" fill="#F38D00" />
                <path d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z" fill="#F38D00" />
                <path d="M9.64788 16H4.87022C2.27302 16 2.16884 14.5637 2.08698 13.4028L1.60326 5.90889C1.58093 5.60378 1.81907 5.33587 2.12419 5.31355C2.43674 5.29866 2.69721 5.52936 2.71953 5.83448L3.20325 13.3284C3.28511 14.4596 3.31488 14.8837 4.87022 14.8837H9.64788C11.2107 14.8837 11.2404 14.4596 11.3149 13.3284L11.7986 5.83448C11.8209 5.52936 12.0888 5.29866 12.3939 5.31355C12.699 5.33587 12.9372 5.59634 12.9148 5.90889L12.4311 13.4028C12.3493 14.5637 12.2451 16 9.64788 16Z" fill="#F38D00" />
                <path d="M8.49403 11.9069H6.0159C5.71079 11.9069 5.45776 11.6539 5.45776 11.3488C5.45776 11.0437 5.71079 10.7906 6.0159 10.7906H8.49403C8.79915 10.7906 9.05217 11.0437 9.05217 11.3488C9.05217 11.6539 8.79915 11.9069 8.49403 11.9069Z" fill="#F38D00" />
                <path d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z" fill="#F38D00" />
              </svg>
            </div>

            <div onClick={() => handleEditQualification(value)} style={{ cursor: "pointer" }}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.1138 16H5.70051C1.70648 16 0 14.2935 0 10.2995V5.88618C0 1.89214 1.70648 0.185669 5.70051 0.185669H7.17161C7.47318 0.185669 7.72327 0.435756 7.72327 0.737331C7.72327 1.03891 7.47318 1.28899 7.17161 1.28899H5.70051C2.30963 1.28899 1.10332 2.49529 1.10332 5.88618V10.2995C1.10332 13.6904 2.30963 14.8967 5.70051 14.8967H10.1138C13.5047 14.8967 14.711 13.6904 14.711 10.2995V8.82838C14.711 8.5268 14.9611 8.27672 15.2627 8.27672C15.5642 8.27672 15.8143 8.5268 15.8143 8.82838V10.2995C15.8143 14.2935 14.1078 16 10.1138 16Z" fill="#222222" />
                <path d="M5.33224 12.2782C4.88356 12.2782 4.47165 12.1163 4.17007 11.8221C3.80965 11.4617 3.65519 10.9395 3.7361 10.3878L4.05239 8.17379C4.11123 7.74718 4.39074 7.19551 4.69231 6.89394L10.4884 1.09781C11.9522 -0.365936 13.438 -0.365936 14.9017 1.09781C15.7035 1.89956 16.0639 2.71602 15.9904 3.53248C15.9242 4.19447 15.5711 4.84176 14.9017 5.50375L9.10561 11.2999C8.80404 11.6015 8.25237 11.881 7.82576 11.9398L5.61175 12.2561C5.51613 12.2782 5.42051 12.2782 5.33224 12.2782ZM11.2681 1.87749L5.472 7.67362C5.33224 7.81338 5.17042 8.13702 5.141 8.32826L4.82471 10.5423C4.79529 10.7556 4.83942 10.9321 4.94976 11.0424C5.06009 11.1528 5.23662 11.1969 5.44993 11.1675L7.66393 10.8512C7.85518 10.8218 8.18618 10.66 8.31857 10.5202L14.1147 4.72407C14.5928 4.24596 14.8429 3.81934 14.8797 3.42214C14.9238 2.94404 14.6737 2.43651 14.1147 1.87013C12.9378 0.693256 12.1287 1.02425 11.2681 1.87749Z" fill="#222222" />
                <path d="M13.681 6.49694C13.6295 6.49694 13.578 6.48959 13.5339 6.47488C11.5994 5.93057 10.0621 4.39327 9.51776 2.45877C9.43685 2.16455 9.60602 1.86298 9.90024 1.77471C10.1945 1.6938 10.496 1.86298 10.5769 2.1572C11.0183 3.72392 12.2614 4.967 13.8281 5.40833C14.1223 5.48924 14.2915 5.79817 14.2106 6.09239C14.1444 6.34248 13.9237 6.49694 13.681 6.49694Z" fill="#222222" />
              </svg>
            </div>
          </div>
        </div>
        )))}
    </div>)}

  </>)
};

export default StudentQualification;