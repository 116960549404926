import { useEffect, useState } from "react";
import axios from "axios";
import "../../../../styles/dashboards/hrdashboard/circulateJob.css";
import "../../../../styles/dashboards/hrdashboard/postjobs.css";
import { useNavigate } from "react-router-dom";
import eyeIcon from "../../../../utils/dashboards/eye.svg";
import locationIcon from "../../../../utils/dashboards/location.svg";
import { Stack, Pagination } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

export default function CirculatedList() {
  const base_Url = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [circulatedJobs, setCirculatedJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 8;

  // Calculate pagination values
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = circulatedJobs.slice(indexOfFirstJob, indexOfLastJob);
  const totalPages = Math.ceil(circulatedJobs.length / jobsPerPage);

  useEffect(() => {
    axios
      .get(`${base_Url}/api/job-circulated-details/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCirculatedJobs(response.data);
      })
      .catch((error) => {
        console.error("Error fetching circulated jobs:", error);
      });
  }, [base_Url, token]);

  const handleDetails = (job) => {
    localStorage.setItem("seecirculatedjobdetails", JSON.stringify(job));
    navigate(`/dashboard/sidebar-page/hr-manager/circulated-collegslist`);
  };

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  // Add this helper function for date formatting
  const formatDate = (dateString) => {
    return new Date(dateString)
      .toLocaleDateString("en-GB")
      .split("/")
      .join("-");
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="d-flex flex-column p-4 gap-4">
            <div className="d-flex align-items-center">
              <h5>
                {" "}
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <path
                    d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                    fill="#222222"
                  />
                  <path
                    d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                    fill="#222222"
                  />
                </svg>{" "}
                &nbsp; Circulated Jobs List
              </h5>
            </div>
            <div className="circulate_job_detail_list">
              {circulatedJobs.length > 0 ? (
                <>
                  {currentJobs.map((job) => (
                    <div className="circulate_job_detail" key={job.job_id}>
                      <div className="circulate_job_detail invite_details">
                        <ul>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDetails(job)}
                          >
                            <Tooltip title="Click to see details">
                              <div>
                                <p className="label-text">Job Title</p>{" "}
                                <p>
                                  <img src={eyeIcon} alt="." />{" "}
                                  {job.job_title
                                    ? capitalizeWords(
                                        job.job_title?.length > 15
                                          ? job.job_title.substring(0, 15) +
                                              "..."
                                          : job.job_title
                                      )
                                    : "N/A"}
                                </p>
                              </div>
                            </Tooltip>
                          </li>

                          <li>
                            <Tooltip title={job.job_status || "N/A"}>
                              <p className="label-text">Status</p>{" "}
                              <p
                                className={`status ${
                                  job.job_status.toLowerCase() === "close"
                                    ? "closed"
                                    : job.job_status.toLowerCase()
                                }`}
                              >
                                {job.job_status || "N/A"}
                              </p>
                            </Tooltip>
                          </li>

                          <li>
                            <Tooltip title={formatDate(job.date_posted)}>
                              <p className="label-text">Start Date</p>{" "}
                              <p>{formatDate(job.date_posted) || "N/A"}</p>
                            </Tooltip>
                          </li>

                          <li>
                            <Tooltip
                              title={`End Date: ${formatDate(job.due_date)}`}
                            >
                              <p className="label-text">End Date</p>{" "}
                              <p>{formatDate(job.due_date) || "N/A"}</p>
                            </Tooltip>
                          </li>

                          <li>
                            <Tooltip title={job.job_type || "N/A"}>
                              <p className="label-text">Job Type</p>{" "}
                              <div className="d-flex justify-content-start">
                                <p className="circulate_job_type text-center">
                                  {job.job_type || "N/A"}
                                </p>
                              </div>
                            </Tooltip>
                          </li>

                          <li>
                            <Tooltip
                              title={`${job.min_salary} to ${job.max_salary} lpa`}
                            >
                              <p className="label-text">Salary</p>{" "}
                              <p>
                                {job.min_salary} to {job.max_salary} lpa
                              </p>
                            </Tooltip>
                          </li>

                          <li>
                            <Tooltip title={`Total Vacancies: ${job.vacancy}`}>
                              <p className="label-text">Vacancy</p>{" "}
                              <p>{job.vacancy}</p>
                            </Tooltip>
                          </li>

                          <li>
                            <Tooltip
                              title={job?.job_location || "Bangalore, India"}
                            >
                              <p className="label-text">Location</p>{" "}
                              <p className="d-flex">
                                <img src={locationIcon} alt="." />
                                <p>
                                  {(job?.job_location &&
                                    job.job_location.charAt(0).toUpperCase() +
                                      job.job_location.slice(1, 12) +
                                      (job.job_location.length > 12
                                        ? "..."
                                        : "")) ||
                                    "Bangalore, India"}
                                </p>
                              </p>
                            </Tooltip>
                          </li>
                        </ul>
                      </div>
                      <div className="circulate_job_clg_invite_details">
                        <Tooltip
                          title={`Total Colleges Shared: ${job.total_colleges_circulated}`}
                        >
                          <p>Shared Colleges {job.total_colleges_circulated}</p>
                        </Tooltip>
                        <Tooltip
                          title={`Total Colleges Accepted: ${job.total_colleges_accepted}`}
                        >
                          <p>Accepted Colleges {job.total_colleges_accepted}</p>
                        </Tooltip>
                        <Tooltip
                          title={`Total Students Applied: ${job.total_applicants}`}
                        >
                          <p>Applied Students {job.total_applicants}</p>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                  <Stack spacing={2} alignItems="center" sx={{ mt: 3, mb: 2 }}>
                    <div className="d-flex justify-content-center pagination_custom">
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(event, value) => setCurrentPage(value)}
                        color="primary"
                        className="mt-3"
                      />
                    </div>
                  </Stack>
                </>
              ) : (
                <div className="no_job_post_yet">
                  No circulated jobs available.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
