import React, { useState, useEffect } from "react";
import "../../styles/auth/auth.css";
import borderLine from "../../utils/auth/images/boderLine.svg";
import { useNavigate } from "react-router-dom";

function UserMessageAfterSignup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  useEffect(() => {
    const userEmail = sessionStorage.getItem("user-email");
    if (userEmail) {
      setEmail(JSON.parse(userEmail));
    }
  }, []);

  return (
    <div className="aftersignup_container">
      <div className="aftersignup_card">
        <div className="aftersignup_div_parent">
          <div>
            <h1 className="aftersignup_title">Hello, User !</h1>
            <img src={borderLine} alt="..." style={{ marginTop: "-10px" }} />
          </div>
          <p className="aftersignup_message">
            For the account activation link, please check the email address you
            registered with, {email}. Please check the Spam and Junk folders as
            well. Within an hour, if you have not received the email, please
            check your firewall settings or get in touch with your IT
            administrator.
          </p>
          <button className="aftersignup_button" onClick={() => navigate("/lateral-hiring")}>
            Okay
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserMessageAfterSignup;
