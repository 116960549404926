import About from "../components/landing_page/about/about";
import Campuses from "../components/landing_page/campuses/campus";
import Contact from "../components/landing_page/contact/contact";
import LandingPage from "../components/landing_page";
import LateralHiring from "../components/landing_page/lateralhiring/lateralhiring";
import LetralMyProfile from "../components/landing_page/lateralhiring/myProfile/MyProfile";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import AllJobs from "../components/landing_page/alljobs/allJobs";

import JobDetails from "../components/landing_page/alljobs/jobDetails";
import SavedJobs from "../components/landing_page/savedJobs/savedJobs";
import MyApplication from "../components/landing_page/myApplication/MyApplication";

import LetralRegister from "../components/auth/letralRegister";
import UserMessageAfterSignup from "../components/auth/userMessage";
import DashboardSideBar from "../components/dashboards/dashboard-sider-bar/index";
import HRManagerMessages from "../components/dashboards/hr-manager-dashbaord/HRManagerMessages ";
import PostJob from "../components/dashboards/hr-manager-dashbaord/jobs/PostJob.jsx";
import HRManagerHome from "../components/dashboards/hr-manager-dashbaord/HRManagerHome .jsx";
import HRManagerAllApplications from "../components/dashboards/hr-manager-dashbaord/HRManagerAllApplications .jsx";
import HRManagerMySchedule from "../components/dashboards/hr-manager-dashbaord/HRManagerMySchedule .jsx";
import HrManagerCampuses from "../components/dashboards/hr-manager-dashbaord/HrManagerCampuses.jsx";
import HRManagerProfile from "../components/dashboards/hr-manager-dashbaord/hrprofile/HRManagerProfile.jsx";
import HRManagerSubUsers from "../components/dashboards/hr-manager-dashbaord/HRManagerSubUsers.jsx";
import HRManagerSettings from "../components/dashboards/hr-manager-dashbaord/HRManagerSettings.jsx";
import HRTransectionHistory from "../components/dashboards/hr-manager-dashbaord/HRTransectionHistory.jsx";

import JobsCirculate from "../components/dashboards/hr-manager-dashbaord/jobs/JobsCirculate.jsx";
import CampusManagerHome from "../components/dashboards/collage-dashboard/CampusManagerHome .jsx";
import CampusManagerStudent from "../components/dashboards/collage-dashboard/CampusManagerStudent .jsx";
import CampusManagerJobs from "../components/dashboards/collage-dashboard/CampusManagerJobs .jsx";
import CampusManagerCourse from "../components/dashboards/collage-dashboard/CampusManagerCourse .jsx";
import CampusManagerMySchedule from "../components/dashboards/collage-dashboard/CampusManagerMySchedule .jsx";
import CampusManagerInvitation from "../components/dashboards/collage-dashboard/CampusManagerInvitation.jsx";
import CampuseManagerCandiate from "../components/dashboards/collage-dashboard/CampuseManagerCandiate.jsx";
import CampuseCandidateRequest from "../components/dashboards/collage-dashboard/CampuseCandidateRequest.jsx";
import CampuseManagerSubscription from "../components/dashboards/collage-dashboard/CampuseManagerSubscription.jsx";
import CampusManagerProfile from "../components/dashboards/collage-dashboard/CampusManagerProfile.jsx";
import CampusManagerSettings from "../components/dashboards/collage-dashboard/CampusManagerSettings.jsx";
import CampuseTransectionHistory from "../components/dashboards/collage-dashboard/CampuseTransectionHistory.jsx";

import AdminRequest from "../components/dashboards/adminDashboard/AdminRequest.jsx";
import CampuseJobList from "../components/dashboards/hr-manager-dashbaord/jobs/CampuseJobList .jsx";
import LateralJobList from "../components/dashboards/hr-manager-dashbaord/jobs/LateralJobList.jsx";
import CirculatedList from "../components/dashboards/hr-manager-dashbaord/jobs/CirculatedList.jsx";
import StudentHome from "../components/dashboards/campus-students-dashbaord/StudentHome .jsx";
import StudentMessages from "../components/dashboards/campus-students-dashbaord/StudentMessages .jsx";
import StudentMyApplication from "../components/dashboards/campus-students-dashbaord/StudentMyApplication .jsx";
import StudentSavedJobs from "../components/dashboards/campus-students-dashbaord/StudentSavedJobs.jsx";
import StudentSettings from "../components/dashboards/campus-students-dashbaord/StudentSettings.jsx";
import StudentProfile from "../components/dashboards/campus-students-dashbaord/student_profile/StudentProfile.jsx";
import StudentInterview from "../components/dashboards/campus-students-dashbaord/StudentInterview.jsx";
import StudentJobs from "../components/dashboards/campus-students-dashbaord/StudentJobs.jsx";
import CollegeDetails from "../components/dashboards/hr-manager-dashbaord/CollegeDetails.jsx";
import HRAssessment from "../components/dashboards/hr-manager-dashbaord/assessments/HRAssessment.jsx";
import CirculateCollegeList from "../components/dashboards/hr-manager-dashbaord/jobs/CirculateCollegeList.jsx";
import AssessmentQuestion from "../components/dashboards/hr-manager-dashbaord/assessments/AssessmentQuestion.jsx";
import Setting from "../components/landing_page/lateralhiring/settings/Settings.jsx";

import ViewApplications from "../components/dashboards/hr-manager-dashbaord/jobs/ViewApplications.jsx";
import ViewLateralCandidateApplication from "../components/dashboards/hr-manager-dashbaord/ViewLateralCandidateApplication.jsx";
import ViewCampusCandidateApplication from "../components/dashboards/hr-manager-dashbaord/ViewCampusCandidateApplication.jsx";
import CampusJobDetails from "../components/dashboards/collage-dashboard/Campus_Job_details.jsx";
import AdminHome from "../components/dashboards/adminDashboard/AdminHome.jsx";
import Students from "../components/dashboards/adminDashboard/Students.jsx";
import Companies from "../components/dashboards/adminDashboard/Companies.jsx";
import MySchedule from "../components/dashboards/adminDashboard/MySchedule.jsx";
import AdminJobListing from "../components/dashboards/adminDashboard/AdminJobListing.jsx";
import AdminAssessment from "../components/dashboards/adminDashboard/AdminAssessment.jsx";
import Settings from "../components/dashboards/adminDashboard/settings/Settings.jsx";
import AdminPlans from "../components/dashboards/adminDashboard/subscriptions/AdminPlans.jsx";
import CandidateOfSelectedCollege from "../components/dashboards/hr-manager-dashbaord/campuse-interview/CandidateOfSelectedCollege.jsx";
import CandidateOfColleges from "../components/dashboards/hr-manager-dashbaord/campuse-interview/CandidateOfColleges.jsx";
import Colleges from "../components/dashboards/adminDashboard/Colleges.jsx";
import AdminCollegeDetail from "../components/dashboards/adminDashboard/admin-college-detail.jsx";
import SubUserList from "../components/dashboards/hr-manager-dashbaord/SubUserList.jsx";
import TransactionHistory from "../components/dashboards/collage-dashboard/TransactionHistory.jsx";
import ViewSubUser from "../components/dashboards/hr-manager-dashbaord/ViewSubUser.jsx";

import ViewCompanyDetails from "../components/dashboards/adminDashboard/ViewCompanyDetails.jsx";
import ClientTestimonial from "../components/dashboards/adminDashboard/content_management/testimonials/ ClientTestimonial.jsx";
import AllTestimonials from "../components/dashboards/adminDashboard/content_management/testimonials/AllTestimonials.jsx";
import FAQ from "../components/dashboards/adminDashboard/content_management/faq/FAQ.jsx";
import EditJob from "../components/dashboards/hr-manager-dashbaord/jobs/EditJob.jsx";
import ViewJobDetails from "../components/dashboards/hr-manager-dashbaord/jobs/ViewJobDetails.jsx";
import Jobs from "../components/dashboards/adminDashboard/Jobs/Jobs.jsx";
import ZoomMeeting from "../components/dashboards/hr-manager-dashbaord/guid/zoommeeting.jsx";

import PublicJobDetails from "../components/common_component/job_details/PublicJobDetails.jsx";
import { components } from "react-select";
import { patch } from "@mui/material";
import JobApplications from "../components/dashboards/adminDashboard/JobApplications.jsx";

export const navigateRoutes = [
  {
    path: "/",
    component: <LateralHiring />,
  },
  {
    path: "/digital-campus-hiring",
    component: <LandingPage />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/about",
    component: <About />,
  },
  {
    path: "/lateral/contact",
    component: <Contact />,
  },
  {
    path: "/lateral/about",
    component: <About />,
  },
  {
    path: "/campuses",
    component: <Campuses />,
  },
  {
    path: "/lateral-hiring",
    component: <LateralHiring />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/lateral/register",
    component: <LetralRegister />,
  },
  {
    path: "/lateral/alljobs",
    component: <AllJobs />,
  },
  {
    path: "/lateral/jobDetails/:id",
    component: <JobDetails />,
  },
  {
    path: "/public/job-details/:id",
    component: <PublicJobDetails />,
  },
  {
    path: "/lateral/savedJobs",
    component: <SavedJobs />,
    protected: true,
  },
  {
    path: "/lateral/myApplication",
    component: <MyApplication />,
    protected: true,
  },
  {
    path: "/lateral/myProfile",
    component: <LetralMyProfile />,
    protected: true,
  },
  {
    path: "/lateral/setting",
    component: <Setting />,
    protected: true,
  },
  {
    path: "/auth/userMessage",
    component: <UserMessageAfterSignup />,
  },
  {
    path: "/dashboard/sidebar-page/:roleName",
    component: <DashboardSideBar />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/home",
    component: <HRManagerHome />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/message",
    component: <HRManagerMessages />,
    protected: true,
  },

  {
    path: "/dashboard/sidebar-page/:roleName/post-job",
    component: <PostJob />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/all-applications",
    component: <HRManagerAllApplications />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/my-schedule",
    component: <HRManagerMySchedule />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/transaction-history",
    component: <HRTransectionHistory />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/campuses",
    component: <HrManagerCampuses />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/my-profile",
    component: <HRManagerProfile />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/sub-user-settings",
    component: <HRManagerSubUsers />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/hr-user-settings",
    component: <HRManagerSettings />,
    protected: true,
  },

  // jobs listing start routing
  {
    path: "/dashboard/sidebar-page/:roleName/campus-job-list",
    component: <CampuseJobList />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/lateral-job-list",
    component: <LateralJobList />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/circulated-job-list",
    component: <CirculatedList />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/circulate-jobs",
    component: <JobsCirculate />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/circulated-collegslist",
    component: <CirculateCollegeList />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/college-details/:collegeId",
    component: <CollegeDetails />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/assessment",
    component: <HRAssessment />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/assessment/:categoryId/:categoryName",
    component: <AssessmentQuestion />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/view-applicants/:id/:jobName",
    component: <ViewApplications />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/lateral/view-candidate-application/:id/:application_id",
    component: <ViewLateralCandidateApplication />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/campus/view-candidate-application/:id/:application_id",
    component: <ViewCampusCandidateApplication />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/collage-applicants/:clgId/:jobId/:round",
    component: <CandidateOfSelectedCollege />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/applied-student-of-college/:collegeId/:jobId/:jobName",
    component: <CandidateOfColleges />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/hr-manager/sub-user-list",
    component: <SubUserList />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/hr-manager/view-job-details/:id/:jobType",
    component: <ViewJobDetails />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/hr-manager/guide-hr",
    component: <ZoomMeeting />,
    protected: true,
  },

  // jobs listing end routing

  // clg dashboard
  {
    path: "/dashboard/sidebar-page/:roleName/clg-home",
    component: <CampusManagerHome />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-student",
    component: <CampusManagerStudent />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-jobs",
    component: <CampusManagerJobs />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-course",
    component: <CampusManagerCourse />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-my-schedule",
    component: <CampusManagerMySchedule />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-company-invitation",
    component: <CampusManagerInvitation />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-candidate-request",
    component: <CampuseCandidateRequest />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-candidates",
    component: <CampuseManagerCandiate />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-campuse-manager-subscription",
    component: <CampuseTransectionHistory />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-settings",
    component: <CampusManagerSettings />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-profile",
    component: <CampusManagerProfile />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-profile",
    component: <CampusManagerProfile />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/clg-upgrade-plan",
    component: <CampuseManagerSubscription />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/job-details/:id",
    component: <CampusJobDetails />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/transaction-history",
    component: <TransactionHistory />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/hr-manager/view-sub-hr-details/:subUserId",
    component: <ViewSubUser />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/hr-manager/edit-job/:jobId/:jobType",
    component: <EditJob />,
    protected: true,
  },

  // student pages routing's
  {
    path: "/dashboard/sidebar-page/:roleName/student-home",
    component: <StudentHome />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/student-message",
    component: <StudentMessages />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/my-application",
    component: <StudentMyApplication />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/student-settings",
    component: <StudentSettings />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/student-profile",
    component: <StudentProfile />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/my-interview",
    component: <StudentInterview />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/student-jobs",
    component: <StudentJobs />,
    protected: true,
  },
  // admin panel
  {
    path: "/dashboard/sidebar-page/:roleName/student-saved-jobs",
    component: <StudentSavedJobs />,
    protected: true,
  },

  // admin panel
  {
    path: "/dashboard/sidebar-page/admin/home",
    component: <AdminHome />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/admin/company-request",
    component: <AdminRequest />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/admin/college-request",
    component: <AdminRequest />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/admin/college-request",
    component: <AdminRequest />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/admin/colleges",
    component: <Colleges />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/admin/colleges/:id",
    component: <AdminCollegeDetail />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/admin/students",
    component: <Students />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/companies",
    component: <Companies />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/admin-my-schedule",
    component: <MySchedule />,
    protected: true,
  } /*  */,
  {
    path: "/dashboard/sidebar-page/admin/all-applications/:name/:filterType",
    component: <JobApplications />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/job-listing",
    component: <AdminJobListing />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/admin-assessment",
    component: <AdminAssessment />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/settings",
    component: <Settings />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/plans",
    component: <AdminPlans />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/:roleName/transaction-history",
    component: <AdminPlans />,
    protected: true,
  },
  {
    path: `/dashboard/sidebar-page/:roleName/content-management`,
    component: <ClientTestimonial />,
    protected: true,
  },
  {
    path: `/dashboard/sidebar-page/:roleName/all-testimonials`,
    component: <AllTestimonials />,
    protected: true,
  },
  {
    path: `/dashboard/sidebar-page/:roleName/faq`,
    component: <FAQ />,
    protected: true,
  },
  {
    path: `/dashboard/sidebar-page/admin/view-company-details/:id`,
    component: <ViewCompanyDetails />,
    protected: true,
  },
  {
    path: "/dashboard/sidebar-page/admin/admin-jobs",
    component: <Jobs />,
    protected: "true",
  },
];
