import React, { useEffect, useState } from "react";
import Png_img_stud from "../../../utils/landingpage/images/Png_img_stud.png";
import Arrow_png_stud from "../../../utils/landingpage/images/Arrow_png_stud.png";
import Facebook_png_stud from "../../../utils/landingpage/images/Facebook_png_stud.png";
import Mditwitter_png_stud from "../../../utils/landingpage/images/Mditwitter_png_stud.png";
import Indeed_png_stud from "../../../utils/landingpage/images/Indeed_png_stud.png";
import Instragram_png_stud from "../../../utils/landingpage/images/Instragram_png_stud.png";
import Three_png_dot from "../../../utils/landingpage/images/Three_png_dot.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import Stack from "@mui/material/Stack";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../../../styles/dashboards/clgstudent/studentmyapplications.css";
import { fetchStudentApplicationData } from "../../../redux/actions/student_profile_action";
import { saveAs } from "file-saver";
import Loader from "react-spinners/ClipLoader";

const StudentMyApplication = () => {
  const studentApplicationData = useSelector((state) => state.studentProfile.getStudentApplicationData);
  const isLoadingRedux = useSelector((state) => state.studentProfile.isLoadingMyApplication);
  const dispatch = useDispatch()

  const [visibledata, Setvisibledata] = useState(true);
  const [activeTab, setActiveTab] = useState("tab1");
  const [showOptions, setShowOptions] = useState(null);
  const [studentinreview, setStudentinreview] = useState([]);
  const [studentshortlist, setStudentshortlist] = useState([]);
  const [studentoffered, setStudentoffered] = useState([]);
  const [discardedstudent, setDiscardedtudent] = useState([]);
  const [studentalljobs, setStudentalljobs] = useState([]);
  const [studentinterviewed, setStudentinterviewed] = useState([]);
  const [withdraw, setWithdraw] = useState([]);
  const [session, setSession] = useState();
  const [startDate, setStartDate] = useState(null);
  const [id, setId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(isLoadingRedux)
  }, [isLoadingRedux]);

  const handleImageClick = (index) => {
    if (showOptions === index) {
      setShowOptions(null);
    } else {
      setShowOptions(index);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleDownloadOfferLetter = (url) => {
    if (!url) {
      console.error("No URL provided for download");
      return;
    }

    try {
      saveAs(url?.offer_letter_url, `${url?.job?.company?.slice(0, 10)}-Offer-letter.pdf`);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  useEffect(() => {
    const Name = JSON.parse(sessionStorage.getItem("userDetails"));
    if (Name) {
      setSession(Name.name);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchStudentApplicationData())
  }, [dispatch]);

  useEffect(() => {
    setStudentinreview(studentApplicationData.in_review?.applications);
    setStudentshortlist(studentApplicationData.shortlisted?.applications);
    setStudentoffered(studentApplicationData.offered?.applications);
    setDiscardedtudent(studentApplicationData.discarded?.applications);
    setStudentalljobs(studentApplicationData.all_jobs?.applications);
    setStudentinterviewed(studentApplicationData.interviewed?.applications);
  }, [dispatch, studentApplicationData])

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     const formattedStartDate = startDate.toISOString().split("T")[0];
  //     const formattedEndDate = endDate.toISOString().split("T")[0];
  //     fetchStudentApplicationDate(formattedStartDate, formattedEndDate);
  //   }
  // }, [endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      fetchStudentApplicationDate(formattedStartDate, formattedEndDate);
    }
  }, [endDate]);

  const formatDate = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12, 0, 0, 0);
    return adjustedDate.toISOString().split("T")[0];
  };

  const fetchStudentApplicationDate = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    setLoading(true)

    // const formattedStartDate = startDate ? startDate.toISOString().split("T")[0] : null;
    // const formattedEndDate = endDate ? endDate.toISOString().split("T")[0] : null;

    const formattedStartDate = startDate ? formatDate(startDate) : null;
    const formattedEndDate = endDate ? formatDate(endDate) : null;

    console.log(`Start Date: ${formattedStartDate}, End Date: ${formattedEndDate}`);

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/student_applied_jobs/?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setLoading(false)
        // setStudentJobData(response.data);
        setStudentinreview(response.data.in_review.applications);
        setStudentshortlist(response.data.shortlisted.applications);
        setStudentoffered(response.data.offered.applications);
        setDiscardedtudent(response.data.discarded.applications);
        setStudentalljobs(response.data.all_jobs.applications);
        setStudentinterviewed(response.data.interviewed.applications);
      })
      .catch((error) => {
        setLoading(false)
      });
  };

  function handlewithdraw(id) {
    const userDetails = {
      // application_ids":[113]
      //  job_id: index,
      application_ids: [id],
    };
    const accessToken = sessionStorage.getItem("accessToken");

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/withdraw_application/`,
        userDetails,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          //  body: JSON.stringify({
          //    application_ids: [113],
          //  }),
        }
      )
      .then((response) => {
        console.log("Save successful", response.data.detail);
        setWithdraw(response.data.detail); // Set the saved message if needed
        toast.success(response.data.detail);

        // fetchStudentApplication();
        dispatch(fetchStudentApplicationData())
        //  fetchStudentAlljobs();
      })
      .catch((error) => {
        toast.error("Failed to save the job. Please try again.");
      });
  }

  const itemsPerPage = 5;

  const totalPages = Math.ceil((
    activeTab === "tab1" ? studentalljobs :
      activeTab === "tab2" ? studentinreview :
        activeTab === "tab3" ? studentinterviewed :
          activeTab === "tab4" ? studentshortlist :
            activeTab === "tab5" ? studentoffered :
              activeTab === "tab6" ? discardedstudent : []
  )?.length / itemsPerPage);

  const currentItems = (
    activeTab === "tab1" ? studentalljobs :
      activeTab === "tab2" ? studentinreview :
        activeTab === "tab3" ? studentinterviewed :
          activeTab === "tab4" ? studentshortlist :
            activeTab === "tab5" ? studentoffered :
              activeTab === "tab6" ? discardedstudent : []
  )?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const formatDatedata = (date) => {
    if (date) {
      return format(date, "dd MMM yyyy"); // Format as "05 Aug 2024"

      // return new Date(date).toISOString().split("T")[0];
    }
    return "";
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    dispatch(fetchStudentApplicationData())
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          {isLoading ?
            (<div className="custom_loader">
              <Loader color="#F38D00" size={50} />
            </div>)
            :
            (<div className="student_campus_myapplication_page">
              <div className="student_campus_Myapplication_parrent">
                <div>
                  <h3 className="student_campus_keep_heading">
                    {" "}
                    Keep it up, {session}
                  </h3>
                  <p className="student_campus_keep_paragraf">
                    Here is the job applications status from{" "}
                    {startDate && <span>{formatDatedata(startDate)}</span>}-
                    {endDate && <span>{formatDatedata(endDate)}</span>}
                  </p>
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div>
                    <DatePicker
                      className="student_campus_day_date"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd MMM yyyy"
                      placeholderText="Select start date"
                    />
                  </div>

                  <div>
                    <DatePicker
                      className="student_campus_day_date"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      maxDate={new Date()}
                      dateFormat="dd MMM yyyy"
                      placeholderText="Select end date"
                    />
                  </div>
                  {/* <p
                  className="student_campus_reset"
                  onClick={() => fetchStudentApplicationDate()}
                >
                  {loading ? <div className="spinner"></div> : 'Save'}
                </p> */}
                  {loading && <div className="spinner"></div>}
                  <p
                    onClick={handleReset}
                    className="student_campus_reset"
                  >
                    Reset
                  </p>
                </div>

              </div>

              <div className="student_campus_tab_div">
                <div className="student_campus_tab-buttons">
                  <button
                    onClick={() => handleTabClick("tab1")}
                    className="student_campus_button_tab"
                  >
                    <p
                      // className="student_campus_paragraf_alljobs"
                      className={`student_campus_paragraf_alljobs ${activeTab === "tab1" ? "activealljob" : ""
                        }`}
                    >
                      All Jobs ({studentalljobs?.length || 0})
                    </p>
                  </button>
                  <button
                    onClick={() => handleTabClick("tab2")}
                    className="student_campus_button_tab1 "
                  >
                    <p
                      // className="student_campus_paragraf_inreview"
                      className={`student_campus_paragraf_inreview ${activeTab === "tab2" ? "activeinreview" : ""
                        }`}
                    >
                      In Review ({studentinreview?.length || 0})
                    </p>
                  </button>
                  <button
                    onClick={() => handleTabClick("tab3")}
                    className="student_campus_button_tab1 "
                  >
                    <p
                      // className="student_campus_paragraf_inreview"
                      className={`student_campus_paragraf_inreview ${activeTab === "tab3" ? "activeinreview" : ""
                        }`}
                    >
                      Interviewing ({studentinterviewed?.length || 0})
                    </p>
                  </button>
                  <button
                    onClick={() => handleTabClick("tab4")}
                    className="student_campus_button_tab1 "
                  >
                    <p
                      // className="student_campus_paragraf_inreview"
                      className={`student_campus_paragraf_inreview ${activeTab === "tab4" ? "Shortlisted" : ""
                        }`}
                    >
                      Shortlisted ({studentshortlist?.length || 0})
                    </p>
                  </button>
                  <button
                    onClick={() => handleTabClick("tab5")}
                    className="student_campus_button_tab1 "
                  >
                    <p
                      // className="student_campus_paragraf_Offered"
                      className={`student_campus_paragraf_Offered ${activeTab === "tab5" ? "Shortlisted" : ""
                        }`}
                    >
                      Offered ({studentoffered?.length || 0})
                    </p>
                  </button>

                  <button
                    onClick={() => handleTabClick("tab6")}
                    className="student_campus_button_tab5"
                  >
                    <p
                      // className="student_campus_paragraf_Offered"
                      className={`student_campus_paragraf_Offered ${activeTab === "tab6" ? "discarded" : ""
                        }`}
                    >
                      Declined ({discardedstudent?.length || 0})
                    </p>
                  </button>
                </div>
                <div className="student_campus_tab-content">
                  {activeTab === "tab1" && (
                    <div>
                      <h2 className="student_campus_alljobs_heading">
                        Applications History
                      </h2>

                      {loading ? <div className="spinner"></div> : null}

                      <div className="student_campus_table_div">
                        {currentItems && currentItems?.length > 0 ? (
                          <table class="student_campus_styled-table">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Company Name</th>
                                <th>Roles</th>
                                <th> Date Applied</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems && currentItems?.map((item, index) => {
                                return (
                                  <tr key={item.id}>
                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td>{item.job.company}</td>
                                    <td>{item.job.title}</td>
                                    <td>
                                      {formatDatedata(item.job.created_at)}
                                    </td>
                                    <td>
                                      <button className="student_campus_short_list">
                                        {item.status.replace("_", " ")}
                                      </button>
                                    </td>
                                    <td>
                                      <img
                                        src={Three_png_dot}
                                        alt=""
                                        className="student_campus_img_Threedot"
                                        onClick={() => handleImageClick(index)}
                                      />

                                      {showOptions === index && (
                                        <div className="more-options-dropdown" style={{ left: "5px" }}>
                                          <ul>
                                            {item.status.replace("_", " ") === "in review" ? (
                                              <li>
                                                <button
                                                  onClick={() => {
                                                    handlewithdraw(item.id);
                                                    setId(item.job.id);
                                                  }}
                                                  className="student_widthdraw_button"
                                                >
                                                  Withdraw
                                                </button>
                                              </li>
                                            ) : null}
                                            <li>
                                              <Link to={`/dashboard/sidebar-page/student/job-details/${item.job.id}`}>
                                                View
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <h1 style={{ fontSize: 16, textAlign: "center" }}>
                            No Data Found
                          </h1>
                        )}
                      </div>
                    </div>
                  )}

                  {activeTab === "tab2" && (
                    <div>
                      <h3 className="student_campus_alljobs_heading">
                        In review
                      </h3>
                      <div className="student_campus_table_div">
                        {studentinreview?.length > 0 ? (
                          <table class="student_campus_styled-table">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Company Name</th>
                                <th>Roles</th>
                                <th> Date Applied</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.map((item, index) => {
                                return (
                                  <tr key={item.id}>
                                    <td>{index + 1}</td>
                                    <td>{item.job.company}</td>
                                    <td>{item.job.title}</td>
                                    <td>{formatDatedata(item.job.created_at)}</td>
                                    <td>
                                      <button className="student_campus_short_list">
                                        {/* {item.job.status}
                                        In review */}
                                        {item.status.replace("_", " ")}
                                      </button>
                                    </td>
                                    <td>
                                      <img
                                        src={Three_png_dot}
                                        alt=""
                                        className="student_campus_img_Threedot"
                                        onClick={() => handleImageClick(index)}
                                      />

                                      {showOptions === index && (

                                        <div className="more-options-dropdown" style={{ left: "5px" }}>
                                          <ul>
                                            {item.status.replace("_", " ") === "in review" ? (
                                              <li>
                                                <button
                                                  onClick={() => {
                                                    handlewithdraw(item.id);
                                                    setId(item.job.id);
                                                  }}
                                                  className="student_widthdraw_button"
                                                >
                                                  Withdraw
                                                </button>
                                              </li>
                                            ) : null}
                                            <li>
                                              <Link to={`/dashboard/sidebar-page/student/job-details/${item.job.id}`}>
                                                View
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>

                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <h1 style={{ fontSize: 16, textAlign: "center" }}>
                            No Data Found
                          </h1>
                        )}
                      </div>
                    </div>
                  )}

                  {activeTab === "tab3" && (
                    <div>
                      <h3 className="student_campus_alljobs_heading">
                        Interviewing
                      </h3>
                      <div className="student_campus_table_div">
                        {studentinterviewed?.length > 0 ? (
                          <table class="student_campus_styled-table">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Company Name</th>
                                <th>Roles</th>
                                <th> Date Applied</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.map((item, index) => {
                                return (
                                  <tr key={item.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {item.job.company || "Not mentioned"}
                                    </td>
                                    <td>{item.job.title || "Not mentioned"}</td>
                                    <td>
                                      {formatDatedata(item.job.created_at)}
                                    </td>
                                    <td>
                                      <button className="student_campus_short_list">
                                        {/* {item.job.status} */}
                                        {item.status.replace("_", " ")}
                                      </button>
                                    </td>
                                    <td>
                                      <img
                                        src={Three_png_dot}
                                        alt=""
                                        className="student_campus_img_Threedot"
                                        onClick={() => handleImageClick(index)}
                                      />

                                      {showOptions === index && (

                                        <div className="more-options-dropdown" style={{ left: "5px" }}>
                                          <ul>
                                            <li>
                                              <Link to={`/dashboard/sidebar-page/student/job-details/${item.job.id}`}>
                                                View
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <h1 style={{ fontSize: 16, textAlign: "center" }}>
                            No Data Found
                          </h1>
                        )}
                      </div>
                    </div>
                  )}

                  {activeTab === "tab4" && (
                    <div>
                      <h3 className="student_campus_alljobs_heading">
                        Shortlisted
                      </h3>
                      <div className="student_campus_table_div">
                        {studentshortlist?.length > 0 ? (
                          <table class="student_campus_styled-table">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Company Name</th>
                                <th>Roles</th>
                                <th> Date Applied</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.map((item, index) => {
                                return (
                                  <tr key={item.id}>
                                    <td>{index + 1}</td>
                                    <td>{item.job.company}</td>
                                    <td>{item.job.title}</td>
                                    <td>{formatDatedata(item.job.created_at)}</td>
                                    <td>
                                      <button className="student_campus_short_list">
                                        {/* {item.job.status}
                                        Shortlisted */}
                                        {item.status.replace("_", " ")}
                                      </button>
                                    </td>
                                    <td>
                                      <img
                                        src={Three_png_dot}
                                        alt=""
                                        className="student_campus_img_Threedot"
                                        onClick={() => handleImageClick(index)}
                                      />

                                      {showOptions === index && (
                                        <div className="more-options-dropdown" style={{ left: "5px" }}>
                                          <ul>
                                            <li>
                                              <Link to={`/dashboard/sidebar-page/student/job-details/${item.job.id}`}>
                                                View
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <h1 style={{ fontSize: 16, textAlign: "center" }}>
                            No Data Found
                          </h1>
                        )}
                      </div>
                    </div>
                  )}

                  {activeTab === "tab5" && (
                    <div>
                      <h3 className="student_campus_alljobs_heading">
                        Offered
                      </h3>
                      <div className="student_campus_table_div">
                        {studentoffered?.length > 0 ? (
                          <table class="student_campus_styled-table">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Company Name</th>
                                <th>Roles</th>
                                <th> Date Applied</th>
                                <th>Status</th>
                                <th>Offer Letter</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.job.company}</td>
                                    <td>{item.job.title}</td>
                                    <td>{formatDatedata(item.job.created_at)}</td>
                                    <td>
                                      <button className="student_campus_short_list">
                                        {/* {item.job.status} */}
                                        {item.status.replace("_", " ")}
                                      </button>
                                    </td>
                                    <td>
                                      {item.offer_letter_url ?
                                        (<button onClick={() => handleDownloadOfferLetter(item)} className="student_campus_short_list" style={{ background: '#f38d00' }}>
                                          Download
                                        </button>)
                                        :
                                        (<button className="student_campus_short_list" style={{ background: '#f38d00' }}>
                                          Not Available
                                        </button>)}
                                    </td>
                                    <td>
                                      <img
                                        src={Three_png_dot}
                                        alt=""
                                        className="student_campus_img_Threedot"
                                        onClick={() => handleImageClick(index)}
                                      />

                                      {showOptions === index && (
                                        <div className="more-options-dropdown" style={{ left: "5px" }}>
                                          <ul>
                                            <li>
                                              <Link to={`/dashboard/sidebar-page/student/job-details/${item.job.id}`}>
                                                View
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <h1 style={{ fontSize: 16, textAlign: "center" }}>
                            No Data Found
                          </h1>
                        )}
                      </div>
                    </div>
                  )}

                  {activeTab === "tab6" && (
                    <div>
                      <h3 className="student_campus_alljobs_heading">
                        Declined
                      </h3>
                      <div className="student_campus_table_div">
                        {discardedstudent?.length > 0 ? (
                          <table className="student_campus_styled-table">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Company Name</th>
                                <th>Roles</th>
                                <th>Date Applied</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.job.company}</td>
                                    <td>{item.job.title}</td>
                                    <td>{formatDatedata(item.job.created_at)}</td>
                                    <td>
                                      <button className="student_campus_short_list">
                                        {/* {item.job.status}
                                        discarded */}
                                        {item.status.replace("_", " ")}
                                      </button>
                                    </td>
                                    <td>
                                      <img
                                        src={Three_png_dot}
                                        alt=""
                                        className="student_campus_img_Threedot"
                                        onClick={() => handleImageClick(index)}
                                      />

                                      {showOptions === index && (

                                        <div className="more-options-dropdown" style={{ left: "5px" }}>
                                          <ul>
                                            <li>
                                              <Link to={`/dashboard/sidebar-page/student/job-details/${item.job.id}`}>
                                                View
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>

                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <h1 style={{ fontSize: 16, textAlign: "center" }}>
                            No Data Found
                          </h1>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="pagination-controls">
                <Stack spacing={2} alignItems="center">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    siblingCount={1}
                    boundaryCount={1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: () => (
                            <img src={leftArrowIcon} alt="prev" />
                          ),
                          next: () => <img src={rightIconArrow} alt="next" />,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              </div>
            </div>)}
        </div>
      </div>
    </>
  );
};
export default StudentMyApplication;
