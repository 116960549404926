import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ThreeDot from "../../../utils/dashboards/three-dot-action-icon.svg";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

const AdminCollegeDetaile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [isCollegeActive, setIsCollegeActive] = useState(true);
  const [collegeById, setCollegeByIdData] = useState({});
  const [coursesData, setCoursesData] = useState([]);
  const [errors, setErrors] = useState({});
  const token = sessionStorage.getItem("accessToken");
  const [formData, setFormData] = useState({
    name: "",
    duration: "",
    description: "",
    contact_info: "",
  });

  const fetchCollegeDetails = async (collegeId) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/details_college/${collegeId}/`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.ok) {
      throw new Error(`Error fetching college details: ${response.status}`);
    }
    return response.json();
  };

  const fetchCourses = async () => {
    const token = sessionStorage.getItem("accessToken");
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/courses/`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error(`Error fetching courses: ${response.status}`);
    }
    return response.json();
  };

  const fetchCollegeData = async (collegeId) => {
    try {
      const [collegeData, coursesData] = await Promise.all([
        fetchCollegeDetails(collegeId),
        fetchCourses(),
      ]);

      setCollegeByIdData(collegeData);
      setCoursesData(coursesData);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      toast.error("Failed to fetch college details or courses.");
    }
  };

  useEffect(() => {
    fetchCollegeData(id);
  }, [id]);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setActiveDropdown(!activeDropdown);
  };

  const handleStatusToggle = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/active_deactive_college/${id}/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        // Toggle college status on success
        setIsCollegeActive((prevStatus) => !prevStatus);
        setActiveDropdown(false); // Close dropdown after action
      }
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Course Name is required";
    if (!formData.duration.trim()) newErrors.duration = "Duration is required";
    if (!formData.description.trim())
      newErrors.description = "Description is required";
    if (!formData.contact_info.trim())
      newErrors.contact_info = "Contact Information is required";
    else if (!/^\d{10}$/.test(formData.contact_info))
      newErrors.contact_info =
        "Contact Information must be a valid 10-digit number";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    alert(JSON.stringify(formData, null, 2));
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div>
          <h6>
            {" "}
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <path
                d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                fill="#222222"
              />
              <path
                d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                fill="#222222"
              />
            </svg>{" "}
            &nbsp; View College Details
          </h6>
        </div>
        <div className="college_detail_page_container">
          <div className="detail_dot_container">
            {" "}
            <span
              className={
                isCollegeActive ? "detail_active_dot" : "detail_deactivate_dot"
              }
            ></span>
          </div>
          <div className="college_detail_page_inner_container">
            <div className="dashborad_detail_page_logo_title_container">
              <img
                src={
                  collegeById?.college_profile?.college_logo_url ||
                  "https://via.placeholder.com/100"
                }
                alt="college_logo"
                className="dashboard_college_detail_logo"
              />
              <div>
                <h4>{`${collegeById?.collegename}`}</h4>
                <p>{`${collegeById?.college_api?.district_name} (${collegeById?.college_api?.state_name})`}</p>
              </div>
            </div>
            <div className="dashborad_detail_page_college_detail_container">
              <div>
                <span>Full Name</span>
                <h5>{collegeById?.name}</h5>
              </div>
              <span className="detail_right_border"></span>
              <div>
                <span>Email</span>
                <h5>{collegeById?.email}</h5>
              </div>
              <span className="detail_right_border"></span>
              <div>
                <span>Phone Number</span>
                <h5>{collegeById?.all_mobno}</h5>
              </div>
              <div onClick={(e) => toggleDropdown(e)} className="dropdown">
                <img src={ThreeDot} alt="action Icon" />
                <div
                  className={`dropdown_content_actions ${
                    activeDropdown ? "show_active" : "show_inactive"
                  }`}
                >
                  <a href="#" onClick={handleStatusToggle}>
                    {isCollegeActive ? "Deactivate" : "Activate"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="details_form_view_and_edit_container">
          <h6>Course Name</h6>

          <div className="details_form_edit_inner_container">
            <h6>Add/Edit Course Details</h6>

            <Form
              autoComplete="off"
              className="admin_dashboard_detail_form"
              onSubmit={handleSubmit}
            >
              <Form.Group
                className="mb-3 form_detail_field"
                controlId="formBasicName"
              >
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Course Name"
                  value={formData.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3 form_detail_field"
                controlId="formCourseName"
              >
                <Form.Control
                  type="text"
                  name="duration"
                  placeholder="Duration About the Course"
                  value={formData.duration}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/\D/g, ""))
                  }
                  onChange={handleChange}
                  isInvalid={!!errors.duration}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.duration}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3 form_detail_field"
                controlId="formBasicDurationAbouttheCourse"
              >
                <Form.Control
                  type="text"
                  name="description"
                  placeholder="Description About the Course"
                  value={formData.description}
                  onChange={handleChange}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3 form_detail_field"
                controlId="formBasicDescriptionAbouttheCourse"
              >
                <Form.Control
                  type="text"
                  name="contact_info"
                  placeholder="Contact Information"
                  value={formData.contact_info}
                  onChange={handleChange}
                  maxLength={10}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/\D/g, ""))
                  }
                  isInvalid={!!errors.contact_info}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contact_info}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="dashboard_request_actions_button_modal">
                <button
                  type="submit"
                  className="dashborad_request_approve_button"
                >
                  Save
                </button>
                <button type="button" className="dashboard_request_ad_course">
                  Add Course
                </button>
              </div>
            </Form>
          </div>
        </div>
        <div className="detail_page_courses_container">
          {coursesData.map((course, index) => (
            <div
              key={index}
              className="college_detail_page_container detail_page_course_inner_container"
            >
              <h6>Course Name</h6>
              <p>Duration : 6 Months</p>
              <p>
                Lorem Ipsum occaecat cupidatat non proident, sunt lorem ipsum at
                ii nor i culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
          ))}
          <div className="college_detail_page_container detail_page_course_inner_container">
            <h6>Course Name</h6>
            <p>Duration : 6 Months</p>
            <p>
              Lorem Ipsum occaecat cupidatat non proident, sunt lorem ipsum at
              ii nor i culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCollegeDetaile;
