import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import "../../../styles/allJobs/allJobs.css";
import "../../../styles/assignmentModel/assignmentModel.css"
import experienceIcon from "../../../utils/landingpage/images/experienceIcon.svg"
import dollerIcon from "../../../utils/landingpage/images/dollerIcon.svg"
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png"
import dummy_logo from '../../../utils/landingpage/images/dummy_logo.png'
import logofacebook from "../../../utils/landingpage/images/logo_facebook.svg";
import logotwitter from "../../../utils/landingpage/images/logo_twitter.svg";
import logoyoutube from "../../../utils/landingpage/images/logo_youtube.svg";
import logoinsta from "../../../utils/landingpage/images/logo_insta.svg";
import axios from 'axios';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from '../../../redux/actions/loginAction';
import { ClipLoader } from "react-spinners";

const validationSchema = Yup.object({
    email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    password: Yup.string().required("Password is required"),
});

const initialValues = {
    email: "",
    password: "",
};

const JobDetails = () => {

    const [experience, setExperience] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [save, setSave] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Most Relevance');
    const [isToken, setIsToken] = useState(false);
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isJobValid, setIsJobValid] = useState(false)
    const [isLogin, setIsLogin] = useState(null);
    const [isLoadingApply, setIsLoadingApply] = useState(false)
    const [jobDetails, setDetails] = useState([]);
    const [similarJobData, setSimilarJobData] = useState()
    const [jobId, setJobId] = useState(null);
    const { id } = useParams();
    const [assignmentModel, setAssignmentModel] = useState(false);
    const [assignmentQuestions, setAssignmentQuestions] = useState([])

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [timeRemaining, setTimeRemaining] = useState(10 * 60);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [profileCompletion, setProfileCompletion] = useState('');
    const [isLoadingQuiz, setIsLoadingQuiz] = useState(false);

    const sliderRef = useRef(null);
    const progressRef = useRef(null);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleBackClick = () => {
        window.history.back();
    }

    const { loading, error, user } = useSelector((state) => state.auth);
    const loginResponse = useSelector((state) => state.auth.loginResponse);

    useEffect(() => {
        if (sliderRef.current && progressRef.current) {
            const percentage = (experience / 30) * 100;
            progressRef.current.style.width = `${percentage}%`;
        }
    }, [experience]);

    useEffect(() => {
        if (!!loginResponse.access_token) {
            setIsToken(false)
            const getToken = sessionStorage.getItem('accessToken');
            setIsLogin(getToken);
        }
    }, [loginResponse])

    const getPostedDate = (createdAt) => {
        return formatDistanceToNow(parseISO(createdAt), { addSuffix: true });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const maxLength = 19;

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await dispatch(loginUser(values, navigate, 'applyLogin', jobId));
        },
    });

    useEffect(() => {
        const getToken = sessionStorage.getItem('accessToken');
        setIsLogin(getToken);
        setJobId(id);

        const newSession = sessionStorage.getItem('accessToken');
        setToken(newSession);

    }, [id, isLogin]);

    const DeletedJobDetails = async () => {
        if (jobId) {
            setIsLoading(true)
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/`
                );

                if (response.status === 200) {
                    setDetails(response.data)
                } else {
                    toast.error("Failed to retrieve job details.");
                }
            } catch (error) {
                if (error.response) {
                    const status = error.response.status;
                    if (status === 404) {
                        toast.error("Job not found.");
                    } else {
                        toast.error("An error occurred. Please try again later.");
                    }
                } else {
                    toast.error("Network error. Please check your connection.");
                }
            } finally {
                setIsLoading(false)
            }
        }
    };

    useEffect(() => {
        if (jobId || token) {  // Check if jobId is available
            const userToken = token;

            setIsLoading(true)
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/${jobId}/`, {
                headers: {
                    Authorization: userToken && `Bearer ${userToken}`,
                }
            })
                .then(response => {
                    setIsLoading(false)
                    setDetails(response.data)
                })
                .catch(error => {
                    setIsLoading(false)
                    if (error.response && error.response.status === 404) {
                        setIsJobValid(true)
                        DeletedJobDetails();
                    }
                });
        } else {
            setIsLoading(false)
        }
    }, [jobId, token]);

    function handleLogin() {
        setIsToken(true);
    }

    const formatSalary = (salary) => {
        // Ensure the salary is a number and round it properly
        if (salary) {
            const floatSalary = parseFloat(salary);  // Convert string to float
            return floatSalary % 1 === 0 ? floatSalary.toFixed(0) : floatSalary.toFixed(2);
        }
        return null; // If salary is null or undefined, return null
    };

    function handleSave(index) {
        const userDetails = {
            job_id: index,
        };

        const userToken = token;

        axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/job/save/`,
            userDetails,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                }
            }
        )
            .then(response => {
                console.log('Save successful', response.data.detail);
                setSave(response.data.detail); // Set the saved message if needed
                toast.success(response.data.detail); // Use the API response message in Toastify

                if (jobId) {  // Check if jobId is available
                    const userToken = token;

                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/${jobId}/`, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        }
                    })
                        .then(response => {
                            console.log('Job details fetched successfully', response.data);
                            setDetails(response.data)
                        })
                        .catch(error => {
                            console.log('Error occurred', error);
                        });
                } else {
                    console.log('Job ID is not available, skipping API call.');
                }
            })
            .catch(error => {
                console.log('Error occurred', error);
                toast.error('Failed to save the job. Please try again.');

                if (jobId) {  // Check if jobId is available 
                    const userToken = token;

                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/${jobId}/`, {
                        headers: {
                            Authorization: userToken ? `Bearer ${userToken}` : '',
                        }
                    })
                        .then(response => {
                            console.log('Job details fetched successfully', response.data);
                            setDetails(response.data)
                        })
                        .catch(error => {
                            console.log('Error occurred', error);
                        });
                } else {
                    console.log('Job ID is not available, skipping API call.');
                }
            });
    }

    function handleUnSave(index) {
        const userToken = token;

        axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/job/unsave/`,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
                data: {
                    job_id: index,
                }
            }
        )
            .then(response => {
                console.log('UnSave successful', response);
                // Show success toast notification
                toast.success(response.data.detail);

                if (jobId) {  // Check if jobId is available
                    const userToken = token;

                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/${jobId}/`, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        }
                    })
                        .then(response => {
                            console.log('Job details fetched successfully', response.data);
                            setDetails(response.data)
                        })
                        .catch(error => {
                            console.log('Error occurred', error);
                        });
                } else {
                    console.log('Job ID is not available, skipping API call.');
                }

            })
            .catch(error => {
                console.log('Error occurred', error);
                // Show error toast notification
                toast.success('Something Getting Wrong');
            });
    }

    useEffect(() => {
        if (jobId) {  // Check if jobId is available
            const userToken = token;

            axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/similar_jobs/`, {
                headers: {
                    Authorization: userToken && `Bearer ${userToken}`,
                }
            })
                .then(response => {
                    console.log('Similar Job details fetched successfully', response.data);
                    setSimilarJobData(response.data);  // Set the fetched job data
                })
                .catch(error => {
                    console.log('Error occurred', error);
                });
        } else {
            console.log('Job ID is not available, skipping API call.');
        }
    }, [jobId]);

    const handleApplyNow = async () => {

        if (profileCompletion < 90) {
            Swal.fire({
                title: 'Profile Incomplete',
                text: 'Complete your profile up to 90% before applying.',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Complete Now',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#FF9800',
                cancelButtonColor: '#6c757d',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/lateral/myProfile');
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.close();
                }
            });
            return;
        }

        if (jobId) {
            try {
                setIsLoadingApply(true)
                const accessToken = sessionStorage.getItem('accessToken');

                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/apply/`,
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }
                );

                if (response.data?.assessment_required === true) {
                    const transformedQuestions = response.data.questions?.map((question) => {
                        let correctOptionId;
                        // Mapping correct_option text to corresponding option id
                        if (question.correct_option === question.option_1) correctOptionId = '1';
                        if (question.correct_option === question.option_2) correctOptionId = '2';
                        if (question.correct_option === question.option_3) correctOptionId = '3';
                        if (question.correct_option === question.option_4) correctOptionId = '4';

                        return {
                            ...question,
                            options: [
                                { id: '1', text: question.option_1 },
                                { id: '2', text: question.option_2 },
                                { id: '3', text: question.option_3 },
                                { id: '4', text: question.option_4 }
                            ],
                            correctAnswer: correctOptionId
                        };
                    });

                    setAssignmentModel(true);
                    setAssignmentQuestions(transformedQuestions);
                } else {
                    if (response.data?.detail === "You have already applied for this job.") {
                        toast.error(response.data?.detail || "Something Went Wrong")
                    }
                    else if (response.data?.detail === "Assessment failed. You did not pass enough questions correctly.") {
                        toast.error("You have Submitted failed")
                    }
                    else if (response.data?.detail === "Application successful.") {
                        toast.success('Application submitted successfully!');
                    }
                    else if (response.data?.detail === "You have exceeded the maximum number of attempts.") {
                        toast.error("You have exceeded the maximum number of attempts.");
                    } else {
                        toast.success(response.data?.detail || "Application submitted")
                    }
                }
            } catch (error) {
                const errorMessage = error.response
                    ? error.response.data.detail || 'Application failed. Please try again.'
                    : error.message;

                toast.error(errorMessage);
            } finally {
                setIsLoadingApply(false)
            }
        } else {
            toast.info('Job ID is missing.');
        }
    };

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         setTimeRemaining((prev) => {
    //             if (prev <= 0) {
    //                 clearInterval(timer);
    //                 // showTimeoutAlert();
    //                 setAssignmentModel(false);
    //                 toast.warning("Assessment Time Out")
    //                 // return 0;
    //                 window.location.reload();
    //             }
    //             return prev - 1;
    //         });
    //     }, 1000);

    //     return () => clearInterval(timer);
    // }, []);

    const showTimeoutAlert = () => {
        Swal.fire({
            title: 'Time Out!',
            text: 'Your time has expired.',
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'OK',
            customClass: {
                confirmButton: 'custom-swal-confirm-button',
            },
        }).then(() => {
            setAssignmentModel(false);
            window.location.reload();
        });
    };

    useEffect(() => {
        if (assignmentModel) {
            const timer = setInterval(() => {
                setTimeRemaining((prev) => {
                    if (prev <= 0) {
                        clearInterval(timer);
                        // handleSubmit();
                        showTimeoutAlert()
                        // setAssignmentModel(false)
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [assignmentModel]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleAnswerSelect = (optionId) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestion]: optionId
        });

        const correctAnswerId = assignmentQuestions[currentQuestion].correctAnswer;

        console.log(`Selected Option ID: ${optionId}, Correct Option ID: ${correctAnswerId}`);

        if (optionId === correctAnswerId) {
            console.log(`Question ${currentQuestion + 1} selected correct answer: ${optionId}`);
        } else {
            console.log(`Question ${currentQuestion + 1}: Incorrect answer selected. You selected: ${optionId}`);
        }
    };

    const handleNext = () => {
        if (currentQuestion < assignmentQuestions?.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        }
    };

    const isCurrentQuestionAnswered = !!selectedAnswers[currentQuestion];

    const handleSkip = () => {
        handleNext();
    };

    const handleSubmit = async () => {
        let correctAnswers = [];

        // Collect correct answers
        assignmentQuestions.forEach((question, index) => {
            const selectedAnswer = selectedAnswers[index];

            if (selectedAnswer && selectedAnswer === question.correctAnswer) {
                const selectedOptionText = question.options.find(option => option.id === selectedAnswer)?.text;

                correctAnswers.push({
                    question_id: question.id,
                    selected_option: selectedOptionText
                });
            }
        });

        const results = {
            answers: correctAnswers
        };

        if (jobId) {
            try {
                setIsLoadingQuiz(true);
                const accessToken = sessionStorage.getItem('accessToken');

                // Send results as part of the POST body
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/apply/`,
                    results, // Send 'results' object in the body
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }
                );

                if (response.data.detail) {
                    setAssignmentModel(false)

                    if (response.data?.detail === "Assessment failed. You did not pass enough questions correctly.") {
                        toast.error(response.data?.detail || "You have Submitted failed")
                    }

                    else if (response.data?.detail === "Application successful.") {
                        toast.success("Application submitted successfully!")
                    } else if (response.data?.detail === "You have already applied for this job.") {
                        toast.error(response.data?.detail || "You have already applied for this job.")
                    } else {
                        toast.success(response.data?.detail || "Application submitted")
                    }

                    setQuizCompleted(true);
                    setCurrentQuestion(0);
                    setSelectedAnswers({})
                    setTimeRemaining(10 * 60)

                } else {
                    setAssignmentModel(false)
                    toast.error('Application failed!');
                    setQuizCompleted(true);
                    setCurrentQuestion(0);
                    setSelectedAnswers({})
                    setTimeRemaining(0)
                }
            } catch (error) {
                const errorMessage = error.response
                    ? error.response.data.detail || 'Application failed. Please try again.'
                    : error.message;

                setQuizCompleted(true);
                setAssignmentModel(false)
                setCurrentQuestion(0);
                setSelectedAnswers({})
                setTimeRemaining(10 * 60)
                toast.error(errorMessage);
            } finally {
                setIsLoadingQuiz(false);
            }
        }
    };

    const handleClickRedirect = () => {
        navigate('/lateral/register');
    }

    const handleBackToHome = () => {
        setAssignmentModel(false)
        setQuizCompleted(true);
        setCurrentQuestion(0);
        setSelectedAnswers({})
        setTimeRemaining(10 * 60)
    }

    const fetchProfileCompletion = async () => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');

            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/profile_completion/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,  // Pass the token in the Authorization header
                },
            });

            const completionValue = parseInt(response.data.profile_completion_percentage.replace('%', '')); // Get value as integer
            setProfileCompletion(completionValue);
        } catch (error) {
            console.error('Error fetching profile completion:', error);
        }
    };

    useEffect(() => {
        fetchProfileCompletion();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: "2-digit", month: "short", year: "numeric" };
        return new Intl.DateTimeFormat("en-US", options).format(date).toUpperCase();
    };

    return (<>
        {/* <ToastContainer /> */}
        <div className='allJobs_main_container'>
            {isLoading ?
                (<div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                    }}
                >
                    <ClipLoader color="#FFA500" size={60} />
                </div>)
                : (<div className='allJobs_container'>
                    <section className="contact_header alljobs_header jobDetails_header">
                        <h2 className='d-grid align-items-center'>
                            <span>Job Details</span>
                        </h2>
                        <h6>Full Details About Job and Company.</h6>
                    </section>

                    <section className='jobDetails_main_container'>
                        <div className='jobDetails_first_container'>

                            <div className='d-flex'>
                                <button onClick={handleBackClick} className='job_details_back_button'>
                                    <svg width="18" height="18" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z" fill="#222222" />
                                        <path d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z" fill="#222222" />
                                    </svg>
                                </button>
                                <b className='jobDetails_b'>Job Details</b>
                            </div>

                            <div className='pt-4 pb-2'>
                                <div className="job-listings">
                                    <div className="job-listing">
                                        <div className="company-info">
                                            <img src={jobDetails.company_profile?.company_logo_url || dummy_logo} alt={`logo`} className="company-logo" />
                                            <div>
                                                <div className="d-flex align-items-center gap-4">
                                                    <h2>{jobDetails.title || 'Not Mentioned'}</h2>
                                                    {jobDetails?.status === "LIVE" ? (<button className="job_list_job_active">Live</button>) : (<button className="job_list_job_active job_list_job_closed">Closed</button>)}
                                                </div>
                                                <div className='letral_rating_container jdrc_class'>
                                                    {/* <div className="company-name">{jobDetails?.company || 'Not Mentioned'}</div> */}
                                                    <div className="company-name">{jobDetails?.company_name || 'Not Mentioned'}</div>
                                                    <div className="rating">
                                                        <span className="star">★</span> {jobDetails.company_profile?.company_rating || '0'} <span style={{ fontWeight: "300", color: "#3c3c3c69" }}>|</span> {jobDetails?.company_profile?.company_review || '0'} Reviews
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="job-details job_details_new">
                                            <span className="detail"> <span className='details_job_icon'><img src={experienceIcon} alt='...' /> </span> {jobDetails?.min_experience || '0'}-{jobDetails?.max_experience || '0'} yrs <span className='stand_icon'>|</span> </span>
                                            <span className="detail">
                                                <span className='details_job_icon'><img src={dollerIcon} alt='...' /></span>
                                                {jobDetails?.min_salary && jobDetails?.max_salary
                                                    ? `${formatSalary(jobDetails.min_salary)}-${formatSalary(jobDetails.max_salary)} LPA`
                                                    : 'Not specified'}
                                                <span className='stand_icon'>|</span>
                                            </span>
                                            <span className="detail"> <span className='details_job_icon'><img src={locationIcon} alt='...' /> </span> {jobDetails?.job_location || 'Not Mentioned'} </span>
                                        </div>
                                        <div className={`listing-footer ${token == null ? `listing-footer-respon-grid` : null}`}>
                                            <div className="job-details">
                                                <span className="detail">
                                                    Posted:
                                                    <span className='custom_fonts'>
                                                        {jobDetails?.created_at ? getPostedDate(jobDetails?.created_at) : 'Date not available'}
                                                    </span>
                                                    <span className='stand_icon'>|</span>
                                                </span>
                                                <span className="detail"> Openings: <span className='custom_fonts'>{jobDetails?.vacancy || '0'}</span> <span className='stand_icon'>|</span> </span>
                                                <span className="detail"> Applicants: <span className='custom_fonts'>{jobDetails?.total_applicants | '0'}</span> </span>
                                            </div>
                                            {isLogin ?
                                                <div className='job_listing_button_reverse' >
                                                    {jobDetails.is_already_saved == true && (<button className="rounded-3 btn jobDetails_register_to_apply_btn " onClick={() => handleUnSave(jobDetails.id)}>
                                                        Saved
                                                    </button>)}
                                                    {jobDetails.is_already_saved == false && (<button className="rounded-3 btn jobDetails_register_to_apply_btn " onClick={() => handleSave(jobDetails.id)}>
                                                        Save
                                                    </button>)}
                                                    {isLoadingApply ?
                                                        (<button
                                                            className={`jobDetails_button`}
                                                        >
                                                            <span className='spinner'></span>
                                                        </button>)
                                                        : (<button
                                                            onClick={handleApplyNow}
                                                            className={`jobDetails_button ${isJobValid ? "disabled_button" : ""}`}
                                                            disabled={jobDetails?.status === "CLOSED" || isJobValid}
                                                        >
                                                            {(jobDetails?.status === "CLOSED" || isJobValid) ? "Job Closed" : "Apply Now"}
                                                        </button>)}
                                                </div>
                                                :
                                                <div className='d-flex gap-2'>
                                                    <button onClick={handleClickRedirect} className="rounded-3 btn jobDetails_register_to_apply_btn ">
                                                        Register to apply
                                                    </button>
                                                    <button onClick={handleLogin} className="jobDetails_button">Login to apply</button>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='jobDatails_description'>
                                <h2>Job Description</h2>
                                <p>{jobDetails?.description || 'Not Mentioned'}</p> <br />
                                <p><span>Company Name:</span> {jobDetails?.company_name || 'Not Mentioned'}</p>
                                <p><span>HR Email:</span> {jobDetails?.company || 'Not Mentioned'}</p>
                                <p><span>Role:</span> {jobDetails?.title || 'Not Mentioned'}</p>
                                <p><span>Job Category:</span> {jobDetails?.job_category_name || 'Not Mentioned'}</p>
                                <p><span>Employment Type:</span> {jobDetails?.type || 'Not Mentioned'}</p>
                                <p><span>Job Location:</span> {jobDetails?.job_location || 'Not Mentioned'}</p>
                                <p><span>Education:</span> {jobDetails?.qualification || 'Not Mentioned'}</p>
                                <p><span>Responsibility:</span> {jobDetails?.responsibility || 'Not Mentioned'}</p>
                                <p><span>Job Status:</span> {jobDetails?.status || 'Not Mentioned'}</p>
                                <p><span>Job End Date:</span> {jobDetails?.end_date ? formatDate(jobDetails.end_date) : "Not Mentioned"}</p>
                                <p><span>Openings:</span> {jobDetails?.vacancy || 'Not Mentioned'}</p>
                                <p><span>Key Skills:</span>
                                    {jobDetails?.job_skills?.map((skill, index) => (
                                        <span style={{ fontWeight: '400' }} key={index}> {skill.skill_name}{index !== jobDetails.job_skills?.length - 1 ? ', ' : ''}</span>
                                    ))}
                                </p>
                                {jobDetails?.perks_benefits && (<p><span>Benefits:</span> {jobDetails?.perks_benefits || 'Not Mentioned'}</p>)}
                            </div>

                            <div className="about-company">
                                <h2>About Company</h2>
                                <p>
                                    {jobDetails?.company_profile?.company_desc || 'Not Mentioned'}
                                </p>
                                {/* <div className="social-icons">
                                    <a href={jobDetails?.company_profile?.facebook} className="social-icon">
                                        <img src={logofacebook} alt="Group-img" />
                                    </a>
                                    <a href={jobDetails?.company_profile?.twitter} className="social-icon">
                                        <img src={logotwitter} alt="Group-img" />
                                    </a>
                                    <a href={jobDetails?.company_profile?.linked_in} className="social-icon">
                                        <img src={logoyoutube} alt="Group-img" />
                                    </a>
                                    <a href={jobDetails?.company_profile?.instagram} className="social-icon">
                                        <img src={logoinsta} alt="Group-img" />
                                    </a>
                                </div> */}
                            </div>

                        </div>

                        <div className={`jobDetails_second_container ${similarJobData?.length < 1 ? `No_similar_jobs_availble` : ''}`} >
                            <b className='jobDetails_b'>Similar Jobs</b>

                            <div className='pt-4'>
                                {similarJobData && similarJobData?.length > 0 ? (
                                    similarJobData.slice(0, 4).map((job) => (
                                        <div className="job-listings" key={job.id}>
                                            <div className="job-listing">
                                                <div className="company-info">
                                                    <Link to={`/lateral/jobDetails/${job.id}`}>
                                                        <img
                                                            src={job.company_profile?.company_logo_url || dummy_logo}
                                                            alt={'logo'}
                                                            className="company-logo"
                                                        />
                                                    </Link>
                                                    <div>
                                                        <Link to={`/lateral/jobDetails/${job.id}`}>
                                                            <h2>{job.title}</h2>
                                                        </Link>
                                                        <div className='d-flex gap-2'>
                                                            <div className="company-name">
                                                                {job?.company_profile?.company_name
                                                                    ? (job?.company_name?.length > maxLength
                                                                        ? `${job.company_name.substring(0, maxLength)}...`
                                                                        : job.company_name)
                                                                    : "Not Mentioned"}
                                                            </div>
                                                            <div className="rating">
                                                                <span className="star">★</span> {job?.company_profile?.company_rating || '0'}
                                                                <span style={{ fontWeight: "300", color: "#3c3c3c69" }}>|</span>
                                                                <span>{`${job?.company_profile?.company_review || '0'} Reviews`.slice(0, 7) + ".."}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="job-details">
                                                    <span className="detail">
                                                        <span className='details_job_icon'><img src={experienceIcon} alt='...' /> </span>
                                                        {`${job?.min_experience || '0'} - ${job?.max_experience || '0'} yrs`}
                                                        <span className='stand_icon'>|</span>
                                                    </span>
                                                    <span className="detail">
                                                        <span className='details_job_icon'><img src={dollerIcon} alt='...' /> </span>
                                                        {job.min_salary ? `${job.min_salary}-${job.max_salary} LPA` : '0'}
                                                        <span className='stand_icon'>|</span>
                                                    </span>
                                                    <span className="detail">
                                                        <span className='details_job_icon'><img src={locationIcon} alt='...' /> </span>
                                                        {job.job_location ? job?.job_location?.slice(0, 9) + ".." : "Not Mentioned"}
                                                    </span>
                                                </div>
                                                <p style={{ fontSize: "14px" }}>
                                                    {job.description.slice(0, 73) + ".."}
                                                </p>
                                                <div className="listing-footer">
                                                    <div className="job-details">
                                                        <span className="detail"> Posted: <span className='custom_fonts'>
                                                            {`${getPostedDate(job?.created_at)}`}
                                                        </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No similar jobs available.</p>
                                )}
                            </div>
                        </div>

                    </section>
                </div>)}
        </div>

        {isToken && (
            <div className='jobDetails_login_popup_main'>
                <div className='jobDetails_login_popup_second_main'>
                    <div className='jobDeatils_login_crossIcon' onClick={() => setIsToken(false)}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.9999 14.9999L1 1M15 1L1 15" stroke="#F38D00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className="register_content_second_container jobDetails_login_popup_second_container">
                        <h2 className="login_heading">Login</h2>
                        <div className="register_header_line"></div>
                        <div className="register_account_options">
                            <p>Please enter your login details</p>
                        </div>
                        <form
                            onSubmit={formik.handleSubmit}
                            className="register_form_container"
                        >
                            <div className="register_form_section_candidate">
                                <div className="candidate_input_group_container">
                                    <div className="login_candidate_input_group">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Enter your email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="register_error_msg">
                                                {formik.errors.email}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="login_candidate_input_group">
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            placeholder="Enter your password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                        />
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className="register_error_msg">
                                                {formik.errors.password}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                {/* <div className="forgot_password_container d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <input type="checkbox" style={{ width: "10px" }} />
                                        <p className="remember_password">Remember Password</p>
                                    </div>
                                    <div>
                                        <p className="forgot_Password">forgot Password?</p>
                                    </div>
                                </div> */}
                            </div>

                            <div className="register_send_btn_container pt-4">
                                <button
                                    type="submit"
                                    className="login_submit_btn"
                                    disabled={formik.isSubmitting}
                                >
                                    {loading ? <div className="spinner"></div> : "Login"}
                                </button>
                                {/* <div className="Login_partitan_line">
                                    <div className="Login_partitan_line_one"></div>
                                    <span>or</span>
                                    <div className="Login_partitan_line_tow"></div>
                                </div>
                                <button className="google_register_send_btn">
                                    <img src={googleicon} alt="google-auth" />
                                    <div className='fw-medium'>Login with google </div>
                                </button> */}
                                <div className="mt-4 pb-4 text-center fw-normal" style={{ fontSize: "14px" }}>
                                    You don’t have an account?{" "}
                                    <Link to={"/register"} style={{ color: "#F38D00", textDecoration: "none" }}>Signup</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )}

        {assignmentModel && (<>
            <div className="quiz-container">
                <div className="content-wrapper">
                    <div className="header">
                        <button className="back-button" onClick={handleBackToHome}>
                            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 13L1.8858 7.75926C1.42019 7.36016 1.42019 6.63984 1.8858 6.24074L8 1" stroke="#F38D00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Back To Home
                        </button>
                    </div>

                    <div className="quiz-layout">
                        <div className="quiz-main">
                            <div className="main-content">
                                <h1 className="quiz-title">Clear the all assessment questions for enter</h1>
                                <p className="quiz-subtitle">Answer all questions to complete the assessment </p>

                                {assignmentQuestions?.length > 0 && (
                                    <div className="question-card">
                                        <h2 className="question-text">
                                            {`${currentQuestion + 1} - ${assignmentQuestions[currentQuestion].question_text}`}
                                        </h2>
                                        <div className="options-container">
                                            {assignmentQuestions[currentQuestion].options?.map((option) => (
                                                <button
                                                    key={option.id}
                                                    className={`option-button ${selectedAnswers[currentQuestion] === option.id ? 'selected' : ''}`}
                                                    onClick={() => handleAnswerSelect(option.id)}
                                                >
                                                    <span className="option-id">{`${option.id})`}</span>
                                                    <span className="option-text">{option.text}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                <div className="action-buttons">
                                    {currentQuestion < assignmentQuestions?.length - 1 ? (
                                        <>
                                            <button onClick={handleNext} className={`btn-primary ${isCurrentQuestionAnswered ? '' : 'disabled'}`} disabled={!isCurrentQuestionAnswered}>
                                                Save & Next
                                            </button>
                                            <button onClick={handleSkip} className="btn-secondary">
                                                Skip
                                            </button>
                                        </>
                                    ) : (
                                        <button onClick={handleSubmit} className="btn-primary">
                                            {isLoadingQuiz ? <span className='spinner'></span> : `Submit Quiz`}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="quiz-sidebar">
                            <div className="timer-card">
                                <div className="timer-circle-container">
                                    <div className="timer-display">
                                        <span className="time">{formatTime(timeRemaining)}</span>
                                    </div>
                                    <svg className="timer-svg" viewBox="0 0 36 36">
                                        <path
                                            d="M18 2.0845
                                            a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                            className="timer-circle-bg"
                                        />
                                        <path
                                            d="M18 2.0845
                                            a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                            className="timer-circle"
                                            style={{
                                                strokeDasharray: `${(timeRemaining / (10 * 60)) * 100}, 100`
                                            }}
                                        />
                                    </svg>
                                </div>
                                <p className="timer-text">Time Remaining</p>
                            </div>

                            <div className="questions-list-card">
                                <h3 className="list-title">Question List</h3>
                                <div className="questions-list">
                                    {assignmentQuestions?.map((_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => setCurrentQuestion(index)}
                                            className={`question-list-item ${index === currentQuestion ? 'active' : ''} ${selectedAnswers[index] ? 'completed' : ''}`}
                                        >
                                            <span style={{ fontWeight: "500" }}>Quiz Question {index + 1}</span>
                                            {selectedAnswers[index] && (
                                                <span className="check-icon">✓</span>
                                            )}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
        }

    </>);
};

export default JobDetails;