import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import "../../../../../styles/admin/faq.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ArrowDropDownCircleRounded } from "@mui/icons-material";

function FAQ() {
  const navigate = useNavigate();
  const [applicantType, setApplicantType] = useState("Lateral");
  const [lateralFAQ, setLateralFAQ] = useState([]);
  const [campusFAQ, setCampusFAQ] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [faqsPerPage] = useState(8);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchFAQs = async () => {
    try {
      const [lateralResponse, campusResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/questionsections/`),
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/faq_student_campus/`),
      ]);

      setLateralFAQ(lateralResponse.data);
      setCampusFAQ(campusResponse.data);
    } catch (error) {
      console.error("Error fetching FAQs:", error);
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  const handleAddFAQ = async () => {
    const endpoint =
      applicantType === "Lateral"
        ? `${process.env.REACT_APP_BASE_URL}/api/questionsections/`
        : `${process.env.REACT_APP_BASE_URL}/api/faq_student_campus/`;

    const body = {
      question: newQuestion,
      answer: newAnswer,
    };

    try {
      const response = await axios.post(endpoint, body);
      if (response.status === 200 || response.status === 201) {
        fetchFAQs(); // Refresh the FAQ list
        setOpenDialog(false); // Close the dialog
        setNewQuestion(""); // Reset the form fields
        setNewAnswer("");
      }
    } catch (error) {
      console.error("Error adding FAQ:", error);
    }
  };

  const handleDeleteFAQ = async (id) => {
    const endpoint =
      applicantType === "Lateral"
        ? `${process.env.REACT_APP_BASE_URL}/api/questionsections/${id}/`
        : `${process.env.REACT_APP_BASE_URL}/api/faq_student_campus/${id}/`;

    try {
      await axios.delete(endpoint);
      fetchFAQs(); // Refresh the FAQ list
    } catch (error) {
      console.error("Error deleting FAQ:", error);
    }
  };

  const faqs = applicantType === "Lateral" ? lateralFAQ : campusFAQ;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastFAQ = currentPage * faqsPerPage;
  const indexOfFirstFAQ = indexOfLastFAQ - faqsPerPage;
  const currentFAQs = faqs.slice(indexOfFirstFAQ, indexOfLastFAQ);

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <h6>
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <path
              d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
              fill="#222222"
            />
            <path
              d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
              fill="#222222"
            />
          </svg>{" "}
          &nbsp;All Applicants
        </h6>
        <div className="d-flex justify-content-between mt-4">
          <button onClick={() => setOpenDialog(true)} className="add_faq_btn">
            Add FAQ
          </button>
          <div className="d-flex">
            <button
              onClick={() => setApplicantType("Campus")}
              className={`all_applicants_toggle_btn ${
                applicantType === "Campus" ? "active" : ""
              }`}
            >
              Campus
            </button>
            <button
              onClick={() => setApplicantType("Lateral")}
              className={`all_applicants_toggle_btn ${
                applicantType === "Lateral" ? "active" : ""
              }`}
            >
              Lateral
            </button>
          </div>
        </div>

        <div className="mt-4 d-flex flex-column gap-3">
          {currentFAQs.map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              style={{
                borderRadius: "24px",
                border: "none",
              }}
            >
              <AccordionSummary
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: "none",
                }}
              >
                <Typography style={{ flex: 1 }}>
                  Q {indexOfFirstFAQ + index + 1}. {faq.question}
                </Typography>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <IconButton onClick={() => handleDeleteFAQ(faq.id)}>
                    <DeleteIcon style={{ color: "#f38d00" }} />
                  </IconButton>
                  <ArrowDropDownCircleRounded style={{ color: "#f38d00" }} />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ border: "none" }}>
                  Ans :- {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        <div className="d-flex justify-content-center">
          <Pagination
            count={Math.ceil(faqs.length / faqsPerPage)}
            color="primary"
            className="mt-3"
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Add New FAQ</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the question and answer for the new FAQ.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="question"
              label="Question"
              type="text"
              fullWidth
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
            />
            <TextField
              margin="dense"
              id="answer"
              label="Answer"
              type="text"
              fullWidth
              value={newAnswer}
              onChange={(e) => setNewAnswer(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ color: "#f38d00" }}
              onClick={() => setOpenDialog(false)}
            >
              Cancel
            </Button>
            <Button style={{ color: "#f38d00" }} onClick={handleAddFAQ}>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default FAQ;
