import "../../../styles/dashboards/campusecandidaterequest.css";
import "../../../styles/admin/adminRequest.css";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Pagination } from "@mui/material";
import searchLogo from "../../../utils/dashboards/searchIconDash.svg";
import RightArrow from "../../../utils/landingpage/images/rightarroworange.svg";
import { toast } from "react-toastify";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function Companies() {
  const [finalCardData, setFinalCardData] = useState([]);
  const [filterCardData, setFilterCardData] = useState([]);
  const [currentItem, setCurrentItem] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const itemPerPage = 10;

  const initialValue = {
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    password: "",
    password2: "",
    phoneNumber: "",
  };

  const handleClose = () => setShowModal(false);

  // Handle form input changes
  const [formData, setFormData] = useState({ ...initialValue });

  const [errors, setErrors] = useState({ ...initialValue });

  const fetchCompanyData = async () => {
    setLoading(true);

    try {
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/show_company/`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const result = await response.json();
      setFilterCardData(result);
      setFinalCardData(result);
    } catch (error) {
      console.error("Failed to fetch company data:", error);
      toast.error("Failed to fetch requests.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    setCurrentItem(finalCardData.slice(startIndex, endIndex));
  }, [currentPage, finalCardData]);

  const filteredRequests = (searchQuery) => {
    return filterCardData.filter((user) =>
      user?.company_name.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearchQuery(value);
    setFinalCardData(filteredRequests(value));
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="campuse_student_request_container">
            <div className="campuse_student_request_header">
              <h3>Company Management</h3>
              <div className="search_input_container">
                <div className="campuse_student_request_search_bar">
                  <img src={searchLogo} alt="search" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <button
                  className="add_college_managment"
                  onClick={() => setShowModal(true)}
                >
                  Add Company
                </button>
              </div>
            </div>
            <div className="campuse_student_request_body">
              <div
                className="campuse_student_request_sub_container"
                style={{ marginTop: "13px" }}
              >
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    {finalCardData.length > 0
                      ? currentItem?.map((item, key) => (
                          <div key={key} className="dashboard_request_list">
                            <div className="dashboard_request_list_card">
                              <div className="dashborad_request_logo_title_container">
                                <img
                                  src={
                                    item?.company_profile?.company_logo_url ||
                                    "https://via.placeholder.com/100"
                                  }
                                  alt="company_logo"
                                  className="dashboard_request_company_logo"
                                />
                                <div>
                                  <h4>{item?.company_name}</h4>
                                  <p>{`${item?.company_profile?.city}, ${item?.company_profile?.state}`}</p>
                                </div>
                              </div>
                              <Link
                                to={`/dashboard/sidebar-page/admin/view-company-details/${item?.id}`}
                                style={{
                                  color: "#f38d00",
                                  textDecoration: "none",
                                }}
                              >
                                View Details
                                <img src={RightArrow} alt="RightArrow" />
                              </Link>
                            </div>
                          </div>
                        ))
                      : "No Companies found"}
                  </>
                )}
              </div>
            </div>
            <div className="campuse_student_request_pagination">
              <div className="d-flex justify-content-center pagination_custom">
                <Pagination
                  count={Math.ceil(finalCardData.length / itemPerPage)}
                  page={currentPage}
                  color="primary"
                  className="mt-3"
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
        className="modal_admin_request"
      >
        <div className="popup_form_container">
          <Modal.Body>
            <Form autoComplete="off" className="admin_dashboard_modal_form">
              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicName"
              >
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  autoComplete="new-firstName"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicLastName"
              >
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  autoComplete="new-lastName"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicEntityName"
              >
                <Form.Control
                  type="text"
                  name="collegeName"
                  placeholder="College Name"
                  autoComplete="new-collegeName"
                />
              </Form.Group>
              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicEmail"
              >
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  autoComplete="new-email"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicPassword"
              >
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="new-password"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicConfirmPassword"
              >
                <Form.Control
                  type="password"
                  name="password2"
                  placeholder="Confirm Password"
                  autoComplete="new-password2"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicPhoneNumber"
              >
                <div></div>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  className="input_field"
                  maxLength={10}
                  pattern="[0-9]{10}"
                  inputMode="numeric"
                  autoComplete="new-phoneNumber"
                />
              </Form.Group>

              {/* <Button variant="primary" type="submit">
              Submit
            </Button> */}
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <div className="dashboard_request_actions_button_modal">
              <button className="dashborad_request_approve_button">Add</button>
              <button
                className="dashboard_request_reject_button"
                onClick={() => handleClose()}
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default Companies;