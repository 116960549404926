import "../../../styles/dashboards/campusecandidaterequest.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "react-spinners/ClipLoader";
import flterIcon from "../../../utils/dashboards/filterIcon.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";

export default function CampuseCandidateRequest() {
  const [activeTab, setActiveTab] = useState("pending");
  const [pendingUsers, setPendingUsers] = useState([]);
  const [rejectedUsers, setRejectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingApproveUserId, setLoadingApproveUserId] = useState(null);
  const [loadingRejectUserId, setLoadingRejectUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState(null);
  const [error, setError] = useState("");

  const token = sessionStorage.getItem("accessToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const fetchUsers = async () => {
    try {
      setLoading(true)
      const pendingResponse = axios.get(
        `${baseUrl}/api/pending-student_campus/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const rejectedResponse = axios.get(
        `${baseUrl}/api/rejected-students-list/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const [pendingData, rejectedData] = await Promise.all([
        pendingResponse,
        rejectedResponse,
      ]);

      setPendingUsers(pendingData.data);
      setRejectedUsers(rejectedData.data);
    } catch (error) {
      toast.error("Failed to fetch users.");
    }
    finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (token) {
      fetchUsers();
    }
    else {
      return;
    }
  }, [token]);

  const handleTabChange = (event) => {
    setActiveTab(event.target.value);
    setSearchTerm("");
  };

  const capitalizeName = (name) => {
    if (!name) return "";
    return name
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleApprove = async (userId) => {
    setLoadingApproveUserId(userId);
    try {
      await axios.post(
        `${baseUrl}/api/approve/student_campus/${userId}/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("User approved successfully!");
      fetchUsers()
      setPendingUsers(pendingUsers.filter((user) => user.id !== userId));
    } catch (error) {
      toast.error("Failed to approve user.");
      console.error("Error approving user:", error);
    } finally {
      setLoadingApproveUserId(null);
    }
  };

  const handleReject = async (userId) => {
    setLoadingRejectUserId(userId);
    try {
      await axios.post(
        `${baseUrl}/api/reject-pending-student/`,
        { student_id: userId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("User rejected successfully!");
      fetchUsers();
      setPendingUsers(pendingUsers.filter((user) => user.id !== userId));
    } catch (error) {
      toast.error("Failed to reject user.");
    } finally {
      setLoadingRejectUserId(null);
    }
  };

  const filterUsers = (users) => {
    return users.filter((user) =>
      `${user.first_name || ""} ${user.last_name || ""}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  };

  const handleViewDetails = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/details_student_campus/${id}/`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDetails(response.data);
      setShowModal(true);
    } catch (error) {
      setError("Failed to fetch details.");
      toast.error("Failed to fetch details.");
    }
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="campuse_student_request_container">
            <div className="campuse_student_request_header">
              <h5>
                {`All Requests[${activeTab === "pending"
                  ? filterUsers(pendingUsers).length
                  : activeTab === "rejected"
                    ? filterUsers(rejectedUsers).length
                    : filterUsers(pendingUsers).length + filterUsers(rejectedUsers).length
                  }]`}
              </h5>
              <div className="d-flex">
                <div className="campuse_student_request_search-bar">
                  <input
                    type="search"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="campuse_student_request_dropdown">
                  <img src={flterIcon} alt="Filter Icon" />
                  <select
                    className="campuse_student_request_dropdown_section"
                    value={activeTab}
                    onChange={handleTabChange}
                  >
                    <option value="all">All</option>
                    <option value="pending">Pending</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              </div>
            </div>

            {loading ? (
              <div className="custom_loader">
                <Loader color="#F38D00" size={50} />
              </div>
            ) : (<div className="campuse_student_request_sub_container">
              <div className="campuse_student_request_list">
                {activeTab === "pending" && filterUsers(pendingUsers).length === 0 && (
                  <div className="no_candidate_found">
                    <p>No Pending Candidate</p>
                  </div>
                )}
                {activeTab === "rejected" &&
                  filterUsers(rejectedUsers).length === 0 && <p>No Rejected Candidate</p>}
                {activeTab === "all" &&
                  filterUsers(pendingUsers).length === 0 &&
                  filterUsers(rejectedUsers).length === 0 && <p>No Request Found</p>}

                {(activeTab === "pending" || activeTab === "all") &&
                  filterUsers(pendingUsers)?.map((user) => (
                    <div key={user.id} className="campuse_student_request_card">
                      <p>
                        {capitalizeName(user.name)} {capitalizeName(user.last_name)}
                      </p>
                      {user?.email && (
                        <p>
                          Email: <span>{user?.email || "N/A"}</span>
                        </p>
                      )}
                      {user?.requested_date && (
                        <p>
                          Requested date: <span>{user?.requested_date || "N/A"}</span>
                        </p>
                      )}
                      <div className="campuse_student_request_actions">
                        <div className="d-flex">
                          <button
                            className="campuse_student_request_approve_button"
                            onClick={() => handleApprove(user.id)}
                            disabled={loadingApproveUserId === user.id}
                          >
                            {loadingApproveUserId === user.id ? (
                              <span className="loader"></span>
                            ) : (
                              "Approve"
                            )}
                          </button>
                          <button
                            className="campuse_student_request_reject-button"
                            onClick={() => handleReject(user.id)}
                            disabled={loadingRejectUserId === user.id}
                          >
                            {loadingRejectUserId === user.id ? (
                              <span className="loader"></span>
                            ) : (
                              "Reject"
                            )}
                          </button>
                        </div>
                        <Link
                          onClick={() => handleViewDetails(user.id)}
                          className="details-link"
                        >
                          View Details &rarr;
                        </Link>
                      </div>
                    </div>
                  ))}


                {(activeTab === "rejected" || activeTab === "all") &&
                  filterUsers(rejectedUsers)?.map((user) => (
                    <div key={user.id} className="campuse_student_request_card">
                      <p>
                        {capitalizeName(user.first_name)} {capitalizeName(user.last_name)}
                      </p>
                      <div className="campuse_student_request_actions">
                        <div className="d-flex">
                          <button
                            className="campuse_student_request_approve_button"
                            onClick={() => handleApprove(user.id)}
                            disabled={loadingApproveUserId === user.id}
                          >
                            {loadingApproveUserId === user.id ? (
                              <span className="loader"></span>
                            ) : (
                              "Re-Approve"
                            )}
                          </button>
                          <button
                            className="campuse_student_request_rejected_button"
                            disabled
                          >
                            Rejected
                          </button>
                        </div>
                        <Link
                          onClick={() => handleViewDetails(user.id)}
                          className="details-link"
                        >
                          View Details &rarr;
                        </Link>
                      </div>
                    </div>
                  ))}

              </div>
            </div>)}

          </div>
        </div>
      </div>


      {/* ------------------------ */}

      {/* Bootstrap Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="mg"
        centered
      >
        <Modal.Body>
          {details ? (
            <>
              <div
                className="text-center"
                style={{ padding: "1rem", fontSize: "2rem" }}
              >
                Student Details
              </div>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicCompanyName">
                  <Form.Label>Student Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={`${details.name || " "} ${details.student_last_name || " "
                      }`}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="ml-1">Email</Form.Label>
                  <Form.Control type="text" value={details.email} readOnly />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="ml-1">Enrollement Number</Form.Label>
                  <Form.Control type="text" value={details.enrollment_no} readOnly />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicMobile">
                  <Form.Label className="ml-1">Contact</Form.Label>
                  <Form.Control
                    type="text"
                    value={details.all_mobno}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCompanyCity">
                  <Form.Label className="ml-1">Message</Form.Label>
                  <Form.Control type="text" value={details.message} readOnly />
                </Form.Group>
              </Form>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* -------------------------- */}
      {/* <ToastContainer /> */}
    </>
  );
}
