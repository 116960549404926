import axios from "axios";
import { setDigitalCampusData, setCounterDigitalData , setDigitalFAQdata, setDigitalTestimonialData, setLoadingCampusLanding } from "../../reducers/digitalCampusReducer";
import { toast } from "react-toastify";

export const fetchCampusHiringLandingPage = () => async (dispatch) => {
  try {

    dispatch(setLoadingCampusLanding(true))

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/student_campus_landing/`,
    );

    dispatch(setDigitalCampusData(response.data));
    dispatch(setCounterDigitalData(response.data?.counters));
    dispatch(setDigitalFAQdata(response.data?.faqs));
    dispatch(setDigitalTestimonialData(response.data?.testimonials))

  } catch (error) {
    toast.error("Failed to fetch Letral Hiring Data");
  }
  finally {
    dispatch(setLoadingCampusLanding(false));
  }
};