export const base_url = `${process.env.REACT_APP_BASE_URL}`;

const API = {
  login: `${base_url}/api/login/`,
  forgotPasMail: `${base_url}/sendresetpasswordemail/`,
  candidateSignUp: `${base_url}/api/student_campusregister/`,
  companySignUp: `${base_url}/api/companyregister/`,
  collegeSignUp: `${base_url}/api/collegeregister/`,
  getClgList: `${base_url}/api/approved-colleges-list/`,
  resetPassWord: `${base_url}/resetpassword/`,
  logout: `{${base_url}/api/logout/}`,
  // HR DASHBOARD API"S IMPLEMENTATIONS
  hrDashbaordHomeCount: `${base_url}/api/jobs/applicants_for_my_jobs/`,
  hrDashbaordHomeCounter1: `${base_url}/api/jobs/company_jobs/`,
  getCampusWiseApplications: `${base_url}/api/jobs/applications_by_college/`,
  pandingClgStdApproval: `${base_url}/api/pending-student_campus/`,
  fecthCompnayRecentJobs: `${base_url}/api/jobs/company_jobs/`,
};

export default API;
