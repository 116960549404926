import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Tooltip } from "@mui/material";
import axios from "axios";
import iim from "../../../utils/dashboards/iim.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend
);

const CampusWiseBarChart = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("accessToken");

  const fetchCollegeData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/jobs/applications_by_college/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const collegeData = response.data;

      const labels = collegeData.map((college) =>
        college.college_name && college.college_name.length > 9
          ? college.college_name.substring(0, 9) + "..."
          : college.college_name || ""
      );

      const data = collegeData.map(
        (college) => college.total_applied_students_percentage || 0 // Ensure zero instead of null/undefined
      );

      setChartData({
        labels,
        datasets: [
          {
            data: data.map((value) => ({
              value,
              display: value === 0 ? 0.5 : value,
            })),
            backgroundColor: data.map((value) =>
              value === 0 ? "rgba(251, 176, 59, 0.3)" : "#FBB03B"
            ),
            borderRadius: 10,
            barThickness: 15,
            maxBarThickness: 16,
          },
        ],
      });
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch college data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCollegeData();
  }, []);

  const options = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        grid: { display: false },
        ticks: {
          display: false,
          callback: function (value) {
            return value === 0.5 ? "0" : value;
          },
        },
        border: { display: false },
      },
      y: {
        grid: { display: false },
        ticks: { display: false },
        border: { display: false },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            // Show actual value in tooltip
            const actualValue = context.raw.value;
            return `${actualValue}%`;
          },
        },
      },
    },
    layout: {
      padding: { top: 0, bottom: 0, right: 0, left: 20 },
    },
  };

  return (
    <div className="campus_bar_char">
      <div className="campus_bar_char_container">
        <ul className="campus_bar_char_chart_counter">
          {[...Array(11)].map((_, i) => (
            <li key={i}>{(i * 10).toString().padStart(2, "0")}</li>
          ))}
        </ul>
      </div>
      <div className="campus_bar_char_clg_container">
        <div className="campus_bar_char_clg_container_sub small text-muted">
          {chartData.labels.map((label, index) => (
            <Tooltip
              key={index}
              title={`${label}: ${
                chartData.datasets[0]?.data[index]?.value || 0
              }%`}
              arrow
              placement="right"
            >
              <div className="d-flex align-items-center mb-2">
                <div
                  className="border p-2 rounded-2"
                  style={{ marginRight: "15px" }}
                >
                  <img
                    src={iim}
                    alt={label}
                    className="rounded"
                    style={{ width: "24px", height: "24px" }}
                  />
                </div>
                <span>{label}</span>
              </div>
            </Tooltip>
          ))}
        </div>

        <div
          className="flex-grow-1 position-relative"
          style={{ minHeight: "300px" }}
        >
          {loading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              Loading...
            </div>
          ) : error ? (
            <div className="d-flex justify-content-center align-items-center h-100 text-danger">
              {error}
            </div>
          ) : (
            <div style={{ height: "100%" }}>
              <Bar data={chartData} options={options} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampusWiseBarChart;
