import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import RoutesHead from "./routes";
import OnlineStatusProvider from "./components/reusablecomponents/CheckOnline";
import { useDispatch } from "react-redux";
import { fetchLetralHiringLandingPage } from "./redux/actions/letralHiringAction";
import { fetchCampusHiringLandingPage } from "./redux/actions/digital_campus_action";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function App() {

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
   
    dispatch(fetchLetralHiringLandingPage());
    dispatch(fetchCampusHiringLandingPage())
    // if(location.pathname === "/"){
    //   dispatch(fetchLetralHiringLandingPage());
    // }
    // else if (location.pathname.includes("lateral")) {
    //   dispatch(fetchLetralHiringLandingPage());
    // }
    // else{
    //   dispatch(fetchCampusHiringLandingPage())
    // }
  }, [dispatch])

  return (
    <div>
      <OnlineStatusProvider>
        <RoutesHead />
      </OnlineStatusProvider>
    </div>
  );
}

export default App;