import { createSlice } from "@reduxjs/toolkit";

const LetralHiringSlice = createSlice({
  name: "letralHiring",
  initialState: {
    letralHiringJobsData: [],
    letralHiringUserData: [],
    landingPageData: [],
    faqsRedux: [],
    alltestimonialsRedux: [],
    popularJobsRedux: [],
    topcompanyRedux: [],
    countersDataRedux: [],
    loadingLetralLanding: false,
  },
  reducers: {
    setLetralHiringJobsData: (state, action) => {
      state.letralHiringJobsData = action.payload;
    },
    setLetralHiringUserData: (state, action) => {
      state.letralHiringUserData = action.payload;
    },
    setLandingPageData: (state, action) => {
      state.landingPageData = action.payload;
    },
    setFaqsRedux: (state, action) => {
      state.faqsRedux = action.payload;
    },
    setAlltestimonialsRedux: (state, action) => {
      state.alltestimonialsRedux = action.payload;
    },
    setPopularJobsRedux: (state, action) => {
      state.popularJobsRedux = action.payload;
    },
    setTopcompanyRedux: (state, action) => {
      state.topcompanyRedux = action.payload;
    },
    setCountersDataRedux: (state, action) => {
      state.countersDataRedux = action.payload;
    },
    setLoadingLetralLanding: (state, action) => {
      state.loadingLetralLanding = action.payload;
    },
    updateLetralHiringJobData: (state, action) => {
      state.letralHiringJobsData = state.letralHiringJobsData.map((job) => {
        if (job.id === action.payload) {
          return { ...job, is_already_saved: !job.is_already_saved };
        }
        return job;
      });
    },
  },
});

// Export Actions
export const {
  setLetralHiringJobsData,
  updateLetralHiringJobData,
  setLetralHiringUserData,
  setLandingPageData,
  landingPageData,
  setFaqsRedux,
  setAlltestimonialsRedux,
  setPopularJobsRedux,
  setTopcompanyRedux,
  setCountersDataRedux,
  setLoadingLetralLanding
} = LetralHiringSlice.actions;

// Export Reducer
export default LetralHiringSlice.reducer;
