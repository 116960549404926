import React, { useState, useEffect } from "react";
import "../../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import dummy_logo from "../../../../utils/landingpage/images/dummy_logo.png";
import leftArrowIcon from "../../../../utils/landingpage/images/leftArrowIcon.svg";

import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "react-spinners/ClipLoader";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ViewJobDetails = () => {
  const { id, jobType } = useParams();
  const [token, setToken] = useState(null);
  const [jobDetails, setDetails] = useState([]);
  const [globalLoading, setGlobalLoading] = useState(false);

  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const newSession = sessionStorage.getItem("accessToken");
    setToken(newSession);

    if (id && newSession) {
      setGlobalLoading(true);

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/${jobType}-jobs/${id}/`,
          {
            headers: {
              Authorization: `Bearer ${newSession}`,
            },
          }
        )
        .then((response) => {
          setDetails(response.data);
          setSkills(response.data.job_skills);
        })
        .catch((error) => {
          console.error("Error fetching job details:", error);
          toast.error("Failed to fetch job details");
        })
        .finally(() => {
          setGlobalLoading(false);
        });
    }
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date).toUpperCase();
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        {globalLoading ? (
          <div className="custom_loader">
            <Loader color="#F38D00" size={50} />
          </div>
        ) : (
          <div className="_main_content_inner_screen_container">
            <div className="job-detail-page">
              <div className="job-detail-header">
                <button
                  className="job-detail-back-button"
                  onClick={() => window.history.back()}
                >
                  <img src={leftArrowIcon} alt="Back" />
                </button>
                <h5>Job Details</h5>
              </div>
              <div className="job-detail-summary">
                <div className="job-left">
                  <img
                    src={
                      jobDetails.company_profile?.company_logo_url || dummy_logo
                    }
                    alt="logo"
                    className="company-logo"
                    style={{ marginRight: "13px" }}
                  />
                  <div className="job-info">
                    <h6>{jobDetails.title || "Not Mentioned"}</h6>
                    <p>{jobDetails.company_name || "Not Mentioned"}</p>
                  </div>
                </div>
                <div className="job-detail-right">
                  <span>
                    <h6>Posted date</h6>
                    {jobDetails?.created_at
                      ? formatDate(jobDetails.created_at)
                      : "Not Mentioned"}
                  </span>
                </div>
              </div>
              <div className="job-detail-description">
                <h5>Job Description</h5>
                <p>{jobDetails.description || "Not Mentioned"}</p>
                <h6>
                  Role: <p>{jobDetails.title || "Not Mentioned"}</p>
                </h6>
                <h6>
                  status: <p>{jobDetails.status || "Not Mentioned"}</p>
                </h6>
                <h6>
                  Job Category: <p>{jobDetails.job_category_name || "Not Mentioned"}</p>
                </h6>
                <h6>
                  Employment Type: <p>{jobDetails.type || "Not Mentioned"}</p>
                </h6>
                <h6>
                  Education: <p>{jobDetails.qualification || "Not Mentioned"}</p>
                </h6>
                <h6>
                  salary Range:
                  <p>
                    {jobDetails.min_salary
                      ? `${parseFloat(jobDetails.min_salary)}`
                      : "0"}{" "}
                    -{" "}
                    {jobDetails.max_salary
                      ? `${parseFloat(jobDetails.max_salary)}`
                      : "0"}{" "}
                    LPA
                  </p>
                </h6>
                <h6>
                  Educational Qualification: <p>{jobDetails.degree_type || "Not Mentioned"}</p>
                </h6>
                <h6>
                  Qualification: <p>{jobDetails.qualification || "Not Mentioned"}</p>
                </h6>
                <h6>
                  Experience:
                  <p>
                    {jobDetails.min_experience
                      ? `${parseFloat(jobDetails.min_experience)}`
                      : "0"}{" "}
                    -{" "}
                    {jobDetails.max_experience
                      ? `${parseFloat(jobDetails.max_experience)}`
                      : "0"}{" "}
                    Years
                  </p>
                </h6>
                <h6>
                  Vacancy: <p>{jobDetails.vacancy || "Not Mentioned"}</p>
                </h6>
                <h6>
                  Job Start Date:{" "}
                  <p>
                    {jobDetails?.start_date
                      ? formatDate(jobDetails.start_date)
                      : "Not Mentioned"}
                  </p>
                </h6>
                <h6>
                  Job Expiry Date:{" "}
                  <p>
                    {jobDetails?.end_date
                      ? formatDate(jobDetails.end_date)
                      : "Not Mentioned"}
                  </p>
                </h6>
                <h6>
                  Location: <p>{jobDetails.job_location || "Not Mentioned"}</p>
                </h6>
                <h6>
                  Key Skills:
                  {skills.length > 0 ? (
                    <p>
                      {skills.map((skill, index) => (
                        <span key={skill.id}>
                          {skill.skill_name}
                          {index < skills.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </p>
                  ) : (
                    <p>Not Mentioned</p>
                  )}
                </h6>
                <h6>
                  Job Responsibility:{" "}
                  <p>{jobDetails.responsibility || "Not Mentioned"}</p>
                </h6>
              </div>
              <div className="job-detail-company">
                <h5>Job Description</h5>
                <p>{jobDetails.description || "Not Mentioned"}</p>
              </div>
              <div className="job-detail-company">
                <h5>Perks Benefits</h5>
                <p>{jobDetails.perks_benefits || "Not Mentioned"}</p>
              </div>
              <div className="job-detail-company">
                <h5>About Company</h5>
                <p>
                  {jobDetails.company_name || "Not Mentioned"}.
                </p>
                <p>HR Email - {jobDetails?.company} </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewJobDetails;
