import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Png_img_stud from "../../../utils/landingpage/images/Png_img_stud.png";
import MPHASIS_png_stud from "../../../utils/landingpage/images/MPHASIS_png_stud.png";
import Systango_png_stud from "../../../utils/landingpage/images/Systango_png_stud.png";
import Accenture_png_stud from "../../../utils/landingpage/images/Accenture_png_stud.png";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import "../../../styles/dashboards/clgstudent/studentinterview.css";
import InterviewDetails from "./InterviewDetails";
import { toast } from "react-toastify";
import Loader from "react-spinners/ClipLoader";
import { setMyInterviewDetails } from "../../../redux/reducers/student_profile_reducer";

const data1 = [
  {
    id: 1,
    companyname: "Microsoft Pvt. Ltd.",
    date: "31 July 2024",
    day: "Wednesday",
    time: "11:00",
    Am: "AM",
    logo: Png_img_stud,
    location: "Pune, Maharashtra",
  },

  {
    id: 2,
    companyname: "Mphasis Pvt. Ltd.",
    date: "31 July 2024",
    day: "Thursday",
    time: "12:00",
    Am: "Pm",
    logo: MPHASIS_png_stud,
    location: "Vadodara , Gujarat",
  },

  {
    id: 3,
    companyname: "Accenture Pvt. Ltd.",
    date: "31 July 2024",
    day: "Friday",
    time: "01:00",
    Am: "Pm",
    logo: Accenture_png_stud,
    location: "Indore, Madhya Pradesh",
  },

  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
];

const StudentInterview = () => {
  const [scheduled, setScheduled] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const showJobDetailsRedux = useSelector((state) => state.studentProfile.myInterviewDetails);
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchInterviewDetails = async () => {
      try {
        setIsLoading(true)
        const accessToken = sessionStorage.getItem("accessToken");

        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/student/schedule_interview/retrieve_interview_details/?student_of=campus`,
          config
        );

        setScheduled(response.data?.interview_details)
      } catch (error) {
        toast.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInterviewDetails();
  }, []);

  const formatDateTime = (dateTime) => {
    const dateObj = new Date(dateTime);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = dateObj.toLocaleDateString("en-US", options);

    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    const time = dateObj.toLocaleTimeString("en-US", timeOptions);

    return { date, time };
  };

  const handleJobDetailsClick = (job) => {
    setSelectedJob(job);
    dispatch(setMyInterviewDetails(true));
  };

  return (
    <div className="_main_content_inner_screen">
      {isLoading ?
        (<div className="custom_loader">
          <Loader color="#F38D00" size={50} />
        </div>)
        :
        (<div className="_main_content_inner_screen_conatiner">
          {showJobDetailsRedux === true ? <InterviewDetails data={selectedJob} /> :
            (<div className="student_campus_interview_data">
              <div className="student_campus_interview_parent">
                <div className="student_campus_job_interview">
                  <div className="student_campus_interview_border">
                    <h2 className="student_campus_interview_content">
                      My Scheduled
                      <span className="student_campus_interview_span">
                        (This Week)
                      </span>
                    </h2>
                  </div>

                  <div className="student_campus_scrollBar_container">
                    {(() => {
                      const filteredJobs = scheduled
                        ?.filter((job) => {
                          const interviewDate = new Date(job.interview_date);
                          const currentDate = new Date();

                          const diffInTime = interviewDate - currentDate;
                          const diffInDays = diffInTime / (1000 * 60 * 60 * 24);

                          return diffInDays >= 0 && diffInDays <= 7;
                        });

                      if (!filteredJobs || filteredJobs.length === 0) {
                        return <p className="no-interviews-message">No Interviews This Week</p>;
                      }

                      return filteredJobs.map((job, index) => {
                        const { date, time } = formatDateTime(job.interview_date);

                        return (
                          <div className="student_campus_company_padding">
                            <div
                              key={job.id}
                              className={`student_campus_company_parent1 ${index === data1.length - 1
                                ? "student_campus_no-border"
                                : ""
                                }`}
                              onClick={() => handleJobDetailsClick(job)}
                            >
                              <div className="student_campus_company_parent">
                                <img
                                  src={job.job_details?.company_profile?.company_logo_url || dummy_logo}
                                  alt="Job Logo"
                                  width={50}
                                  height={50}
                                  className="student_campus_job_logo"
                                />
                                <div>
                                  <h2 className="student_campus_job_interviewcompany">
                                    {job.job_details?.company}
                                  </h2>
                                  <p className="student_campus_job_interviewlocation">
                                    {job.job_details?.job_location || 'Not Mentioned'}
                                  </p>
                                </div>
                              </div>

                              <div className="student_campus_time_am">
                                <h2 className="student_campus_job_interviewtime">Job Title</h2>
                                <p className="student_campus_job_interviewampm">{job?.job_details?.title}</p>
                              </div>

                              <div className="student_campus_date_day">
                                <h2 className="student_campus_job_interviewdate">Interview Timing</h2>
                                <p className="student_campus_job_interviewday">{date} {time}</p>
                              </div>

                              <div className="student_campus_time_am">
                                <h2 className="student_campus_job_interviewtime">Interview Mode</h2>
                                <p className="student_campus_job_interviewampm">{job?.zoom_meeting_link ? 'Virtual' : 'Physical'}</p>
                              </div>

                            </div>
                          </div>
                        );
                      });
                    })()}
                  </div>

                </div>
              </div>

              <div className="student_campus_interview_parent">
                <div className="student_campus_job_interview">
                  <div className="student_campus_interview_border">
                    <h2 className="student_campus_interview_content">
                      Upcoming Interviews
                    </h2>
                  </div>
                  <div className="student_campus_scrollBar_container">
                    {scheduled && scheduled?.map((job, index) => {
                      const { date, time } = formatDateTime(job.interview_date);

                      if (!scheduled || scheduled.length === 0) {
                        return <p className="no-interviews-message">No Interviews Scheduled</p>;
                      }
                      return (
                        <div className="student_campus_company_padding">
                          <div
                            key={job.id}
                            className={`student_campus_company_parent1 ${index === data1.length - 1
                              ? "student_campus_no-border"
                              : ""
                              }`}
                            onClick={() => handleJobDetailsClick(job)}
                          >
                            <div className="student_campus_company_parent">
                              <img
                                src={job.job_details?.company_profile?.company_logo_url || dummy_logo}
                                alt="Job Logo"
                                width={50}
                                height={50}
                                className="student_campus_job_logo"
                              />
                              <div>
                                <h2 className="student_campus_job_interviewcompany">
                                  {job.job_details?.company}
                                </h2>
                                <p className="student_campus_job_interviewlocation">
                                  {job.job_details?.job_location || 'Not Mentioned'}
                                </p>
                              </div>
                            </div>

                            <div className="student_campus_time_am">
                              <h2 className="student_campus_job_interviewtime">Job Title</h2>
                              <p className="student_campus_job_interviewampm">{job?.job_details?.title}</p>
                            </div>

                            <div className="student_campus_date_day">
                              <h2 className="student_campus_job_interviewdate">Interview Timing</h2>
                              <p className="student_campus_job_interviewday">{date} {time}</p>
                            </div>

                            <div className="student_campus_time_am">
                              <h2 className="student_campus_job_interviewtime">Interview Mode</h2>
                              <p className="student_campus_job_interviewampm">{job?.zoom_meeting_link ? 'Virtual' : 'Physical'}</p>
                            </div>

                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>)}
        </div>)}
    </div>
  );
};

export default StudentInterview;