import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../../styles/dashboards/hrdashboard/viewappllications.css";
import "../../../../styles/dashboards/hrdashboard/campuseinterview.css";
import searchIconDash from "../../../../utils/dashboards/searchIconDash.svg";
import axios from "axios";
import "../../../../styles/dashboards/hrdashboard/viewappllications.css";
import { toast } from "react-toastify";
import { Select, MenuItem, FormControl, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { debounce } from "lodash";
import { TextField, InputAdornment } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { FileUpload, VisibilitySharp } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    padding: "8px 14px",
    borderRadius: "4px",
    border: "1px solid #E0E0E0",
    backgroundColor: "#fff",
    "&:focus": {
      borderRadius: "4px",
      borderColor: "#2196F3",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    padding: "16px",
    minWidth: "400px",
  },
}));

const StyledDialogTitle = styled(DialogTitle)({
  fontSize: "20px",
  fontWeight: "600",
  color: "#333",
  padding: "16px 24px",
});

const StyledDialogContent = styled(DialogContent)({
  padding: "20px 24px",
  '& input[type="file"]': {
    border: "1px dashed #ccc",
    borderRadius: "4px",
    padding: "20px",
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      borderColor: "#666",
    },
  },
});

const StyledDialogActions = styled(DialogActions)({
  padding: "16px 24px",
  "& .MuiButton-root": {
    borderRadius: "20px",
    padding: "8px 24px",
    "&.MuiButton-contained": {
      backgroundColor: "#ffa500",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#ff9000",
      },
    },
  },
});

export default function CandidateOfColleges() {
  const navigate = useNavigate();
  const roleName = JSON.parse(sessionStorage.getItem("userRoleName"));
  const { jobName, collegeId, jobId } = useParams();
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [loadingStatusIds, setLoadingStatusIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadingOfferId, setUploadingOfferId] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const token = sessionStorage.getItem("accessToken");
  const circulateJobData = JSON.parse(
    localStorage.getItem("seecirculatedjobdetails")
  );

  const candidateSelection = [
    { value: "in_review", label: "In-review " },
    { value: "shortlisted", label: "Shortlisted" },
    { value: "interviewed", label: "Interviewed" },
    { value: "discarded", label: "Declined" },
    { value: "offered", label: "Offered" },
  ];

  const getCandidates = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/hr/jobs/colleges/all_status_applications/?college_id=${collegeId}&job_id=${jobId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCandidates(response.data.students);
    } catch (error) {
      console.error("Error fetching candidates:", error);
    } finally {
      setLoading(false);
    }
  }, [collegeId, jobId, token]);

  useEffect(() => {
    getCandidates();
  }, [getCandidates]);

  const handleCheckboxChange = (event, candidateId) => {
    if (event.target.checked) {
      const candidate = candidates.find((c) => c.id === candidateId);
      if (selectedCandidates.length > 0) {
        const firstSelectedCandidate = candidates.find(
          (c) => c.id === selectedCandidates[0]
        );

        if (firstSelectedCandidate.status !== candidate.status) {
          toast.error("You can only select candidates with the same status");
          return;
        }
      }

      setSelectedCandidates((prev) => [...prev, candidateId]);
    } else {
      setSelectedCandidates((prev) => prev.filter((id) => id !== candidateId));
    }
  };

  const handleStatusChange = async (newStatus) => {
    try {
      setStatusLoading(true);
      if (selectedCandidates.length === 0) {
        toast.error("Please select at least one candidate.");
        return;
      }

      if (!newStatus) {
        toast.error("Please select a status");
        return;
      }

      // Get the first selected candidate
      const firstCandidate = candidates.find(
        (c) => c.id === selectedCandidates[0]
      );

      // Check if all selected candidates have the same current status
      const allSameStatus = selectedCandidates.every((candidateId) => {
        const candidate = candidates.find((c) => c.id === candidateId);
        return candidate.status === firstCandidate.status;
      });

      if (!allSameStatus) {
        toast.error(
          "All selected candidates must have the same current status"
        );
        return;
      }

      // Get valid status options and check transition
      const validStatusOptions = getStatusOptions(firstCandidate.status);
      const isValidTransition = validStatusOptions.some(
        (option) => option.value === newStatus
      );

      if (!isValidTransition) {
        toast.error(
          `Cannot transition from ${firstCandidate.status} to ${newStatus}`
        );
        return;
      }

      // Make the API call
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/update_application_status/`,
        {
          application_ids: selectedCandidates,
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Status updated successfully.");
      getCandidates();
      setSelectedCandidates([]);
      setStatus("");
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status.");
    } finally {
      setStatusLoading(false);
    }
  };

  const handleSeeCandidate = () => {
    navigate(
      `/dashboard/sidebar-page/${roleName}/collage-applicants/${collegeId}/${circulateJobData?.job_id}/${circulateJobData?.no_of_interview_rounds}`
    );
  };

  const filterCandidates = (candidates) => {
    return candidates.filter((candidate) => {
      const fullName = `${candidate.student_details.name || ""} ${
        candidate.student_details.student_last_name || ""
      }`.toLowerCase();
      const email = (candidate.student_details.email || "").toLowerCase();
      const contact = (candidate.student_details.all_mobno || "").toLowerCase();
      const query = searchQuery.toLowerCase();

      return (
        fullName.includes(query) ||
        email.includes(query) ||
        contact.includes(query)
      );
    });
  };

  const candidatesByStatus = {
    all: filterCandidates(candidates),
    in_review: filterCandidates(
      candidates.filter((c) => c.status === "in_review")
    ),
    interviewed: filterCandidates(
      candidates.filter((c) => c.status === "interviewed")
    ),
    shortlisted: filterCandidates(
      candidates.filter((c) => c.status === "shortlisted")
    ),
    discarded: filterCandidates(
      candidates.filter((c) => c.status === "discarded")
    ),
    offered: filterCandidates(candidates.filter((c) => c.status === "offered")),
  };

  const getStatusOptions = (currentStatus) => {
    if (currentStatus === "discarded") return [];
    const baseOptions = [{ value: "discarded", label: "Declined" }];
    switch (currentStatus) {
      case "in_review":
        return [{ value: "shortlisted", label: "Shortlisted" }, ...baseOptions];
      case "shortlisted":
        return [{ value: "interviewed", label: "Interview" }, ...baseOptions];
      case "interviewed":
        return [...baseOptions];
      case "offered":
        return [];
      default:
        return baseOptions;
    }
  };

  const debouncedStatusUpdate = useCallback(
    debounce(async (candidateId, newStatus) => {
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/update_application_status/`,
          {
            application_ids: [candidateId],
            status: newStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success("Status updated successfully.");
        getCandidates();
      } catch (error) {
        console.error("Error updating status:", error);
        toast.error("Failed to update status.");
      } finally {
        setLoadingStatusIds((prev) => prev.filter((id) => id !== candidateId));
        setOpenDropdownId(null);
      }
    }, 500),
    [token, getCandidates]
  );

  const renderStatusCell = (candidate) => {
    const statusOptions = getStatusOptions(candidate.status);
    const isLoading = loadingStatusIds.includes(candidate.id);
    const isStatusFinal =
      candidate.status === "discarded" || candidate.status === "offered";

    return (
      <div className="status-cell">
        <div
          className={`status-badge ${candidate.status}`}
          onClick={(e) => {
            e.stopPropagation();
            if (!isLoading && !isStatusFinal) {
              setOpenDropdownId(
                openDropdownId === candidate.id ? null : candidate.id
              );
            }
          }}
        >
          {isLoading ? (
            <div className="status-loader">
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {candidate.status}
              {statusOptions.length > 0 && !isStatusFinal && (
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  style={{ marginLeft: "4px" }}
                >
                  <path d="M7 10l5 5 5-5z" />
                </svg>
              )}
            </>
          )}
        </div>

        {statusOptions.length > 0 &&
          openDropdownId === candidate.id &&
          !isLoading && (
            <div className="status-dropdown">
              {statusOptions
                .filter((option) => option.value !== candidate.status)
                .map((option) => (
                  <div
                    key={option.value}
                    className="status-option"
                    onClick={(e) => {
                      e.stopPropagation();
                      setLoadingStatusIds((prev) => [...prev, candidate.id]);
                      debouncedStatusUpdate(candidate.id, option.value);
                    }}
                  >
                    {option.label}
                  </div>
                ))}
            </div>
          )}
      </div>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openDropdownId && !event.target.closest(".status-cell")) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openDropdownId]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const firstCandidate = candidatesByStatus[activeTab][0];
      if (!firstCandidate) return;

      const candidatesWithSameStatus = candidatesByStatus[activeTab]
        .filter((c) => c.status === firstCandidate.status)
        .map((c) => c.id);

      setSelectedCandidates(candidatesWithSameStatus);
    } else {
      setSelectedCandidates([]);
    }
  };

  const handleOfferLetterUpload = async (applicationId) => {
    if (!selectedFile) {
      toast.error("Please select a file to upload");
      return;
    }

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("application_ids", [applicationId]);
      formData.append("status", "offered");
      formData.append("offer_letter", selectedFile);

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/update_application_status/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Offer letter uploaded successfully");
      setOpenUploadDialog(false);
      setSelectedFile(null);
      getCandidates(); // Refresh the candidates list
    } catch (error) {
      console.error("Error uploading offer letter:", error);
      toast.error("Failed to upload offer letter");
    } finally {
      setIsUploading(false);
      setUploadingOfferId(null);
    }
  };

  const uploadDialog = (
    <StyledDialog
      open={openUploadDialog}
      onClose={() => {
        setOpenUploadDialog(false);
        setSelectedFile(null);
      }}
    >
      <StyledDialogTitle>Upload Offer Letter</StyledDialogTitle>
      <StyledDialogContent>
        <input
          type="file"
          accept=".pdf"
          onChange={(e) => setSelectedFile(e.target.files[0])}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setOpenUploadDialog(false);
            setSelectedFile(null);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleOfferLetterUpload(uploadingOfferId)}
          disabled={!selectedFile || isUploading}
        >
          {isUploading ? "Uploading..." : "Upload"}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );

  const renderOfferLetterCell = (candidate) => (
    <td className="text-center d-flex gap-2 align-items-center justify-content-center">
      {candidate.offer_letter_url && (
        <Tooltip title="View Offer Letter" arrow placement="top">
          <a
            href={candidate?.offer_letter_url}
            target="_blank"
            rel="noopener noreferrer"
            className="view_application_btn"
          >
            <VisibilitySharp sx={{ fontSize: 20 }} />
          </a>
        </Tooltip>
      )}

      <Tooltip title="Upload Offer Letter" arrow placement="top">
        <button
          className="view_application_btn"
          onClick={() => {
            setUploadingOfferId(candidate?.id);
            setOpenUploadDialog(true);
          }}
        >
          <FileUpload sx={{ fontSize: 20 }} />
        </button>
      </Tooltip>
    </td>
  );

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="view_application_main_container">
          <h6>
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <path
                d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                fill="#222222"
              />
              <path
                d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                fill="#222222"
              />
            </svg>{" "}
            &nbsp; {jobName} Applied Candidate By College
          </h6>
          <div className="d-flex gap-4 align-items-center">
            <h6>Total : {candidates.length}</h6>
          </div>
        </div>

        <div className="capuse_interview_tabs-container">
          <div className="capuse_interview_tabs">
            <button
              className={`capuse_interview_tab-button ${
                activeTab === "all" ? "capuse_interview_active" : ""
              }`}
              onClick={() => {
                setActiveTab("all");
                setSelectedCandidates([]);
                setStatus("");
              }}
            >
              All ({candidatesByStatus.all.length})
            </button>
            {candidateSelection.map((tab) => (
              <button
                key={tab.value}
                className={`capuse_interview_tab-button ${
                  activeTab === tab.value ? "capuse_interview_active" : ""
                }`}
                onClick={() => {
                  setActiveTab(tab.value);
                  setSelectedCandidates([]);
                  setStatus("");
                }}
              >
                {tab.label} ({candidatesByStatus[tab.value]?.length || 0})
              </button>
            ))}
          </div>
          <div>
            <TextField
              placeholder="Search candidates..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              variant="outlined"
              size="small"
              sx={{
                width: "300px",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  borderRadius: "24px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIconDash} alt="search" />
                  </InputAdornment>
                ),
              }}
            />

            {activeTab === "interviewed" &&
              candidatesByStatus["interviewed"].length > 0 && (
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={handleSeeCandidate}
                  sx={{
                    float: "right",
                    borderRadius: "20px",
                    backgroundColor: "#ffa500",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#ffa500",
                    },
                  }}
                >
                  See Interview
                </Button>
              )}
          </div>
          <div>
            <div className="campuse_std_job_applications-container">
              <div className="d-flex gap-1">
                {selectedCandidates.length !== 0 && activeTab !== "all" && (
                  <>
                    <div className="status-cell">
                      <FormControl>
                        <CustomSelect
                          value={status}
                          onChange={(e) => {
                            const newStatus = e.target.value;
                            const firstCandidate = candidates.find(
                              (c) => c.id === selectedCandidates[0]
                            );
                            const validOptions = getStatusOptions(
                              firstCandidate.status
                            );
                            const isValidTransition = validOptions.some(
                              (option) => option.value === newStatus
                            );

                            if (isValidTransition) {
                              setStatus(newStatus);
                              handleStatusChange(newStatus);
                            } else {
                              toast.error(
                                `Cannot transition to ${newStatus} from current status`
                              );
                            }
                          }}
                          displayEmpty
                          disabled={statusLoading}
                        >
                          <MenuItem value="" disabled>
                            Change Status
                          </MenuItem>
                          {selectedCandidates.length > 0 &&
                            (() => {
                              const firstCandidate = candidates.find(
                                (c) => c.id === selectedCandidates[0]
                              );
                              return getStatusOptions(
                                firstCandidate.status
                              ).map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ));
                            })()}
                        </CustomSelect>
                      </FormControl>
                      {statusLoading && (
                        <div
                          className="status-loader"
                          style={{ marginLeft: "8px" }}
                        >
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {loading ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : candidatesByStatus[activeTab]?.length === 0 ? (
            <div className="no-candidates-container bg-white text-center p-5 mt-3 rounded-3">
              <p>No candidates found in this category.</p>
            </div>
          ) : (
            <div className="view_applicationlisting_table">
              <table>
                <thead className="view_applicationlisting_table_head">
                  <tr>
                    <th>
                      {activeTab !== "all" &&
                        candidatesByStatus[activeTab]?.length > 0 &&
                        candidatesByStatus[activeTab].some(
                          (c) =>
                            c.status !== "discarded" && c.status !== "offered"
                        ) && (
                          <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={
                              selectedCandidates.length > 0 &&
                              selectedCandidates.length ===
                                candidatesByStatus[activeTab].filter(
                                  (c) =>
                                    c.status ===
                                      candidatesByStatus[activeTab][0].status &&
                                    c.status !== "discarded" &&
                                    c.status !== "offered"
                                ).length
                            }
                          />
                        )}
                    </th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Contact</th>
                    <th>Applied At</th>
                    <th>Actions</th>
                    {(activeTab === "offered" ||
                      candidatesByStatus[activeTab].some(
                        (c) => c.status === "offered"
                      )) && <th>Offer Letter</th>}
                  </tr>
                </thead>
                <tbody>
                  {candidatesByStatus[activeTab].map((c, index) => (
                    <tr key={index}>
                      <td>
                        {activeTab !== "all" &&
                          c.status !== "discarded" &&
                          c.status !== "offered" && (
                            <input
                              type="checkbox"
                              checked={selectedCandidates.includes(c.id)}
                              onChange={(e) => handleCheckboxChange(e, c.id)}
                              disabled={
                                selectedCandidates.length > 0 &&
                                !selectedCandidates.includes(c.id) &&
                                c.status !==
                                  candidates.find(
                                    (candidate) =>
                                      candidate.id === selectedCandidates[0]
                                  ).status
                              }
                            />
                          )}
                      </td>
                      <td>
                        {c.student_details.name || "N/A"} &nbsp;
                        {c.student_details.student_last_name || "N/A"}
                      </td>
                      <td>{c.student_details.email || "N/A"}</td>
                      <td>
                        {activeTab == "all" ? c?.status : renderStatusCell(c)}
                      </td>
                      <td>{c.student_details.all_mobno || "N/A"}</td>
                      <td>{new Date(c.applied_at).toLocaleDateString()}</td>
                      <td>
                        <button
                          className="view_application_btn"
                          onClick={() => {
                            navigate(
                              `/dashboard/sidebar-page/hr-manager/campus/view-candidate-application/${c.student_details.id}/${c.id}`
                            );
                          }}
                        >
                          See Application
                        </button>
                      </td>
                      {(activeTab === "offered" ||
                        candidatesByStatus[activeTab].some(
                          (c) => c.status === "offered"
                        )) &&
                        renderOfferLetterCell(c)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {uploadDialog}
    </div>
  );
}
