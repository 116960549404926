import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../../styles/admin/setting.css";
import axios from "axios";
import { toast } from "react-toastify";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";

function Settings() {
  const token = sessionStorage.getItem("accessToken");
  const user = JSON.parse(sessionStorage.getItem("userDetails"));
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEmailUpdate, setISEMailUpdate] = useState(false);
  const base_Url = process.env.REACT_APP_BASE_URL;
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [openOTPDialog, setOpenOTPDialog] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const emailFormik = useFormik({
    initialValues: {
      email: user?.email || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      if (values.email === user?.email) {
        toast.info("Please enter a different email address");
        return;
      }

      try {
        setISEMailUpdate(true);
        const response = await axios.post(
          `${base_Url}/api/update-email/`,
          {
            email: values.email,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.status === 200) {
          setNewEmail(values.email);
          setOpenOTPDialog(true);
          toast.success(response.data.msg);
        }
      } catch (error) {
        if (error.response) {
          const errorData = error.response.data;
          if (errorData.non_field_errors) {
            toast.error(errorData.non_field_errors[0]);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      } finally {
        setISEMailUpdate(false);
      }
    },
  });

  const passwordFormik = useFormik({
    initialValues: { old_password: "", password: "", password2: "" },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Current password is required"),
      password: Yup.string()
        .min(5, "Password must be at least 5 characters")
        .required("New password is required")
        .test(
          "different-from-old-password",
          "New password cannot be the same as the old password",
          function (value) {
            return value !== this.parent.old_password;
          }
        ),
      password2: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Confirm password is required"),
    }),

    onSubmit: async (values) => {
      setPasswordLoading(true);
      try {
        const response = await axios.post(
          `${base_Url}/api/changepassword/`,
          values,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.status === 200) {
          toast.success("Password changed successfully!");
          passwordFormik.resetForm();
        }
      } catch (error) {
        if (error.response) {
          const errorData = error.response.data;
          if (errorData.non_field_errors) {
            toast.error(errorData.non_field_errors[0]);
          } else {
            toast.error(
              "Failed to change password. Please check the details and try again."
            );
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      } finally {
        setPasswordLoading(false);
      }
    },
  });

  const handleVerifyOTP = async (otp) => {
    try {
      const response = await axios.post(
        `${base_Url}/api/change-email-verify-otp/`,
        {
          otp,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        toast.success("Email updated successfully!");
        const updatedUser = { ...user, email: newEmail };
        sessionStorage.setItem("userDetails", JSON.stringify(updatedUser));
        setOpenOTPDialog(false);
        emailFormik.resetForm({ values: { email: newEmail } });
      }
    } catch (error) {
      if (error.response) {
        const errorData = error.response.data;
        if (errorData.non_field_errors) {
          toast.error(errorData.non_field_errors[0]);
        } else {
          toast.error("In valid otp please try again !");
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleResendOTP = async () => {
    try {
      const response = await axios.post(
        `${base_Url}/api/update-email/`,
        {
          email: user.email,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        toast.success("OTP resent successfully!");
      }
    } catch (error) {
      if (error.response) {
        const errorData = error.response.data;
        if (errorData.non_field_errors) {
          toast.error(errorData.non_field_errors[0]);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      throw error;
    }
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="admin_settings_container">
          <div className="admin_settings_headings">
            <h6>Login Details</h6>
          </div>
          <hr />

          <div className="admin_settings_main_sections_heading">
            <div className="admin_settings_basic_info_container">
              <h6>Basic Information</h6>
              <p>
                This is your personal information that you can update anytime.
              </p>
            </div>

            {/* Email Update Form */}
            <div className="admin_settings_basic_form_container">
              <div className="admin_settings_basic_form_heading">
                <h6>Update Email</h6>
                <p>
                  Your current email is{" "}
                  <span className="email"> {user?.email}</span>
                </p>
              </div>
              <form
                className="admin_settings_basic_form"
                onSubmit={emailFormik.handleSubmit}
              >
                <div className="admin_settings_basic_form_details">
                  <div className="input-details">
                    <input
                      name="email"
                      type="email"
                      placeholder="Enter your abc@gmail.com"
                      value={emailFormik.values.email}
                      onChange={emailFormik.handleChange}
                      onBlur={emailFormik.handleBlur}
                    />

                    {emailFormik.touched.email && emailFormik.errors.email ? (
                      <span className="error-admin">
                        {emailFormik.errors.email}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="admin_settings_form_submit_btn">
                  <button type="submit">
                    {isEmailUpdate ? "Updating..." : "Update"}
                  </button>
                </div>
              </form>
            </div>

            {/* Password Update Form */}
            <div className="admin_settings_basic_form_container">
              <div className="admin_settings_basic_form_heading">
                <h6>Password</h6>
                <p>
                  If you wish to change your password, you can change it here.
                </p>
              </div>
              <form
                className="admin_settings_basic_form"
                onSubmit={passwordFormik.handleSubmit}
              >
                <div className="admin_settings_basic_form_details">
                  <div className="input-details">
                    <input
                      name="old_password"
                      type="password"
                      placeholder="Enter your current password"
                      value={passwordFormik?.values?.old_password}
                      onChange={passwordFormik.handleChange}
                      onBlur={passwordFormik.handleBlur}
                    />
                    {passwordFormik.touched.old_password &&
                    passwordFormik.errors.old_password ? (
                      <div className="error-admin">
                        {passwordFormik.errors.old_password}
                      </div>
                    ) : null}
                  </div>

                  <div className="input-details">
                    <input
                      name="password"
                      type="password"
                      placeholder="Enter new password"
                      value={passwordFormik.values.password}
                      onChange={passwordFormik.handleChange}
                      onBlur={passwordFormik.handleBlur}
                    />
                    {passwordFormik.touched.password &&
                    passwordFormik.errors.password ? (
                      <div className="error-admin">
                        {passwordFormik.errors.password}
                      </div>
                    ) : null}
                  </div>

                  <div className="input-details">
                    <input
                      name="password2"
                      type="password"
                      placeholder="Confirm password"
                      value={passwordFormik.values.password2}
                      onChange={passwordFormik.handleChange}
                      onBlur={passwordFormik.handleBlur}
                    />
                    {passwordFormik.touched.password2 &&
                    passwordFormik.errors.password2 ? (
                      <div className="error-admin">
                        {passwordFormik.errors.password2}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="admin_settings_form_submit_btn">
                  <button type="submit">
                    {" "}
                    {passwordLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="admin_notifications">
            <div className="admin_notification_one">
              <h6>Notifications</h6>
              <p>
                This is your personal information that you can update anytime.
              </p>
            </div>
            <div className="admin_notification_one checkbox-wrapper">
              <input
                className="tgl tgl-light"
                id="admin_toggle"
                type="checkbox"
              />
              <label className="tgl-btn" htmlFor="admin_toggle"></label>
            </div>
          </div>
        </div>
      </div>
      <OTPVerificationDialog
        open={openOTPDialog}
        onClose={() => setOpenOTPDialog(false)}
        onVerify={handleVerifyOTP}
        onResend={handleResendOTP}
        email={newEmail}
        user={user}
      />
    </div>
  );
}

export default Settings;

const OTPVerificationDialog = ({
  open,
  onClose,
  onVerify,
  onResend,
  email,
  user,
}) => {
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes in seconds
  const [canResend, setCanResend] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const otpFormik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^[0-9]{6}$/, "Must be exactly 6 digits")
        .required("OTP is required"),
    }),
    onSubmit: async (values) => {
      setIsVerifying(true);
      try {
        await onVerify(values.otp);
        onClose();
      } catch (error) {
        console.error("OTP verification failed:", error);
      } finally {
        setIsVerifying(false);
      }
    },
  });

  useEffect(() => {
    let timer;
    if (timeLeft > 0 && open) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            setCanResend(true);
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [timeLeft, open]);

  const handleResend = async () => {
    setIsResending(true);
    try {
      await onResend();
      setTimeLeft(120);
      setCanResend(false);
    } catch (error) {
      console.error("Failed to resend OTP:", error);
    } finally {
      setIsResending(false);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Verify Email Change</DialogTitle>
      <DialogContent>
        <div className="space-y-4">
          <Typography variant="body1" className="mb-4">
            Please enter the 6-digit OTP sent to your current email (
            {user?.email})
          </Typography>
          <Typography variant="body2" color="textSecondary">
            After verification, your email will be updated to: {email}
          </Typography>

          <form onSubmit={otpFormik.handleSubmit} className="space-y-4">
            <TextField
              fullWidth
              name="otp"
              label="Enter OTP"
              value={otpFormik.values.otp}
              onChange={otpFormik.handleChange}
              onBlur={otpFormik.handleBlur}
              error={otpFormik.touched.otp && Boolean(otpFormik.errors.otp)}
              helperText={otpFormik.touched.otp && otpFormik.errors.otp}
              inputProps={{
                maxLength: 6,
                pattern: "[0-9]*",
              }}
              className="mt-4"
            />
          </form>

          <Typography variant="body2" color="textSecondary" className="mt-2">
            Time remaining: {formatTime(timeLeft)}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className="p-4 admin_settings_form_submit_btn">
        <button
          onClick={handleResend}
          disabled={!canResend || isResending}
          color="secondary"
        >
          {isResending ? "Resending..." : "Resend OTP"}
        </button>
        <button
          onClick={otpFormik.handleSubmit}
          disabled={!otpFormik.isValid || isVerifying}
          variant="contained"
          color="primary"
        >
          {isVerifying ? "Verifying..." : "Verify OTP"}
        </button>
      </DialogActions>
    </Dialog>
  );
};
