import React, { useState, useRef, useEffect } from "react";
import "../../../styles/allJobs/allJobs.css";
import searchIcon from "../../../utils/landingpage/images/search-icon.svg";
import allFilters from "../../../utils/landingpage/images/allFilters.svg";
import upArrow from "../../../utils/landingpage/images/uparrowIcon.svg";
import downArrow from "../../../utils/landingpage/images/downIcon.svg";
import microsoftIMG from "../../../utils/landingpage/images/microsoft-logo.svg";
import experienceIcon from "../../../utils/landingpage/images/experienceIcon.svg";
import dollerIcon from "../../../utils/landingpage/images/dollerIcon.svg";
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png";
import saveIcon from "../../../utils/landingpage/images/saveIcon.svg";
import savedIcon from "../../../utils/landingpage/images/savedIocn.svg";
import rightArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLetralHiringJobs } from "../../../redux/actions/letralHiringAction";
import { formatDistanceToNow, set } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import {
  setLetralHiringJobsData,
  updateLetralHiringJobData,
} from "../../../redux/reducers/letralHiringReducer";

const AllJobs = () => {
  const dispatch = useDispatch();
  const jobsData = useSelector(
    (state) => state.letralHiring.letralHiringJobsData
  );
  const [searchTitle, setsearchTitle] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [selectedExperienceRange, setSelectedExperienceRange] = useState("");
  const [experience, setExperience] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [save, setSave] = useState();
  const [token, setToken] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Recomended");
  const [currentPage, setCurrentPage] = useState(1);
  const [suggestedTitle, setSuggestedTitle] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [allLocationSuggestions, setAllLocationSuggestions] = useState([]);

  const [jobs, setJobs] = useState([]);
  const [sortedJobs, setSortedJobs] = useState([]);
  const [sortOption, setSortOption] = useState("recommended");
  const [sideFilterdData, setSideFilteredData] = useState([]);
  const [getCategorys, setGetCategorys] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const suggestionsRef = useRef(null);
  const locationSuggestionsRef = useRef(null);
  const [showFilters, setShowFilters] = useState(false);
  const [showFiltersResponsive, setShowFiltersResponsive] = useState(false);
  const [handleChangeState, setHandleChangeState] = useState(false)

  const handleSearch = async () => {
    let min_experience = null;
    let max_experience = null;

    if (selectedExperienceRange) {
      const [min, max] = selectedExperienceRange.split("-").map(Number);
      min_experience = min;
      max_experience = max;
    }

    const url = `${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/?min_experience=${min_experience || ""
      }&max_experience=${max_experience || ""
      }&job_location=${searchLocation}&title=${searchTitle}`;

    try {
      setIsLoading(true);
      const response = await axios.get(url);
      // console.log("Search Results:", response.data);
      // setJobs(response.data.length > 0 ? response.data : [])
      dispatch(
        setLetralHiringJobsData(response.data.length > 0 ? response.data : [])
      );
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setsearchTitle(value);

    if (value) {
      const filteredSuggestions = suggestedTitle.filter((suggestion) =>
        value ? suggestion?.toLowerCase().includes(value?.toLowerCase()) : true
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    const accestoken = sessionStorage.getItem('accessToken');
    setToken(accestoken)
  }, [token])

  const handleLocationInputChange = (e) => {
    const value = e.target.value;
    setSearchLocation(value);

    const filteredLocationSuggestions = allLocationSuggestions.filter(
      (location) =>
        value && typeof location === "string"
          ? location?.toLowerCase().includes(value?.toLowerCase())
          : false
    );

    setLocationSuggestions(filteredLocationSuggestions);
  };

  const handleLocationSuggestionClick = (location) => {
    setSearchLocation(location); // Fill the input with the selected suggestion
    setLocationSuggestions([]); // Close the dropdown after selecting a suggestion
  };

  const handleSuggestionClick = (suggestion) => {
    setsearchTitle(suggestion);
    setSuggestions([]);
  };

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setSuggestions([]);
    }
    if (
      locationSuggestionsRef.current &&
      !locationSuggestionsRef.current.contains(event.target)
    ) {
      setLocationSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (jobsData.length < 1 && handleChangeState === false) {
      dispatch(fetchLetralHiringJobs());
    }
  }, [dispatch, handleChangeState, jobsData.length]);

  useEffect(() => {
    if (jobsData.length > 0) {
      setSideFilteredData(jobsData);
    }
  }, [jobsData]);

  const [expandedSections, setExpandedSections] = useState({
    experience: true,
    employmentTypes: true,
    categories: true,
    jobLevel: true,
    salaryRange: true,
  });

  const [employmentTypes, setEmploymentTypes] = useState({
    "Full-Time": false,
    "Part-Time": false,
    Remote: false,
    Internship: false,
  });

  const [categories, setCategories] = useState({
    Sales: false,
    Marketing: false,
    "Human Resources": false,
    Finance: false,
    Engineering: false,
    IT: false,
  });

  const [salaryRange, setSalaryRange] = useState({
    "0-1": false,
    "1-3": false,
    "3-5": false,
    "5-8": false,
    "8-15": false,
    "15+": false,
  });

  const [experianceRange, setExperianceRange] = useState({
    "0-1": false,
    "1-3": false,
    "3-5": false,
    "5-8": false,
    "8-15": false,
    "15+": false,
  });

  const getMinAndMaxExperience = () => {
    const selectedRanges = Object.keys(experianceRange).filter(
      (range) => experianceRange[range]
    );

    if (selectedRanges.length === 0)
      return { min_experience: null, max_experience: null }; // No default values

    let minExp = Infinity;
    let maxExp = 0;

    selectedRanges.forEach((range) => {
      if (range === "15+") {
        minExp = Math.min(minExp, 15);
        // maxExp = Math.max(maxExp, 30); 
      } else {
        const [min, max] = range.split("-").map(Number);
        minExp = Math.min(minExp, min);
        maxExp = Math.max(maxExp, max || 30);
      }
    });

    return {
      min_experience: minExp === Infinity ? null : minExp, // Send null if no valid minExp
      max_experience: maxExp === 0 ? null : maxExp, // Send null if no valid maxExp
    };
  };

  const getMinAndMaxSalary = () => {
    const selectedRanges = Object.keys(salaryRange)?.filter(
      (range) => salaryRange[range]
    );

    if (selectedRanges.length === 0)
      return { min_salary: null, max_salary: null }; // No default values

    let minSalary = Infinity;
    let maxSalary = 0;

    selectedRanges?.forEach((range) => {
      if (range === "15+") {
        minSalary = Math.min(minSalary, 15);
        // maxSalary = Math.max(maxSalary, 1000000); // Set max to a high value only if explicitly selected
      } else {
        const [min, max] = range.split("-").map(Number);
        minSalary = Math.min(minSalary, min);
        maxSalary = Math.max(maxSalary, max || 1000);
      }
    });

    return {
      min_salary: minSalary === Infinity ? null : minSalary, // If no min salary, send null
      max_salary: maxSalary === 0 ? null : maxSalary, // If no max salary, send null
    };
  };

  const mapEmploymentTypesToAPI = (types) => {
    const typeMap = {
      "Full-Time": "Full-Time",
      "Part-Time": "Part-Time",
      Remote: "Remote",
      Internship: " Internship",
    };

    return types.map((type) => typeMap[type] || type);
  };

  const sliderRef = useRef(null);
  const progressRef = useRef(null);
  const dropdownRef = useRef(null);

  const fetchFilteredJobs = async () => {
    const selectedEmploymentTypes = Object.keys(employmentTypes).filter(
      (type) => employmentTypes[type]
    );
    const selectedCategories = Object.keys(categories).filter(
      (category) => categories[category]
    );

    const mappedEmploymentTypes = mapEmploymentTypesToAPI(
      selectedEmploymentTypes
    );

    const { min_salary, max_salary } = getMinAndMaxSalary();
    const { min_experience, max_experience } = getMinAndMaxExperience();

    const accessToken = sessionStorage.getItem("accessToken");

    const config = {
      headers: {
        Authorization: accessToken && `Bearer ${accessToken}`,
      },
      params: {
        min_experience: min_experience || null, // Only send if not null
        max_experience: max_experience || null, // Only send if not null
        type: mappedEmploymentTypes.join(",") || null,
        job_category: selectedCategories.join(",") || null,
        min_salary: min_salary || null, // Only send if not null
        max_salary: max_salary || null, // Only send if not null
      },
    };

    if (jobsData.length < 1 || handleChangeState === true) {
      try {

        // const response = await axios.get(
        //   `${process.env.REACT_APP_BASE_URL}/api/jobs/`,
        //   config
        // );
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/`,
          config
        );

        // setSideFilteredData(response.data);

        dispatch(setLetralHiringJobsData(response.data))

        let titleFilter = response.data.map((value) => value.title);
        let locationFilter = response.data.map((value) => value.job_location);

        setSuggestedTitle(titleFilter);
        setAllLocationSuggestions(locationFilter);

      } catch (error) {
        toast.error("Something Went Wrong" || error);
      } finally {
        setIsLoading(false);
        setShowFilters(false);
      }
    }

  };

  useEffect(() => {
    fetchFilteredJobs();
  }, [employmentTypes, categories, salaryRange, experianceRange]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/job-categories/`
        );
        setGetCategorys(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const getPostedDate = (createdAt) => {
    return formatDistanceToNow(new Date(createdAt), { addSuffix: true });
  };

  const handleExperienceChange = (e) => {
    setExperience(e.target.value);
  };

  const toggleSectionExpansion = (section) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
    setHandleChangeState(true)
  };

  const handleEmploymentTypeChange = (type) => {
    setEmploymentTypes((prev) => ({ ...prev, [type]: !prev[type] }));
    setHandleChangeState(true)
  };

  const handleCategoryChange = (category) => {
    setCategories((prev) => ({ ...prev, [category]: !prev[category] }));
    setHandleChangeState(true)
  };

  const handleSalaryRangeChange = (range) => {
    setSalaryRange((prev) => ({ ...prev, [range]: !prev[range] }));
    setHandleChangeState(true)
  };

  const handleExperianceChange = (range) => {
    setExperianceRange((prev) => ({ ...prev, [range]: !prev[range] }));
    setHandleChangeState(true)
  };

  useEffect(() => {
    if (sliderRef.current && progressRef.current) {
      const percentage = (experience / 30) * 100;
      progressRef.current.style.width = `${percentage}%`;
    }
  }, [experience]);

  function handleSave(index) {
    const userDetails = {
      job_id: index,
    };

    const userToken = token;

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/job/save/`, userDetails, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        setSave(response.data.detail);
        dispatch(updateLetralHiringJobData(index));
        toast.success(response.data.detail);
        fetchFilteredJobs();
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  function handleUnSave(index) {
    const userToken = token;

    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/job/unsave/`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: {
          job_id: index,
        },
      })
      .then((response) => {
        dispatch(updateLetralHiringJobData(index));
        toast.success(response.data.detail);
        fetchFilteredJobs();
      })
      .catch((error) => {
        toast.error("Failed to save the job. Please try again.");
      });
  }

  const formatSalary = (salary) => {
    // Ensure the salary is a number and round it properly
    if (salary) {
      const floatSalary = parseFloat(salary); // Convert string to float
      return floatSalary % 1 === 0
        ? floatSalary.toFixed(0)
        : floatSalary.toFixed(2);
    }
    return null;
  };

  const getFilteredJobs = () => {
    let filteredJobs = [...jobsData];

    // let filteredJobs = [...sideFilterdData];

    if (selectedOption === "Recommended") {
      filteredJobs.sort((a, b) => {
        const ratingA = a.company_profile?.company_rating || 0;
        const ratingB = b.company_profile?.company_rating || 0;
        return ratingB - ratingA;
      });
    } else if (selectedOption === "Date Posted") {
      filteredJobs.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    } else if (selectedOption === "Most Relevance") {
      const userProfile = sessionStorage.getItem("userDesignation") || {};
      const userRole = userProfile?.toLowerCase() || "";

      if (!userRole) {
        console.error("User role is not available");
        return [];
      }

      const sanitizedUserRole = userRole.replace(/[^a-zA-Z0-9\s]/g, "");
      const roleRegex = new RegExp(`\\b${sanitizedUserRole}\\b`, "i");

      filteredJobs = filteredJobs.filter((job) => {
        const jobTitle = job.title?.toLowerCase() || "";
        return roleRegex.test(jobTitle);
      });

      // const partialUserRole = userRole
      //   .slice(0, 15)
      //   .replace(/[^a-zA-Z0-9]/g, "");
      // const roleRegex = new RegExp(partialUserRole, "i");

      // filteredJobs = filteredJobs.filter((job) => {
      //   const jobTitle = job.title
      //     ?.toLowerCase()
      //     .slice(0, 15)
      //     .replace(/[^a-zA-Z0-9]/g, "");
      //   return roleRegex.test(jobTitle);
      // });
    }

    return filteredJobs;
  };

  const filteredJobs = getFilteredJobs();

  const itemsPerPage = 5;
  const totalPages = Math.ceil(filteredJobs.length / itemsPerPage);

  const currentItems = filteredJobs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    setCurrentPage(1);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOpenFilter = () => {
    if (window.innerWidth < 850) {
      setShowFilters(!showFilters);
    }
    // if(window.innerWidth > 851){
    //   setShowFiltersResponsive(false)
    // }
    // setShowFilters(true)
  };

  const handleClearFilter = () => {
    dispatch(fetchLetralHiringJobs());
    setsearchTitle("");
    setSearchLocation("");
    setSelectedExperienceRange(null);
    setSideFilteredData(null)

    setEmploymentTypes({
      "Full-Time": false,
      "Part-Time": false,
      Remote: false,
      Internship: false,
    });

    setEmploymentTypes({
      "Full-Time": false,
      "Part-Time": false,
      Remote: false,
      Internship: false,
    });

    setCategories({
      Sales: false,
      Marketing: false,
      "Human Resources": false,
      Finance: false,
      Engineering: false,
      IT: false,
    });

    setSalaryRange({
      "0-1": false,
      "1-3": false,
      "3-5": false,
      "5-8": false,
      "8-15": false,
      "15+": false,
    });

    setExperianceRange({
      "0-1": false,
      "1-3": false,
      "3-5": false,
      "5-8": false,
      "8-15": false,
      "15+": false,
    });
  };

  return (
    <div className="allJobs_main_container">
      {isLoading ?
        (<div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ClipLoader color="#FFA500" size={60} />
        </div>)
        :
        (<div className="allJobs_container">
          <section className="contact_header alljobs_header">
            <h2 className="d-grid align-items-center">
              <span>Search Your Dream</span>
              <span>Job Which You Deserve</span>
            </h2>
            <h6>
              Discover your next career move with StartNaukri, get your new job.
            </h6>
          </section>

          <section className="alljobs_jobs_section_head ">
            <div className="alljobs_search_section">
              <div className="box alljobs_box position-relative">
                <div className="search-box">
                  <img
                    src={searchIcon}
                    className="alljobs_box_img"
                    alt="search icon"
                  />
                  <input
                    type="search"
                    className="search-input alljobs_search_inputs"
                    placeholder="Search Job"
                    value={searchTitle}
                    onChange={handleInputChange}
                  />

                  {suggestions.length > 0 && (
                    <ul className="suggestions-list" ref={suggestionsRef}>
                      {suggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  )}

                  <select className="search-select alljobs_search_inputs"
                    onChange={(e) => setSelectedExperienceRange(e.target.value)}>
                    <option value="">Experience</option>
                    <option value="0-1">0-1 years</option>
                    <option value="1-3">1-3 years</option>
                    <option value="3-5">3-5 years</option>
                    <option value="5-7">5-7 years</option>
                    <option value="7-30">7 +</option>
                  </select>

                  <input
                    type="text"
                    className="search-location alljobs_search_inputs"
                    placeholder="Location"
                    value={searchLocation}
                    onChange={handleLocationInputChange}
                  />

                  {locationSuggestions.length > 0 && (
                    <ul className="suggestions-list" ref={locationSuggestionsRef}>
                      {locationSuggestions.map((location, index) => (
                        <li key={index} onClick={() => handleLocationSuggestionClick(location)}>
                          {location}
                        </li>
                      ))}
                    </ul>
                  )}

                  <button className="search-button" onClick={handleSearch}>
                    Search
                  </button>
                </div>
              </div>
              {/* <div className="Popular-Search">
                <span>Popular Search: </span>
                <span className="alljobs_popular_search">
                  Frontend Developer, UI/UX Designer, Backend Developer
                </span>
              </div> */}
              <div className="Popular-Search">
                <span>Explore Opportunities: </span>
                <span className="alljobs_popular_search">
                  Job Title, Experience Level, Location
                </span>
              </div>
            </div>

            <div className="alljobs_content_head_main">
              <div className="alljobs_content_inner_first">

                <div className="d-flex justify-content-between gap-2">
                  <button className="all_filters_btn" onClick={handleOpenFilter}>
                    <span>
                      <img src={allFilters} alt="..." />
                    </span>
                    <span>All Filters</span>
                  </button>

                  <button className="all_filters_btn" onClick={handleClearFilter}>
                    <span>
                      {/* <img src={allFilters} alt="..." /> */}
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.9999 14.9999L1 1M15 1L1 15" stroke="#F38D00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                    <span>Clear Filters</span>
                  </button>
                </div>

                {showFilters && window.innerWidth < 850 ? (
                  <div className="mb-2 filter-panel">
                    <button
                      className="filter_Panel_Cancel"
                      onClick={handleOpenFilter}
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.9999 14.9999L1 1M15 1L1 15" stroke="#F38D00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                    <div className="pt-4 custom-pl-13">
                      <div className="filter-section">
                        <h3
                          onClick={() =>
                            toggleSectionExpansion("employmentTypes")
                          }
                        >
                          Type of Employment
                          <span
                            className={`arrow ${expandedSections.employmentTypes ? "up" : "down"
                              }`}
                          >
                            {expandedSections.employmentTypes ? (
                              <img src={upArrow} alt="up arrow" />
                            ) : (
                              <img src={downArrow} alt="down arrow" />
                            )}
                          </span>
                        </h3>
                        {expandedSections.employmentTypes && (
                          <div className="checkbox-container">
                            {Object.entries(employmentTypes).map(
                              ([type, checked]) => (
                                <label key={type} className="checkbox-label">
                                  <input
                                    type="checkbox"
                                    checked={checked}
                                    onChange={() =>
                                      handleEmploymentTypeChange(type)
                                    }
                                  />
                                  <span className="checkbox-custom"></span>
                                  {type}
                                </label>
                              )
                            )}
                          </div>
                        )}
                      </div>

                      <hr />

                      <div className="filter-section">
                        <h3 onClick={() => toggleSectionExpansion("categories")}>
                          Categories
                          <span
                            className={`arrow ${expandedSections.categories ? "up" : "down"
                              }`}
                          >
                            {expandedSections.categories ? (
                              <img src={upArrow} alt="up arrow" />
                            ) : (
                              <img src={downArrow} alt="down arrow" />
                            )}
                          </span>
                        </h3>

                        {/* {expandedSections?.categories && (
                        <div className="checkbox-container category-container-scroll">
                          {getCategorys &&
                            getCategorys?.map((category) => (
                              <label
                                key={category.id}
                                className="checkbox-label"
                              >
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    handleCategoryChange(category.id)
                                  }
                                />
                                <span className="checkbox-custom"></span>
                                {category?.job_category?.length > 15
                                  ? `${category?.job_category.slice(0, 16)}..`
                                  : category?.job_category}
                              </label>
                            ))}
                        </div>
                      )} */}

                        <div className="checkbox-container category-container-scroll">
                          {getCategorys &&
                            getCategorys?.map((category) => (
                              <label key={category.id} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  onChange={() => handleCategoryChange(category.id)}
                                  checked={categories[category.id] || false}
                                />
                                <span className="checkbox-custom"></span>
                                {category?.job_category?.length > 15
                                  ? `${category?.job_category.slice(0, 16)}..`
                                  : category?.job_category}
                              </label>
                            ))}
                        </div>

                      </div>

                      <hr />

                      <div className="filter-section">
                        <h3 onClick={() => toggleSectionExpansion("salaryRange")}>
                          Salary Range
                          <span
                            className={`arrow ${expandedSections.salaryRange ? "up" : "down"
                              }`}
                          >
                            {expandedSections.salaryRange ? (
                              <img src={upArrow} alt="up arrow" />
                            ) : (
                              <img src={downArrow} alt="down arrow" />
                            )}
                          </span>
                        </h3>
                        {expandedSections.salaryRange && (
                          <div className="checkbox-container">
                            {Object.entries(salaryRange)?.map(
                              ([range, checked]) => (
                                <label key={range} className="checkbox-label">
                                  <input
                                    type="checkbox"
                                    checked={checked}
                                    onChange={() =>
                                      handleSalaryRangeChange(range)
                                    }
                                  />
                                  <span className="checkbox-custom"></span>
                                  {range} LPA
                                </label>
                              )
                            )}
                          </div>
                        )}
                      </div>

                      <hr />

                      <div className="filter-section">
                        <h3
                          onClick={() =>
                            toggleSectionExpansion("experianceRange")
                          }
                        >
                          Exeperience
                          <span
                            className={`arrow ${expandedSections?.experianceRange ? "up" : "down"
                              }`}
                          >
                            {expandedSections?.experianceRange ? (
                              <img src={upArrow} alt="up arrow" />
                            ) : (
                              <img src={downArrow} alt="down arrow" />
                            )}
                          </span>
                        </h3>
                        {expandedSections?.experianceRange && (
                          <div className="checkbox-container">
                            {Object.entries(experianceRange).map(
                              ([range, checked]) => (
                                <label key={range} className="checkbox-label">
                                  <input
                                    type="checkbox"
                                    checked={checked}
                                    onChange={() => handleExperianceChange(range)}
                                  />
                                  <span className="checkbox-custom"></span>
                                  {`${range} Years`}
                                </label>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (window.innerWidth > 850 &&
                  <div className="filter-panel">
                    <button
                      className="filter_Panel_Cancel"
                      onClick={handleOpenFilter}
                    >
                      X{" "}
                    </button>
                    <div className="custom-pl-13">
                      <div className="filter-section">
                        <h3
                          onClick={() =>
                            toggleSectionExpansion("employmentTypes")
                          }
                        >
                          Type of Employment
                          <span
                            className={`arrow ${expandedSections.employmentTypes ? "up" : "down"
                              }`}
                          >
                            {expandedSections.employmentTypes ? (
                              <img src={upArrow} alt="up arrow" />
                            ) : (
                              <img src={downArrow} alt="down arrow" />
                            )}
                          </span>
                        </h3>
                        {expandedSections.employmentTypes && (
                          <div className="checkbox-container">
                            {Object.entries(employmentTypes).map(
                              ([type, checked]) => (
                                <label key={type} className="checkbox-label">
                                  <input
                                    type="checkbox"
                                    checked={checked}
                                    onChange={() =>
                                      handleEmploymentTypeChange(type)
                                    }
                                  />
                                  <span className="checkbox-custom"></span>
                                  {type}
                                </label>
                              )
                            )}
                          </div>
                        )}
                      </div>

                      <hr />

                      <div className="filter-section">
                        <h3 onClick={() => toggleSectionExpansion("categories")}>
                          Categories
                          <span
                            className={`arrow ${expandedSections.categories ? "up" : "down"
                              }`}
                          >
                            {expandedSections.categories ? (
                              <img src={upArrow} alt="up arrow" />
                            ) : (
                              <img src={downArrow} alt="down arrow" />
                            )}
                          </span>
                        </h3>

                        {/* {expandedSections?.categories && (
                          <div className="checkbox-container category-container-scroll">
                            {getCategorys &&
                              getCategorys?.map((category) => (
                                <label
                                  key={category.id}
                                  className="checkbox-label"
                                >
                                  <input
                                    type="checkbox"
                                    onChange={() =>
                                      handleCategoryChange(category.id)
                                    }
                                  />
                                  <span className="checkbox-custom"></span>
                                  {category?.job_category?.length > 15
                                    ? `${category?.job_category.slice(0, 16)}..`
                                    : category?.job_category}
                                </label>
                              ))}
                          </div>
                        )} */}

                        <div className="checkbox-container category-container-scroll">
                          {getCategorys &&
                            getCategorys?.map((category) => (
                              <label key={category.id} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  onChange={() => handleCategoryChange(category.id)}
                                  checked={categories[category.id] || false}
                                />
                                <span className="checkbox-custom"></span>
                                {category?.job_category?.length > 15
                                  ? `${category?.job_category.slice(0, 16)}..`
                                  : category?.job_category}
                              </label>
                            ))}
                        </div>

                      </div>

                      <hr />

                      <div className="filter-section">
                        <h3 onClick={() => toggleSectionExpansion("salaryRange")}>
                          Salary Range
                          <span
                            className={`arrow ${expandedSections.salaryRange ? "up" : "down"
                              }`}
                          >
                            {expandedSections.salaryRange ? (
                              <img src={upArrow} alt="up arrow" />
                            ) : (
                              <img src={downArrow} alt="down arrow" />
                            )}
                          </span>
                        </h3>
                        {expandedSections.salaryRange && (
                          <div className="checkbox-container">
                            {Object.entries(salaryRange)?.map(
                              ([range, checked]) => (
                                <label key={range} className="checkbox-label">
                                  <input
                                    type="checkbox"
                                    checked={checked}
                                    onChange={() =>
                                      handleSalaryRangeChange(range)
                                    }
                                  />
                                  <span className="checkbox-custom"></span>
                                  {range} LPA
                                </label>
                              )
                            )}
                          </div>
                        )}
                      </div>

                      <hr />

                      <div className="filter-section">
                        <h3
                          onClick={() =>
                            toggleSectionExpansion("experianceRange")
                          }
                        >
                          Exeperience
                          <span
                            className={`arrow ${expandedSections?.experianceRange ? "up" : "down"
                              }`}
                          >
                            {expandedSections?.experianceRange ? (
                              <img src={upArrow} alt="up arrow" />
                            ) : (
                              <img src={downArrow} alt="down arrow" />
                            )}
                          </span>
                        </h3>
                        {expandedSections?.experianceRange && (
                          <div className="checkbox-container">
                            {Object.entries(experianceRange).map(
                              ([range, checked]) => (
                                <label key={range} className="checkbox-label">
                                  <input
                                    type="checkbox"
                                    checked={checked}
                                    onChange={() => handleExperianceChange(range)}
                                  />
                                  <span className="checkbox-custom"></span>
                                  {`${range} Years`}
                                </label>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="alljobs_content_inner_second">
                <div>
                  <div className="job-header">
                    <h2> {filteredJobs?.length} jobs</h2>
                    <div className="sort-dropdown" ref={dropdownRef}>
                      <div className="dropdown-container">
                        <button
                          onClick={toggleDropdown}
                          className="dropdown-toggle"
                        >
                          <span className="costom-pr-2">Sort by: </span>{" "}
                          {selectedOption}
                          <span className="dropdown-arrow">
                            {isOpen ? (
                              <img
                                src={upArrow}
                                alt="up arrow"
                                style={{ width: "16px" }}
                              />
                            ) : (
                              <img src={downArrow} alt="down arrow" />
                            )}
                          </span>
                        </button>
                        {isOpen && (
                          <ul className="dropdown-menu">
                            {["Recommended", "Date Posted", "Most Relevance"]?.map((option) => {
                              if (option === "Most Relevance") {
                                if (!token) return null;
                              }
                              return (
                                <li key={option} onClick={() => handleOptionClick(option)}>
                                  {option}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>

                  {filteredJobs?.length === 0 && (
                    <center>
                      <b>No Jobs Found</b>
                    </center>
                  )}

                  <div className="job-listings">
                    {currentItems?.map((job, index) => (
                      <div key={index} className="job-listing">
                        <div className="company-info">
                          <Link to={`/lateral/jobDetails/${job.id}`}>
                            <img
                              src={
                                job.company_profile?.company_logo_url ||
                                dummy_logo
                              }
                              alt={"logo"}
                              className="company-logo"
                            />
                          </Link>
                          <div>
                            <Link to={`/lateral/jobDetails/${job.id}`}>
                              <div className="d-flex align-items-center gap-4">
                                <h2>{job.title || 'Not Mentioned'}</h2>
                                {job?.status === "LIVE" ? (<button className="job_list_job_active">Live</button>) : (<button className="job_list_job_active job_list_job_closed">Closed</button>)}
                              </div>
                            </Link>
                            <div className="d-flex gap-2">
                              <div className="company-name">
                                {job.company_profile?.company_name ||
                                  "Not Mentioned"}
                              </div>
                              <div className="rating">
                                <span className="star">★</span>{" "}
                                {job.company_profile?.company_rating || "0"}
                                <span
                                  style={{
                                    fontWeight: "200",
                                    color: "#3c3c3c69",
                                  }}
                                >
                                  |
                                </span>{" "}
                                {job.company_profile?.company_review || "0"}{" "}
                                Reviews
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="job-details">
                          <span className="detail">
                            <span className="details_job_icon">
                              <img src={experienceIcon} alt="..." />
                            </span>{" "}
                            {job.min_experience || 0} - {job.max_experience || 0}{" "}
                            yrs <span className="stand_icon">|</span>{" "}
                          </span>
                          <span className="detail">
                            <span className="details_job_icon">
                              <img src={dollerIcon} alt="..." />
                            </span>{" "}
                            {job.min_salary && job.max_salary
                              ? `${formatSalary(job.min_salary)}-${formatSalary(
                                job.max_salary
                              )} LPA`
                              : "Not specified"}
                            <span className="stand_icon">|</span>{" "}
                          </span>
                          <span className="detail">
                            <span className="details_job_icon">
                              <img src={experienceIcon} alt="..." />
                            </span>{" "}
                            {job?.type || "Not Mentioned"}
                            <span className="stand_icon">|</span>{" "}
                          </span>
                          <span className="detail">
                            <span className="details_job_icon">
                              <img src={locationIcon} alt="..." />
                            </span>{" "}
                            {job.job_location || "Not Mentioned"}
                          </span>
                        </div>
                        <p className="job-description">
                          {job.description.length > 170
                            ? `${job.description.slice(0, 170)}...`
                            : job.description}
                        </p>
                        <div className="listing-footer">
                          <span className="posted-date">
                            {getPostedDate(job.created_at)}
                          </span>
                          {token == null ? null : (
                            <button
                              className="save-button"
                              style={{
                                background: "transparent",
                                color: "#000000de",
                                fontWeight: "400",
                                marginTop: "0",
                                padding: "0"
                              }}
                            >
                              {job.is_already_saved ? (
                                <div onClick={() => handleUnSave(job.id)}>
                                  <span className="details_job_icon">
                                    <img src={savedIcon} alt="..." />
                                  </span>
                                  <span className="ps-2">
                                    Saved
                                  </span>
                                </div>
                              ) : (
                                <div onClick={() => handleSave(job.id)}>
                                  <span className="details_job_icon">
                                    <img src={saveIcon} alt="..." />
                                  </span>
                                  <span className="ps-2">
                                    Save
                                  </span>
                                </div>
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Pagination Controls */}
                  <div className="pagination-controls">
                    <Stack spacing={2} alignItems="center">
                      <Pagination
                        className="custom-pagination" // Add the custom class here
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        siblingCount={1}
                        boundaryCount={1}
                        renderItem={(item) => (
                          <PaginationItem
                            components={{
                              previous: () => (
                                <img
                                  className="campus-next-btn-pagination"
                                  src={leftArrowIcon}
                                  alt="prev"
                                />
                              ),
                              next: () => (
                                <img
                                  className="campus-prev-btn-pagination"
                                  src={rightIconArrow}
                                  alt="next"
                                />
                              ),
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>)
      }
    </div>
  );
};

export default AllJobs;