import React, { useState, useEffect, useRef } from "react";
import "../../../styles/allJobs/allJobs.css";
import "../../../styles/myApplication/myapplication.css";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const MyApplication = () => {
  const [isOpen, setIsOpen] = useState(null);
  const [activeFilter, setActiveFilter] = useState("InReview");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [scheduled, setScheduled] = useState([]);
  const [applications, setApplications] = useState({
    Shortlisted: [],
    InReview: [],
    Rejected: [],
    Interviewed: [],
    Offered: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 5;
  const dropdownRef = useRef(null);
  const navigate = useNavigate()

  const [userToken, setUserToken] = useState(null);

  const handleJobTitle = (id) => {
    navigate(`/lateral/jobDetails/${id}`)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const SessionData = sessionStorage.getItem("accessToken");
    setUserToken(SessionData);
  }, []);

  const fetchApplications = async () => {
    if (!userToken) return;
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/student_applied_jobs/`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      setApplications((prevState) => ({
        ...prevState,
        Shortlisted: response.data.shortlisted?.applications || [],
        InReview: response.data.in_review?.applications || [],
        Rejected: response.data.discarded?.applications || [],
        Offered: response.data.offered?.applications || [],
        Interviewed: response.data.interviewed?.applications || [],
      }));
    } catch (error) {
      console.error("API Error:", error);
      setError("Error fetching application data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userToken) {
      fetchApplications();
    }
  }, [userToken]);

  const totalPages = Math.ceil(
    applications[activeFilter]?.length / itemsPerPage
  );

  const currentItems =
    applications[activeFilter]?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    ) || [];

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDropDown = (id) => {
    setIsOpen(isOpen === id ? null : id);
  };

  const handleWithdraw = async (jobId) => {
    const SessionData = sessionStorage.getItem("accessToken");

    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to withdraw your application?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f38d00",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, withdraw it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/jobs/withdraw_application/`,
            { application_ids: [jobId] },
            {
              headers: {
                Authorization: `Bearer ${SessionData}`,
              },
            }
          );
          toast.success("Application withdrawn successfully.");
          if (response) {
            fetchApplications();
          }
        } catch (error) {
          toast.error("Failed to withdraw application.");
        }
      }
    });
  };

  const handleDownloadOfferLetter = (url) => {
    if (!url) {
      console.error("No URL provided for download");
      return;
    }

    try {
      saveAs(url?.offer_letter_url, `${url?.job?.company?.slice(0, 10)}-Offer-letter.pdf`);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // useEffect(() => {
  //   const fetchInterviewDetails = async () => {
  //     try {
  //       const accessToken = sessionStorage.getItem("accessToken");

  //       const config = {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       };

  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}/api/student/schedule_interview/retrieve_interview_details/?student_of=lateral`,
  //         config
  //       );

  //       setScheduled(response.data?.interview_details)

  //       setApplications((prevState) => ({
  //         ...prevState,
  //         Interviewed: response?.data?.interview_details || [],
  //       }));

  //     } catch (error) {
  //       toast.error(error);
  //     }
  //   };

  //   fetchInterviewDetails();
  // }, []);

  const formatDateTime = (dateTime) => {
    const dateObj = new Date(dateTime);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = dateObj.toLocaleDateString("en-US", options);

    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    const time = dateObj.toLocaleTimeString("en-US", timeOptions);

    return { date, time };
  };

  const filters = [
    { value: "InReview", label: "In-Review", count: applications.InReview?.length, color: "#f38d00" },
    { value: "Shortlisted", label: "Shortlisted", count: applications.Shortlisted?.length, color: "#00854a" },
    { value: "Interviewed", label: "Interview", count: applications.Interviewed?.length, color: "#ffac3a" },
    { value: "Rejected", label: "Declined", count: applications.Rejected?.length, color: "#ff652d" },
    { value: "Offered", label: "Offered", count: applications.Offered?.length, color: "#00a55c" }
  ];

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  if (error) return <div>{error}</div>;

  return (
    <div className="allJobs_main_container">
      {loading ?
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ClipLoader color="#FFA500" size={60} />
        </div>
        :
        (<div className="allJobs_container">
          <section className="contact_header alljobs_header savedJobs_header">
            <h2 className="d-grid align-items-center">
              <span>My Application</span>
              <span className="allJobs_savedJobs_numbers">{`(${applications[activeFilter]?.length})`}</span>
            </h2>
          </section>

          <section className="alljobs_jobs_section_head myApplication_section_head mb-4">
            <div className="application-history">

              {windowWidth < 550 ? (
                <div className="letral-filter-container">
                  <b>Recent Applications History</b>
                  <div className="letral-custom-dropdown" ref={dropdownRef}>
                    <button
                      className="letral-dropdown-btn"
                      style={{
                        backgroundColor: filters.find((filter) => filter.value === activeFilter)?.color,
                        border: `2px solid ${filters.find((filter) => filter.value === activeFilter)?.color}`,
                        color: "white"
                      }}
                      onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                      {filters.find((filter) => filter.value === activeFilter)?.label} ({applications[activeFilter]?.length})
                      <span className="letral-arrow">&#9662;</span>
                    </button>

                    {dropdownOpen && (
                      <ul className="letral-dropdown-menu">
                        {filters?.map((filter) => (
                          <li
                            key={filter.value}
                            className={`letral-dropdown-item ${activeFilter === filter.value ? "letral-active" : ""}`}
                            style={{
                              backgroundColor: activeFilter === filter.value ? filter.color : "white",
                              border: activeFilter === filter.value ? `2px solid ${filter.color}` : "2px solid transparent",
                              color: activeFilter === filter.value ? "white" : "#333"
                            }}
                            onClick={() => {
                              setActiveFilter(filter.value);
                              setDropdownOpen(false);
                            }}
                          >
                            {filter.label} ({filter.count})
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center myApplication_top_header">
                  <b>Recent Applications History</b>
                  <div className="status-filters">

                    <button
                      className={`filter ${activeFilter === "InReview"
                        ? "review_active"
                        : "filter_review"
                        }`}
                      onClick={() => {
                        setActiveFilter("InReview");
                        setCurrentPage(1);
                      }}
                    >
                      In-Review ({applications.InReview?.length})
                    </button>

                    <button
                      className={`filter ${activeFilter === "Shortlisted"
                        ? "Shortlisted_active"
                        : "filter_shortlisted"
                        }`}
                      onClick={() => {
                        setActiveFilter("Shortlisted");
                        setCurrentPage(1);
                      }}
                    >
                      Shortlisted ({applications.Shortlisted?.length})
                    </button>

                    <button
                      className={`filter ${activeFilter === "Interviewed"
                        ? "review_active"
                        : "filter_review"
                        }`}
                      onClick={() => {
                        setActiveFilter("Interviewed");
                        setCurrentPage(1);
                      }}
                    >
                      Interview ({applications.Interviewed?.length})
                    </button>

                    <button
                      className={`filter ${activeFilter === "Rejected"
                        ? "rejected_active"
                        : "filter_rejected"
                        }`}
                      onClick={() => {
                        setActiveFilter("Rejected");
                        setCurrentPage(1);
                      }}
                    >
                      Declined ({applications.Rejected?.length})
                    </button>

                    <button
                      className={`filter ${activeFilter === "Offered"
                        ? "Shortlisted_active"
                        : "filter_shortlisted"
                        }`}
                      onClick={() => {
                        setActiveFilter("Offered");
                        setCurrentPage(1);
                      }}
                    >
                      Offered ({applications.Offered?.length})
                    </button>

                  </div>
                </div>
              )}

              <div className="applications-list">
                {currentItems?.length === 0 ? (
                  <p className="d-flex justify-content-center pt-4 pb-4">
                    No Application History
                  </p>
                ) : (
                  currentItems?.map((app) => (
                    <div key={app.id}>
                      {activeFilter !== "Interviewed" && (<div className="application-item">

                        <div className="application_active_filer_flex_container">
                          <div className="d-flex align-items-center">
                            <div className="application-company-logo">
                              <img
                                onClick={() => handleJobTitle(app.job.id)} style={{ cursor: "pointer" }}
                                src={
                                  app.job.company_profile?.company_logo_url || dummy_logo
                                }
                                alt="logo"
                              />
                            </div>
                            <div className="application-details">
                              <h3 onClick={() => handleJobTitle(app.job.id)} style={{ cursor: "pointer" }}>
                                {app.job.title?.length > 20
                                  ? `${app.job.title.slice(0, 20)}...`
                                  : app.job.title || "N/A"}
                              </h3>
                              <p>
                                {`${app.job.company_profile?.company_name || "Not Mentioned"
                                  }, ${app.job.job_location || "N/A"}`?.length > 30
                                  ? `${`${app.job.company_profile?.company_name || "Not Mentioned"
                                    }, ${app.job.job_location || "N/A"}`.slice(
                                      0,
                                      30
                                    )}...`
                                  : `${app.job.company_profile?.company_name || "N/A"
                                  }, ${app.job.job_location || "N/A"}`}
                              </p>
                            </div>
                          </div>
                          <div className="application-date">
                            <p>Date Applied</p>
                            <p>
                              {new Date(app.applied_at).toLocaleDateString() || "N/A"}
                            </p>
                          </div>
                        </div>

                        {activeFilter === "Offered" && app?.offer_letter_url && (<div style={{ display: "grid", gap: "2px" }}>
                          <p>Offer Letter</p>
                          <div className="new_letral_interview_details_join_now student_interview_details_join_now pt-0">
                            <button onClick={() => handleDownloadOfferLetter(app)} style={{ background: '#f38d00' }}>
                              Download
                            </button>
                          </div>
                        </div>)}

                        <div
                          className="d-flex justify-content-between align-items-center gap-2"
                          style={{ paddingRight: "25px", position: "relative" }}
                        >
                          {activeFilter !== "Interviewed" && activeFilter !== "Offered" && (<span className={`status ${activeFilter.toLowerCase()} status_with_zero_width`}>
                            {activeFilter}
                          </span>)}

                          <button
                            onClick={() => handleDropDown(app.id)}
                            className="more-options"
                          >
                            ⋮
                          </button>
                          {isOpen === app.id && (
                            <div className="more-options-dropdown">
                              <ul>
                                {activeFilter == "InReview" ? (
                                  <li>
                                    <a
                                      href=".."
                                      onClick={(event) => {
                                        event.preventDefault();
                                        handleWithdraw(app.id);
                                      }}
                                    >
                                      Withdraw
                                    </a>
                                  </li>
                                ) : null}
                                <li>
                                  <Link to={`/lateral/jobDetails/${app.job.id}`}>
                                    View
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>)}

                      {activeFilter === "Interviewed" &&
                        app?.status === "interviewed" && (() => {
                          const { date, time } = formatDateTime(app?.interview_date);

                          return (
                            <div className="application-item">

                              <div className="application_interview_flex_container">

                                <div className="application_interview_flex_container">
                                  <div className="d-flex align-items-center">
                                    <div className="application-company-logo">
                                      <img
                                        src={
                                          app.job?.company_profile?.company_logo_url || dummy_logo
                                        }
                                        alt="logo"
                                      />
                                    </div>
                                    <div className="application-details">
                                      <div className="d-flex align-items-center gap-4">
                                        <h3 style={{ cursor: "pointer" }}>
                                          {app.job?.title?.length > 20
                                            ? `${app.job?.title?.slice(0, 20)}...`
                                            : app.job?.title || "N/A"}
                                        </h3>
                                        {app?.interview_date ? (
                                          new Date(app.interview_date) >= new Date() ? (
                                            <button className="job_list_job_active">Live</button>
                                          ) : (
                                            <button className="job_list_job_active job_list_job_closed">Expired</button>
                                          )
                                        ) : (
                                          <button className="job_list_job_active job_list_job_closed">Expired</button>
                                        )}                                      </div>
                                      <p>
                                        {`${app.job?.company?.length > 20 ? `${app.job?.company?.slice(0, 20)}...`
                                          : app.job?.company || "N/A"
                                          }`}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="application-date">
                                    <p>Interview Date</p>
                                    <p>
                                      {date || "N/A"} {time || "N/A"}
                                    </p>
                                  </div>
                                </div>

                                {/* <div className="application-date" style={{ textAlign: "center" }}>
                                  <p>Interview Round</p>
                                  <p>
                                    {app.interview_round || "N/A"}
                                  </p>
                                </div> */}
                              </div>

                              <div className="d-flex justify-content-between align-items-center gap-4">
                                {app?.zoom_meeting_link && (<div className="application-date" style={{ textAlign: "center" }}>
                                  <p>Interview URL</p>
                                  {new Date(app.interview_date) >= new Date() && app?.zoom_meeting_link ? (
                                    <div className="new_letral_interview_details_join_now student_interview_details_join_now pt-0">
                                      <a data-tip={"Click to join the Zoom Meeting"} data-for="locationTooltip" href={app.zoom_meeting_link || ''} target='..'>
                                        <button> Join Now </button>
                                      </a>
                                      <ReactTooltip id="locationTooltip" place="top" effect="solid" />
                                    </div>
                                  ) : 'Not Available'}
                                </div>)
                                }

                                {app?.interview_location && (<div className="application-date" style={{ textAlign: "center" }}>
                                  <p>Interview Location</p>
                                  {app?.interview_location ? (
                                    <div className="new_letral_interview_details_join_now student_interview_details_join_now pt-0">
                                      <p data-tip={app?.interview_location || "N/A"} data-for="locationTooltip" style={{ fontWeight: "normal" }}>
                                        {app.interview_location?.length > 20 ? `${app.interview_location?.slice(0, 20)}...` : app.interview_location || "N/A"}
                                      </p>
                                      <ReactTooltip id="locationTooltip" place="top" effect="solid" />
                                    </div>
                                  ) : 'Not Mentioned'}
                                </div>)}

                                <div
                                  className="d-flex justify-content-between align-items-center gap-2"
                                  style={{ paddingRight: "25px", position: "relative" }}
                                >
                                  <button
                                    onClick={() => handleDropDown(app.id)}
                                    className="more-options"
                                  >
                                    ⋮
                                  </button>
                                  {isOpen === app.id && (
                                    <div className="more-options-dropdown">
                                      <ul>
                                        <li>
                                          <Link to={`/lateral/jobDetails/${app.job?.id}`}>
                                            View
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>

                            </div>
                          );
                        })()}

                    </div>
                  ))
                )}
              </div>
            </div>
          </section>

          <div className="pt-4 pagination-controls">
            <Stack spacing={2} alignItems="center">
              <Pagination
                className="custom-pagination"
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                siblingCount={1}
                boundaryCount={1}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: () => (
                        <img
                          className="campus-next-btn-pagination"
                          src={leftArrowIcon}
                          alt="prev"
                        />
                      ),
                      next: () => (
                        <img
                          className="campus-prev-btn-pagination"
                          src={rightIconArrow}
                          alt="next"
                        />
                      ),
                    }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>
        </div>)}
    </div>
  );
};

export default MyApplication;