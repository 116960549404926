import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/auth/auth.css";
import logoimg from "../../utils/landingpage/images/logo_img.svg";
import starnaukri from "../../utils/auth/images/start-nuakry.svg";
import googleicon from "../../utils/auth/images/googleicon.svg";
import borderLine from "../../utils/auth/images/boderLine.svg";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/actions/loginAction";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";
import { logInReducerResponse, setRole } from "../../redux/reducers/loginReducer";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|net|org|info|biz|co|in|io|ai|tv|xyz)$/,
      "Email must end with a valid domain (.com, .net, .org, etc.)"
    )
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(5, "Password should be at least 5 characters long"),
});

const initialValues = {
  email: "",
  password: "",
};

const baseURL = process.env.REACT_APP_BASE_URL;

const Login = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetPsdActive, setResetPsdActive] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoding, setIsLoading] = useState(false);
  const [isSendingReset, setIsSendingReset] = useState(false);
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get("userId");
    const token = params.get("token");

    if (userId !== null && token !== null) {
      setResetPsdActive(true);
    }

    if (userId && token) {
      setUserId(userId);
      setToken(token);
      setResetPsdActive(true);
    }
  }, [location]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(loginUser(values, navigate, ''));
    },
  });

  function handleForgetPassword(e) {
    e.preventDefault();
    setIsSendingReset(true);
    const formData = {
      email: registeredEmail,
    };

    axios
      .post(`${baseURL}/sendresetpasswordemail/`, formData)
      .then((res) => {
        setResetSuccess(true);
        setRegisteredEmail("");
        toast.success(res.data.msg);
      })
      .catch((error) => {
        if (error.response && error.response.data.non_field_errors) {
          // Display the error message from the API response
          toast.error(error.response.data.non_field_errors[0]);
          setIsSendingReset(false);
        } else {
          // Display a generic error message if something else went wrong
          toast.error("Something went wrong. Please try again.");
        }
        console.log(error);
      })
      .finally(() => {
        setIsSendingReset(false);
      });
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      setIsLoading(true)
      const response = await axios.post(
        `${baseURL}/resetpassword/${userId}/${token}/`,
        {
          password: newPassword,
          password2: confirmPassword,
        }
      );

      if (response.status == 200) {
        toast.success("Password reset successfully");
        setResetPsdActive(false);
        navigate("/login"); // Redirect to login page
      } else {
        toast.error(response.data.message || "Failed to reset password");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("An error occurred while resetting password");
    } finally {
      setIsLoading(false)
    }
  };

  const handleGoogleLogin = (credentialResponse) => {
    const { credential } = credentialResponse;

    try {
      const decodedToken = jwtDecode(credential);

      const { email, given_name, family_name, sub, email_verified } = decodedToken;

      const payload = {
        email,
        given_name,
        family_name,
        sub,
        email_verified
      };

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/login/`, payload)
        .then((response) => {
          dispatch(logInReducerResponse(response.data));
          sessionStorage.setItem("accessToken", response.data.access_token);
          sessionStorage.setItem("refreshToken", response.data.refresh_token);
          sessionStorage.setItem("login_type", response.data?.user_details?.login_type);
          sessionStorage.setItem(
            "userDetails",
            JSON.stringify(response.data.user_details)
          );

          const publicJobId = sessionStorage.getItem('PublicJobId');

          Swal.fire({
            title: "Success!",
            text: `Welcome Back Dear ${response.data.user_details.name.toUpperCase()}`,
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
            showConfirmButton: false,
            customClass: {
              popup: "custom-swal-popup",
            },
          });

          const role = response.data.user_details.role;

          let roleName;

          if (role == 1) {
            roleName = "clg-manager";
          }
          if (role == 2) {
            roleName = "admin";
          }
          if (role == 3) {
            roleName = "hr-manager";
          }
          if (role == 4) {
            roleName = "Student";
          }
          if (role == 5) {
            roleName = "sub-hr";
          }

          sessionStorage.setItem("userRoleName", JSON.stringify(roleName) || "");

          dispatch(setRole(roleName));

          if (role === 0) {
            navigate("/lateral-hiring");
          }
          else if ([1, 2, 3, 4, 5].includes(role)) {
            navigate(`/dashboard/sidebar-page/${roleName}`);
          }

        })
        .catch((error) => {
          toast.error(error || "Google Login Failed!");

          if (error.response) {
            if (error.response.status === 404) {
              Swal.fire({
                title: "Error!",
                text: `${error.response?.data?.errors?.non_field_errors?.[0]}`,
                icon: "error",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "custom-swal-confirm-button",
                },
              });
            } else if (error.response.status === 403) {
              Swal.fire({
                title: "Account Not Activated",
                text:
                  error.response.data?.errors?.non_field_errors?.[0] ||
                  error.response.data?.msg ||
                  "Please verify your email OTP to activate your account.",
                icon: "warning",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "custom-swal-confirm-button",
                },
              });
            }
            else if (error.response.status === 400 && error.response.data?.errors?.non_field_errors?.[0] === "You are a manual user. Your email is only valid for manual login.") {
              Swal.fire({
                title: "Error!",
                text:
                  error.response.data?.errors?.non_field_errors?.[0] ||
                  "You are a manual user. Your email is only valid for manual login.",
                icon: "warning",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "custom-swal-confirm-button",
                },
              });
            }
            else {
              Swal.fire({
                title: "Error!",
                text: `${error.response?.data?.errors?.non_field_errors?.[0]}`,
                icon: "error",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "custom-swal-confirm-button",
                },
              });
            }
          } else {
            console.log(error);
            Swal.fire({
              title: "Error!",
              text: "An unexpected error occurred.",
              icon: "error",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "custom-swal-confirm-button",
              },
            });
          }

        });
    } catch (error) {

      if (error.response) {
        if (error.response.status === 404) {
          Swal.fire({
            title: "Error!",
            text: `${error.response?.data?.errors?.non_field_errors?.[0]}`,
            icon: "error",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "custom-swal-confirm-button",
            },
          });
        } else if (error.response.status === 403) {
          Swal.fire({
            title: "Account Not Activated",
            text:
              error.response.data?.errors?.non_field_errors?.[0] ||
              error.response.data?.msg ||
              "Please verify your email OTP to activate your account.",
            icon: "warning",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
        else if (error.response.status === 400 && error.response.data?.errors?.non_field_errors?.[0] === "You are a manual user. Your email is only valid for manual login.") {
          Swal.fire({
            title: "Error!",
            text:
              error.response.data?.errors?.non_field_errors?.[0] ||
              "You are a manual user. Your email is only valid for manual login.",
            icon: "warning",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
        else {
          Swal.fire({
            title: "Error!",
            text: `${error.response?.data?.errors?.non_field_errors?.[0]}`,
            icon: "error",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      } else {
        console.log(error);
        Swal.fire({
          title: "Error!",
          text: "An unexpected error occurred.",
          icon: "error",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "custom-swal-confirm-button",
          },
        });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="register_user_container">
        <div className="h-100 w-100 bg-info-subtle"></div>
        <div className="register_main_parent">
          <div className="register_user_main">
            <div className="register_background_first">
              <div className="register_background_first_inner">
                <Link to="/lateral-hiring">
                  <img src={logoimg} alt="..." />
                </Link>
                <div className="register_background_heading">
                  <h1>Welcome Back to Start Naukri!</h1>
                  <p>
                    To keep connected with us please enter your personal info
                  </p>
                </div>
              </div>
            </div>
            <div className="register_content_second">
              <div className="register_content_second_container">
                <div className="login-mobile_logo">
                  <img src={starnaukri} alt="start-naukri" />
                </div>
                <h2 className="login_heading">Login</h2>
                <div className="register_header_line"></div>
                <div className="register_account_options">
                  <p>Please enter your login details</p>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="register_form_container"
                >
                  <div className="register_form_section_candidate">
                    <div className="candidate_input_group_container">
                      <div className="login_candidate_input_group login_candidate_input_group_new">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="register_error_msg">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="login_candidate_input_group login_candidate_input_group_new">
                        <input
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="register_error_msg">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="forgot_password_container d-flex justify-content-between">
                      <div>
                        <p
                          className="forgot_Password"
                          onClick={() => setShowPopup(true)}
                        >
                          forgot Password?
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="register_send_btn_container pt-4">
                    <button
                      type="submit"
                      className="login_submit_btn"
                      disabled={loading || formik.isSubmitting}
                    >
                      {loading ? <div className="spinner"></div> : "Login"}
                    </button>
                    <div className="Login_partitan_line">
                      <div className="Login_partitan_line_one"></div>
                      <span>or</span>
                      <div className="Login_partitan_line_tow"></div>
                    </div>
                    {/* <button onClick={() => googleLogin()} className="google_register_send_btn">
                      <img src={googleicon} alt="google-auth" />
                      <div>Login with Google</div>
                    </button> */}
                    <GoogleLogin
                      onSuccess={handleGoogleLogin}
                      onError={() => {
                        toast.error("Google Login Failed!");
                      }}
                    />
                    <div className="mt-5 text-center">
                      You don’t have an account?{" "}
                      <Link
                        to={"/register"}
                        style={{ textDecoration: "none", color: "#F38D00" }}
                      >
                        Signup
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer position="top-right" /> */}
      </div>

      {showPopup && (
        <div className="forgot-password-overlay">
          <div className="forgot-password-popup">
            <div style={{ width: "65%" }}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "auto" }}
              >
                <div className="text-center">
                  {resetSuccess == false ? (
                    <h2 className="forgot-password-title">Forgot Password</h2>
                  ) : (
                    <h2 className="forgot-password-title">
                      Please Check Your Registered Email!
                    </h2>
                  )}
                  <img
                    src={borderLine}
                    alt="..."
                    style={{ marginTop: "-10px" }}
                  />
                </div>
              </div>

              {resetSuccess == false ? (
                <p className="forgot-password-description">
                  Please enter your register email address
                  <br />
                  and reset your password
                </p>
              ) : (
                <p className="forgot-password-description rest-password-sent">
                  A password reset link has been sent to your registered email
                  address. Please follow the <br /> instructions in the email to
                  reset your password.
                </p>
              )}

              {resetSuccess == false ? (
                <form onSubmit={handleForgetPassword}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={registeredEmail}
                    onChange={(e) => setRegisteredEmail(e.target.value)}
                    className="forgot-password-input"
                    required
                  />
                  <div className="forgot-password-buttons">
                    <button
                      type="button"
                      onClick={() => setShowPopup(false)}
                      className="forgot-password-cancel"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="forgot-password-send"
                      disabled={isSendingReset}
                    >
                      {isSendingReset ? (
                        <div className="spinner"></div>
                      ) : (
                        "Send"
                      )}
                    </button>
                  </div>
                </form>
              ) : null}
            </div>
          </div>
        </div>
      )}

      {resetPsdActive && (
        <div className="forgot-password-overlay">
          <div className="forgot-password-popup">
            <div style={{ width: "65%" }}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "auto" }}
              >
                <div className="text-center">
                  <h2 className="forgot-password-title">Reset your password</h2>
                  <img
                    src={borderLine}
                    alt="..."
                    style={{ marginTop: "-10px" }}
                  />
                </div>
              </div>
              <p className="forgot-password-description">
                Create a new password to continue.
              </p>

              <form onSubmit={handleResetPassword}>
                <div className="forgot-password-input-group">
                  <label
                    htmlFor="newPassword"
                    className="forgot-password-label"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Enter your new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="forgot-password-input"
                    required
                  />
                </div>
                <div className="forgot-password-input-group">
                  <label
                    htmlFor="confirmPassword"
                    className="forgot-password-label"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="password2"
                    placeholder="Confirm your new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="forgot-password-input"
                    required
                  />
                </div>
                <div className="forgot-password-buttons">
                  <button type="submit" className="forgot-password-send w-100">
                    {isLoding ? <span className="spinner"></span> : `Reset Password`}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;