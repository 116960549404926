import axios from "axios";
import {
  fetchDashboardFailure,
  fetchDashboardStart,
  fetchDashboardSuccess,
} from "../../reducers/super_admin_reducer";

export const fetchSuperAdminDashboardData = () => async (dispatch) => {
  try {
    dispatch(fetchDashboardStart());
    const token = sessionStorage.getItem("accessToken");

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/admin_home_page/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to fetch dashboard data");
    }

    dispatch(fetchDashboardSuccess(response.data));
  } catch (error) {
    dispatch(fetchDashboardFailure(error.message));
  }
};
