import React, { useState, useEffect, useCallback } from "react";
import CountUp from "react-countup";
import "../../../styles/landing_page/landingpage.css";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const Counter = () => {
  const [counters, setCounters] = useState([]);
  const [startCounting, setStartCounting] = useState(0);
  const location = useLocation();

  const CounterData = useSelector((state) => state.letralHiring?.countersDataRedux);
  const CounterDataDigitalHiring = useSelector((state) => state.digitalCampusHiring?.counterDigitalData);

  console.log("-----CounterData", CounterData)

  useEffect(() => {
    if ((location.pathname.includes("lateral") || location.pathname === "/") && CounterData) {

      const formattedData = Object.keys(CounterData)?.map((key) => ({
        title: key,
        rating: `${CounterData[key]}`,
      }));

      console.log("----formated", CounterData)

      setCounters(formattedData)
      setStartCounting(true)

    } else {

      if (CounterDataDigitalHiring) {
        const formattedData = Object.keys(CounterDataDigitalHiring)?.map((key) => ({
          title: key,
          rating: `${CounterDataDigitalHiring[key]}`,
        }));

        setCounters(formattedData);
        setStartCounting(true);

      }

    }
  }, [location.pathname, CounterData, CounterDataDigitalHiring])

  const parseRating = (rating) => {
    return parseInt(rating.replace(/[^0-9]/g, ""), 10);
  };

  const formatNumber = useCallback((num) => {
    if (num >= 1000) {
      return `${(num / 1000).toFixed(0)}k +`;
    }
    return num;
  }, []);

  return (
    <>
      <section className="counter_main">
        <div className="counter_container">
          <div className="counter_row">
            <div className="counter_value row ">
              {counters && counters?.map((data, index) => (
                <div key={index} className="counter-inner col-6 col-md-3">
                  {startCounting && (
                    <CountUp
                      end={`${parseRating(data.rating)}`}
                      duration={2}
                      separator=","
                      formattingFn={formatNumber}
                    />
                  )}
                  <h6>{data.title}</h6>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Counter;