import axios from "axios";
import API from "../../../Api";
import { logInReducerResponse, setRole } from "../../reducers/loginReducer";
import Swal from "sweetalert2";

export const loginUser = (userData, navigate, key, jobId) => async (dispatch) => {
    dispatch({ type: "auth/loading", payload: true }); // Start loading

    try {
      const response = await axios.post(`${API.login}`, userData);

      if (response.status === 200) {
        dispatch(logInReducerResponse(response.data));
        sessionStorage.setItem("accessToken", response.data.access_token);
        sessionStorage.setItem("refreshToken", response.data.refresh_token);
        sessionStorage.setItem("login_type", response.data?.user_details?.login_type);
        sessionStorage.setItem(
          "userDetails",
          JSON.stringify(response.data.user_details)
        );

        const publicJobId = sessionStorage.getItem('PublicJobId');

        Swal.fire({
          title: "Success!",
          text: `Welcome Back Dear ${response.data.user_details.name.toUpperCase()}`,
          icon: "success",
          timer: 1500, // Time in milliseconds before the popup is automatically closed
          timerProgressBar: true, // Show a progress bar that counts down
          showConfirmButton: false, // Hide the confirm button
          customClass: {
            popup: "custom-swal-popup", // Optional: Apply custom styling if needed
          },
        });

        const role = response.data.user_details.role;

        let roleName;

        if (role == 1) {
          roleName = "clg-manager";
        }
        if (role == 2) {
          roleName = "admin";
        }
        if (role == 3) {
          roleName = "hr-manager";
        }
        if (role == 4) {
          roleName = "Student";
        }
        if (role == 5) {
          roleName = "sub-hr";
        }

        sessionStorage.setItem("userRoleName", JSON.stringify(roleName) || "");
        dispatch(setRole(roleName));

        if (key !== "applyLogin") {
          const publicJobId = sessionStorage.getItem("PublicJobId");

          if (role == 4 && publicJobId) {
            navigate(`/dashboard/sidebar-page/student/job-detasdsils/${publicJobId}`);
          }
          else if (roleName == 1 && publicJobId) {
            navigate(`/dashboard/sidebar-page/clg-manager/job-details/${publicJobId}`);
          }
          else if (role === 0) {
            navigate("/lateral-hiring");
          }
          else if ([1, 2, 3, 4, 5].includes(role)) {
            navigate(`/dashboard/sidebar-page/${roleName}`);
          }
        }
        else {
          navigate(`/lateral/jobDetails/${jobId}`);
        }
      }
    } catch (error) {

      if (error.response) {
        if (error.response.status === 404) {
          Swal.fire({
            title: "Error!",
            text: `${error.response?.data?.errors?.non_field_errors?.[0] || 'Something Went Wrong'}`,
            icon: "error",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "custom-swal-confirm-button",
            },
          });
        } else if (error.response.status === 403) {
          Swal.fire({
            title: "Account Not Activated",
            text:
              error.response.data?.errors?.non_field_errors?.[0] ||
              error.response.data?.msg ||
              "Please verify your email OTP to activate your account.",
            icon: "warning",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "custom-swal-confirm-button",
            },
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: `${error.response?.data?.errors?.non_field_errors?.[0] || error.response?.data?.errors?.email_verified?.[0] || "Something Went Wrong"}`,
            icon: "error",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      } else {
        console.log(error);
        Swal.fire({
          title: "Error!",
          text: "An unexpected error occurred.",
          icon: "error",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "custom-swal-confirm-button",
          },
        });
      }
    } finally {
      dispatch({ type: "auth/loading", payload: false }); // Stop loading
    }
  };
