import { createSlice } from "@reduxjs/toolkit";

const CampusHiringSlice = createSlice({
    name: "digitalCampusHiring",
    initialState: {
        digitalCampusData: [],
        counterDigitalData: [],
        digitalFAQdata: [],
        digitalTestimonialData: [],
        loadingCampusLanding: false,
    },
    reducers: {
        setDigitalCampusData: (state, action) => {
            state.digitalCampusData = action.payload;
        },
        setCounterDigitalData: (state, action) => {
            state.counterDigitalData = action.payload;
        },
        setDigitalFAQdata: (state, action) => {
            state.digitalFAQdata = action.payload;
        },
        setDigitalTestimonialData: (state, action) => {
            state.digitalTestimonialData = action.payload
        },
        setLoadingCampusLanding: (state, action) => {
            state.loadingCampusLanding = action.payload;
        },
    },
});

export const {
    setDigitalCampusData,
    setLoadingCampusLanding,
    setCounterDigitalData,
    setDigitalFAQdata,
    setDigitalTestimonialData
} = CampusHiringSlice.actions;

export default CampusHiringSlice.reducer;