import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../styles/landing_page/lateralhiring.css";
import leftArrowIcon from "../../../utils/landingpage/images/leftarrowwhiteicon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightarroworange.svg";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const CustomArrow = ({ direction, onClick }) => (
  <button
    onClick={onClick}
    className={`custom-arrow ${direction === "prev" ? "prev-arrow" : "next-arrow"}`}
  >
    <img
      src={direction === "prev" ? leftArrowIcon : rightIconArrow}
      alt={direction === "prev" ? "Previous" : "Next"}
      style={{ height: "31px" }}
    />
  </button>
);

const ThinkAbout = () => {
  const sliderRef = useRef(null);
  const location = useLocation();
  const [allTestimonials, setAllTestimonials] = useState([]);

  const TestimonialData = useSelector((state) => state.letralHiring?.alltestimonialsRedux);

  useEffect(() => {
    if ((location.pathname.includes("lateral") || location.pathname === "/") && TestimonialData) {
      setAllTestimonials(TestimonialData);
    } else {
      setAllTestimonials([]);
    }
  }, [location.pathname, TestimonialData]);

  const getSliderSettings = () => {
    const isSingleSlide = allTestimonials?.length === 1;

    return {
      dots: false,
      infinite: !isSingleSlide,
      speed: 500,
      slidesToShow: isSingleSlide ? 1 : 2,
      slidesToScroll: isSingleSlide ? 1 : 2,
      arrows: false,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: !isSingleSlide,
          },
        },
      ],
    };
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <section className="think-about-main">
      <div className="think-about-container">
        <div className="think-about-content">
          <div className="think-about-content-wrapper">
            <div className="think-about-content-heading">
              <span>See what our users</span>
              <span>think about us.</span>
            </div>
            <div className="think-about-navigation">
              <CustomArrow direction="prev" onClick={goToPrev} />
              <CustomArrow direction="next" onClick={goToNext} />
            </div>
          </div>
        </div>
        <div className="think-about-slider-section">
          <div className="think-about-slider-container">
            <Slider ref={sliderRef} {...getSliderSettings()}>
              {allTestimonials.map((value, index) => (
                <div className="think-about-box-parent" key={index}>
                  <div className="think-about-box">
                    <img
                      src={value.student_photo_url}
                      alt=".."
                      className="think-about-img think-about-img-new"
                      style={{ marginLeft: "0px" }}
                    />
                    <h3 className="think-about-name">
                      {value.first_name} {value.last_name}
                    </h3>
                    <p className="think-about-title">{value.position}</p>
                    <p className="think-about-text">
                      {value.review_description?.length > 130
                        ? `${value.review_description?.slice(0, 130)}..`
                        : value.review_description}
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThinkAbout;









// import React, { useRef, useState, useEffect } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../../../styles/landing_page/lateralhiring.css";
// import leftArrowIcon from "../../../utils/landingpage/images/leftarrowwhiteicon.svg";
// import rightIconArrow from "../../../utils/landingpage/images/rightarroworange.svg";
// import "bootstrap/dist/css/bootstrap.min.css";

// const CustomArrow = ({ direction, onClick }) => (
//   <button
//     onClick={onClick}
//     className={`custom-arrow ${direction === "prev" ? "prev-arrow" : "next-arrow"
//       }`}
//   >
//     <img
//       src={direction === "prev" ? leftArrowIcon : rightIconArrow}
//       alt={direction === "prev" ? "Previous" : "Next"}
//       style={{ height: "31px" }}
//     />
//   </button>
// );

// const ThinkAbout = ({ data }) => {
//   const sliderRef = useRef(null);
//   const [Alltestimonials, setAllTestimonials] = useState([]);

//   const UserReview = {
//     "id": 1,
//     "user": 454,
//     "first_name": "New mohit",
//     "last_name": "Burman",
//     "student_photo_url": "https://devbackend.startnaukri.com/media/student_photos/170054_v9_bb_LFqMQtJ.jpg",
//     "review_description": "great",
//     "rating": "4",
//     "position": "dev"
//   }

//   useEffect(() => {
//     if (data) {
//       setAllTestimonials([...data, UserReview])
//     }
//   }, [data]);

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 2,
//     slidesToScroll: 2,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 576,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const goToPrev = () => {
//     sliderRef.current.slickPrev();
//   };

//   const goToNext = () => {
//     sliderRef.current.slickNext();
//   };

//   return (
//     <section className="think-about-main">
//       <div className="think-about-container">
//         <div className="think-about-content">
//           <div className="think-about-content-wrapper">
//             <div className="think-about-content-heading">
//               <span>See what our users</span>
//               <span>think about us.</span>
//             </div>
//             <div className="think-about-navigation">
//               <CustomArrow direction="prev" onClick={goToPrev} />
//               <CustomArrow direction="next" onClick={goToNext} />
//             </div>
//           </div>
//         </div>
//         <div className="think-about-slider-section">
//           <div className="think-about-slider-container">
//             <Slider ref={sliderRef} {...settings}>
//               {Alltestimonials &&
//                 Alltestimonials?.map((value, index) => (
//                   <div className="think-about-box-parent" key={index}>
//                     <div className="think-about-box">
//                       <img
//                         src={value.student_photo_url}
//                         alt=".."
//                         className="think-about-img think-about-img-new"
//                         style={{ marginLeft: "0px" }}
//                       />
//                       <h3 className="think-about-name">{value.first_name} {value.last_name}</h3>
//                       <p className="think-about-title">{value.position}</p>
//                       <p className="think-about-text">
//                         {value.review_description?.length > 130
//                           ? `${value.review_description?.slice(0, 130)}..`
//                           : value.review_description}
//                       </p>
//                       <p>&nbsp;</p>
//                     </div>
//                   </div>
//                 ))}
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ThinkAbout;