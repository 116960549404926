import { createSlice } from "@reduxjs/toolkit";

const StudentProfilePercentageSlice = createSlice({
    name: 'studentProfilePercentage',
    initialState: {
        studentProfilePercentageValue: '',
    },
    reducers:{
       setStudentProfilePercentageValue: (state, action) => {
        state.studentProfilePercentageValue = action.payload
       }
    }
});

export const {setStudentProfilePercentageValue, studentProfilePercentageValue} = StudentProfilePercentageSlice.actions;

export default StudentProfilePercentageSlice.reducer;