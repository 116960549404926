import React, { useEffect, useState } from "react";
import searchLogo from "../../../utils/dashboards/searchIconDash.svg";
import filterIcon from "../../../utils/dashboards/filterIcon.svg";
import { Link } from "react-router-dom";
import RightArrow from "../../../utils/landingpage/images/rightarroworange.svg";
import { toast } from "react-toastify";
import { Autocomplete, Pagination, TextField } from "@mui/material";
import Loader from "react-spinners/ClipLoader";
import "../../../styles/dashboards/campusecandidaterequest.css";
import "../../../styles/admin/adminRequest.css";
import "../../../styles/admin/student.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Students() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState("pending");
  const [isLoading, setIsLoading] = useState(false)
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const [studentType, setStudentType] = useState("lateral");
  const [lateralStudentTableData, setLateralStudentTableData] = useState([]);
  const [changeStudentTableData, setChangeStudentTableData] = useState([]);
  const [filterStudentTableData, setFilterStudentTableData] = useState([]);
  const [campusStudentTableData, setCampusStudentTableData] = useState([]);
  const token = sessionStorage.getItem("accessToken");
  const [isSubmit, setIsSubmit] = useState(false);
  const [colleges, setColleges] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    student_last_name: "",
    email: "",
    password: "",
    password2: "",
    all_mobno: "",
    all_paddress: "",
    student_campus_clg: "",
    message: "",
    enrollment_no: "",
    login_type: "manual",
    student_campus_clg: null,
  });

  const applicationType = useSelector((state) => state.superAdmin.activeApplication);

  const fetchColleges = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/approved-colleges-list/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setColleges(response.data);
    } catch (error) {
      console.error("Error fetching students data:", error);
      toast.error("Failed to fetch student data.");
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = () => {
    setStudentType((prevType) =>
      prevType === "lateral" ? "campus" : "lateral"
    );
    setFormData((prevData) => ({
      ...prevData,
      message:
        studentType === "lateral"
          ? "i am a student of campus"
          : "i am a student lateral",
      student_campus_clg:
        studentType === "campus" ? "" : prevData.student_campus_clg,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const apiUrl =
      studentType === "campus"
        ? "/api/student_campusregister/"
        : "/api/studentregister/";

    setIsSubmit(true);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}${apiUrl}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        toast.success(response.data.msg);
        setShowAddModal(false);
        setIsSubmit(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsSubmit(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.enrollment_no
        ) {
          toast.error(
            error.response.data.enrollment_no[0] || error.response.data.email[0]
          );
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.email
        ) {
          toast.error(error.response.data.email[0]);
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      });
  };

  const fetchAllStudentData = async () => {
    try {
      setLoading(true);
      setIsLoading(true)
      const [lateralResponse, campusResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_BASE_URL}/api/show_student/`, {
          method: "GET",
        }),
        fetch(`${process.env.REACT_APP_BASE_URL}/api/show_student_campus/`, {
          method: "GET",
        }),
      ]);

      if (!lateralResponse.ok) {
        throw new Error(`Error: Lateral API(${lateralResponse.status})`);
      }
      if (!campusResponse.ok) {
        throw new Error(`Error: Campus API(${campusResponse.status})`);
      }

      const [lateralData, campusData] = await Promise.all([
        lateralResponse.json(),
        campusResponse.json(),
      ]);

      setLateralStudentTableData(lateralData);
      setFilterStudentTableData(applicationType === "Campus" ? campusData : lateralData);
      setChangeStudentTableData(lateralData);
      setCampusStudentTableData(campusData);
    } catch (error) {
      console.error("Failed to fetch student data:", error);
      toast.error("Failed to fetch student data.");
    } finally {
      setLoading(false);
      setIsLoading(false)
    }
  };

  const currentItems = filterStudentTableData
    .filter((student) =>
      `${student.name} ${student.student_last_name}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const totalPages = Math.ceil(lateralStudentTableData.length / itemsPerPage);

  useEffect(() => {
    const abortController = new AbortController();

    fetchAllStudentData();
    fetchColleges();

    return () => abortController.abort();
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    setSelectedOption(applicationType ? applicationType : "Lateral")
  }, [applicationType])

  const handleSelectClick = () => {
    setIsActive(!isActive);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    const changeData =
      option === "Campus" ? campusStudentTableData : lateralStudentTableData;
    setFilterStudentTableData(changeData);
    setChangeStudentTableData(changeData);
    setIsActive(false);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const filteredRequests = (searchQuery) => {
    return changeStudentTableData.filter((student) =>
      student?.name.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearchQuery(value);
    setFilterStudentTableData(filteredRequests(value));
    setCurrentPage(1);
  };

  return (
    <main className="_main_content_inner_screen">
      {isLoading ? (
        <div className="custom_loader">
          <Loader color="#F38D00" size={50} />
        </div>
      ) : (
        <section className="_main_content_inner_screen_conatiner">
          <div>
            <div className="campuse_student_request_header">

              <div className="d-flex align-items-center gap-2">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <path
                    d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                    fill="#222222"
                  />
                  <path
                    d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                    fill="#222222"
                  />
                </svg>
                <h5 className="pt-2">{`Students Management [${filterStudentTableData?.length || 0}]`}</h5>
              </div>

              <div className="search_input_container">
                <div className="campuse_student_request_search_bar">
                  <img src={searchLogo} alt="search" />
                  <input
                    type="search"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>

                <div
                  className={`select_menu_dropdown ${isActive ? "select_active" : ""
                    }`}
                >
                  <div
                    className="select_btn_dropdown"
                    onClick={handleSelectClick}
                  >
                    <img src={filterIcon} alt="Filter Icon" />
                    <span className="select_dropdown_text">
                      {selectedOption}
                    </span>
                    {isActive ? "▲" : "▼"}
                  </div>

                  {isActive && (
                    <ul className="select_dropdown_options_list">
                      <li
                        className="select_dropdown_option"
                        onClick={() => handleOptionClick("Campus")}
                      >
                        Campus
                      </li>
                      <li
                        className="select_dropdown_option"
                        onClick={() => handleOptionClick("Lateral")}
                      >
                        Lateral
                      </li>
                    </ul>
                  )}
                </div>
                <button
                  className="add_college_managment"
                  onClick={() => setShowAddModal(true)}
                >
                  Add Student
                </button>
              </div>
            </div>

            <div className="studente_container">
              {filterStudentTableData?.length > 0 ? (
                <table className="candidate-table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Student Name</th>
                      <th>Email</th>
                      <th>
                        {selectedOption === "Campus"
                          ? "College Name"
                          : "Experience"}
                      </th>
                      <th>Phone Number</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems?.map((candidate, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                        <td>{`${String(candidate?.name)} ${String(
                          candidate?.student_last_name
                        )}`}</td>
                        <td>
                          {candidate.email ? String(candidate.email) : "--"}
                        </td>
                        <td>
                          {selectedOption === "Campus"
                            ? candidate?.student_campus_clg_name || "N/A"
                            : candidate?.studentlateral_profile?.experience ||
                            "--"}
                        </td>
                        <td>
                          {candidate?.all_mobno
                            ? candidate.all_mobno.startsWith("+91")
                              ? candidate.all_mobno
                              : `+91 ${candidate.all_mobno}`
                            : "--"}
                        </td>

                        <td>
                          <button
                            onClick={() => {
                              setSelectedStudent(candidate);
                              setShowViewModal(true);
                            }}
                            style={{
                              color: "#f38d00",
                              textDecoration: "none",
                              background: "none",
                              border: "none",
                            }}
                          >
                            View <img src={RightArrow} alt="arrow" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-requests-message">No Data Available</div>
              )}
            </div>
            {filterStudentTableData.length > 10 && (
              <div className="d-flex justify-content-center pagination_custom">
                <Pagination
                  count={Math.ceil(
                    filterStudentTableData.length / itemsPerPage
                  )}
                  page={currentPage}
                  color="primary"
                  className="mt-3"
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </section>
      )}

      {showAddModal && (
        <div className="modal_overlay">
          <div className="modal_content student_add_modal">
            <div className="d-flex gap-2 align-items-center justify-content-center">
              <button className="toggle_button" onClick={handleToggle}>
                {studentType}
              </button>
            </div>
            <div className="student_form">
              <div className="form_group grid_container">
                <div>
                  <label className="form_label">First Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter first name"
                    className="form_input"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="form_label">Last Name</label>
                  <input
                    type="text"
                    name="student_last_name"
                    placeholder="Enter last name"
                    className="form_input"
                    value={formData.student_last_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {studentType === "campus" && (
                <div className="form_group">
                  <label className="form_label">College Name</label>
                  <Autocomplete
                    value={formData.collegeName}
                    onChange={(event, newValue) => {
                      setFormData((prev) => ({
                        ...prev,
                        collegeName: newValue,
                        student_campus_clg: newValue?.id || "",
                      }));
                    }}
                    options={colleges}
                    getOptionLabel={(option) => option?.college_name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "2px 4px 2px 6px !important",
                      },
                      "& .MuiAutocomplete-input": {
                        padding: "0px",
                        border: "none",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select College"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          sx: { fontSize: "0.875rem" }, // Makes the input text smaller (14px)
                        }}
                        InputLabelProps={{
                          sx: { fontSize: "0.875rem" }, // Makes the label text smaller (14px)
                        }}
                      />
                    )}
                  />
                </div>
              )}
              <div className="form_group grid_container">
                <div>
                  <label className="form_label">Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                    className="form_input"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="form_label">Mobile Number</label>
                  <input
                    type="text"
                    name="all_mobno"
                    placeholder="Enter mobile number"
                    className="form_input"
                    value={formData.all_mobno}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form_group grid_container">
                <div>
                  <label className="form_label">Password</label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    className="form_input"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="form_label">Confirm Password</label>
                  <input
                    type="password"
                    name="password2"
                    placeholder="Confirm password"
                    className="form_input"
                    value={formData.password2}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form_group">
                <div className="">
                  <label className="form_label">Address</label>
                  <input
                    type="text"
                    name="all_paddress"
                    placeholder="Enter address"
                    className="form_input"
                    value={formData.all_paddress}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {studentType === "campus" && (
                <div className="form_group">
                  <label className="form_label">Enrollment No</label>
                  <input
                    type="text"
                    name="enrollment_no"
                    placeholder="Enter enrollment number"
                    className="form_input"
                    value={formData.enrollment_no}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
            <div className="modal_actions">
              <button
                className="cancel_button"
                onClick={() => setShowAddModal(false)}
              >
                Cancel
              </button>
              <button className="submit_button" onClick={handleSubmit}>
                {isSubmit ? <span className="spinner"></span> : "Add Student"}
              </button>
            </div>
          </div>
        </div>
      )}

      {showViewModal && selectedStudent && (
        <div className="modal_overlay">
          <div className="modal_content student_view_modal">
            <h3 className="modal_title">Student Details</h3>
            <div className="student_details">
              <div className="form_group">
                <label className="form_label">First Name</label>
                <input
                  type="text"
                  value={selectedStudent.name}
                  disabled
                  className="form_input disabled"
                />
              </div>
              <div className="form_group">
                <label className="form_label">Last Name</label>
                <input
                  type="text"
                  value={selectedStudent.student_last_name}
                  disabled
                  className="form_input disabled"
                />
              </div>
              <div className="form_group">
                <label className="form_label">Email</label>
                <input
                  type="email"
                  value={selectedStudent.email}
                  disabled
                  className="form_input disabled"
                />
              </div>
              <div className="form_group">
                <label className="form_label">Phone Number</label>
                <input
                  type="text"
                  value={selectedStudent.all_mobno}
                  disabled
                  className="form_input disabled"
                />
              </div>
            </div>
            <div className="modal_actions">
              <button
                className="close_button"
                onClick={() => setShowViewModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
