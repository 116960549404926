import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Pie } from "react-chartjs-2";
import "../../../../styles/dashboards/hrdashboard/hrprofile.css";
import HeadQuarterAddress from "./HeadQuarterAddress";
import CompanyDetails from "./CompanyDetails";
import ProfileCreation from "./ProfileCreation";
import Instagram from "../../../../utils/landingpage/images/blackinstgram.svg";
import zoom_logo from "../../../../utils/dashboards/zoom_logo.png";
import Twitter from "../../../../utils/landingpage/images/blacktwiter.svg";
import LinkedIn from "../../../../utils/landingpage/images/blacklinkedin.svg";
import editIcon from "../../../../utils/dashboards/edit-icon.svg";
import orange_delete_icon from "../../../../utils/dashboards/orange_delete_icon.svg";
import editrevertIcon from "../../../../utils/dashboards/edit-revert.svg";
import { updateHrProfileData } from "../../../../redux/actions/hr_profile_action";
import {
  fetchHrProfileData,
  fetchProfileCompletion,
} from "../../../../redux/actions/hr_profile_action";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { setGetHrProfileData } from "../../../../redux/reducers/hrProfileReducer";

const HRManagerProfile = () => {
  const [activeTab, setActiveTab] = useState("ProfileCreation");
  const [isSocialEditMode, setSocialEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isZoomEdit, setIsZoomEdit] = useState(false);
  const [isZoomCredentials, setIsZoomCredentials] = useState(false);
  const [isSocialMediaOpenModel, setIsSocialMediaOpenModel] = useState(false);
  const [editingValue, setEditingValue] = useState([]);
  const [isAlreadySocialDataAvailable, setisAlreadySocialDataAvailable] = useState(false);
  const [showSocialMediaRedux, setShowSocialMediaRedux] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [isSocialLoading, setIsSocialLoading] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    const userDetails = sessionStorage.getItem('userDetails');

    const parsedDetails = JSON.parse(userDetails);
    const companyProfileId = parsedDetails?.company_profile?.user;

    // setCollageProfileId(parsedDetails?.college_api?.college_id)
    setAccessToken(token)
    setCompanyId(companyProfileId)
  }, [accessToken])

  const dispatch = useDispatch();

  const hrProfileData = useSelector(
    (state) => state.hrProfile.getHrProfileData
  );

  const [credential, setCredential] = useState({
    zoom_account_id: "",
    zoom_client_id: "",
    zoom_client_secret: ""
  });

  const [initialCredential, setInitialCredential] = useState({
    zoom_account_id: false,
    zoom_client_id: false,
    zoom_client_secret: false
  });

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    instagram: "",
    twitter: "",
    linkedin: "",
  });

  const [editSocialMediaDataRedux, setEditSocialMediaDataRedux] = useState({
    instagram: "",
    twitter: "",
    linkedin: "",
  })

  const [initialSocialValuesSet, setInitialSocialValuesSet] = useState({
    instagram: false,
    twitter: false,
    linkedin: false,
  });

  const handleSocialFieldChange = (fieldName, value) => {
    if (!initialSocialValuesSet[fieldName]) {
      setInitialSocialValuesSet((prev) => ({ ...prev, [fieldName]: true }));
    }
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const payload = {
        zoom_account_id: initialCredential.zoom_account_id
          ? credential.zoom_account_id
          : hrProfileData?.zoom_account_id || "",
        zoom_client_id: initialCredential.zoom_client_id
          ? credential.zoom_client_id
          : hrProfileData?.zoom_client_id || "",
        zoom_client_secret: initialCredential.zoom_client_secret
          ? credential.zoom_client_secret
          : hrProfileData?.zoom_client_secret || "",
      };

      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/company_profile/${companyId}/`, payload);

      if (response.status === 200) {
        toast.success("Credentials saved successfully!");
        dispatch(setGetHrProfileData(response.data));
        setInitialCredential({
          zoom_account_id: false,
          zoom_client_id: false,
          zoom_client_secret: false,
        });
        setIsZoomCredentials(true);
        setIsZoomEdit(false);
      } else {
        toast.error("Failed to save credentials. Please try again.");
      }
    } catch (error) {
      console.error("Error saving credentials:", error);
      toast.error("An error occurred while saving credentials.");
    } finally {
      setIsLoading(false)
    }
  };

  const handleCredintialFieldChange = (fieldName, value) => {
    if (!initialCredential[fieldName]) {
      setInitialCredential((prev) => ({ ...prev, [fieldName]: true }));
    }
    setCredential((prev) => ({ ...prev, [fieldName]: value }));
  };

  useEffect(() => {
    if (hrProfileData?.zoom_account_id && hrProfileData?.zoom_client_id && hrProfileData?.zoom_client_secret) {
      setIsZoomCredentials(true)
    }
    if (hrProfileData?.social_media_links?.length > 0) {
      setisAlreadySocialDataAvailable(true);
      setShowSocialMediaRedux(true);

      const socialMediaData = {
        instagram: hrProfileData?.social_media_links.find(link => link.platform === 'instagram')?.url || '',
        twitter: hrProfileData?.social_media_links.find(link => link.platform === 'twitter')?.url || '',
        linkedin: hrProfileData?.social_media_links.find(link => link.platform === 'linkedin')?.url || '',
      };
      setEditSocialMediaDataRedux(socialMediaData)
    }
    if (hrProfileData?.social_media_links?.length < 1) {
      setIsSocialMediaOpenModel(false)
      setisAlreadySocialDataAvailable(false)
      setFormData({
        ...formData,
        twitter: "",
        instagram: "",
        linkedin: "",
      })
    }
  }, [hrProfileData]);

  const profileCompletion = useSelector(
    (state) => state.hrProfile.profileCompletion
  );

  const remainingCompletion = 100 - (profileCompletion || 0);

  const pieChartData = {
    datasets: [
      {
        data: [profileCompletion, remainingCompletion],
        backgroundColor: ["#4CAF50", "#E0E0E0"],
        borderWidth: 0,
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
  };

  const handleZoomEditClick = () => {
    setIsZoomEdit((prev) => !prev);
  };

  useEffect(() => {
    dispatch(fetchHrProfileData());
    dispatch(fetchProfileCompletion());
  }, [dispatch]);

  const handleEditSocialMedia = (link) => {
    setIsSocialMediaOpenModel(true);
    // setIsAlreadySocialData(true);
    // dispatch(setIsAlreadySocialDataAvailable(true))
    setisAlreadySocialDataAvailable(true)
    setEditingValue(link)
    // setShowSocialMedia(false);

    // dispatch(setShowSocialMediaRedux(false))
    setShowSocialMediaRedux(false)
  }

  const socialMediavalidationSchema = Yup.object({
    instagram: Yup.string()
      .url("Please enter a valid Instagram URL")
      .nullable(),
    twitter: Yup.string()
      .url("Please enter a valid Twitter URL")
      .nullable(),
    linkedin: Yup.string()
      .url("Please enter a valid LinkedIn URL")
      .nullable(),
  });

  const handleAddSocialMedia = async (e) => {
    e.preventDefault();

    try {
      setIsSocialLoading(true)
      await socialMediavalidationSchema.validate(formData, { abortEarly: false });

      if (!formData.instagram && !formData.twitter && !formData.linkedin) {
        toast.error("Please add at least one social media link");
        return;
      }

      const accessToken = sessionStorage.getItem("accessToken");

      const userDetails = sessionStorage.getItem('userDetails');

      const parsedDetails = JSON.parse(userDetails);
      const companyProfileId = parsedDetails?.company_profile?.user;

      // setCollageProfileId(parsedDetails?.college_api?.college_id)
      setCompanyId(companyProfileId)

      const payload = {
        social_media_links: [
          { platform: "instagram", url: formData.instagram || '' },
          { platform: "twitter", url: formData.twitter || '' },
          { platform: "linkedin", url: formData.linkedin || '' },
        ]?.filter((link) => link.url),
      };

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/company_profile/${companyProfileId}/`,
        payload,
        config
      );

      if (response.status === 200) {
        toast.success("Added Successfully");

        // fetchCollegeProfile();
        dispatch(updateHrProfileData());

        setisAlreadySocialDataAvailable(true);
        setIsSocialMediaOpenModel(false);
        setShowSocialMediaRedux(true);
      }
    } catch (error) {
      if (error.inner) {
        error.inner.forEach((err) => toast.error(err.message));
      } else {
        console.error("Error in API:", error);
      }
    } finally {
      setIsSocialLoading(false)
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleEditSocialMediaCancel = () => {
    // dispatch(setIsAlreadySocialDataAvailable(true));
    setisAlreadySocialDataAvailable(true)
    setIsSocialMediaOpenModel(false);
    // dispatch(setShowSocialMediaRedux(true))
    setShowSocialMediaRedux(true)
  }

  const validateURL = (url) => {
    const urlRegex = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-]*)*\/?$/;
    return urlRegex.test(url);
  };

  const handleEditSocialMediaSubmit = async () => {

    const accessToken = sessionStorage.getItem("accessToken");
    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData?.company_profile?.user;

    const socialMediaLinks = [
      {
        platform: "instagram",
        url: formData.instagram || hrProfileData.social_media_links?.find(link => link.platform === "instagram")?.url || "",
      },
      {
        platform: "twitter",
        url: formData.twitter || hrProfileData.social_media_links?.find(link => link.platform === "twitter")?.url || "",
      },
      {
        platform: "linkedin",
        url: formData.linkedin || hrProfileData.social_media_links?.find(link => link.platform === "linkedin")?.url || "",
      }
    ].filter(link => link.url);

    const invalidLinks = socialMediaLinks.filter(link => !validateURL(link.url));
    if (invalidLinks.length > 0) {
      invalidLinks.forEach(link => {
        toast.error(`Invalid URL format for ${link.platform}`);
      });
      return;
    }

    const payload = {
      social_media_links: socialMediaLinks
    };

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/company_profile/${userId}/`,
        payload,
        config
      );

      if (response.status === 200) {
        toast.success("Social media links updated successfully");
        // fetchData();
        // dispatch(fetchStudentProfileData())

        // fetchCollegeProfile()
        dispatch(updateHrProfileData());

        setIsSocialMediaOpenModel(false);
        // setIsAlreadySocialData(true);
        // dispatch(setIsAlreadySocialDataAvailable(true))
        setisAlreadySocialDataAvailable(true)
        // showSocialMedia(true);
        // dispatch(setShowSocialMediaRedux(false))
        setShowSocialMediaRedux(false)
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const handleDeleteSocialMedia = async (id) => {
    const accessToken = sessionStorage.getItem("accessToken");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/all_profile/social-media-links/${id}/`,
        config
      );

      if (response.status) {
        toast.success("Deleted Successfully");
        // fetchData();

        // fetchCollegeProfile()
        dispatch(updateHrProfileData());

        setisAlreadySocialDataAvailable(true);
        setIsSocialMediaOpenModel(false)
      }
    } catch (error) {
      console.error("Error in API:", error);
      toast.error(error.response?.data?.message || "Failed to delete social media link");
    }
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <ul className="student_campus_profile_tabs">
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === "ProfileCreation" ? "active" : ""
                }`}
              onClick={() => setActiveTab("ProfileCreation")}
            >
              Profile Creation
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === "HeadQuarterAddress" ? "active" : ""
                }`}
              onClick={() => setActiveTab("HeadQuarterAddress")}
            >
              Head Quarter Address
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === "CompanyDetails" ? "active" : ""
                }`}
              onClick={() => setActiveTab("CompanyDetails")}
            >
              Company Details
            </button>
          </li>
        </ul>
        <div className="d-flex justify-content-between align-items-start">
          <div className="student_campus_profile_card">
            <div className="student_campus_profile_card_body">
              {activeTab === "ProfileCreation" && (
                <>
                  <ProfileCreation hrProfileData={hrProfileData} />
                </>
              )}
              {activeTab === "HeadQuarterAddress" && (
                <>
                  <HeadQuarterAddress hrProfileData={hrProfileData} />
                </>
              )}
              {activeTab === "CompanyDetails" && (
                <>
                  <CompanyDetails hrProfileData={hrProfileData} />
                </>
              )}
            </div>
          </div>
          <div style={{ width: "28%" }}>
            <div className="student_campus_profile_social">
              <p>Complete Your Profile</p>
              <div className="mr-4 epf-chart-wrapper new-epf-chart-wrapper">
                <div className="epf-chart-container student_campus_canvas_container">
                  <Pie data={pieChartData} options={pieChartOptions} />
                  <div className="student_campus_canvas_percentage_value">
                    {`${profileCompletion}%` || "0%"}
                  </div>
                </div>
              </div>
            </div>

            <br />

            <div className="student_campus_profile_social">
              <h3 className="student_campus_profile_social_title">
                {isZoomCredentials && (<span
                  onClick={handleZoomEditClick}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginRight: "13px",
                  }}
                >
                  <img
                    src={isZoomEdit ? editrevertIcon : editIcon}
                    alt="Edit"
                  />
                </span>)}
                Zoom Meeting Credentials
              </h3>
              <div className="student_campus_profile_social_list">
                {" "}
                {(isZoomEdit || !isZoomCredentials) ? (
                  <>
                    <div className="sites_icon sites_icon_second">
                      <div className="images">
                        <img src={zoom_logo} alt="Zoom" />
                      </div>
                      <div>
                        <p>Zoom Account ID</p>
                        <input
                          type="text"
                          name="zoom_account_id"
                          value={
                            !initialCredential.zoom_account_id
                              ? hrProfileData?.zoom_account_id || ""
                              : credential.zoom_account_id
                          }
                          onChange={(e) => handleCredintialFieldChange("zoom_account_id", e.target.value)}
                          className="socialLinks_input zoom_meeting_inputs"
                          placeholder="Zoom Account ID"
                        />
                      </div>
                    </div>
                    <div className="sites_icon sites_icon_second">
                      <div className="images">
                        <img src={zoom_logo} alt="Zoom" />
                      </div>
                      <div>
                        <p>Zoom Client ID</p>
                        <input
                          type="text"
                          name="zoom_client_id"
                          value={
                            !initialCredential.zoom_client_id
                              ? hrProfileData?.zoom_client_id || ""
                              : credential.zoom_client_id
                          }
                          onChange={(e) => handleCredintialFieldChange("zoom_client_id", e.target.value)}
                          className="socialLinks_input zoom_meeting_inputs"
                          placeholder="Zoom Client ID"
                        />
                      </div>
                    </div>
                    <div className="sites_icon sites_icon_second">
                      <div className="images">
                        <img src={zoom_logo} alt="Zoom" />
                      </div>
                      <div>
                        <p>Zoom Client Secret</p>
                        <input
                          type="text"
                          name="zoom_client_secret"
                          value={
                            !initialCredential.zoom_client_secret
                              ? hrProfileData?.zoom_client_secret || ""
                              : credential.zoom_client_secret
                          }
                          onChange={(e) => handleCredintialFieldChange("zoom_client_secret", e.target.value)}
                          className="socialLinks_input zoom_meeting_inputs"
                          placeholder="Zoom Client Secret"
                        />
                      </div>
                    </div>
                    <div className="zoom_crediantial_button_div">
                      <button onClick={handleSave} disabled={isLoading} className="zoom_crediantial_button_save">{isLoading ? `Saving...` : `Save`}</button>
                      {isZoomCredentials && (<button onClick={handleZoomEditClick} className="zoom_crediantial_button_cancel">Cancel</button>)}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="sites_icon">
                      <div className="images">
                        <img src={zoom_logo} alt="Instagram" />
                      </div>
                      <div>
                        <span>Zoom Account ID</span>
                        <br />
                        <b>{hrProfileData?.zoom_account_id || ""}</b>
                      </div>
                    </div>
                    <div className="sites_icon">
                      <div className="images">
                        <img src={zoom_logo} alt="Twitter" />
                      </div>
                      <div>
                        <span>Zoom Client ID</span>
                        <br />
                        <b>{hrProfileData?.zoom_client_id || ""}</b>
                      </div>
                    </div>
                    <div className="sites_icon">
                      <div className="images">
                        <img src={zoom_logo} alt="LinkedIn" />
                      </div>
                      <div>
                        <span>Zoom Client Secret</span>
                        <br />
                        <b>{hrProfileData?.zoom_client_secret || ""}</b>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="mt-4 student_campus_profile_social">
              <div className="social_Sites_Innerdiv">
                <div className="sites_topView pb-4">
                  <p>{isSocialMediaOpenModel ? `Edit Social Media Links` : `Social Media Links`}</p>
                </div>
                <div className="sites_downview">
                  <div className="sites_down_innerview">

                    {isSocialMediaOpenModel === true && isAlreadySocialDataAvailable === true && showSocialMediaRedux === false && (<>

                      <div className="sites_icon campus_student_sites_icon">
                        <div className="images">
                          <img src={Instagram} alt="Icon" />
                        </div>
                        <div style={{ width: "100%", position: "relative" }}>
                          <span>Instagram</span>
                          <br />
                          <div className="d-grid">
                            <input
                              type="text"
                              name="instagram"
                              // value={"" || formData.instagram || editSocialMediaDataRedux?.instagram}
                              // onChange={handleInputChange}
                              value={
                                !initialSocialValuesSet.instagram
                                  ? editSocialMediaDataRedux?.instagram || ""
                                  : formData.instagram
                              }
                              onChange={(e) => handleSocialFieldChange("instagram", e.target.value)}
                              className="epf-input-field student_epf_input_fields_new"
                            />
                            <br />
                            {/* {socialErrors.instagram && (
                            <span className="errorprofile new_error_profile">
                              {socialErrors.instagram}
                            </span>
                          )} */}
                          </div>
                        </div>
                      </div>
                      <div className="sites_icon campus_student_sites_icon mt-2">
                        <div className="images">
                          <img src={Twitter} alt="Twitter" />
                        </div>
                        <div style={{ width: "100%", position: "relative" }}>
                          <span>Twitter</span>
                          <br />
                          <div className="d-grid">
                            <input
                              type="text"
                              name="twitter"
                              // value={formData.twitter || editSocialMediaDataRedux?.twitter}
                              // onChange={handleInputChange}
                              value={
                                !initialSocialValuesSet.twitter
                                  ? editSocialMediaDataRedux?.twitter || ""
                                  : formData.twitter
                              }
                              onChange={(e) => handleSocialFieldChange("twitter", e.target.value)}
                              className="epf-input-field student_epf_input_fields_new"
                            />
                            {/* {socialErrors.twitter && (
                            <span className="errorprofile new_error_profile">
                              {socialErrors.twitter}
                            </span>
                          )} */}
                          </div>
                        </div>
                      </div>
                      <div className="sites_icon campus_student_sites_icon mt-2">
                        <div className="images">
                          <img src={LinkedIn} alt="LinkedIn" />
                        </div>
                        <div style={{ width: "100%", position: "relative" }}>
                          <span>LinkedIn</span>
                          <br />
                          <div className="d-grid">
                            <input
                              type="text"
                              name="linkedin"
                              // value={formData.linkedin || editSocialMediaDataRedux?.linkedin}
                              // onChange={handleInputChange}
                              value={
                                !initialSocialValuesSet.linkedin
                                  ? editSocialMediaDataRedux?.linkedin || ""
                                  : formData.linkedin
                              }
                              onChange={(e) => handleSocialFieldChange("linkedin", e.target.value)}
                              className="epf-input-field student_epf_input_fields_new"
                            />
                            {/* {socialErrors.linkedin && (
                            <span className="errorprofile new_error_profile">
                              {socialErrors.linkedin}
                            </span>
                          )} */}
                          </div>
                        </div>
                      </div>

                      <div className="pt-4 d-flex gap-4" style={{ paddingLeft: "2.5rem" }}>
                        <button onClick={handleEditSocialMediaSubmit} className="student_epf_submit_bttn mt-0">
                          Save
                        </button>
                        <button
                          onClick={handleEditSocialMediaCancel}
                          className="student_epf_submit_bttn mt-0"
                          style={{
                            background: "transparent",
                            border: "1px solid rgba(243, 141, 0, 1)",
                            color: "rgba(243, 141, 0, 1)",
                          }}
                        >
                          Cancel
                        </button>
                      </div>

                    </>)}

                    {(isSocialMediaOpenModel === false && isAlreadySocialDataAvailable === false) && (
                      <>
                        <div className="sites_icon campus_student_sites_icon">
                          <div className="images">
                            <img src={Instagram} alt="icon" />
                          </div>
                          <div style={{ width: "100%" }}>
                            <span>Instagram</span>
                            <br />
                            <input
                              type="text"
                              name="instagram"
                              value={formData.instagram}
                              onChange={handleInputChange}
                              className="epf-input-field student_epf_input_fields_new"
                            />
                          </div>
                        </div>
                        <div className="sites_icon campus_student_sites_icon mt-2">
                          <div className="images">
                            <img src={Twitter} alt="icon" />
                          </div>
                          <div style={{ width: "100%" }}>
                            <span>Twitter</span>
                            <br />
                            <input
                              type="text"
                              name="twitter"
                              value={formData.twitter}
                              onChange={handleInputChange}
                              className="epf-input-field student_epf_input_fields_new"
                            />
                          </div>
                        </div>
                        <div className="sites_icon campus_student_sites_icon mt-2">
                          <div className="images">
                            <img src={LinkedIn} alt="icon" />
                          </div>
                          <div style={{ width: "100%" }}>
                            <span>LinkedIn</span>
                            <br />
                            <input
                              type="text"
                              name="linkedin"
                              value={formData.linkedin}
                              onChange={handleInputChange}
                              className="epf-input-field student_epf_input_fields_new"
                            />
                          </div>
                        </div>
                        <div className="pt-4" style={{ paddingLeft: "33px" }}>
                          <button onClick={handleAddSocialMedia} disabled={isSocialLoading} className=" student_epf_submit_bttn mt-0">
                            {isSocialLoading ? `Saving...` : `Save`}
                          </button>
                        </div>
                      </>
                    )}

                    {(showSocialMediaRedux === true && isSocialMediaOpenModel === false && isAlreadySocialDataAvailable === true) && (
                      <>
                        {hrProfileData?.social_media_links
                          ?.filter((link) => link.url)
                          ?.map((link) => (
                            <div key={link.id} className="sites_icon student_sites_icon" style={{ width: "100%" }}>
                              <div className="d-flex align-items-start">
                                <div className="images">
                                  <img
                                    src={
                                      link.platform === "instagram"
                                        ? Instagram
                                        : link.platform === "twitter"
                                          ? Twitter
                                          : link.platform === "linkedin"
                                            ? LinkedIn
                                            : ""
                                    }
                                    alt='icon'
                                  />
                                </div>
                                <div>
                                  <span>
                                    <span>{link.platform.charAt(0).toUpperCase() + link.platform.slice(1)}</span>
                                    <span style={{ paddingLeft: "1.5rem" }}><img onClick={() => handleEditSocialMedia(link)} src={editIcon} alt="Edit" style={{ width: "16px", cursor: "pointer", marginRight: "6px" }} /> <img onClick={() => handleDeleteSocialMedia(link.id)} src={orange_delete_icon} alt="Edit" style={{ width: "16px", cursor: "pointer" }} /> </span>
                                  </span>
                                  <br />
                                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                                    {link.url}
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    )}

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HRManagerProfile;
