import React, { useState, useRef, useEffect, useMemo } from "react";
import "../../../../styles/landing_page/myProfile.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Instagram from "../../../../utils/landingpage/images/blackinstgram.svg"
import Twitter from "../../../../utils/landingpage/images/blacktwiter.svg";
import LinkedIn from "../../../../utils/landingpage/images/blacklinkedin.svg";
import editIcon from "../../../../utils/dashboards/edit-icon.svg";
import editrevertIcon from "../../../../utils/dashboards/edit-revert.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginUserDetailas } from "../../../../redux/actions/letralHiringAction";
import axios from "axios";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import "react-toastify/dist/ReactToastify.css";
import { FaChevronDown } from "react-icons/fa";
import Swal from 'sweetalert2';
import "react-datepicker/dist/react-datepicker.css";
import { color } from "framer-motion";
import dummy_logo from "../../../../utils/landingpage/images/user-icon-dummy.png";
import ReactTooltip from "react-tooltip";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const degreeOptions = {
  bachelors: {
    courses: [
      {
        name: "B.Tech",
        specializations: [
          "Computer Science",
          "IT",
          "Electrical",
          "Electronics",
          "Mechanical",
          "Civil",
          "Chemical",
          "Aerospace",
          "1",
          "Automobile",
          "Mechatronics",
        ],
      },
      {
        name: "BBA",
        specializations: [
          "Marketing",
          "Finance",
          "HR",
          "Operations",
          "Entrepreneurship",
          "Supply Chain",
        ],
      },
      {
        name: "MBBS",
        specializations: ["General Medicine"],
      },
      {
        name: "B.Sc.",
        specializations: [
          "Physics",
          "Chemistry",
          "Mathematics",
          "Biology",
          "Biotechnology",
          "Environmental Science",
          "Statistics",
          "Computer Science",
        ],
      },
      {
        name: "B.Com",
        specializations: [
          "General",
          "Accounting",
          "Finance",
          "Banking",
          "Management",
          "Business Administration",
        ],
      },
      {
        name: "BCA",
        specializations: [
          "Computer Applications",
          "Software Engineering",
          "Networking",
          "Database Management",
        ],
      },
      {
        name: "B.Design",
        specializations: [
          "Fashion Design",
          "Interior Design",
          "Graphic Design",
          "Textile Design",
        ],
      },
      {
        name: "B.Pharma",
        specializations: ["Pharmacy", "Pharmaceutical Sciences"],
      },
      {
        name: "B.Arch",
        specializations: ["Architecture", "Urban Planning"],
      },
      {
        name: "LLB",
        specializations: ["Law", "Corporate Law", "Criminal Law"],
      },
    ],
  },
  masters: {
    courses: [
      {
        name: "M.Tech",
        specializations: [
          "Computer Science",
          "IT",
          "Mechanical",
          "Civil",
          "Electrical",
          "Electronics",
          "Chemical",
          "Aerospace",
          "Biotechnology",
          "Data Science",
          "Software Engineering",
          "Information Security",
        ],
      },
      {
        name: "MBA",
        specializations: [
          "Marketing",
          "Finance",
          "HR",
          "Operations",
          "International Business",
          "Entrepreneurship",
          "Supply Chain",
          "Business Analytics",
          "Digital Marketing",
        ],
      },
      {
        name: "M.A.",
        specializations: [
          "English Literature",
          "History",
          "Sociology",
          "Political Science",
          "Economics",
          "Psychology",
          "Journalism and Mass Communication",
          "Public Administration",
          "Cultural Studies",
          "Linguistics",
        ],
      },
      {
        name: "M.Sc.",
        specializations: [
          "Physics",
          "Chemistry",
          "Mathematics",
          "Biology",
          "Biotechnology",
          "Environmental Science",
          "Statistics",
          "Computer Science",
          "Data Science",
        ],
      },
      {
        name: "M.Com",
        specializations: ["Accountancy", "Finance", "Business Management"],
      },
      {
        name: "MCA",
        specializations: [
          "Software Development",
          "Networking",
          "Database Management",
          "Cloud Computing",
        ],
      },
      {
        name: "M.Design",
        specializations: ["Industrial Design", "Graphic Design"],
      },
      {
        name: "M.Pharma",
        specializations: ["Pharmacy", "Pharmaceutical Sciences"],
      },
      {
        name: "M.Arch",
        specializations: ["Architecture", "Urban Design"],
      },
      {
        name: "LLM",
        specializations: ["Corporate Law", "International Law", "Criminal Law"],
      },
    ],
  },
  doctorate: {
    courses: [
      {
        name: "PhD in Engineering",
        specializations: [
          "Computer Science",
          "Mechanical",
          "Civil",
          "Electrical",
          "Electronics",
          "Chemical",
          "Aerospace",
          "Biotechnology",
          "Data Science",
        ],
      },
      {
        name: "PhD in Business and Management",
        specializations: [
          "Marketing",
          "Finance",
          "HR Management",
          "Organizational Behavior",
          "Supply Chain Management",
        ],
      },
      {
        name: "PhD in Humanities",
        specializations: [
          "English Literature",
          "History",
          "Sociology",
          "Political Science",
          "Economics",
          "Cultural Studies",
        ],
      },
      {
        name: "PhD in Sciences",
        specializations: [
          "Physics",
          "Chemistry",
          "Biology",
          "Mathematics",
          "Environmental Science",
        ],
      },
      {
        name: "PhD in Law",
        specializations: ["Corporate Law", "International Law", "Criminal Law"],
      },
    ],
  },
};

ChartJS.register(ArcElement, Tooltip, Legend);

const LetralMyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(
    (state) => state.letralHiring.letralHiringUserData
  );
  const [updatedData, setUpdatedData] = useState([]);
  const [letralUserData, setLetralUserData] = useState([]);
  const [profileImage, setProfileImage] = useState(
    updatedData?.student_photo_url || ""
  );
  const [profileCompletion, setProfileCompletion] = useState("");
  const [resumeFile, setResumeFile] = useState(null);
  const [submittedData, setSubmittedData] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditModelOpen, setIsEditModelOpen] = useState(false);
  const [isSubmittedQualification, setIsSubmittedQualification] =
    useState(false);
  const [isEditModelOpenQualification, setIsEditModelOpenQualification] =
    useState(false);
  const [isSubmittedCertification, setIsSubmittedCertification] =
    useState(false);
  const [isQualificationCancel, setIsQualificationCancel] = useState(false);
  const [isEmployementCancel, setIsEmployementCancel] = useState(false);
  const [isCertifiationCancel, setIsCertifiationCancel] = useState(false)
  const [isEditModelOpenCertification, setIsEditModelOpenCertification] =
    useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [isSubmittedSkill, setIsSubmittedSkill] = useState(false);
  const [isEditModelOpenSkill, setIsEditModelOpenSkill] = useState(false);
  const [isSubmittedEmployment, setisSubmittedEmployment] = useState(false);
  const [isEditModelOpenEmployment, setIsEditModelOpenEmployment] =
    useState(false);
  const [isCurrentEmployment, setIsCurrentEmployment] = useState("Yes");
  const [employmentType, setEmploymentType] = useState("Full Time");
  const [selectedDegree, setSelectedDegree] = useState("");
  const [availableCourses, setAvailableCourses] = useState([]);
  const [availableSpecializations, setAvailableSpecializations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [institutes, setInstitutes] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [editingValue, setEditingValue] = useState([]);
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [isAlreadySocialData, setIsAlreadySocialData] = useState(false);
  const [isSocialMediaOpenModel, setIsSocialMediaOpenModel] = useState(false)
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [isLoadingPersonal, setIsLoadingPersonal] = useState(false);
  const [isLoadingSocial, setIsLoadingSocial] = useState(false);
  const [isLoadingQualification, setIsLoadingQualification] = useState(false);
  const [isLoadingEmployement, setIsLoadingEmployement] = useState(false);
  const [isLoadingCertification, setIsLoadingCertification] = useState(false)
  const [editSocialMediaData, setEditSocialMediaData] = useState({
    instagram: '',
    twitter: '',
    linkedin: ''
  });
  const [bioError, setBioError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appLoading, setAppLoading] = useState(false)
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const suggestionsRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchProfileCompletion = async () => {
    try {
      const accessToken = sessionStorage.getItem("accessToken");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/profile_completion/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Pass the token in the Authorization header
          },
        }
      );

      const completionValue = parseInt(
        response.data.profile_completion_percentage.replace("%", "")
      ); // Get value as integer
      setProfileCompletion(completionValue);
    } catch (error) {
      console.error("Error fetching profile completion:", error);
    }
  };

  useEffect(() => {
    fetchProfileCompletion();
  }, []);

  const remainingCompletion = 100 - (profileCompletion || 0);

  useEffect(() => {
    const fetchInstitutes = async () => {
      try {
        const queryParam = searchTerm ? searchTerm : "";
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/college-data/?search=${queryParam}`
        );
        if (response.data && response.data.results) {
          setInstitutes(response.data.results);
        }
      } catch (error) {
        console.error("Error fetching institutes:", error);
        setInstitutes([]);
      }
    };

    fetchInstitutes();
  }, [searchTerm]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleInputChangeCollage = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const matchingInstitute = institutes.find(
      (institute) => institute.college_name.toLowerCase() === value.toLowerCase()
    );

    if (matchingInstitute) {
      setFormData({
        ...formData,
        institute: matchingInstitute.college_name,
      });
    } else {
      setFormData({
        ...formData,
        institute: value,
      });
    }
  }

  const handleInputEditCollageChange = (field, value) => {
    setSearchTerm(value);

    const matchingInstitute = institutes.find(
      (institute) => institute.college_name.toLowerCase() === value.toLowerCase()
    );

    if (!initialValuesSet[field]) {
      setInitialValuesSet((prev) => ({ ...prev, [field]: true }));
    }

    if (matchingInstitute) {
      setFormData({
        ...formData,
        [field]: matchingInstitute.college_name,
      });
    } else {
      setFormData({
        ...formData,
        [field]: value,
      });
    }

    setErrors({ ...errors, [field]: "" });
  };

  const handleBlur = () => {
    if (!formData.institute) {
      setSearchTerm("");
    }
  };

  useEffect(() => {
    setProfileImage(updatedData?.student_photo_url || null);
    setResumeFile(updatedData?.studentlateral_resume_url || null);
  }, [updatedData]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    currentLocation: "",
    designation: "",
    bio: "",
    preferredLocation: "",
    homeState: "",
    homeCity: "",
    experiencedOrFresher: "",
    is_experienced: "",
    experience: "",
    noticePeriod: "",
    annualSalary: "",
    expectedSalary: "",
    degree: "",
    institute: "",
    courseName: "",
    courseType: "",
    startingYear: "",
    endingYear: "",
    school: "",
    board: "",
    passingYear: "",

    instagram: "",
    twitter: "",
    linkedin: "",

    currentCompanyName: "",
    currentlyWorking: "",
    currentjoiningYear: "",
    currentjoiningMonth: "",
    currentjobTitle: "",
    currentSalary: "",
    EmploymentType: "",
    previousCompanyName: "",
    previousjoiningYear: "",
    previousjoiningMonth: "",
    previousjobTitle: "",
    previousTillYear: "",
    previousTillMonth: "",

    certificateCourseName: "",
    certificateInstitute: "",
    certificateType: "",
    specialization: "",
    certificateStartingYear: "",
    certificateEndingYear: "",
    certificateValidity: "",
    skills: "",
    qualifications: [],
    employments: [],
    certificates: [],
  });

  const [phoneError, setPhoneError] = useState(false)

  const [initialValuesSet, setInitialValuesSet] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    dob: false,
    gender: false,
    currentLocation: false,
    designation: false,
    bio: false,
    preferredLocation: false,
    homeState: false,
    homeCity: false,
    experiencedOrFresher: false,
    is_experienced: false,
    experience: false,
    noticePeriod: false,
    annualSalary: false,
    expectedSalary: false,
    degree: false,
    institute: false,
    courseName: false,
    courseType: false,
    startingYear: false,
    endingYear: false,
    school: false,
    board: false,
    passingYear: false,

    instagram: false,
    twitter: false,
    linkedin: false,

    currentCompanyName: false,
    currentlyWorking: false,
    currentjoiningYear: false,
    currentjoiningMonth: false,
    currentjobTitle: false,
    currentSalary: false,
    EmploymentType: false,
    previousCompanyName: false,
    previousjoiningYear: false,
    previousjoiningMonth: false,
    previousjobTitle: false,
    previousTillYear: false,
    previousTillMonth: false,

    certificateCourseName: false,
    certificateInstitute: false,
    certificateType: false,
    specialization: false,
    certificateStartingYear: false,
    certificateEndingYear: false,
    certificateValidity: false,
    skills: false,
    qualifications: [],
    employments: [],
    certificates: [],
  });

  const validate = () => {
    let tempErrors = {};
    let isValid = true;

    // if (!formData.phone) {
    //     tempErrors.phone = "Phone number is required";
    //     isValid = false;
    // }
    if (formData.phone && formData.phone.length < 10) {
      tempErrors.phone = "Phone number must be at least 10 digits";
      isValid = false;
    }
    if (!formData.dob) {
      tempErrors.dob = "Date of Birth is required";
      isValid = false;
    }
    if (!formData.gender) {
      tempErrors.gender = "Gender is required";
      isValid = false;
    }
    if (!formData.currentLocation) {
      tempErrors.currentLocation = "Current Location is required";
      isValid = false;
    }
    if (!formData.designation) {
      tempErrors.designation = "Designation is required";
      isValid = false;
    }
    if (!formData.bio) {
      tempErrors.bio = "Bio is required";
      isValid = false;
    }
    if (formData.bio.trim().split(/\s+/).length > 100) {
      tempErrors.bio = "Bio should not exceed 100 words";
      isValid = false;
    }
    if (!formData.preferredLocation) {
      tempErrors.preferredLocation = "Preferred Location is required";
      isValid = false;
    }
    if (!formData.homeState) {
      tempErrors.homeState = "Home State is required";
      isValid = false;
    }
    if (!formData.homeCity) {
      tempErrors.homeCity = "Home City is required";
      isValid = false;
    }
    if (formData.experiencedOrFresher === "experienced") {
      if (!formData.experience) {
        tempErrors.experience = "Experience is required";
        isValid = false;
      }
      if (!formData.noticePeriod) {
        tempErrors.noticePeriod = "Notice Period is required";
        isValid = false;
      }
      if (!formData.annualSalary) {
        tempErrors.annualSalary = "Annual Salary is required";
        isValid = false;
      }
    }
    if (!formData.expectedSalary) {
      tempErrors.expectedSalary = "Expected Salary is required";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const validateQualification = () => {
    const newErrors = {};

    if (!formData.degree) {
      newErrors.degree = "Degree is required";
    }
    if (
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate") &&
      !formData.institute
    ) {
      newErrors.institute = "Institute is required";
    }
    if (
      !formData.courseName &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.courseName = "Course Name is required";
    }
    if (
      !formData.courseType &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.courseType = "Course Type is required";
    }
    if (
      !formData.specialization &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.specialization = "Specialization is required";
    }
    if (
      !formData.startingYear &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.startingYear = "Starting Year is required";
    }
    if (
      !formData.endingYear &&
      (formData.degree === "bachelors" ||
        formData.degree === "masters" ||
        formData.degree === "doctorate")
    ) {
      newErrors.endingYear = "Ending Year is required";
    }
    if (
      formData.startingYear &&
      formData.endingYear &&
      Number(formData.endingYear) <= Number(formData.startingYear)
    ) {
      newErrors.endingYear =
        "Ending Year must be greater than Starting Year";
    }
    if (
      !formData.school &&
      (formData.degree === "10" || formData.degree === "12")
    ) {
      newErrors.school = "School Name is required";
    }
    if (
      !formData.board &&
      (formData.degree === "10" || formData.degree === "12")
    ) {
      newErrors.board = "Board is required";
    }
    if (
      !formData.passingYear &&
      (formData.degree === "10" || formData.degree === "12")
    ) {
      newErrors.passingYear = "Passing Year is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateEmployment = () => {
    const newErrors = {};

    if (isCurrentEmployment === "Yes") {
      if (!formData.currentCompanyName) {
        newErrors.currentCompanyName = "Current company name is required";
      }
      if (!formData.noticePeriod) {
        newErrors.noticePeriod = "Notice period is required";
      }
      if (!formData.currentjoiningYear) {
        newErrors.currentjoiningYear = "Joining year is required";
      }
      if (!formData.currentjoiningMonth) {
        newErrors.currentjoiningMonth = "Joining month is required";
      }
      if (!formData.currentjobTitle) {
        newErrors.currentjobTitle = "Job title is required";
      }
      if (!formData.currentSalary) {
        newErrors.currentSalary = "Current salary is required";
      }
      if (formData.currentjoiningYear && formData.currentjoiningMonth) {
        const monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];

        const joiningMonth = monthNames.indexOf(formData.currentjoiningMonth);
        const joiningYear = Number(formData.currentjoiningYear);

        if (joiningMonth === -1) {
          return;
        }

        if (isNaN(joiningYear)) {
          console.log('Invalid Year values');
          return;
        }

        const joiningDate = new Date(joiningYear, joiningMonth);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        if (
          joiningYear > currentYear ||
          (joiningYear === currentYear && joiningMonth > currentMonth)
        ) {
          newErrors.currentjoiningYear = "Joining date cannot be in the future";
          toast.error("Joining date cannot be in the future");
        }
      }
    }

    if (isCurrentEmployment === "No") {
      if (!formData.previousCompanyName) {
        newErrors.previousCompanyName = "Previous company name is required";
      }
      if (!formData.previousjoiningYear) {
        newErrors.previousjoiningYear = "Joining year is required";
      }
      if (!formData.previousjoiningMonth) {
        newErrors.previousjoiningMonth = "Joining month is required";
      }
      if (!formData.previousjobTitle) {
        newErrors.previousjobTitle = "Job title is required";
      }
      if (!formData.previousTillYear) {
        newErrors.previousTillYear = "End year is required";
      }
      if (!formData.previousTillMonth) {
        newErrors.previousTillMonth = "End month is required";
      }
      if (
        formData.previousjoiningYear &&
        formData.previousjoiningMonth &&
        formData.previousTillYear &&
        formData.previousTillMonth
      ) {
        const monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];

        const joiningMonth = monthNames.indexOf(formData.previousjoiningMonth);
        const endingMonth = monthNames.indexOf(formData.previousTillMonth);

        if (joiningMonth === -1 || endingMonth === -1) {
          return;
        }

        const joiningYear = Number(formData.previousjoiningYear);
        const endingYear = Number(formData.previousTillYear);

        if (
          isNaN(joiningYear) || isNaN(endingYear)
        ) {
          console.log('Invalid Year values');
          return;
        }

        const joiningDate = new Date(joiningYear, joiningMonth);
        const endingDate = new Date(endingYear, endingMonth);

        if (endingDate <= joiningDate) {
          newErrors.previousTillYear =
            "Ending date must be greater than Joining date";
        }

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        if (
          joiningYear > currentYear ||
          (joiningYear === currentYear && joiningMonth > currentMonth)
        ) {
          newErrors.previousjoiningYear = "Joining date cannot be in the future";
        }

        if (
          endingYear > currentYear ||
          (endingYear === currentYear && endingMonth > currentMonth)
        ) {
          newErrors.previousTillYear = "End date cannot be in the future";
        }

      }

      // if (
      //   formData.previousjoiningYear &&
      //   formData.previousTillYear &&
      //   Number(formData.previousTillYear) <= Number(formData.previousjoiningYear)
      // ) {
      //   newErrors.previousTillYear =
      //     "Ending Year must be greater than Joining Year";
      // }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const hanldeAddQualification = async (e) => {
    e.preventDefault();

    const isValid = validateQualification();

    if (!isValid) {
      return;
    }

    let instituteValue = formData.institute;
    if (formData.degree === "12" || formData.degree === "10") {
      instituteValue = formData.school;
    }

    let fromDate = null;
    let toDate = null;
    let passingYear = null;
    let boardValue = null;

    if (formData.degree === "12" || formData.degree === "10") {
      passingYear = formatYearToDate(formData.passingYear);
      boardValue = formData.board;
    } else {
      fromDate = formatYearToDate(formData.startingYear);
      toDate = formatYearToDate(formData.endingYear);
      boardValue = null;
      passingYear = null;
    }

    const newQualification = {
      institute: instituteValue,
      school_name: instituteValue,
      degree: formData.degree,
      course_type: formData.courseType || "fulltime",
      course_name: formData.courseName || null,
      course_specification: formData.specialization || null,
      board: boardValue,
      passing_year: passingYear,
      from_date: fromDate,
      to_date: toDate,
    };

    const previousQualification = updatedData?.qualifications || [];
    const mergedQualification = [...previousQualification, newQualification];

    const payload = {
      qualifications: mergedQualification,
    };

    const accessToken = sessionStorage.getItem("accessToken");

    const userSessionData = sessionStorage.getItem("userDetails");

    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      setIsLoadingQualification(true)
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsSubmittedQualification(true);
        setUpdatedData(response.data);
        toast.success("Added Successfully");
        fetchProfileCompletion();
        setFormData({
          degree: "",
          institute: "",
          courseName: "",
          courseType: "",
          specialization: "",
          startingYear: "",
          endingYear: "",
          school: "",
          board: "",
          passingYear: "",
        });
        setIsQualificationCancel(true)
      }
    } catch (error) {
      toast.error('Something Went Wrong');
    } finally {
      setIsLoadingQualification(false)
    }
  };

  const addNewQualification = () => {
    setIsEditModelOpenQualification(false);
    setIsSubmittedQualification(false);
  };

  const handleAddNewEmployment = () => {
    setisSubmittedEmployment(false);
    setIsEditModelOpenEmployment(false);
  };

  const handleEditEmploymentDetails = (value, event) => {
    event.preventDefault();

    let editedEmployment = {};

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // 0-based index for months (Jan = 0)
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    if (formData.previousjoiningYear || formData.previousTillYear || formData.previousjoiningMonth || formData.previousTillMonth) {
      const startingYear = parseInt(formData.previousjoiningYear || value?.joining_year, 10);
      const startingMonth = formData.previousjoiningMonth
        ? monthNames.indexOf(formData.previousjoiningMonth)
        : monthNames.indexOf(value?.joining_month);
      const endingYear = parseInt(formData.previousTillYear || value?.worked_till_year, 10);
      const endingMonth = formData.previousTillMonth
        ? monthNames.indexOf(formData.previousTillMonth)
        : monthNames.indexOf(value?.worked_till_month);

      // Validate if ending year/month is before starting year/month
      if (endingYear < startingYear || (endingYear === startingYear && endingMonth < startingMonth)) {
        toast.error("Ending date cannot be earlier than starting date");
        return;
      }

      // Validate if ending date is in the future
      if (
        endingYear > currentYear ||
        (endingYear === currentYear && endingMonth > currentMonth)
      ) {
        toast.error("Ending date cannot be in the future");
        return;
      }
    }

    if (formData.currentjoiningYear || formData.currentjoiningMonth) {

      const joiningMonth = monthNames.indexOf(formData.currentjoiningMonth || value?.joining_month);
      const joiningYear = parseInt(formData.currentjoiningYear || value?.joining_year, 10);

      // Validate if joining date is in the future
      if (
        joiningYear > currentYear ||
        (joiningYear === currentYear && joiningMonth > currentMonth)
      ) {
        toast.error("Joining date cannot be in the future");
        return;
      }
    }

    if (formData.currentCompanyName) {
      editedEmployment.current_company_name = formData.currentCompanyName;
    }

    if (formData.previousCompanyName) {
      editedEmployment.current_company_name = formData.previousCompanyName;
    }

    if (formData.currentjobTitle) {
      editedEmployment.current_job_title = formData.currentjobTitle;
    }

    if (formData.previousjobTitle) {
      editedEmployment.current_job_title = formData.previousjobTitle;
    }

    if (formData.currentjoiningYear) {
      editedEmployment.joining_year = formData.currentjoiningYear;
    }

    if (formData.currentjoiningMonth) {
      editedEmployment.joining_month = formData.currentjoiningMonth;
    }

    if (formData.currentSalary) {
      editedEmployment.current_salary = formData.currentSalary;
    }

    if (formData.previousTillYear) {
      editedEmployment.worked_till_year = formData.previousTillYear;
    }

    if (formData.previousjoiningYear) {
      editedEmployment.joining_year = formData.previousjoiningYear;
    }

    if (formData.previousjoiningMonth) {
      editedEmployment.joining_month = formData.previousjoiningMonth;
    }

    if (formData.previousTillMonth) {
      editedEmployment.worked_till_month = formData.previousTillMonth;
    }

    if (formData.noticePeriod) {
      editedEmployment.employment_notice_period = formData.noticePeriod;
    }

    if (formData.department) {
      editedEmployment.department = formData.department;
    }

    if (formData.location) {
      editedEmployment.location = formData.location;
    }

    if (
      isCurrentEmployment === "Yes" &&
      editingValue.is_current_employment !== true
    ) {
      editedEmployment.is_current_employment = true;
    } else if (
      isCurrentEmployment === "No" &&
      editingValue.is_current_employment !== false
    ) {
      editedEmployment.is_current_employment = false;
    }

    if (employmentType !== editingValue.employment_type) {
      editedEmployment.employment_type = employmentType;
    }

    if (Object.keys(editedEmployment).length === 0) {
      toast.info("No changes detected.");
      return;
    }

    const accessToken = sessionStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/employment/${value.id}/`;

    setIsLoadingEmployement(true)
    axios
      .put(url, editedEmployment, config)
      .then((response) => {

        fetchData();

        setFormData({
          currentCompanyName: "",
          previousCompanyName: "",
          previousjobTitle: "",
          previousjoiningMonth: "",
          previousjoiningYear: "",
          currentjobTitle: "",
          currentjoiningYear: "",
          currentjoiningMonth: "",
          currentSalary: "",
          noticePeriod: "",
          previousTillYear: "",
          previousTillMonth: "",
          currentlyWorking: "",
        });

        setInitialValuesSet({
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          dob: null,
          gender: null,
          currentLocation: null,
          designation: null,
          bio: null,
          preferredLocation: null,
          homeState: null,
          homeCity: null,
          experiencedOrFresher: null,
          is_experienced: null,
          experience: null,
          noticePeriod: null,
          annualSalary: null,
          expectedSalary: null,
          degree: null,
          institute: null,
          courseName: null,
          courseType: null,
          startingYear: null,
          endingYear: null,
          school: null,
          board: null,
          passingYear: null,

          instagram: null,
          twitter: null,
          linkedin: null,

          currentCompanyName: null,
          currentlyWorking: null,
          currentjoiningYear: null,
          currentjoiningMonth: null,
          currentjobTitle: null,
          currentSalary: null,
          EmploymentType: null,
          previousCompanyName: null,
          previousjoiningYear: null,
          previousjoiningMonth: null,
          previousjobTitle: null,
          previousTillYear: null,
          previousTillMonth: null,

          certificateCourseName: null,
          certificateInstitute: null,
          certificateType: null,
          specialization: null,
          certificateStartingYear: null,
          certificateEndingYear: null,
          certificateValidity: null,
          skills: null,
          qualifications: [],
          employments: [],
          certificates: [],
        });

        toast.success("Employment record updated successfully");
        fetchProfileCompletion();
        setisSubmittedEmployment(true);
        setIsEditModelOpenEmployment(false);
        setIsLoadingEmployement(false)
      })
      .catch((error) => {
        setIsLoadingEmployement(false)
        toast.error(
          error.response?.data?.message || "Error updating employment details"
        );
      });
  };

  const handleSubmitEmployment = async (e) => {
    e.preventDefault();

    if (validateEmployment()) {
      const accessToken = sessionStorage.getItem("accessToken");
      const userSessionData = sessionStorage.getItem("userDetails");
      const parsedData = JSON.parse(userSessionData);
      const userId = parsedData.id;

      const newEmployment = {
        is_current_employment: isCurrentEmployment === "Yes",
        employment_type: employmentType,
        current_company_name:
          isCurrentEmployment === "Yes"
            ? formData.currentCompanyName
            : formData.previousCompanyName,
        current_job_title:
          isCurrentEmployment === "Yes"
            ? formData.currentjobTitle
            : formData.previousjobTitle,
        joining_year:
          isCurrentEmployment === "Yes"
            ? formData.currentjoiningYear
            : formData.previousjoiningYear,
        joining_month:
          isCurrentEmployment === "Yes"
            ? formData.currentjoiningMonth
            : formData.previousjoiningMonth,
        current_salary:
          isCurrentEmployment === "Yes" ? formData.currentSalary : null,
        employment_notice_period:
          isCurrentEmployment === "Yes" ? formData.noticePeriod : null,
        worked_till_year:
          isCurrentEmployment === "No" ? formData.previousTillYear : null,
        worked_till_month:
          isCurrentEmployment === "No" ? formData.previousTillMonth : null,
        location: formData.location,
        department: formData.department,
      };

      const previousEmployments = updatedData?.employments || [];
      const mergedEmployment = [...previousEmployments, newEmployment];

      const payload = {
        employments: mergedEmployment,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      try {
        setIsLoadingEmployement(true)
        const response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
          payload,
          config
        );

        toast.success("Added Successfully");

        setUpdatedData(response.data);

        setisSubmittedEmployment(true);
        setIsEditModelOpenEmployment(false);
        fetchProfileCompletion();
        setIsEmployementCancel(true);
        setFormData({
          currentCompanyName: "",
          currentjobTitle: "",
          currentjoiningYear: "",
          currentjoiningMonth: "",
          currentSalary: "",
          noticePeriod: "",
          previousTillYear: "",
          previousTillMonth: "",
          currentlyWorking: "",
        });
      } catch (error) {
        console.error("Error in API:", error);
        toast.error(error.response?.data?.message || "Someting went wrong");
      } finally {
        setIsLoadingEmployement(false)
      }
    } else {
      console.log("Form has errors");
    }
  };

  const handleEditEmploymentCancel = () => {
    setisSubmittedEmployment(true);
    setIsEditModelOpenEmployment(false);
  };

  const [certificates, setCertificates] = useState(
    updatedData?.certificates || []
  );

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleInputChangeWithValidation = (e) => {
    const { name, value } = e.target;

    if (
      name === "firstName" &&
      value.charAt(0) !== value.charAt(0).toUpperCase()
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: "First letter should be capitalized",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: "",
      }));
    }

    // Update the form data
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // const handleInputChangeNewValidation = (e) => {
  //   const { name, value } = e.target;

  //   if (
  //     name === "firstName" &&
  //     value.charAt(0) !== value.charAt(0).toUpperCase()
  //   ) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       firstName: "First letter should be capitalized",
  //     }));
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       firstName: "",
  //     }));
  //   }

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChangeNewValidation = (field, value) => {
    if (
      field === "firstName" &&
      value.charAt(0) !== value.charAt(0).toUpperCase()
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: "First letter should be capitalized",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: "",
      }));
    }
    if (!initialValuesSet[field]) {
      setInitialValuesSet((prev) => ({ ...prev, [field]: true }));
    }
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleInputChangeLastNameValidation = (field, value) => {
    if (
      field === "lastName" &&
      value.charAt(0) !== value.charAt(0).toUpperCase()
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lastName: "First letter should be capitalized",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lastName: "",
      }));
    }
    if (!initialValuesSet[field]) {
      setInitialValuesSet((prev) => ({ ...prev, [field]: true }));
    }
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const formatSalary = (value) => {
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + " Cr"; // Crores
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + " L"; // Lakhs
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + " K"; // Thousands
    }
    return value; // Less than 1000, show the original number
  };

  const handleAddSocialMedia = async (e) => {
    e.preventDefault();

    const accessToken = sessionStorage.getItem("accessToken");
    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/;

    if (!formData.instagram && !formData.twitter && !formData.linkedin) {
      toast.error("Please add at least one social media link");
      return;
    }

    if (
      (formData.instagram && !urlRegex.test(formData.instagram)) ||
      (formData.twitter && !urlRegex.test(formData.twitter)) ||
      (formData.linkedin && !urlRegex.test(formData.linkedin))
    ) {
      toast.error("Enter a valid URL");
      return;
    }

    const payload = {
      social_media_links: [
        { platform: "instagram", url: formData.instagram || '' },
        { platform: "twitter", url: formData.twitter || '' },
        { platform: "linkedin", url: formData.linkedin || '' },
      ].filter((link) => link.url) // Filters out any empty URLs
    };

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      setIsLoadingSocial(true);
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
        payload,
        config
      );

      if (response.status === 200) {
        toast.success("Added Successfully");
        setUpdatedData(response.data)
        setIsAlreadySocialData(true);
        setIsSocialMediaOpenModel(false);
        setShowSocialMedia(true);
      }
    } catch (error) {
      console.error("Error in API:", error);
    } finally {
      setIsLoadingSocial(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^[0-9]{10}$/; // Matches exactly 10 digits
    if (!phoneRegex.test(value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  const handleInputEditChange = (field, value) => {
    if (!initialValuesSet[field]) {
      setInitialValuesSet((prev) => ({ ...prev, [field]: true }));
    }
    setFormData({
      ...formData,
      [field]: value,
    });
    setErrors({ ...errors, [field]: "" });
    if (field === "phone") {
      validatePhoneNumber(value)
    }
  };

  // const handleInputBioChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === 'bio') {
  //     const wordCount = value.trim().split(/\s+/).length;

  //     if (wordCount > 100) {

  //       setBioError(true)
  //     }
  //     else {
  //       setBioError(false)
  //     }
  //   }

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });

  //   setErrors({ ...errors, [name]: "" });
  // }

  const handleInputBioEditChange = (field, value) => {
    if (field === 'bio') {
      const wordCount = value.trim().split(/\s+/).length;

      if (wordCount > 100) {
        // setErrors({
        //   ...errors,
        //   bio: "Bio should not exceed 100 words",
        // });
        setBioError(true)
      }
      else {
        setBioError(false)
      }
    }
    if (!initialValuesSet[field]) {
      setInitialValuesSet((prev) => ({ ...prev, [field]: true }));
    }
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  // const handleSkillInputChange = (e) => {
  //   const { value } = e.target;
  //   setFormData({ ...formData, skills: value });

  //   if (value) {
  //     const filtered = suggestedSkills?.filter((skill) =>
  //       skill.toLowerCase().startsWith(value.toLowerCase())
  //     );
  //     setFilteredSkills(filtered);
  //   } else {
  //     setFilteredSkills([]);
  //   }
  // };

  const handleSkillInputChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, skills: value });

    if (value) {
      const filtered = suggestedSkills
        ?.filter((skill) => skill.toLowerCase().startsWith(value.toLowerCase()))
        .filter((skill) => !selectedSkills.includes(skill));
      setFilteredSkills(filtered);
    } else {
      setFilteredSkills([]);
    }
  };

  const handleDateInputChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = value.slice(0, 4); // Ensuring only the year is entered

    setFormData({
      ...formData,
      [name]: formattedValue, // Directly setting the year
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleDateEditInputChange = (e) => {
    const { name, value } = e.target;

    const formattedValue =
      value instanceof Date
        ? value.toISOString().split("T")[0] // Format date to "yyyy-MM-dd"
        : value;

    setFormData({
      ...formData,
      [name]: formattedValue,
    });

    setErrors({ ...errors, [name]: "" });
  };

  const handleCertificateStartDateChange = (selectedDate) => {
    if (selectedDate) {
      const formattedDate = format(selectedDate, "yyyy-MM-dd");

      setFormData({
        ...formData,
        certificateStartingYear: formattedDate,
      });
      setErrors({ ...errors, certificateStartingYear: "" });
    }
  }

  const handleCertificateEndDateChange = (selectedDate) => {
    if (selectedDate) {
      const formattedDate = format(selectedDate, "yyyy-MM-dd");

      setFormData({
        ...formData,
        certificateEndingYear: formattedDate,
      });
      setErrors({ ...errors, certificateEndingYear: "" });
    }
  };

  const [errors, setErrors] = useState({});

  const fileInputRef = useRef(null);
  const resumeInputRef = useRef(null);

  const handlePersonalSubmit = async (event) => {
    event.preventDefault();

    if (!validate()) return;

    const accessToken = sessionStorage.getItem("accessToken");

    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    let isExperienced = formData.experiencedOrFresher
      ? formData.experiencedOrFresher === "experienced"
      : updatedData?.is_experienced;

    const payload = {
      first_name: formData.firstName || updatedData.first_name || "",
      last_name: formData.lastName || updatedData.last_name || "",
      phone_no: formData.phone || updatedData.phone_no || null,
      dob: formData.dob || updatedData.dob || null,
      gender: formData.gender || updatedData.gender || null,
      current_location:
        formData.currentLocation || updatedData.current_location || null,
      designation: formData.designation || updatedData.designation || null,
      bio: formData.bio || updatedData.bio || null,
      preferred_location:
        formData.preferredLocation || updatedData.preferred_location || null,
      state: formData.homeState || updatedData.state || null,
      city: formData.homeCity || updatedData.city || null,
      notice_period: formData.noticePeriod || updatedData.notice_period || null,
      experience: formData.experience || updatedData.experience || null,
      annual_salary: formData.annualSalary || updatedData.annual_salary || null,
      expected_salary:
        formData.expectedSalary || updatedData.expected_salary || null,
      is_experienced: isExperienced || updatedData?.is_experienced,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      setIsLoadingPersonal(true)
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsSubmitted(true);
        setSubmittedData(response.data);
        fetchProfileCompletion();
        toast.success("Added Successfully");
        setUpdatedData(response.data);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          dob: "",
          gender: "",
          currentLocation: "",
          designation: "",
          bio: "",
          preferredLocation: "",
          homeState: "",
          homeCity: "",
          experiencedOrFresher: "",
          experience: "",
          noticePeriod: "",
          annualSalary: "",
          expectedSalary: "",
          certificateCourseName: "",
          certificateInstitute: "",
          certificateType: "",
          specialization: "",
          certificateStartingYear: "",
          certificateEndingYear: "",
          certificateValidity: "",
          skills: "",
          qualifications: [],
          employments: [],
          certificates: [],
        });
        dispatch(fetchLoginUserDetailas());
      } else {
        toast.error(errors);
      }
    } catch (error) {
      toast.error(errors);
    }
    finally {
      setIsLoadingPersonal(false)
    }
  };

  useEffect(() => {
    if (selectedDegree) {
      // Dynamically populate available courses based on the selected degree
      setAvailableCourses(degreeOptions[selectedDegree]?.courses || []);

      // setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     //courseName: '',
      //     specialization: '',
      // }));
    }
  }, [selectedDegree]);

  useEffect(() => {
    const selectedCourse = availableCourses.find(
      (course) => course.name === formData.courseName
    );
    setAvailableSpecializations(
      selectedCourse ? selectedCourse.specializations : []
    );
  }, [formData.courseName, availableCourses]);

  // useEffect(() => {
  //   const token = sessionStorage.getItem("accessToken");

  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/api/job-skills/`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       const skills = response.data.map((skill) => skill.skill_name);
  //       setSuggestedSkills(skills);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching skills:", error);
  //     });
  // }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("accessToken");

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/job-skills/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const skills = response.data.map((skill) => skill.skill_name);

        // Filter out skills that are already in selectedSkills
        const filteredSkills = skills.filter((skill) => !selectedSkills.includes(skill));
        setSuggestedSkills(filteredSkills);
      })
      .catch((error) => {
        console.error("Error fetching skills:", error);
      });
  }, [selectedSkills]);

  const fetchData = () => {
    const accessToken = sessionStorage.getItem("accessToken");

    const userSessionData = sessionStorage.getItem("userDetails");

    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    if (!userId) {
      return;
    }

    const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`;

    setAppLoading(true)
    axios
      .get(url, config)
      .then((response) => {
        setAppLoading(false)
        setUpdatedData(response.data);
        dispatch(fetchLoginUserDetailas())
        if (response.data.certificates?.length > 0) {
          setIsSubmittedCertification(true);
          setIsCertifiationCancel(true)
        }
        if (response.data.certificates?.length < 1) {
          setIsSubmittedCertification(false);
          setIsEditModelOpenCertification(false)
          setIsCertifiationCancel(false)
        }
        if (response.data.skill_name) {
          setIsSubmittedSkill(true);
          const skillNames = response.data.skill_name
          setSelectedSkills(skillNames);
          setFormData({ ...formData, skills: '' });
        }
        if (response.data.employments?.length > 0) {
          setisSubmittedEmployment(true);
          setIsEmployementCancel(true)
        }
        if (response.data.employments?.length < 1) {
          setisSubmittedEmployment(false);
          setIsEditModelOpenEmployment(false);
          setIsEmployementCancel(false);
        }
        if (response.data.dob !== null && response.data.phone_no !== null) {
          setIsSubmitted(true);
        }
        if (response.data.qualifications?.length > 0) {
          setIsQualificationCancel(true)
          setIsSubmittedQualification(true);
        }
        if (response.data.qualifications?.length < 1) {
          setIsQualificationCancel(false)
          setIsEditModelOpenQualification(false);
          setIsSubmittedQualification(false)
        }
        if (response.data?.social_media_links?.length > 0) {
          setIsSocialMediaOpenModel(false)
          setIsAlreadySocialData(true);
          setShowSocialMedia(true);
        }
        if (response.data?.social_media_links?.length < 1) {
          setIsAlreadySocialData(false);
          setIsSocialMediaOpenModel(false);
        }

        setEditSocialMediaData({
          instagram: response.data?.social_media_links?.find(link => link.platform === 'instagram')?.url || '',
          twitter: response.data?.social_media_links?.find(link => link.platform === 'twitter')?.url || '',
          linkedin: response.data?.social_media_links?.find(link => link.platform === 'linkedin')?.url || ''
        });
        if (response.data?.designation) {
          sessionStorage.setItem('userDesignation', response?.data?.designation)
        }
        fetchProfileCompletion();
      })
      .catch((error) => {
        setAppLoading(false)
        if (error.response && error.response.status === 401) {
          navigate("/login");
          sessionStorage.clear();
        } else {
          toast.error("Failed to fetch User details. Please Login Again");
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteSkills = async (id) => {
    const token = sessionStorage.getItem("accessToken");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/job-skills/${id}/`, config)
      toast.success("Skill updated successfully");
      setFormData({ ...formData, skills: '' });
      fetchData()
      setFilteredSkills([]);
      fetchProfileCompletion();
    }
    catch (error) {
      toast.error(error)
    }

  }

  const handleSkillClick = async (skill) => {
    const isSkillSelected = selectedSkills.includes(skill);
    const updatedSkills = isSkillSelected
      ? selectedSkills.filter((s) => s !== skill)
      : [...selectedSkills, skill];

    setSelectedSkills(updatedSkills);

    setFormData({ ...formData, skills: '' });

    const payload = {
      skill_name: skill,
    };

    const token = sessionStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/job-skills/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Skill updated successfully");
      setFormData({ ...formData, skills: '' });
      fetchData()
      setFilteredSkills([]);
      fetchProfileCompletion();

    } catch (error) {
      toast.error("Error updating skill:", error);
    }
  };

  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => setProfileImage(e.target.result); // Preview the selected image
  //     reader.readAsDataURL(file);
  //     handleImageUploadSubmit();
  //   }
  // };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const MAX_FILE_SIZE = 700 * 1024;

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        toast.error("Photo should be within 700KB.");
        return;
      }

      handleImageUploadSubmit(file);
    }
  };

  const handleDeleteProfilePhoto = async () => {
    try {
      const accessToken = sessionStorage.getItem("accessToken");
      const userSessionData = sessionStorage.getItem("userDetails");

      if (!accessToken || !userSessionData) {
        toast.error("User session expired. Please log in again.");
        return;
      }

      const parsedData = JSON.parse(userSessionData);
      const userId = parsedData.id;

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const payload = {
        student_photo: null,
        student_photo_url: null,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
        payload,
        config
      );

      if (response.status === 200) {
        toast.success("Photo Deleted Successfully");
        fetchData();
      } else {
        toast.error("Failed to delete photo. Please try again.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  const handleResumeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const validExtensions = ["docx", "doc", "pdf"];
      const maxSize = 700 * 1024; // 500 KB
      const minSize = 5 * 1024;
      // const maxSize = 2 * 1024 * 1024; 
      // const minSize = 10 * 1024; 

      if (
        validExtensions.includes(fileExtension) &&
        file.size <= maxSize &&
        file.size >= minSize
      ) {
        // setResumeFile(file);
        handleSubmitResume(file);
      } else {
        let errorMessage = "Please upload a valid file (DOCX, DOC, PDF)";
        if (file.size < minSize) {
          errorMessage += " of at least 5KB.";
        } else {
          errorMessage = "Please Upload the file Within 700KB."
        }
        toast.error(errorMessage); // Display error message using Toastify
      }
    }
  };

  const handleSubmitResume = async (file) => {
    if (!file) {
      alert("Please upload a resume.");
      return;
    }

    const userSessionData = sessionStorage.getItem("userDetails");

    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    const formData = new FormData();
    formData.append("studentlateral_resume", file);


    try {
      setIsLoading(true);

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Resume uploaded successfully!");
        setUpdatedData(response.data);
        fetchProfileCompletion();
      } else {
        toast.error("Failed to upload resume.");
      }
    } catch (error) {
      toast.error("Failed to upload resume.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUploadSubmit = async () => {
    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    try {
      const file = fileInputRef.current.files[0];
      if (!file) return alert("Please select an image to upload.");

      const formData = new FormData();
      formData.append("student_photo", file);

      setLoading(true);
      setIsImageUploaded(false);

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.data) {
        setProfileImage(URL.createObjectURL(file));
        setIsImageUploaded(true);
        setUpdatedData(response.data);
        fetchProfileCompletion();
        toast.success("Added Successfully");
        dispatch(fetchLoginUserDetailas());
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const pieChartData = {
    datasets: [
      {
        data: [profileCompletion, remainingCompletion],
        backgroundColor: ["#4CAF50", "#E0E0E0"],
        borderWidth: 0,
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%", // Decrease this value to make the chart segments thicker
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
  };

  // const generateResume = () => {
  //     const element = document.getElementById('pdf-container');

  //     const originalPaddingBottom = element.style.paddingBottom;
  //     element.style.paddingBottom = '40px';

  //     const options = {
  //         filename: 'Generated-Resume.pdf',
  //         margin: [2, 0, 2, 0],
  //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  //     };

  //     html2pdf().set(options).from(element).save().then(() => {
  //         element.style.paddingBottom = originalPaddingBottom;
  //     });
  // };
  const generateResume = () => {
    const element = document.getElementById("pdf-container");

    const options = {
      margin: 0,
      filename: `Abhay-Y-K-Sharma-StartNaukri-Profile.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);

          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();
          const pageText = `Page ${i} of ${totalPages}`;

          pdf.setFontSize(10);
          const textWidth =
            (pdf.getStringUnitWidth(pageText) * pdf.internal.getFontSize()) /
            pdf.internal.scaleFactor;
          pdf.text(pageText, (pageWidth - textWidth) / 2, pageHeight - 5);

          if (i > 1) {
            const marginTop = 20;
            pdf.setPage(i);
            pdf.text(" ", 0, marginTop);
          }
        }
      })
      .save();
  };

  const handleEditClick = () => {
    setIsSubmitted(false);
    setIsEditModelOpen(!isEditModelOpen);
  };

  const handleEditSocialMedia = (link) => {
    setIsSocialMediaOpenModel(true);
    setIsAlreadySocialData(true);
    setEditingValue(link)
    setShowSocialMedia(false);
  }

  const handleEditSocialMediaSubmit = async () => {

    const accessToken = sessionStorage.getItem("accessToken");
    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    const socialMediaLinks = [
      {
        platform: "instagram",
        url: formData.instagram || updatedData.social_media_links.find(link => link.platform === "instagram")?.url || "",
      },
      {
        platform: "twitter",
        url: formData.twitter || updatedData.social_media_links.find(link => link.platform === "twitter")?.url || "",
      },
      {
        platform: "linkedin",
        url: formData.linkedin || updatedData.social_media_links.find(link => link.platform === "linkedin")?.url || "",
      }
    ].filter(link => link.url);

    const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+\/?/;

    for (const link of socialMediaLinks) {
      if (!urlRegex.test(link.url)) {
        toast.error(`Invalid URL format for ${link.platform}. Please enter a valid URL.`);
        return;
      }
    }

    const payload = {
      social_media_links: socialMediaLinks
    };

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    };

    try {
      setIsLoadingSocial(true);
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
        payload,
        config
      );

      if (response.status === 200) {
        toast.success("Social media links updated successfully");
        fetchData();
        setIsSocialMediaOpenModel(false);
        setIsAlreadySocialData(true);
        showSocialMedia(true);
        setFormData((prevFormData) => ({
          ...prevFormData,
          instagram: "",
          twitter: "",
          linkedin: "",
        }));
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoadingSocial(false);
    }
  }

  const handleEditSocialMediaCancel = () => {
    setShowSocialMedia(true);
    setIsSocialMediaOpenModel(false);
    setIsAlreadySocialData(true)
  }

  const handleDeleteSocialMedia = async (id) => {
    const accessToken = sessionStorage.getItem("accessToken");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/socialmedialinks/${id}/`,
        config
      );

      if (response.status) {
        toast.success("Deleted Successfully");
        // setIsAlreadySocialData(true);
        // setIsSocialMediaOpenModel(false)
        fetchData();
        setFormData((prevFormData) => ({
          ...prevFormData,
          instagram: "",
          twitter: "",
          linkedin: "",
        }));
      }
    } catch (error) {
      console.error("Error in API:", error);
      toast.error(error.response?.data?.message || "Failed to delete social media link");
    }
  };

  const handleEditQualification = (value) => {
    setIsSubmittedQualification(false);
    setIsEditModelOpenQualification(!isEditModelOpenQualification);
    setEditingValue(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      degree: value.degree,
      courseName: value.course_name,
      specialization: value.course_specification,
    }));
    setSelectedDegree(value.degree);
    let newvalue = degreeOptions[value.degree]?.courses.find(
      (item) => item.name === value.course_name
    );
    setAvailableSpecializations(newvalue?.specializations);

    if (!value) {
      setFormData({
        certificateCourseName: "",
        certificateInstitute: "",
        certificateType: "",
        specialization: "",
        certificateStartingYear: "",
        certificateEndingYear: "",
        certificateValidity: "",
      });
    }
  };

  const handleDeleteQualification = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this qualification?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ffa500',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/qualification/${id}/`;
        const accessToken = sessionStorage.getItem('accessToken');

        axios.delete(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
          .then(response => {
            toast.success("Qualification deleted successfully");
            fetchData()
          })
          .catch(error => {
            toast.error("Something Went Wrong");
          });
      }
    });
  };

  const handleEditNewQualification = (value, event) => {
    event.preventDefault();

    let editedQualification = {};

    if (formData.degree) {
      editedQualification.degree = formData.degree;
    }

    if (formData.institute) {
      editedQualification.institute = formData.institute;
    }

    if (formData.courseType) {
      editedQualification.course_type = formData.courseType;
    }

    if (formData.courseName) {
      editedQualification.course_name = formData.courseName;
    }

    if (formData.specialization) {
      editedQualification.course_specification = formData.specialization;
    }

    if (formData.board) {
      editedQualification.board = formData.board;
    }

    if (formData.specialization) {
      editedQualification.course_specification = formData.specialization;
    }

    if (formData.school) {
      editedQualification.school_name = formData.school;
    }

    if (formData.startingYear) {
      editedQualification.from_date = formatYearToDate(formData.startingYear);
    }

    if (formData.passingYear) {
      editedQualification.passing_year = formatYearToDate(formData.passingYear);
    }

    if (formData.endingYear) {
      editedQualification.to_date = formatYearToDate(formData.endingYear);
    }

    if (
      ((formData.startingYear || value.to_date) ||
        (formData.endingYear || value.from_date)) &&
      parseInt(formData.endingYear || value.to_date) <= parseInt(formData.startingYear || value.from_date)
    ) {
      toast.error("Ending year cannot be equal or less than the starting year");
      return;
    }

    if (Object.keys(editedQualification).length === 0) {
      toast.info("No data to update");
      return;
    }

    const accessToken = sessionStorage.getItem("accessToken");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/qualification/${value.id}/`;

    setIsLoadingQualification(true)
    axios
      .put(url, editedQualification, config)
      .then((response) => {
        toast.success("Added Successfully");
        // setUpdatedData(response.data);
        fetchData();
        fetchProfileCompletion();
        setIsEditModelOpenQualification(false);
        setIsSubmittedQualification(true);
        setIsLoadingQualification(false)

        setInitialValuesSet({
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          dob: null,
          gender: null,
          currentLocation: null,
          designation: null,
          bio: null,
          preferredLocation: null,
          homeState: null,
          homeCity: null,
          experiencedOrFresher: null,
          is_experienced: null,
          experience: null,
          noticePeriod: null,
          annualSalary: null,
          expectedSalary: null,
          degree: null,
          institute: null,
          courseName: null,
          courseType: null,
          startingYear: null,
          endingYear: null,
          school: null,
          board: null,
          passingYear: null,

          instagram: null,
          twitter: null,
          linkedin: null,

          currentCompanyName: null,
          currentlyWorking: null,
          currentjoiningYear: null,
          currentjoiningMonth: null,
          currentjobTitle: null,
          currentSalary: null,
          EmploymentType: null,
          previousCompanyName: null,
          previousjoiningYear: null,
          previousjoiningMonth: null,
          previousjobTitle: null,
          previousTillYear: null,
          previousTillMonth: null,

          certificateCourseName: null,
          certificateInstitute: null,
          certificateType: null,
          specialization: null,
          certificateStartingYear: null,
          certificateEndingYear: null,
          certificateValidity: null,
          skills: null,
          qualifications: [],
          employments: [],
          certificates: [],
        });

      })
      .catch((error) => {
        toast.error(error);
        setIsLoadingQualification(false)
      });
  };

  const handleEditCertification = (value) => {
    setIsSubmittedCertification(false);
    setIsEditModelOpenCertification(!isEditModelOpenCertification);
    setEditingValue(value);
    // setFormData({
    //   certificateCourseName: "",
    //   certificateInstitute: "",
    //   certificateType: "",
    //   specialization: "",
    //   certificateStartingYear: "",
    //   certificateEndingYear: "",
    //   certificateValidity: "",
    // });
  };

  const handleDeleteCertification = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this qualification?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ffa500',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/certification/${id}/`;
        const accessToken = sessionStorage.getItem('accessToken');

        axios.delete(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
          .then(response => {
            toast.success("Certification deleted successfully");
            fetchData()
          })
          .catch(error => {
            toast.error("Something Went Wrong");
          });
      }
    });
  }

  const [editedCourseName, setEditedCourseName] = useState(
    editingValue.course_name || ""
  );

  const handleEditSkill = () => {
    setIsSubmittedSkill(false);
    setIsEditModelOpenSkill(!isEditModelOpenSkill);
  };

  const handleEditEmployment = (value) => {
    setEditingValue(value);
    setisSubmittedEmployment(false);
    setIsCurrentEmployment(value.is_current_employment ? "Yes" : "No");
    setEmploymentType(value.employment_type);
    setIsEditModelOpenEmployment(true);
    setFormData({
      currentCompanyName: "",
      currentjobTitle: "",
      currentjoiningYear: "",
      currentjoiningMonth: "",
      currentSalary: "",
      noticePeriod: "",
      previousTillYear: "",
      previousTillMonth: "",
      currentlyWorking: "",
    });
  };

  useEffect(() => {
    if (editingValue?.employment_type) {
      setEmploymentType(editingValue.employment_type);
    }
  }, [editingValue]);

  const handleDeleteEmployment = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this qualification?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ffa500',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/employment/${id}/`;
        const accessToken = sessionStorage.getItem('accessToken');

        axios.delete(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
          .then(response => {
            toast.success("Certification deleted successfully");
            fetchData()
          })
          .catch(error => {
            toast.error("Something Went Wrong");
          });
      }
    });
  }

  const handleEditCancel = () => {
    setIsSubmitted(true);
    setIsEditModelOpen(false);
  };

  const handleEditQualificationCancel = () => {
    setIsSubmittedQualification(true);
    setIsEditModelOpenQualification(false);
  };

  const handleEditCertificationCancel = () => {
    setIsSubmittedCertification(true);
    setIsEditModelOpenCertification(false);
  };

  const handleEditSkillCancel = () => {
    setIsSubmittedSkill(true);
    setIsEditModelOpenSkill(false);
  };

  // useEffect(() => {
  //   if (updatedData?.skill_name?.length > 0) {
  //     const skillsArray = updatedData.skill_name.map(
  //       (skill) => skill.skill_name
  //     );
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       skills: skillsArray.join(", "),
  //     }));
  //     setSelectedSkills(skillsArray);
  //   }
  // }, [updatedData]);

  const editPersonalDetails = async (event) => {
    event.preventDefault();

    if (bioError === true || phoneError === true) {
      return;
    }

    const accessToken = sessionStorage.getItem("accessToken");
    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    let isExperienced =
      formData.experiencedOrFresher === "experienced" ? true : false;

    const payload = {
      first_name: formData.firstName || updatedData.first_name || "",
      last_name: formData.lastName || updatedData.last_name || "",
      phone_no: formData.phone || updatedData.phone_no || null,
      dob: formData.dob || updatedData.dob || null,
      gender: formData.gender || updatedData?.gender || null,
      current_location:
        formData.currentLocation || updatedData.current_location || null,
      designation: formData.designation || updatedData.designation || null,
      bio: formData.bio || updatedData.bio || null,
      preferred_location:
        formData.preferredLocation || updatedData.preferred_location || null,
      state: formData.homeState || updatedData.state || null,
      city: formData.homeCity || updatedData.city || null,
      notice_period: formData.noticePeriod || updatedData.notice_period || null,
      experience: formData.experience || updatedData.experience || null,
      annual_salary: formData.annualSalary || updatedData.annual_salary || null,
      expected_salary:
        formData.expectedSalary || updatedData.expected_salary || null,
      is_experienced: isExperienced || updatedData?.is_experienced,
    };

    if (Object.keys(payload).length === 0) {
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      setIsLoadingPersonal(true);

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsSubmitted(true);
        setSubmittedData(response.data);
        fetchProfileCompletion();
        setIsEditModelOpen(false);
        setIsSubmitted(true);
        toast.success("Added Successfully");
        // setFormData({
        //   firstName: "",
        //   lastName: "",
        //   email: "",
        //   phone: "",
        //   dob: "",
        //   gender: "",
        //   currentLocation: "",
        //   designation: "",
        //   bio: "",
        //   preferredLocation: "",
        //   homeState: "",
        //   homeCity: "",
        //   experiencedOrFresher: "",
        //   experience: "",
        //   noticePeriod: "",
        //   annualSalary: "",
        //   expectedSalary: "",
        // });
        setUpdatedData(response.data);
        if (response?.data?.designation) {
          sessionStorage.setItem('userDesignation', response?.data?.designation)
        }
        dispatch(fetchLoginUserDetailas())
      } else {
        toast.error(errors);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoadingPersonal(false);
    }
  };

  const formatYearToDate = (year) => (year ? `${year}-01-01` : "");

  const submitCertification = (data) => {
    setCertificates(data.certificates);
    const accessToken = sessionStorage.getItem("accessToken");
    const userSessionData = sessionStorage.getItem("userDetails");

    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`;
    setIsLoadingCertification(true);
    axios
      .put(url, data, config)
      .then((response) => {
        toast.success("Added Successfully");
        setUpdatedData(response.data);
        fetchProfileCompletion();
        setIsCertifiationCancel(true);
        setIsLoadingCertification(false);
      })
      .catch((error) => {
        toast.error(error);
        setIsLoadingCertification(false);
      });
  };

  const handleEditCertificationDeatils = (value, event) => {
    event.preventDefault();

    let editedCertificate = {};

    const startingDate = formData.certificateStartingYear
      ? new Date(formData.certificateStartingYear)
      : value.course_from
        ? new Date(value.course_from)
        : null;

    const endingDate = formData.certificateEndingYear
      ? new Date(formData.certificateEndingYear)
      : value.course_to
        ? new Date(value.course_to)
        : null;

    if (startingDate && endingDate && endingDate < startingDate) {
      toast.error("Ending date cannot be before starting date");
      return;
    }

    const currentYear = new Date().getFullYear();
    if (formData.certificateValidity) {
      const validityYear = new Date(formData.certificateValidity).getFullYear();
      if (validityYear < currentYear) {
        toast.error("Certificate validity cannot be less than the current year");
        return;
      }
    }

    if (formData.certificateCourseName) {
      editedCertificate.course_name = formData.certificateCourseName;
    }

    if (formData.certificateInstitute) {
      editedCertificate.course_institute = formData.certificateInstitute;
    }

    if (formData.certificateType) {
      editedCertificate.course_type = formData.certificateType;
    }

    if (formData.certificateStartingYear) {
      editedCertificate.course_from = formatToYYYYMMDD(
        formData.certificateStartingYear
      );
    }

    if (formData.certificateEndingYear) {
      editedCertificate.course_to = formatToYYYYMMDD(
        formData.certificateEndingYear
      );
    }

    if (formData.certificateValidity) {
      editedCertificate.valid_till = formatToYYYYMMDD(
        formData.certificateValidity
      );
    }

    editedCertificate.is_expired = false;

    if (Object.keys(editedCertificate).length === 0) {
      toast.info("No data to update");
      return;
    }

    const accessToken = sessionStorage.getItem("accessToken");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/certification/${value.id}/`;
    setIsLoadingCertification(true)
    axios
      .put(url, editedCertificate, config)
      .then((response) => {
        toast.success("Updated Successfully");
        fetchData();
        fetchProfileCompletion();
        setIsEditModelOpenCertification(false);
        setIsSubmittedCertification(true);
        setIsLoadingCertification(false)
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Something went wrong");
        setIsLoadingCertification(false)
      });
  };

  const formatToYYYYMMDD = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date); // Ensure the input is a Date object
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  // const handleEditCertificationDeatils = (value, event) => {
  //   event.preventDefault();

  //   let editedCertificate = {};

  //   const startingDate = formData.certificateStartingYear
  //     ? new Date(formData.certificateStartingYear)
  //     : value.course_from
  //       ? new Date(value.course_from)
  //       : null;

  //   const endingDate = formData.certificateEndingYear
  //     ? new Date(formData.certificateEndingYear)
  //     : value.course_to
  //       ? new Date(value.course_to)
  //       : null;

  //   if (startingDate && endingDate && endingDate < startingDate) {
  //     toast.error("Ending date cannot be before starting date");
  //     return;
  //   }

  //   if (formData.certificateCourseName) {
  //     editedCertificate.course_name = formData.certificateCourseName;
  //   }

  //   if (formData.certificateInstitute) {
  //     editedCertificate.course_institute = formData.certificateInstitute;
  //   }

  //   if (formData.certificateType) {
  //     editedCertificate.course_type = formData.certificateType;
  //   }

  //   if (formData.certificateStartingYear) {
  //     editedCertificate.course_from = formatYearToDate(
  //       formData.certificateStartingYear
  //     );
  //   }

  //   if (formData.certificateEndingYear) {
  //     editedCertificate.course_to = formatYearToDate(
  //       formData.certificateEndingYear
  //     );
  //   }

  //   if (formData.certificateValidity) {
  //     editedCertificate.valid_till = formatYearToDate(
  //       formData.certificateValidity
  //     );
  //   }

  //   editedCertificate.is_expired = false;

  //   if (Object.keys(editedCertificate).length === 0) {
  //     toast.info("No data to update");
  //     return;
  //   }

  //   const accessToken = sessionStorage.getItem("accessToken");

  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   };

  //   const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/certification/${value.id}/`;

  //   axios
  //     .put(url, editedCertificate, config)
  //     .then((response) => {
  //       toast.success("Added Successfully");
  //       // setUpdatedData(response.data);
  //       fetchData();
  //       fetchProfileCompletion();
  //       setIsEditModelOpenCertification(false);
  //       setIsSubmittedCertification(true);
  //     })
  //     .catch((error) => {
  //       toast.error(error);
  //     });
  // };

  const handleAddNewCertification = () => {
    setFormData({
      certificateCourseName: "",
      certificateInstitute: "",
      certificateType: "",
      specialization: "",
      certificateStartingYear: "",
      certificateEndingYear: "",
      certificateValidity: "",
    });
    setIsSubmittedCertification(false);
  };

  const handleAddCertificationDetails = (event) => {
    event.preventDefault();

    let newErrors = {};

    const currentYear = new Date().getFullYear();

    const {
      certificateCourseName = "",
      certificateInstitute = "",
      certificateType,
      certificateStartingYear,
      certificateEndingYear,
      certificateValidity,
    } = formData || {};

    if (!certificateCourseName.trim()) {
      newErrors.certificateCourseName = "Course name is required";
    }

    if (!certificateInstitute.trim()) {
      newErrors.certificateInstitute = "Institute name is required";
    }

    if (!certificateType) {
      newErrors.certificateType = "Course type is required";
    }

    if (!certificateStartingYear) {
      newErrors.certificateStartingYear = "Valid starting year is required";
    }

    if (!certificateEndingYear) {
      newErrors.certificateEndingYear = "Valid ending date is required";
    } else {
      const startDate = new Date(certificateStartingYear);
      const endDate = new Date(certificateEndingYear);

      if (endDate < startDate) {
        newErrors.certificateEndingYear = "Ending date cannot be before starting date";
      }
    }

    // if (!certificateEndingYear) {
    //   newErrors.certificateEndingYear = "Valid ending year is required";
    // } else if (
    //   parseInt(certificateEndingYear) < parseInt(certificateStartingYear)
    // ) {
    //   newErrors.certificateEndingYear =
    //     "Ending year cannot be before starting year";
    // }

    if (certificateValidity) {
      if (!/^\d{4}$/.test(certificateValidity)) {
        newErrors.certificateValidity = "Valid year is required (4 digits)";
      } else if (parseInt(certificateValidity) < currentYear) {
        newErrors.certificateValidity =
          "Certificate validity must be a future year";
      }
    } else {
      newErrors.certificateValidity = "Certificate validity is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSubmittedCertification(false);
      return;
    }

    setIsSubmittedCertification(true);
    setIsEditModelOpenCertification(false);

    const newCertificate = {
      course_name: certificateCourseName,
      course_institute: certificateInstitute,
      course_type: certificateType,
      specialization: formData.specialization,
      course_from: certificateStartingYear,
      course_to: certificateEndingYear,
      valid_till: formatYearToDate(certificateValidity),
      is_expired: false,
    };

    const previousCertificates = updatedData?.certificates || [];
    const mergedCertificates = [...previousCertificates, newCertificate];

    const payload = {
      certificates: mergedCertificates,
    };

    submitCertification(payload);
  };

  const getYearsRange = (startYear) => {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let i = currentYear; i >= startYear; i--) {
      years.push(i);
    }
    return years;
  };

  const years = getYearsRange(1980);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const noticePeriods = [
    "0-7 days",
    "7-15 days",
    "15-30 days",
    "30-45 days",
    "More than 45 days",
  ];

  useEffect(() => {
  }, [editingValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setFilteredSkills([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const today = new Date();
  const maxDate = new Date(today.setFullYear(today.getFullYear() - 10)).toISOString().split("T")[0];

  return (

    appLoading ? (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
        }
        }
      >
        <ClipLoader color="#FFA500" size={60} />
      </div >
    ) : (<div className="epf-container">
      {/* <ToastContainer /> */}
      <div className="epf-header flex items-center justify-between p-6 bg-white rounded-lg shadow-md pb-2">
        <div className="epf-image-wrapper relative">

          {(profileImage || isImageUploaded) ? (
            <img
              src={profileImage || dummy_logo}
              alt="ProfilePhoto"
              className="epf-profile-pic w-20 h-20 rounded-full object-cover"
            />
          ) : (
            <div className="epf_loading_image_container epf-loading-placeholder w-20 h-20 flex items-center justify-center bg-gray-200 rounded-full">
              {loading ? <span>Uploading...</span> : <span>No Image</span>}
            </div>
          )}

          {profileImage ?
            (<button
              type="button"
              className="epf-upload-trigger bg-blue-500 rounded-full p-2 flex items-center justify-center cursor-pointer"
              onClick={handleDeleteProfilePhoto}
              aria-label="Upload Profile Picture"
            >
              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z" fill="#FFFFFF" />
                <path d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z" fill="#FFFFFF" />
                <path d="M9.64788 16H4.87022C2.27302 16 2.16884 14.5637 2.08698 13.4028L1.60326 5.90889C1.58093 5.60378 1.81907 5.33587 2.12419 5.31355C2.43674 5.29866 2.69721 5.52936 2.71953 5.83448L3.20325 13.3284C3.28511 14.4596 3.31488 14.8837 4.87022 14.8837H9.64788C11.2107 14.8837 11.2404 14.4596 11.3149 13.3284L11.7986 5.83448C11.8209 5.52936 12.0888 5.29866 12.3939 5.31355C12.699 5.33587 12.9372 5.59634 12.9148 5.90889L12.4311 13.4028C12.3493 14.5637 12.2451 16 9.64788 16Z" fill="#FFFFFF" />
                <path d="M8.49403 11.9069H6.0159C5.71079 11.9069 5.45776 11.6539 5.45776 11.3488C5.45776 11.0437 5.71079 10.7906 6.0159 10.7906H8.49403C8.79915 10.7906 9.05217 11.0437 9.05217 11.3488C9.05217 11.6539 8.79915 11.9069 8.49403 11.9069Z" fill="#FFFFFF" />
                <path d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z" fill="#FFFFFF" />
              </svg>
            </button>)
            : (<button
              type="button"
              className="epf-upload-trigger bg-blue-500 rounded-full p-2 flex items-center justify-center cursor-pointer"
              onClick={() => fileInputRef.current.click()}
              aria-label="Upload Profile Picture"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.6294 11.2078H1.98252C1.18062 11.2078 0.515625 10.5428 0.515625 9.74094C0.515625 8.93904 1.18062 8.27405 1.98252 8.27405H17.6294C18.4313 8.27405 19.0963 8.93904 19.0963 9.74094C19.0963 10.5428 18.4313 11.2078 17.6294 11.2078Z"
                  fill="white"
                />
                <path
                  d="M9.80771 19.0313C9.00581 19.0313 8.34082 18.3663 8.34082 17.5644V1.91752C8.34082 1.11561 9.00581 0.450623 9.80771 0.450623C10.6096 0.450623 11.2746 1.11561 11.2746 1.91752V17.5644C11.2746 18.3663 10.6096 19.0313 9.80771 19.0313Z"
                  fill="white"
                />
              </svg>
            </button>)}

          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            onChange={handleImageUpload}
            accept="image/*"
          />
        </div>

        <div className="epf-resume-block flex flex-col items-start">
          <div className="d-flex justify-content-between align-items-center">
            <div className="epf-resume-prompt">Already have a resume</div>
            <div className='d-flex align-items-center gap-4 mt-2'>
              {updatedData?.studentlateral_resume_url && (<div
                onClick={() => {
                  if (updatedData?.studentlateral_resume_url) {
                    window.open(updatedData?.studentlateral_resume_url, "_blank");
                  } else {
                    toast.error("Resume URL not available!");
                  }
                }}
                className="add_resume_text text-sm text-red-600 mb-2"
                style={{ fontSize: "13px", color: "#28a745" }}>Preview Resume</div>)}
              <div
                onClick={() => resumeInputRef.current.click()}
                className="add_resume_text text-sm text-red-600 mb-2"
                style={{ fontSize: "13px" }}
              >
                {resumeFile ? "Resume Added" : "Add Resume"}
              </div>
            </div>
          </div>
          <div
            className="epf-format-info flex items-center text-xs text-gray-500 mb-2"
            onClick={() => resumeInputRef.current.click()}
          >
            <span className="epf-format-icon mr-2">
              <svg
                width="30"
                height="33"
                viewBox="0 0 30 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5431 12.0712C14.3942 12.0712 14.2603 12.0414 14.1114 11.9819C13.6947 11.8181 13.4268 11.4014 13.4268 10.9549V2.02445C13.4268 1.4142 13.9328 0.908142 14.5431 0.908142C15.1533 0.908142 15.6594 1.4142 15.6594 2.02445V8.26086L16.731 7.18921C17.1627 6.75757 17.8771 6.75757 18.3087 7.18921C18.7404 7.62085 18.7404 8.33528 18.3087 8.76692L15.3319 11.7437C15.1235 11.9521 14.8259 12.0712 14.5431 12.0712Z"
                  fill="#F38D00"
                />
                <path
                  d="M14.5415 12.0731C14.2587 12.0731 13.9759 11.9689 13.7527 11.7457L10.7759 8.76887C10.3442 8.33723 10.3442 7.6228 10.7759 7.19116C11.2075 6.75952 11.9219 6.75952 12.3536 7.19116L15.3304 10.168C15.762 10.5996 15.762 11.314 15.3304 11.7457C15.1071 11.9689 14.8243 12.0731 14.5415 12.0731Z"
                  fill="#F38D00"
                />
                <path
                  d="M20.4968 32.9082H8.58958C0.03125 32.9082 0.03125 28.3685 0.03125 24.3498V22.8614C0.03125 19.5423 0.03125 15.7915 7.10117 15.7915C8.87237 15.7915 9.52727 16.2231 10.4501 16.9078C10.4947 16.9525 10.5543 16.9822 10.5989 17.0418L12.1171 18.6492C13.3971 20.0037 15.719 20.0037 16.9991 18.6492L18.5172 17.0418C18.5619 16.9971 18.6065 16.9525 18.6661 16.9078C19.5889 16.2083 20.2438 15.7915 22.015 15.7915C29.0849 15.7915 29.0849 19.5423 29.0849 22.8614V24.3498C29.0551 30.0355 26.1825 32.9082 20.4968 32.9082ZM7.10117 18.0241C2.26386 18.0241 2.26386 19.5423 2.26386 22.8614V24.3498C2.26386 28.4281 2.26386 30.6755 8.58958 30.6755H20.4968C24.9323 30.6755 26.8225 28.7853 26.8225 24.3498V22.8614C26.8225 19.5423 26.8225 18.0241 21.9852 18.0241C20.9136 18.0241 20.6903 18.1581 20.0503 18.6344L18.6065 20.1674C17.5349 21.2986 16.0911 21.9237 14.5432 21.9237C12.9953 21.9237 11.5515 21.2986 10.4799 20.1674L9.0361 18.6344C8.39608 18.1581 8.17282 18.0241 7.10117 18.0241Z"
                  fill="#F38D00"
                />
                <path
                  d="M4.129 18.0244C3.51875 18.0244 3.0127 17.5183 3.0127 16.9081V10.9544C3.0127 8.06693 3.01269 4.47988 8.49002 3.95894C9.10027 3.8994 9.65098 4.34592 9.71052 4.97105C9.77005 5.5813 9.32353 6.13201 8.6984 6.19154C5.2453 6.50411 5.2453 7.90321 5.2453 10.9544V16.9081C5.2453 17.5183 4.73924 18.0244 4.129 18.0244Z"
                  fill="#F38D00"
                />
                <path
                  d="M24.9616 18.025C24.3514 18.025 23.8453 17.5189 23.8453 16.9087V10.955C23.8453 7.90382 23.8453 6.50472 20.3922 6.17727C19.782 6.11773 19.3354 5.56702 19.395 4.95678C19.4545 4.34653 19.9903 3.88513 20.6155 3.95955C26.0928 4.48049 26.0928 8.06754 26.0928 10.955V16.9087C26.0779 17.5189 25.5719 18.025 24.9616 18.025Z"
                  fill="#F38D00"
                />
              </svg>
            </span>
            {isLoading ? (
              <span className="epf-format-text">
                Uploading...
              </span>
            ) : (
              <span className="epf-format-text">
                Supported Formats: DOCX, DOC, PDF
                <br />
                Max Size: 700KB
              </span>
            )}
          </div>
          <input
            type="file"
            ref={resumeInputRef}
            className="hidden"
            onChange={handleResumeUpload}
            accept=".docx,.doc,.pdf"
          />
        </div>

        <div className="position-relative res_epf_container_width">
          {profileCompletion > "89" && (
            <div style={{ position: "absolute", top: "-31px", right: "0" }}>
              <div
                onClick={generateResume}
                className="add_resume_text text-sm text-red-600 mb-2"
                data-tip="click to generate your resume"
              >
                Generate Resume
              </div>
              <ReactTooltip place="top" type="dark" effect="solid" />
            </div>
          )}
          <div className="epf-completion-card new_epf_completion_card_letral_main">
            <div className="complete_profile_text">
              <span>Complete Your</span> <span>Profile</span>
            </div>
            <div className="mr-4 epf-chart-wrapper">
              <div className="epf-chart-container">
                <Pie data={pieChartData} options={pieChartOptions} />
              </div>
              <div className="epf-completion-value">
                {`${profileCompletion}%` || "0%"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4 pb-4">
        <div
          className="epf-form-container"
          style={isSubmitted ? { position: "relative" } : { display: "block" }}
        >
          <div className="epf_form_name_heading">
            <h2 className="epf-form-heading">
              {isEditModelOpen ? "Edit Details" : "Personal Information"}
            </h2>
            <hr />
          </div>
          {isSubmitted && isEditModelOpen === false && (
            <div className="epf_edit_Details_button" onClick={handleEditClick}>
              <b>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                    fill="#F38D00"
                  />
                </svg>
                <span className="pl-4"> Edit Details</span>{" "}
              </b>
            </div>
          )}
          {isEditModelOpen ? (
            <>
              <div className='student_my_profile_summary' style={{ width: "100%" }} >
                <p>
                  <b>Edit Profile Summary:</b>
                </p>
                <textarea
                  name='bio'
                  // value={formData.bio || updatedData?.bio}
                  placeholder="Add your bio"
                  // onChange={handleInputBioChange}
                  value={
                    !initialValuesSet.bio
                      ? updatedData?.bio || ""
                      : formData.bio
                  }
                  onChange={(e) => handleInputBioEditChange("bio", e.target.value)}
                  rows={3}
                  style={{ width: '100%', resize: 'vertical' }}
                />
                {bioError && (<p style={{ color: "red" }}>Bio should not exceed 100 words</p>)}
              </div> <br />

              <form className="epf-form-layout" onSubmit={editPersonalDetails}>
                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.firstName ? "input-error" : ""
                      }`}
                    placeholder="First Name"
                    name="firstName"
                    // value={
                    //   capitalizeFirstLetter(formData.firstName) ||
                    //   capitalizeFirstLetter(updatedData?.first_name)
                    // }
                    // onChange={(e) => handleInputChangeWithValidation(e)}
                    value={
                      !initialValuesSet.firstName
                        ? capitalizeFirstLetter(updatedData?.first_name) || ""
                        : capitalizeFirstLetter(formData.firstName)
                    }
                    onChange={(e) => handleInputChangeNewValidation("firstName", e.target.value)}
                  />
                  {errors.firstName && (
                    <span className="error-message">
                      First letter should be capitalized
                    </span>
                  )}
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className="epf-input-field"
                    placeholder="Last Name"
                    name="lastName"
                    // value={
                    //   capitalizeFirstLetter(formData.lastName) ||
                    //   capitalizeFirstLetter(updatedData?.last_name)
                    // }
                    // onChange={(e) => handleInputChangeWithValidation(e)}
                    value={
                      !initialValuesSet.lastName
                        ? capitalizeFirstLetter(updatedData?.last_name) || ""
                        : capitalizeFirstLetter(formData.lastName)
                    }
                    onChange={(e) => handleInputChangeLastNameValidation("lastName", e.target.value)}
                  />
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className="epf-input-field"
                    placeholder="Enter your Email"
                    name="email"
                    value={updatedData.email || formData.email}
                    disabled
                  />
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className="epf-input-field"
                    placeholder="Enter your phone number"
                    name="phone"
                    type="number"
                    // value={formData.phone || updatedData?.phone_no}
                    value={
                      !initialValuesSet.phone
                        ? updatedData?.phone_no || ""
                        : formData.phone
                    }
                    onChange={(e) => handleInputEditChange("phone", e.target.value)}
                  />
                  {phoneError && <span className="error-message">enter valid phone number </span>}
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className="epf-input-field"
                    placeholder="Date of Birth"
                    name="dob"
                    type="date"
                    // value={formData.dob || updatedData?.dob}
                    // onChange={handleInputChange}
                    value={
                      !initialValuesSet.dob
                        ? updatedData?.dob || ""
                        : formData.dob
                    }
                    onChange={(e) => handleInputEditChange("dob", e.target.value)}
                    onFocus={(e) => {
                      e.target.type = "date";
                      e.target.max = maxDate;
                    }}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </div>

                <div className="epf-input-wrapper epf-input-gap">
                  <select
                    name="gender"
                    // value={formData.gender || updatedData?.gender}
                    // onChange={handleInputChange}
                    value={
                      !initialValuesSet.gender
                        ? updatedData?.gender || ""
                        : formData.gender
                    }
                    onChange={(e) => handleInputEditChange("gender", e.target.value)}
                    className={`epf-input-field ${errors.gender ? "input-error" : ""
                      }`}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Others</option>
                  </select>
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.designation ? "input-error" : ""
                      }`}
                    placeholder="Your Designation"
                    name="designation"
                    // value={formData.designation || updatedData?.designation}
                    // onChange={handleInputChange}
                    value={
                      !initialValuesSet.designation
                        ? updatedData?.designation || ""
                        : formData.designation
                    }
                    onChange={(e) => handleInputEditChange("designation", e.target.value)}
                  />
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className="epf-input-field"
                    placeholder="Current Location"
                    name="currentLocation"
                    // value={ updatedData?.current_location }
                    // onChange={handleInputChange}
                    value={
                      !initialValuesSet.currentLocation
                        ? updatedData?.current_location || ""
                        : formData.currentLocation
                    }
                    onChange={(e) => handleInputEditChange("currentLocation", e.target.value)}
                  />
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className="epf-input-field"
                    placeholder="Preferred Location"
                    name="preferredLocation"
                    // value={
                    //   formData.preferredLocation ||
                    //   updatedData?.preferred_location
                    // }
                    // onChange={handleInputChange}
                    value={
                      !initialValuesSet.preferredLocation
                        ? updatedData?.preferred_location || ""
                        : formData.preferredLocation
                    }
                    onChange={(e) => handleInputEditChange("preferredLocation", e.target.value)}
                  />
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className="epf-input-field"
                    placeholder="Home State"
                    name="homeState"
                    // value={formData.homeState || updatedData?.state}
                    // onChange={handleInputChange}
                    value={
                      !initialValuesSet.homeState
                        ? updatedData?.state || ""
                        : formData.homeState
                    }
                    onChange={(e) => handleInputEditChange("homeState", e.target.value)}
                  />
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className="epf-input-field"
                    placeholder="Home City"
                    name="homeCity"
                    // value={formData.homeCity || updatedData?.city}
                    // onChange={handleInputChange}
                    value={
                      !initialValuesSet.homeCity
                        ? updatedData?.city || ""
                        : formData.homeCity
                    }
                    onChange={(e) => handleInputEditChange("homeCity", e.target.value)}
                  />
                </div>

                <div className="epf-input-wrapper epf-input-gap">
                  <select
                    name="experiencedOrFresher"
                    value={
                      formData.experiencedOrFresher ||
                      (updatedData?.is_experienced === true
                        ? "experienced"
                        : "fresher")
                    }
                    onChange={handleInputChange}
                    className="epf-input-field"
                  >
                    <option value="">Are you Experienced or Fresher?</option>
                    <option value="experienced">Experienced</option>
                    <option value="fresher">Fresher</option>
                  </select>
                </div>

                {formData.experiencedOrFresher === "experienced" ||
                  (updatedData?.is_experienced === true && (
                    <>
                      <div className="epf-input-wrapper">
                        <input
                          className="epf-input-field"
                          placeholder="Experience in years"
                          name="experience"
                          // value={formData.experience || updatedData?.experience}
                          // onChange={handleInputChange}
                          value={
                            !initialValuesSet.experience
                              ? updatedData?.experience || ""
                              : formData.experience
                          }
                          onChange={(e) => handleInputEditChange("experience", e.target.value)}
                        />
                      </div>

                      <div className="epf-input-wrapper">
                        <input
                          className="epf-input-field"
                          placeholder="Notice Period in days"
                          name="noticePeriod"
                          // value={
                          //   formData.noticePeriod || updatedData?.notice_period
                          // }
                          // onChange={handleInputChange}
                          value={
                            !initialValuesSet.noticePeriod
                              ? updatedData?.notice_period || ""
                              : formData.noticePeriod
                          }
                          onChange={(e) => handleInputEditChange("noticePeriod", e.target.value)}
                        />
                      </div>

                      <div className="epf-input-wrapper">
                        <input
                          className="epf-input-field"
                          placeholder="Annual Salary in LPA"
                          name="annualSalary"
                          // value={
                          //   formData.annualSalary || updatedData?.annual_salary
                          // }
                          // onChange={handleInputChange}
                          value={
                            !initialValuesSet.annualSalary
                              ? updatedData?.annual_salary || ""
                              : formData.annualSalary
                          }
                          onChange={(e) => handleInputEditChange("annualSalary", e.target.value)}
                        />
                        <p
                          style={{ color: "green", fontSize: "12px" }}
                        >
                          {(formData.annualSalary || updatedData?.annual_salary)
                            ? `${formData.annualSalary || updatedData?.annual_salary
                            } LPA (${formatSalary(
                              formData.annualSalary || updatedData?.annual_salary * 100000
                            )})`
                            : ""}
                        </p>
                      </div>
                    </>
                  ))}

                <div className="epf-input-wrapper">
                  <input
                    className="epf-input-field"
                    placeholder="Expected Salary"
                    name="expectedSalary"
                    // value={
                    //   formData.expectedSalary || updatedData?.expected_salary
                    // }
                    // onChange={handleInputChange}
                    value={
                      !initialValuesSet.expectedSalary
                        ? updatedData?.expected_salary || ""
                        : formData.expectedSalary
                    }
                    onChange={(e) => handleInputEditChange("expectedSalary", e.target.value)}
                  />
                  <p
                    style={{ color: "green", fontSize: "12px" }}
                  >
                    {(formData.expectedSalary || updatedData?.expected_salary)
                      ? `${formData.expectedSalary || updatedData?.expected_salary
                      } LPA (${formatSalary(
                        formData.expectedSalary || updatedData?.expected_salary * 100000
                      )})`
                      : ""}
                  </p>
                </div>

                <div className="d-flex gap-4">
                  <button type="submit" className="epf-submit-btn mt-0">
                    {isLoadingPersonal ? <span className="spinner"></span> : `Save`}
                  </button>
                  <button
                    onClick={handleEditCancel}
                    className="epf-submit-btn mt-0"
                    style={{
                      background: "transparent",
                      border: "1px solid rgba(243, 141, 0, 1)",
                      color: "rgba(243, 141, 0, 1)",
                    }}
                  >
                    Cancel
                  </button>
                </div>

              </form>
            </>
          ) : null}
          {isSubmitted === true && isEditModelOpen === false ? (
            <div className="submitted-data-container">
              <div className='student_my_profile_summary' style={{ width: "100%" }}>
                <p>
                  <b>My Profile Summary:</b>
                </p>
                <textarea
                  value={formData.bio}
                  placeholder={updatedData?.bio}
                  onChange={handleInputChange}
                  rows={3}
                  style={{ width: '100%', resize: 'vertical' }}
                />
              </div>
              <div className="submitted-data-column">
                <p>
                  <b>First Name:</b> {updatedData?.first_name || "N/A"}
                </p>
                <p>
                  <b>Last Name:</b> {updatedData?.last_name || "N/A"}
                </p>
                <p>
                  <b>Email:</b> {updatedData?.email || "N/A"}
                </p>
                <p>
                  <b>Phone:</b> {updatedData?.phone_no || "N/A"}
                </p>
                <p>
                  <b>Designation:</b>{" "}
                  {updatedData?.designation || "N/A"}
                </p>
              </div>
              <div className="submitted-data-column">
                <p>
                  <b>DOB:</b> {updatedData?.dob || "N/A"}
                </p>
                <p>
                  <b>Gender:</b> {updatedData?.gender || "N/A"}
                </p>
                <p>
                  <b>Current Location:</b>{" "}
                  {updatedData?.current_location || "N/A"}
                </p>
                <p>
                  <b>Preferred Location:</b>{" "}
                  {updatedData?.preferred_location || "N/A"}
                </p>
                <p>
                  <b>Home State:</b> {updatedData?.state || "N/A"}
                </p>
              </div>
              <div className="submitted-data-column">
                <p>
                  <b>Home City:</b> {updatedData?.city || "N/A"}
                </p>
                <p>
                  <b>Experience:</b>{" "}
                  {`${updatedData?.experience || "N/A"} Year`}
                </p>
                <p>
                  <b>Notice Period:</b>{" "}
                  {`${updatedData?.notice_period || "N/A"} Day's`}
                </p>
                <p>
                  <b>Annual Salary:</b>{" "}
                  {`${updatedData?.annual_salary || "N/A"} LPA`}
                </p>
                <p>
                  <b>Expected Salary:</b>{" "}
                  {`${updatedData?.expected_salary || "N/A"} LPA`}
                </p>
              </div>
            </div>
          ) : (
            isEditModelOpen === false &&
            isSubmitted === false && (<>

              <div className='student_my_profile_summary' style={{ width: "100%" }} >
                <p>
                  <b>Add Profile Summary:</b>
                </p>
                <textarea
                  name='bio'
                  value={formData.bio}
                  placeholder='Add Your Proifle Summary'
                  onChange={handleInputChange}
                  rows={3}
                  style={{ width: '100%', resize: 'vertical' }}
                  className={errors.bio ? "input-error" : ""}
                />
                {errors.bio && (
                  <span className="error-text">{errors.bio}</span>
                )}
              </div> <br />

              <form className="epf-form-layout" onSubmit={handlePersonalSubmit}>
                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.firstName ? "input-error" : ""
                      }`}
                    placeholder="First Name"
                    name="firstName"
                    // value={
                    //   capitalizeFirstLetter(formData.firstName) ||
                    //   capitalizeFirstLetter(updatedData?.first_name) ||
                    //   ""
                    // }
                    // onChange={handleInputChange}

                    value={
                      !initialValuesSet.firstName
                        ? capitalizeFirstLetter(updatedData?.first_name) || ""
                        : capitalizeFirstLetter(formData.firstName)
                    }
                    onChange={(e) => handleInputChangeNewValidation("firstName", e.target.value)}
                  />
                  {errors.firstName && (
                    <span className="error-message">
                      First letter should be capitalized
                    </span>
                  )}
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.lastName ? "input-error" : ""
                      }`}
                    placeholder="Last Name"
                    name="lastName"
                    // value={formData.lastName || updatedData?.last_name || ""}
                    // onChange={handleInputChange}
                    value={
                      !initialValuesSet.lastName
                        ? capitalizeFirstLetter(updatedData?.last_name) || ""
                        : capitalizeFirstLetter(formData.lastName)
                    }
                    onChange={(e) => handleInputChangeLastNameValidation("lastName", e.target.value)}
                  />
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field`}
                    placeholder="Enter your Email"
                    name="email"
                    value={updatedData?.email || ""}
                    readOnly
                    style={{ cursor: "no-drop" }}
                  />
                </div>

                {/* <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.bio ? "input-error" : ""
                      }`}
                    placeholder="Add Your Bio"
                    name="bio"
                    value={formData.bio}
                    onChange={handleInputChange}
                  />
                  {errors.bio && (
                    <span className="error-text">{errors.bio}</span>
                  )}
                </div> */}

                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.phone ? "input-error" : ""
                      }`}
                    placeholder="Enter your phone number"
                    name="phone"
                    type="number"
                    // value={
                    //   formData.phone ||
                    //   updatedData?.phone_no?.replace("+91", "")
                    // }
                    // onChange={(e) => {
                    //   const value = e.target.value;
                    //   if (value.length <= 10) {
                    //     handleInputChange(e);
                    //   }
                    // }}
                    value={
                      !initialValuesSet.phone
                        ? updatedData?.phone_no?.replace("+91", "") || ""
                        : formData.phone
                    }
                    // onChange={(e) => handleInputEditChange("phone", e.target.value)}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 10) {
                        handleInputEditChange("phone", e.target.value);
                      }
                    }}
                  />
                  {errors.phone && (
                    <span className="error-text">{errors.phone}</span>
                  )}
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.dob ? "input-error" : ""
                      }`}
                    placeholder="Enter your DOB"
                    name="dob"
                    type="text"
                    value={formData.dob}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                      e.target.type = "date";
                      e.target.max = maxDate;
                    }}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                  {errors.dob && (
                    <span className="error-text">{errors.dob}</span>
                  )}
                </div>

                {/* <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field ${errors.gender ? "input-error" : ""}`}
                                    placeholder="Gender"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleInputChange}
                                />
                                {errors.gender && <span className="error-text">{errors.gender}</span>}
                            </div> */}

                <div className="epf-input-wrapper epf-input-gap">
                  <select
                    name="gender"
                    value={formData.gender || updatedData?.gender}
                    onChange={handleInputChange}
                    className={`epf-input-field ${errors.gender ? "input-error" : ""
                      }`}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Others</option>
                  </select>
                  {errors.gender && (
                    <span className="error-text">{errors.gender}</span>
                  )}
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.designation ? "input-error" : ""
                      }`}
                    placeholder="Your Designation"
                    name="designation"
                    value={formData.designation}
                    onChange={handleInputChange}
                  />
                  {errors.designation && (
                    <span className="error-text">{errors.designation}</span>
                  )}
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.currentLocation ? "input-error" : ""
                      }`}
                    placeholder="Current Location"
                    name="currentLocation"
                    value={formData.currentLocation}
                    onChange={handleInputChange}
                  />
                  {errors.currentLocation && (
                    <span className="error-text">{errors.currentLocation}</span>
                  )}
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.preferredLocation ? "input-error" : ""
                      }`}
                    placeholder="Preferred Location"
                    name="preferredLocation"
                    value={formData.preferredLocation}
                    onChange={handleInputChange}
                  />
                  {errors.preferredLocation && (
                    <span className="error-text">
                      {errors.preferredLocation}
                    </span>
                  )}
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.homeState ? "input-error" : ""
                      }`}
                    placeholder="Home State"
                    name="homeState"
                    value={formData.homeState}
                    onChange={handleInputChange}
                  />
                  {errors.homeState && (
                    <span className="error-text">{errors.homeState}</span>
                  )}
                </div>

                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.homeCity ? "input-error" : ""
                      }`}
                    placeholder="Home City"
                    name="homeCity"
                    value={formData.homeCity}
                    onChange={handleInputChange}
                  />
                  {errors.homeCity && (
                    <span className="error-text">{errors.homeCity}</span>
                  )}
                </div>

                <div className="epf-input-wrapper epf-input-gap">
                  <select
                    name="experiencedOrFresher"
                    value={formData.experiencedOrFresher}
                    onChange={handleInputChange}
                    className={`epf-input-field ${errors.experiencedOrFresher ? "input-error" : ""
                      }`}
                  >
                    <option value="">Are you Experienced or Fresher?</option>
                    <option value="experienced">Experienced</option>
                    <option value="fresher">Fresher</option>
                  </select>
                  {errors.experiencedOrFresher && (
                    <span className="error-text">
                      {errors.experiencedOrFresher}
                    </span>
                  )}
                </div>

                {formData.experiencedOrFresher === "experienced" && (
                  <>
                    <div className="epf-input-wrapper">
                      <input
                        className={`epf-input-field ${errors.experience ? "input-error" : ""
                          }`}
                        placeholder="Experience in years"
                        name="experience"
                        value={formData.experience}
                        onChange={handleInputChange}
                      />
                      {errors.experience && (
                        <span className="error-text">{errors.experience}</span>
                      )}
                    </div>

                    <div className="epf-input-wrapper">
                      <input
                        className={`epf-input-field ${errors.noticePeriod ? "input-error" : ""
                          }`}
                        placeholder="Notice Period in days"
                        name="noticePeriod"
                        value={formData.noticePeriod}
                        onChange={handleInputChange}
                      />
                      {errors.noticePeriod && (
                        <span className="error-text">
                          {errors.noticePeriod}
                        </span>
                      )}
                    </div>

                    <div className="epf-input-wrapper">
                      <input
                        className={`epf-input-field ${errors.annualSalary ? "input-error" : ""
                          }`}
                        placeholder="Annual Salary in LPA"
                        name="annualSalary"
                        value={formData.annualSalary}
                        onChange={handleInputChange}
                      />
                      {errors.annualSalary && (
                        <span className="error-text">
                          {errors.annualSalary}
                        </span>
                      )}
                      {!errors.annualSalary && (
                        <p
                          style={{ color: "green", fontSize: "12px" }}
                        >
                          {formData.annualSalary
                            ? `${formData.annualSalary
                            } LPA (${formatSalary(
                              formData.annualSalary * 100000
                            )})`
                            : ""}
                        </p>
                      )}
                    </div>
                  </>
                )}

                <div className="epf-input-wrapper">
                  <input
                    className={`epf-input-field ${errors.expectedSalary ? "input-error" : ""
                      }`}
                    placeholder="Expected Salary"
                    name="expectedSalary"
                    value={formData.expectedSalary}
                    onChange={handleInputChange}
                  />
                  {errors.expectedSalary && (
                    <span className="error-text">{errors.expectedSalary}</span>
                  )}
                  {!errors.expectedSalary && (
                    <p
                      style={{ color: "green", fontSize: "12px" }}
                    >
                      {formData.expectedSalary
                        ? `${formData.expectedSalary
                        } LPA (${formatSalary(
                          formData.expectedSalary * 100000
                        )})`
                        : ""}
                    </p>
                  )}
                </div>

                <button type="submit" className="epf-submit-btn mt-0">
                  {isLoadingPersonal ? <span className="spinner"></span> : `Save`}
                </button>
              </form>
            </>)
          )}
        </div>
      </div>

      <div className="pt-4 pb-4">
        <div className="letral_social_media_main rightsection_socialsites">
          <div className="social_Sites_Innerdiv">
            <div className="sites_topView pb-4">
              <p>{isSocialMediaOpenModel ? `Edit Social Media Links` : `Social Media Links`}</p>
            </div>
            <div className="sites_downview">
              <div className="sites_down_innerview">

                {isSocialMediaOpenModel === true && isAlreadySocialData === true && showSocialMedia === false && (<>

                  <div className="sites_icon student_sites_icon" style={{ overflow: "unset" }}>
                    <div className="images">
                      <img src={Instagram} alt="Icon" />
                    </div>
                    <div style={{ width: "100%" }}>
                      <span>Instagram</span>
                      <br />
                      <input
                        type="text"
                        name="instagram"
                        // value={formData.instagram || editSocialMediaData.instagram}
                        // onChange={handleInputChange}
                        value={
                          !initialValuesSet.instagram
                            ? editSocialMediaData.instagram || ""
                            : formData.instagram
                        }
                        onChange={(e) => handleInputEditChange("instagram", e.target.value)}
                        className="epf-input-field"
                      />
                    </div>
                  </div>
                  <div className="sites_icon student_sites_icon mt-2" style={{ overflow: "unset" }}>
                    <div className="images">
                      <img src={Twitter} alt="Twitter" />
                    </div>
                    <div style={{ width: "100%" }}>
                      <span>Twitter</span>
                      <br />
                      <input
                        type="text"
                        name="twitter"
                        // value={formData.twitter || editSocialMediaData.twitter}
                        // onChange={handleInputChange}
                        value={
                          !initialValuesSet.twitter
                            ? editSocialMediaData.twitter || ""
                            : formData.twitter
                        }
                        onChange={(e) => handleInputEditChange("twitter", e.target.value)}
                        className="epf-input-field"
                      />
                    </div>
                  </div>
                  <div className="sites_icon student_sites_icon mt-2" style={{ overflow: "unset" }}>
                    <div className="images">
                      <img src={LinkedIn} alt="LinkedIn" />
                    </div>
                    <div style={{ width: "100%" }}>
                      <span>LinkedIn</span>
                      <br />
                      <input
                        type="text"
                        name="linkedin"
                        // value={formData.linkedin || editSocialMediaData.linkedin}
                        // onChange={handleInputChange}
                        value={
                          !initialValuesSet.linkedin
                            ? editSocialMediaData.linkedin || ""
                            : formData.linkedin
                        }
                        onChange={(e) => handleInputEditChange("linkedin", e.target.value)}
                        className="epf-input-field"
                      />
                    </div>
                  </div>

                  <div className="d-flex gap-4 ps-4 pt-4">
                    <button onClick={handleEditSocialMediaSubmit} className="epf-submit-btn mt-0">
                      {isLoadingSocial ? <span className="spinner"></span> : `Save`}
                    </button>
                    <button
                      onClick={handleEditSocialMediaCancel}
                      className="epf-submit-btn mt-0"
                      style={{
                        background: "transparent",
                        border: "1px solid rgba(243, 141, 0, 1)",
                        color: "rgba(243, 141, 0, 1)",
                      }}
                    >
                      Cancel
                    </button>
                  </div>

                </>)}

                {(isSocialMediaOpenModel === false && isAlreadySocialData === false) && (
                  <>
                    <div className="sites_icon student_sites_icon" style={{ overflow: "unset" }}>
                      <div className="images">
                        <img src={Instagram} alt="icon" />
                      </div>
                      <div style={{ width: "100%" }}>
                        <span>Instagram</span>
                        <br />
                        <input
                          type="text"
                          name="instagram"
                          value={formData.instagram}
                          onChange={handleInputChange}
                          className="epf-input-field"
                        />
                      </div>
                    </div>
                    <div className="sites_icon student_sites_icon mt-2" style={{ overflow: "unset" }}>
                      <div className="images">
                        <img src={Twitter} alt="icon" />
                      </div>
                      <div style={{ width: "100%" }}>
                        <span>Twitter</span>
                        <br />
                        <input
                          type="text"
                          name="twitter"
                          value={formData.twitter}
                          onChange={handleInputChange}
                          className="epf-input-field"
                        />
                      </div>
                    </div>
                    <div className="sites_icon student_sites_icon mt-2" style={{ overflow: "unset" }}>
                      <div className="images">
                        <img src={LinkedIn} alt="icon" />
                      </div>
                      <div style={{ width: "100%" }}>
                        <span>LinkedIn</span>
                        <br />
                        <input
                          type="text"
                          name="linkedin"
                          value={formData.linkedin}
                          onChange={handleInputChange}
                          className="epf-input-field"
                        />
                      </div>
                    </div>
                    <div className="pt-4" style={{ paddingLeft: "33px" }}>
                      <button onClick={handleAddSocialMedia} className="epf-submit-btn mt-0">
                        {isLoadingSocial ? <span className="spinner"></span> : `Save`}
                      </button>
                    </div>
                  </>
                )}

                {(showSocialMedia === true && isSocialMediaOpenModel === false && isAlreadySocialData === true) && (
                  <>
                    {updatedData?.social_media_links
                      ?.filter((link) => link.url)
                      .map((link) => (
                        <div key={link.id} className="sites_icon student_sites_icon">
                          <div className="d-flex align-items-start">
                            <div className="images">
                              <img
                                src={
                                  link.platform === "instagram"
                                    ? Instagram
                                    : link.platform === "twitter"
                                      ? Twitter
                                      : link.platform === "linkedin"
                                        ? LinkedIn
                                        : ""
                                }
                                alt='icon'
                              />
                            </div>
                            <div>
                              <span>{link.platform.charAt(0).toUpperCase() + link.platform.slice(1)}</span>
                              <br />
                              <a href={link.url} target="_blank" rel="noopener noreferrer">
                                {link.url}
                              </a>
                            </div>
                          </div>
                          <div style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "10px" }}>
                            <img onClick={() => handleEditSocialMedia(link)} src={editIcon} alt="Edit" style={{ width: "16px" }} />
                            <div onClick={() => handleDeleteSocialMedia(link.id)}>
                              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z" fill="#F38D00" />
                                <path d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z" fill="#F38D00" />
                                <path d="M9.64788 16H4.87022C2.27302 16 2.16884 14.5637 2.08698 13.4028L1.60326 5.90889C1.58093 5.60378 1.81907 5.33587 2.12419 5.31355C2.43674 5.29866 2.69721 5.52936 2.71953 5.83448L3.20325 13.3284C3.28511 14.4596 3.31488 14.8837 4.87022 14.8837H9.64788C11.2107 14.8837 11.2404 14.4596 11.3149 13.3284L11.7986 5.83448C11.8209 5.52936 12.0888 5.29866 12.3939 5.31355C12.699 5.33587 12.9372 5.59634 12.9148 5.90889L12.4311 13.4028C12.3493 14.5637 12.2451 16 9.64788 16Z" fill="#F38D00" />
                                <path d="M8.49403 11.9069H6.0159C5.71079 11.9069 5.45776 11.6539 5.45776 11.3488C5.45776 11.0437 5.71079 10.7906 6.0159 10.7906H8.49403C8.79915 10.7906 9.05217 11.0437 9.05217 11.3488C9.05217 11.6539 8.79915 11.9069 8.49403 11.9069Z" fill="#F38D00" />
                                <path d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z" fill="#F38D00" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4 pb-4">
        <div
          className="epf-form-container"
          style={
            isSubmittedQualification
              ? { position: "relative" }
              : { display: "block" }
          }
        >
          <div className="qualification-form-container">
            <div className="d-flex justify-content-between">
              <div className="epf_form_name_heading">
                <h2 className="epf-form-heading">
                  {isEditModelOpenQualification
                    ? "Edit Qualifications Details"
                    : "Qualifications Details"}
                </h2>
                <hr />
              </div>

              {isSubmittedQualification == true &&
                isEditModelOpenQualification == false && (
                  <div
                    className="epf_form_name_heading"
                    onClick={addNewQualification}
                  >
                    <h2
                      className="epf-form-heading"
                      style={{
                        color: "rgba(243, 141, 0, 1)",
                        cursor: "pointer",
                      }}
                    >
                      {isEditModelOpenQualification
                        ? null
                        : "+ Add Qualifications"}
                    </h2>
                  </div>
                )}
            </div>

            {isEditModelOpenQualification ? (
              <>
                <form
                  onSubmit={(event) =>
                    handleEditNewQualification(editingValue, event)
                  }
                  className="epf-form-layout"
                >
                  <div className="epf-input-row">
                    <div className="epf-input-wrapper epf-input-gap">
                      <select
                        id="degree"
                        name="degree"
                        value={formData.degree || editingValue.degree}
                        onChange={(e) => {
                          handleInputChange(e);
                          setSelectedDegree(e.target.value);
                        }}
                        className={`epf-input-field ${errors.degree ? "input-error" : ""
                          }`}
                      >
                        <option value="">Degree</option>
                        <option value="bachelors">
                          Bachelor's Degree/Diploma
                        </option>
                        <option value="masters">Master's Degree</option>
                        <option value="doctorate">Doctorate/PHD</option>
                        <option value="12">12th</option>
                        <option value="10">10th</option>
                      </select>
                    </div>

                    {(formData.degree === "bachelors" ||
                      formData.degree === "masters" ||
                      formData.degree === "doctorate" ||
                      formData.degree === "") && (
                        <div
                          className="epf-input-wrapper epf-input-gap"
                          style={{ position: "relative" }}
                        >
                          <div
                            className="search-dropdown-wrapper"
                            style={{ position: "relative" }}
                          >
                            <input
                              type="text"
                              placeholder="Search institute"
                              // value={
                              //   searchTerm ||
                              //   (isEditModelOpenQualification &&
                              //     editingValue.institute) ||
                              //   ""
                              // }
                              // onChange={handleInputChangeCollage}
                              value={
                                !initialValuesSet.institute
                                  ? editingValue.institute || ""
                                  : searchTerm
                              }
                              onChange={(e) => handleInputEditCollageChange("institute", e.target.value)}
                              className="epf-input-field"
                              onClick={toggleDropdown}
                              onBlur={handleBlur}
                            />
                            <FaChevronDown
                              className="dropdown-icon"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                                width: "15px",
                                height: "15px",
                              }}
                              onClick={toggleDropdown}
                            />
                          </div>

                          {showDropdown && (
                            <ul
                              className="dropdown-list"
                              style={{
                                position: "absolute",
                                width: "100%",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                zIndex: "1",
                                maxHeight: "200px",
                                overflowY: "auto",
                                listStyle: "none",
                                padding: "0",
                                margin: "0",
                              }}
                            >
                              {institutes.length > 0 ? (
                                institutes.map((institute) => (
                                  <li
                                    key={institute.id}
                                    style={{
                                      padding: "10px",
                                      borderBottom: "1px solid #ccc",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setFormData({
                                        ...formData,
                                        institute: institute.university_name,
                                      });
                                      setSearchTerm(institute.university_name);
                                      setShowDropdown(false);
                                    }}
                                  >
                                    {institute.university_name}
                                  </li>
                                ))
                              ) : (
                                <li style={{ padding: "10px", color: "#888" }}>
                                  No institutes found
                                </li>
                              )}
                            </ul>
                          )}

                          {errors.institute && (
                            <span className="error-text">{errors.institute}</span>
                          )}
                        </div>
                      )}

                    {formData.degree === "10" && (
                      <div className="epf-input-wrapper epf-input-gap">
                        <input
                          type="text"
                          id="school"
                          name="school"
                          placeholder="School Name"
                          // value={formData.school || editingValue.school_name}
                          // onChange={handleInputChange}
                          value={
                            !initialValuesSet.school
                              ? editingValue.school_name || ""
                              : formData.school
                          }
                          onChange={(e) => handleInputEditChange("school", e.target.value)}
                          className={`epf-input-field ${errors.school ? "input-error" : ""
                            }`}
                        />
                      </div>
                    )}

                    {formData.degree === "12" && (
                      <div className="epf-input-wrapper epf-input-gap">
                        <input
                          type="text"
                          id="school"
                          name="school"
                          placeholder="School Name"
                          // value={formData.school || editingValue.institute}
                          // onChange={handleInputChange}
                          value={
                            !initialValuesSet.school
                              ? editingValue.school_name || ""
                              : formData.school
                          }
                          onChange={(e) => handleInputEditChange("school", e.target.value)}
                          className={`epf-input-field ${errors.school ? "input-error" : ""
                            }`}
                        />
                      </div>
                    )}

                    {(formData.degree === "bachelors" ||
                      formData.degree === "masters" ||
                      formData.degree === "doctorate" ||
                      formData.degree === "") && (
                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="courseName"
                            name="courseName"
                            value={
                              formData.courseName || editingValue.course_name
                            }
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.courseName ? "input-error" : ""
                              }`}
                          >
                            <option value="">Course Name</option>
                            {availableCourses.map((course) => (
                              <option key={course.name} value={course.name}>
                                {course.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                  </div>

                  {(formData.degree === "bachelors" ||
                    formData.degree === "masters" ||
                    formData.degree === "doctorate" ||
                    formData.degree === "") && (
                      <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="courseType"
                            name="courseType"
                            value={
                              formData.courseType || editingValue.course_type
                            }
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.courseType ? "input-error" : ""
                              }`}
                          >
                            <option value="">Course Type</option>
                            <option value="fulltime">Full Time</option>
                            <option value="parttime">Part Time</option>
                            <option value="correspondence/distance">
                              Correspondence/Distance learning
                            </option>
                          </select>
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="specialization"
                            name="specialization"
                            value={
                              formData.specialization ||
                              editingValue.course_specification
                            }
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.specialization ? "input-error" : ""
                              }`}
                          >
                            <option value="">Specialization</option>
                            {availableSpecializations.map((spec) => (
                              <option key={spec} value={spec}>
                                {spec}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="d-flex gap-4">
                          {/* <div className="epf-input-wrapper epf-input-gap">
                            <input
                              type="number"
                              id="startingYear"
                              name="startingYear"
                              placeholder="Starting Year"
                              value={
                                formData.startingYear ||
                                editingValue.from_date?.slice(0, 4)
                              }
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.startingYear ? "input-error" : ""
                                }`}
                            />
                          </div> */}

                          <div className="epf-input-wrapper epf-input-gap">
                            <select
                              id="startingYear"
                              name="startingYear"
                              value={
                                formData.startingYear ||
                                editingValue.from_date?.slice(0, 4)
                              }
                              onChange={handleDateInputChange}
                              className={`epf-input-field ${errors.startingYear ? "input-error" : ""
                                }`}
                            >
                              <option value="">Starting Year</option>
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="epf-input-wrapper epf-input-gap">
                            <select
                              type="number"
                              id="endingYear"
                              name="endingYear"
                              placeholder="Ending Year"
                              value={
                                formData.endingYear ||
                                editingValue.to_date?.slice(0, 4)
                              }
                              onChange={handleDateInputChange}
                              className={`epf-input-field ${errors.endingYear ? "input-error" : ""
                                }`}
                            >
                              <option value="">Ending Year</option>
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>

                          {/* <div className="epf-input-wrapper epf-input-gap">
                            <input
                              type="number"
                              id="endingYear"
                              name="endingYear"
                              placeholder="Ending Year"
                              value={
                                formData.endingYear ||
                                editingValue.to_date?.slice(0, 4)
                              }
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.endingYear ? "input-error" : ""
                                }`}
                            />
                          </div> */}
                        </div>
                      </div>
                    )}

                  {formData.degree === "10" && (
                    <div className="epf-input-row">
                      <div className="epf-input-wrapper epf-input-gap">
                        <select
                          id="board"
                          name="board"
                          value={formData.board || editingValue.board}
                          onChange={handleInputChange}
                          className={`epf-input-field ${errors.board ? "input-error" : ""
                            }`}
                        >
                          <option value="">Board</option>
                          <option value="cbse">CBSE</option>
                          <option value="icse">ICSE</option>
                          <option value="nios">NIOS</option>
                          <option value="stateBoard">State Board</option>
                        </select>
                        {errors.board && (
                          <span className="error-text">{errors.board}</span>
                        )}
                      </div>

                      <div className="d-flex gap-4">
                        <div className="epf-input-wrapper epf-input-gap">
                          <input
                            type="number"
                            id="passingYear"
                            name="passingYear"
                            placeholder="Passing Year"
                            value={
                              formData.passingYear ||
                              editingValue.passing_year?.slice(0, 4)
                            }
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.passingYear ? "input-error" : ""
                              }`}
                          />
                          {errors.passingYear && (
                            <span className="error-text">
                              {errors.passingYear}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {formData.degree === "12" && (
                    <div className="epf-input-row">
                      <div className="epf-input-wrapper epf-input-gap">
                        <select
                          id="board"
                          name="board"
                          value={formData.board || editingValue.board}
                          onChange={handleInputChange}
                          className={`epf-input-field ${errors.board ? "input-error" : ""
                            }`}
                        >
                          <option value="">Board</option>
                          <option value="cbse">CBSE</option>
                          <option value="icse">ICSE</option>
                          <option value="nios">NIOS</option>
                          <option value="stateBoard">State Board</option>
                        </select>
                        {errors.board && (
                          <span className="error-text">{errors.board}</span>
                        )}
                      </div>

                      <div className="d-flex gap-4">
                        <div className="epf-input-wrapper epf-input-gap">
                          <input
                            type="number"
                            id="passingYear"
                            name="passingYear"
                            placeholder="Passing Year"
                            value={
                              formData.passingYear ||
                              editingValue.passing_year?.slice(0, 4)
                            }
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.passingYear ? "input-error" : ""
                              }`}
                          />
                          {errors.passingYear && (
                            <span className="error-text">
                              {errors.passingYear}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="d-flex gap-4">
                    <button type="submit" className="epf-submit-btn mt-0">
                      {isLoadingQualification ? <span className="spinner"></span> : `Save`}
                    </button>
                    <button
                      onClick={handleEditQualificationCancel}
                      className="epf-submit-btn mt-0"
                      style={{
                        background: "transparent",
                        border: "1px solid rgba(243, 141, 0, 1)",
                        color: "rgba(243, 141, 0, 1)",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </>
            ) : null}

            {isSubmittedQualification == true &&
              isEditModelOpenQualification == false ? (
              <>
                {updatedData &&
                  updatedData?.qualifications &&
                  updatedData?.qualifications.map((value, index) => {
                    if (
                      value.degree.includes("12") ||
                      value.degree.includes("10")
                    ) {
                      return (
                        <div key={index} className="d-flex profilepage_editdetails">
                          <div className="submitted-data-container">
                            <div className="submitted-data-column">
                              <p>
                                <b>School:</b> {value.school_name}
                              </p>
                              <p>
                                <b>Board:</b> {value.board}
                              </p>
                              <p>
                                <b>Class:</b> {value.degree}th
                              </p>
                            </div>
                            <div className="submitted-data-column">
                              <p>
                                <b>Passing Year:</b>{" "}
                                {value.passing_year.substring(0, 4)}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex gap-4">
                            <div
                              className="epf_qualification_edit_btn"
                              onClick={() => handleEditQualification(value)}
                            >
                              <b
                                className="d-flex align-items-center gap-2"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <svg
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                                    fill="#F38D00"
                                  />
                                </svg>
                                <span className="pl-4"> Edit Details</span>
                              </b>
                            </div>
                            <div
                              className="epf_qualification_edit_btn"
                              onClick={() => handleDeleteQualification(value.id)}
                            >
                              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z" fill="#F38D00" />
                                <path d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z" fill="#F38D00" />
                                <path d="M9.64788 16H4.87022C2.27302 16 2.16884 14.5637 2.08698 13.4028L1.60326 5.90889C1.58093 5.60378 1.81907 5.33587 2.12419 5.31355C2.43674 5.29866 2.69721 5.52936 2.71953 5.83448L3.20325 13.3284C3.28511 14.4596 3.31488 14.8837 4.87022 14.8837H9.64788C11.2107 14.8837 11.2404 14.4596 11.3149 13.3284L11.7986 5.83448C11.8209 5.52936 12.0888 5.29866 12.3939 5.31355C12.699 5.33587 12.9372 5.59634 12.9148 5.90889L12.4311 13.4028C12.3493 14.5637 12.2451 16 9.64788 16Z" fill="#F38D00" />
                                <path d="M8.49403 11.9069H6.0159C5.71079 11.9069 5.45776 11.6539 5.45776 11.3488C5.45776 11.0437 5.71079 10.7906 6.0159 10.7906H8.49403C8.79915 10.7906 9.05217 11.0437 9.05217 11.3488C9.05217 11.6539 8.79915 11.9069 8.49403 11.9069Z" fill="#F38D00" />
                                <path d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z" fill="#F38D00" />
                              </svg>
                            </div>
                          </div>

                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={index}
                          className="d-flex profilepage_editdetails"
                        >
                          <div className="submitted-data-container">
                            <div className="submitted-data-column">
                              <p>
                                <b>Institute:</b> {value.institute}
                              </p>
                              <p>
                                <b>Course Name:</b> {value.course_name}
                              </p>
                              <p>
                                <b>Course Type:</b> {value.course_type}
                              </p>
                              <p>
                                <b>Degree:</b> {value.degree}
                              </p>
                            </div>
                            <div className="submitted-data-column">
                              <p>
                                <b>Specialization:</b>{" "}
                                {value.course_specification}
                              </p>
                              <p>
                                <b>Starting Year:</b>{" "}
                                {value.from_date.substring(0, 4)}
                              </p>
                              <p>
                                <b>Ending Year:</b>{" "}
                                {value.to_date.substring(0, 4)}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex gap-4">
                            <div
                              className="epf_qualification_edit_btn"
                              onClick={() => handleEditQualification(value)}
                            >
                              <b
                                className="d-flex align-items-center gap-2"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <svg
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                                    fill="#F38D00"
                                  />
                                </svg>
                                <span className="pl-4"> Edit Details</span>
                              </b>
                            </div>
                            <div
                              className="epf_qualification_edit_btn"
                              onClick={() => handleDeleteQualification(value.id)}
                            >
                              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z" fill="#F38D00" />
                                <path d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z" fill="#F38D00" />
                                <path d="M9.64788 16H4.87022C2.27302 16 2.16884 14.5637 2.08698 13.4028L1.60326 5.90889C1.58093 5.60378 1.81907 5.33587 2.12419 5.31355C2.43674 5.29866 2.69721 5.52936 2.71953 5.83448L3.20325 13.3284C3.28511 14.4596 3.31488 14.8837 4.87022 14.8837H9.64788C11.2107 14.8837 11.2404 14.4596 11.3149 13.3284L11.7986 5.83448C11.8209 5.52936 12.0888 5.29866 12.3939 5.31355C12.699 5.33587 12.9372 5.59634 12.9148 5.90889L12.4311 13.4028C12.3493 14.5637 12.2451 16 9.64788 16Z" fill="#F38D00" />
                                <path d="M8.49403 11.9069H6.0159C5.71079 11.9069 5.45776 11.6539 5.45776 11.3488C5.45776 11.0437 5.71079 10.7906 6.0159 10.7906H8.49403C8.79915 10.7906 9.05217 11.0437 9.05217 11.3488C9.05217 11.6539 8.79915 11.9069 8.49403 11.9069Z" fill="#F38D00" />
                                <path d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z" fill="#F38D00" />
                              </svg>
                            </div>
                          </div>

                        </div>
                      );
                    }
                  })}
              </>
            ) : (
              isEditModelOpenQualification == false &&
              isSubmittedQualification === false && (
                <form
                  onSubmit={hanldeAddQualification}
                  className="epf-form-layout"
                >
                  <div className="epf-input-row">
                    <div className="epf-input-wrapper epf-input-gap">
                      <select
                        id="degree"
                        name="degree"
                        value={formData.degree}
                        onChange={(e) => {
                          handleInputChange(e);
                          setSelectedDegree(e.target.value);
                        }}
                        className={`epf-input-field ${errors.degree ? "input-error" : ""
                          }`}
                      >
                        <option value="">Degree</option>
                        <option value="bachelors">
                          Bachelor's Degree/Diploma
                        </option>
                        <option value="masters">Master's Degree</option>
                        <option value="doctorate">Doctorate/PHD</option>
                        <option value="12">12th</option>
                        <option value="10">10th</option>
                      </select>
                      {errors.degree && (
                        <span className="error-text">{errors.degree}</span>
                      )}
                    </div>

                    {(formData.degree === "bachelors" ||
                      formData.degree === "masters" ||
                      formData.degree === "doctorate" ||
                      formData.degree === "") && (
                        <div
                          className="epf-input-wrapper epf-input-gap"
                          style={{ position: "relative" }}
                        >
                          <div
                            className="search-dropdown-wrapper"
                            style={{ position: "relative" }}
                          >
                            <input
                              type="text"
                              placeholder="Search institute"
                              value={searchTerm}
                              onChange={handleInputChangeCollage}
                              className="epf-input-field"
                              onClick={toggleDropdown}
                              onBlur={handleBlur}
                            />
                            <FaChevronDown
                              className="dropdown-icon"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                                width: "15px",
                                height: "15px",
                              }}
                              onClick={toggleDropdown}
                            />
                          </div>

                          {showDropdown && (
                            <ul
                              className="dropdown-list"
                              style={{
                                position: "absolute",
                                width: "100%",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                zIndex: "1",
                                maxHeight: "200px",
                                overflowY: "auto",
                                listStyle: "none",
                                padding: "0",
                                margin: "0",
                              }}
                            >
                              {institutes.length > 0 ? (
                                institutes.map((institute) => (
                                  <li
                                    key={institute.id}
                                    style={{
                                      padding: "10px",
                                      borderBottom: "1px solid #ccc",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setFormData({
                                        ...formData,
                                        institute: institute.college_name,
                                      });
                                      setSearchTerm(institute.college_name);
                                      setShowDropdown(false);
                                    }}
                                  >
                                    {institute.college_name}
                                  </li>
                                ))
                              ) : (
                                <li style={{ padding: "10px", color: "#888" }}>
                                  No institutes found
                                </li>
                              )}
                            </ul>
                          )}

                          {errors.institute && (
                            <span className="error-text">{errors.institute}</span>
                          )}
                        </div>
                      )}

                    {(formData.degree === "12" || formData.degree === "10") && (
                      <div className="epf-input-wrapper epf-input-gap">
                        <input
                          type="text"
                          id="school"
                          name="school"
                          placeholder="School Name"
                          value={formData.school}
                          onChange={handleInputChange}
                          className={`epf-input-field ${errors.school ? "input-error" : ""
                            }`}
                        />
                        {errors.school && (
                          <span className="error-text">{errors.school}</span>
                        )}
                      </div>
                    )}

                    {(formData.degree === "bachelors" ||
                      formData.degree === "masters" ||
                      formData.degree === "doctorate" ||
                      formData.degree === "") && (
                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="courseName"
                            name="courseName"
                            value={formData.courseName}
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.courseName ? "input-error" : ""
                              }`}
                          >
                            <option value="">Course Name</option>
                            {availableCourses.map((course) => (
                              <option key={course.name} value={course.name}>
                                {course.name}
                              </option>
                            ))}
                          </select>
                          {errors.courseName && (
                            <span className="error-text">
                              {errors.courseName}
                            </span>
                          )}
                        </div>
                      )}
                  </div>
                  {(formData.degree === "bachelors" ||
                    formData.degree === "masters" ||
                    formData.degree === "doctorate" ||
                    formData.degree === "") && (
                      <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="courseType"
                            name="courseType"
                            value={formData.courseType}
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.courseType ? "input-error" : ""
                              }`}
                          >
                            <option value="">Course Type</option>
                            <option value="fulltime">Full Time</option>
                            <option value="parttime">Part Time</option>
                            <option value="correspondence/distance">
                              Correspondence/Distance learning
                            </option>
                          </select>
                          {errors.courseType && (
                            <span className="error-text">
                              {errors.courseType}
                            </span>
                          )}
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="specialization"
                            name="specialization"
                            value={formData.specialization}
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.specialization ? "input-error" : ""
                              }`}
                          >
                            <option value="">Specialization</option>
                            {availableSpecializations.map((spec) => (
                              <option key={spec} value={spec}>
                                {spec}
                              </option>
                            ))}
                          </select>
                          {errors.specialization && (
                            <span className="error-text">
                              {errors.specialization}
                            </span>
                          )}
                        </div>

                        <div className="d-flex gap-4">
                          <div className="epf-input-wrapper epf-input-gap">
                            <select
                              id="startingYear"
                              name="startingYear"
                              value={formData.startingYear}
                              onChange={handleDateInputChange}
                              className={`epf-input-field ${errors.startingYear ? "input-error" : ""
                                }`}
                            >
                              <option value="">Starting Year</option>
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                            {errors.startingYear && (
                              <span className="error-text">
                                {errors.startingYear}
                              </span>
                            )}
                          </div>

                          <div className="epf-input-wrapper epf-input-gap">
                            <select
                              type="number"
                              id="endingYear"
                              name="endingYear"
                              placeholder="Ending Year"
                              value={formData.endingYear}
                              onChange={handleDateInputChange}
                              className={`epf-input-field ${errors.endingYear ? "input-error" : ""
                                }`}
                            >
                              <option value="">Ending Year</option>
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                            {errors.endingYear && (
                              <span className="error-text">
                                {errors.endingYear}
                              </span>
                            )}
                          </div>
                          {/* <div className="epf-input-wrapper epf-input-gap">
                            <input
                              type="number"
                              id="startingYear"
                              name="startingYear"
                              placeholder="Starting Year"
                              value={formData.startingYear}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.startingYear ? "input-error" : ""
                                }`}
                            />
                            {errors.startingYear && (
                              <span className="error-text">
                                {errors.startingYear}
                              </span>
                            )}
                          </div>

                          <div className="epf-input-wrapper epf-input-gap">
                            <input
                              type="number"
                              id="endingYear"
                              name="endingYear"
                              placeholder="Ending Year"
                              value={formData.endingYear}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.endingYear ? "input-error" : ""
                                }`}
                            />
                            {errors.endingYear && (
                              <span className="error-text">
                                {errors.endingYear}
                              </span>
                            )}
                          </div> */}
                        </div>
                      </div>
                    )}

                  {(formData.degree === "12" || formData.degree === "10") && (
                    <div className="epf-input-row">
                      <div className="epf-input-wrapper epf-input-gap">
                        <select
                          id="board"
                          name="board"
                          value={formData.board}
                          onChange={handleInputChange}
                          className={`epf-input-field ${errors.board ? "input-error" : ""
                            }`}
                        >
                          <option value="">Board</option>
                          <option value="cbse">CBSE</option>
                          <option value="icse">ICSE</option>
                          <option value="nios">NIOS</option>
                          <option value="stateBoard">State Board</option>
                        </select>
                        {errors.board && (
                          <span className="error-text">{errors.board}</span>
                        )}
                      </div>

                      <div className="epf-input-wrapper epf-input-gap">
                        <select
                          id="passingYear"
                          name="passingYear"
                          value={formData.passingYear}
                          onChange={handleDateInputChange}
                          className={`epf-input-field ${errors.passingYear ? "input-error" : ""
                            }`}
                        >
                          <option value="">Passing Year</option>
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                        {errors.passingYear && (
                          <span className="error-text">
                            {errors.passingYear}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="d-flex gap-4">
                    <button type="submit" className="epf-submit-btn mt-0">
                      {isLoadingQualification ? <span className="spinner"></span> : `Save`}
                    </button>
                    {isQualificationCancel && (<button
                      onClick={handleEditQualificationCancel}
                      className="epf-submit-btn mt-0"
                      style={{
                        background: "transparent",
                        border: "1px solid rgba(243, 141, 0, 1)",
                        color: "rgba(243, 141, 0, 1)",
                      }}
                    >
                      Cancel
                    </button>)}
                  </div>
                </form>
              )
            )}
          </div>
        </div>
      </div>

      <div className="pt-4 pb-4">
        <div
          className="epf-form-container"
          style={
            isSubmittedEmployment
              ? { position: "relative" }
              : { display: "block" }
          }
        >
          <div className="qualification-form-container">
            <div className="epf_form_name_heading">
              <h2 className="epf-form-heading">
                {isSubmittedEmployment
                  ? "Employment Details"
                  : "Edit Employment Details"}
              </h2>
              <hr />
            </div>
            {isSubmittedEmployment && isEditModelOpenEmployment === false && (
              <div
                className="epf_edit_Details_button"
                onClick={handleAddNewEmployment}
              >
                <b>
                  <span className="pl-4">+ Add Employment </span>
                </b>
              </div>
            )}

            {isEditModelOpenEmployment ? (
              <div className="employment-details-container">
                <form
                  onSubmit={(event) =>
                    handleEditEmploymentDetails(editingValue, event)
                  }
                >
                  {/* Is Current Employment */}
                  {/* <div className="form-section">
                    <b>Is this your current Company?</b>
                    <div className="radio-group">
                      {(isCurrentEmployment === "Yes" || editingValue?.is_current_employment === true) && (<button
                        type="button"
                        className={`toggle-btn ${isCurrentEmployment === "Yes" ||
                          editingValue?.is_current_employment
                          ? "active"
                          : ""
                          }`}
                      // onClick={() => setIsCurrentEmployment("Yes")}
                      >
                        Yes
                      </button>)}
                      
                      {(isCurrentEmployment === "No" || editingValue?.is_current_employment === false) && (<button
                        type="button"
                        className={`toggle-btn ${isCurrentEmployment === "No" ||
                          editingValue?.is_current_employment === false
                          ? "active"
                          : ""
                          }`}
                      // onClick={() => setIsCurrentEmployment("No")}
                      >
                        No
                      </button>)}
                    </div>
                  </div> */}

                  {/* Employment Type */}
                  <div className="form-section">
                    <b>Employment Type</b>
                    <div className="radio-group">
                      <button
                        type="button"
                        // className={`toggle-btn ${employmentType === "Full Time" ||
                        //   editingValue?.employment_type === "Full Time"
                        //   ? "active"
                        //   : ""
                        //   }`}
                        className={`toggle-btn ${employmentType === "Full Time" ? "active" : ""}`}
                        onClick={() => setEmploymentType("Full Time")}
                      >
                        Full Time
                      </button>
                      <button
                        type="button"
                        // className={`toggle-btn ${employmentType === "Internship" ||
                        //   editingValue?.employment_type === "Internship"
                        //   ? "active"
                        //   : ""
                        //   }`}
                        className={`toggle-btn ${employmentType === "Internship" ? "active" : ""}`}
                        onClick={() => setEmploymentType("Internship")}
                      >
                        Internship
                      </button>
                    </div>
                  </div>

                  {/* Current Employment Fields */}
                  {(editingValue?.is_current_employment === true ||
                    isCurrentEmployment === "Yes") && (
                      <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                          <input
                            type="text"
                            id="currentCompanyName"
                            name="currentCompanyName"
                            placeholder="Current Company Name"
                            // value={
                            //   formData.currentCompanyName ||
                            //   editingValue?.current_company_name
                            // }
                            // onChange={handleInputChange}
                            value={
                              !initialValuesSet.currentCompanyName
                                ? editingValue.current_company_name || ""
                                : formData.currentCompanyName
                            }
                            onChange={(e) => handleInputEditChange("currentCompanyName", e.target.value)}
                            className={`epf-input-field ${errors.currentCompanyName ? "input-error" : ""
                              }`}
                          />
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="noticePeriod"
                            name="noticePeriod"
                            value={
                              formData.noticePeriod ||
                              editingValue?.employment_notice_period
                            }
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.noticePeriod ? "input-error" : ""
                              }`}
                          >
                            <option value="">Notice Period</option>
                            {noticePeriods.map((period, index) => (
                              <option key={index} value={period}>
                                {period}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="d-flex justify-content-between align-items-center gap-2">
                          <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                            <select
                              id="currentjoiningYear"
                              name="currentjoiningYear"
                              value={
                                formData.currentjoiningYear ||
                                editingValue?.joining_year
                              }
                              onChange={handleDateInputChange}
                              className={`epf-input-field ${errors.currentjoiningYear ? "input-error" : ""
                                }`}
                            >
                              <option value="">Joining Year</option>
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                            <select
                              id="currentjoiningMonth"
                              name="currentjoiningMonth"
                              value={
                                formData.currentjoiningMonth ||
                                editingValue?.joining_month
                              }
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.currentjoiningMonth ? "input-error" : ""
                                }`}
                            >
                              <option value="">Joining Month</option>
                              {months.map((month, index) => (
                                <option key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                          <input
                            type="text"
                            id="currentjobTitle"
                            name="currentjobTitle"
                            placeholder="Current Job Title"
                            // value={
                            //   formData.currentjobTitle ||
                            //   editingValue?.current_job_title
                            // }
                            // onChange={handleInputChange}
                            value={
                              !initialValuesSet.currentjobTitle
                                ? editingValue.current_job_title || ""
                                : formData.currentjobTitle
                            }
                            onChange={(e) => handleInputEditChange("currentjobTitle", e.target.value)}
                            className={`epf-input-field ${errors.currentjobTitle ? "input-error" : ""
                              }`}
                          />
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                          <input
                            type="text"
                            id="currentSalary"
                            name="currentSalary"
                            placeholder="Current Salary"
                            // value={
                            //   formData.currentSalary ||
                            //   editingValue?.current_salary
                            // }
                            // onChange={handleInputChange}
                            value={
                              !initialValuesSet.currentSalary
                                ? editingValue.current_salary || ""
                                : formData.currentSalary
                            }
                            onChange={(e) => handleInputEditChange("currentSalary", e.target.value)}
                            className={`epf-input-field ${errors.currentSalary ? "input-error" : ""
                              }`}
                          />
                        </div>
                      </div>
                    )}

                  {/* Previous Employment Fields */}
                  {(editingValue?.is_current_employment === false ||
                    isCurrentEmployment === "No") && (
                      <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                          <input
                            type="text"
                            id="previousCompanyName"
                            name="previousCompanyName"
                            placeholder="Previous Company Name"
                            // value={
                            //   formData.previousCompanyName ||
                            //   editingValue?.current_company_name
                            // }
                            // onChange={handleInputChange}
                            value={
                              !initialValuesSet.previousCompanyName
                                ? editingValue.current_company_name || ""
                                : formData.previousCompanyName
                            }
                            onChange={(e) => handleInputEditChange("previousCompanyName", e.target.value)}
                            className={`epf-input-field ${errors.previousCompanyName ? "input-error" : ""
                              }`}
                          />
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                          <input
                            type="text"
                            id="previousjobTitle"
                            name="previousjobTitle"
                            placeholder="Current Job Title"
                            // value={
                            //   formData.previousjobTitle ||
                            //   editingValue?.current_job_title
                            // }
                            // onChange={handleInputChange}
                            value={
                              !initialValuesSet.previousjobTitle
                                ? editingValue.current_job_title || ""
                                : formData.previousjobTitle
                            }
                            onChange={(e) => handleInputEditChange("previousjobTitle", e.target.value)}
                            className={`epf-input-field ${errors.previousjobTitle ? "input-error" : ""
                              }`}
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center gap-2">
                          <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                            <select
                              id="previousjoiningYear"
                              name="previousjoiningYear"
                              value={
                                formData.previousjoiningYear ||
                                editingValue?.joining_year
                              }
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.previousjoiningYear ? "input-error" : ""
                                }`}
                            >
                              <option value="">Joining Year</option>
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                            <select
                              id="previousjoiningMonth"
                              name="previousjoiningMonth"
                              value={
                                formData.previousjoiningMonth ||
                                editingValue?.joining_month
                              }
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.previousjoiningMonth ? "input-error" : ""
                                }`}
                            >
                              <option value="">Joining Month</option>
                              {months.map((month, index) => (
                                <option key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center gap-2">
                          <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                            <select
                              id="previousTillYear"
                              name="previousTillYear"
                              value={
                                formData.previousTillYear ||
                                editingValue?.worked_till_year
                              }
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.previousTillYear ? "input-error" : ""
                                }`}
                            >
                              <option value="">Worked Till Year</option>
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                            <select
                              id="previousTillMonth"
                              name="previousTillMonth"
                              value={
                                formData.previousTillMonth ||
                                editingValue?.worked_till_month
                              }
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.previousTillMonth ? "input-error" : ""
                                }`}
                            >
                              <option value="">Worked Till Month</option>
                              {months.map((month, index) => (
                                <option key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="d-flex gap-4">
                    <button type="submit" className="epf-submit-btn mt-0">
                      {isLoadingEmployement ? <span className="spinner"></span> : `Save`}
                    </button>
                    <button
                      onClick={handleEditEmploymentCancel}
                      className="epf-submit-btn mt-0"
                      style={{
                        background: "transparent",
                        border: "1px solid rgba(243, 141, 0, 1)",
                        color: "rgba(243, 141, 0, 1)",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            ) : null}

            {isSubmittedEmployment === true &&
              isEditModelOpenEmployment === false
              ? updatedData &&
              updatedData?.employments?.map((value, index) => (
                <div key={index} className="d-flex gap-2 profilepage_editdetails">
                  <div className="submitted-data-container">
                    <div className="submitted-data-column">
                      <p>
                        <b>Job Title:</b> {value.current_job_title}
                      </p>
                      <p>
                        <b>
                          {value.is_current_employment === true
                            ? "Company Name:"
                            : "Company Name"}
                        </b>{" "}
                        {value.current_company_name}
                      </p>
                      <p>
                        <b>Employment Type:</b> {value.employment_type}
                      </p>
                    </div>
                    <div className="submitted-data-column">
                      <p>
                        <b>Joining Year:</b> {value.joining_month}{" "}
                        {value.joining_year} {value.is_current_employment === true && ` - Present`}
                      </p>
                      {value.is_current_employment === false && (
                        <p>
                          <b>Worked Till:</b> {value.worked_till_month}{" "}
                          {value.worked_till_year}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-4">
                    <div
                      className="epf_qualification_edit_btn"
                      onClick={() => handleEditEmployment(value)}
                    >
                      <b
                        className="d-flex align-items-center gap-2"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                            fill="#F38D00"
                          />
                        </svg>
                        <span className="pl-4"> Edit Details</span>
                      </b>
                    </div>
                    <div
                      className="epf_qualification_edit_btn"
                      onClick={() => handleDeleteEmployment(value.id)}
                    >
                      <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z" fill="#F38D00" />
                        <path d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z" fill="#F38D00" />
                        <path d="M9.64788 16H4.87022C2.27302 16 2.16884 14.5637 2.08698 13.4028L1.60326 5.90889C1.58093 5.60378 1.81907 5.33587 2.12419 5.31355C2.43674 5.29866 2.69721 5.52936 2.71953 5.83448L3.20325 13.3284C3.28511 14.4596 3.31488 14.8837 4.87022 14.8837H9.64788C11.2107 14.8837 11.2404 14.4596 11.3149 13.3284L11.7986 5.83448C11.8209 5.52936 12.0888 5.29866 12.3939 5.31355C12.699 5.33587 12.9372 5.59634 12.9148 5.90889L12.4311 13.4028C12.3493 14.5637 12.2451 16 9.64788 16Z" fill="#F38D00" />
                        <path d="M8.49403 11.9069H6.0159C5.71079 11.9069 5.45776 11.6539 5.45776 11.3488C5.45776 11.0437 5.71079 10.7906 6.0159 10.7906H8.49403C8.79915 10.7906 9.05217 11.0437 9.05217 11.3488C9.05217 11.6539 8.79915 11.9069 8.49403 11.9069Z" fill="#F38D00" />
                        <path d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z" fill="#F38D00" />
                      </svg>
                    </div>
                  </div>
                </div>
              ))
              : isEditModelOpenEmployment === false && (
                <div className="employment-details-container">
                  <form onSubmit={handleSubmitEmployment}>
                    <div>
                      <div className="form-section">
                        <b>Is this your current Company?</b>
                        <div className="radio-group">
                          <button
                            type="button"
                            className={`toggle-btn ${isCurrentEmployment === "Yes" ? "active" : ""
                              }`}
                            onClick={() => setIsCurrentEmployment("Yes")}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className={`toggle-btn ${isCurrentEmployment === "No" ? "active" : ""
                              }`}
                            onClick={() => setIsCurrentEmployment("No")}
                          >
                            No
                          </button>
                        </div>
                      </div>

                      <div className="form-section">
                        <b>Employment Type</b>
                        <div className="radio-group">
                          <button
                            type="button"
                            className={`toggle-btn ${employmentType === "Full Time" ? "active" : ""
                              }`}
                            onClick={() => setEmploymentType("Full Time")}
                          >
                            Full Time
                          </button>
                          <button
                            type="button"
                            className={`toggle-btn ${employmentType === "Internship" ? "active" : ""
                              }`}
                            onClick={() => setEmploymentType("Internship")}
                          >
                            Internship
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="epf-form-layout">
                      {isCurrentEmployment === "Yes" && (
                        <div className="epf-input-row">
                          <div className="epf-input-wrapper epf-input-gap">
                            <input
                              type="text"
                              id="currentCompanyName"
                              name="currentCompanyName"
                              placeholder="Current Company Name"
                              value={formData.currentCompanyName}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.currentCompanyName ? "input-error" : ""
                                }`}
                            />
                            {errors.currentCompanyName && (
                              <span className="error-text">
                                {errors.currentCompanyName}
                              </span>
                            )}
                          </div>

                          <div className="epf-input-wrapper epf-input-gap">
                            <select
                              id="noticePeriod"
                              name="noticePeriod"
                              value={formData.noticePeriod}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.noticePeriod && errors.noticePeriod
                                ? "input-error"
                                : ""
                                }`}
                            >
                              <option value="">Notice Period</option>
                              {noticePeriods.map((period, index) => (
                                <option key={index} value={period}>
                                  {period}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="d-flex justify-content-between align-items-center gap-2">
                            <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                              <select
                                id="currentjoiningYear"
                                name="currentjoiningYear"
                                value={formData.currentjoiningYear}
                                onChange={handleDateInputChange}
                                className={`epf-input-field ${errors.currentjoiningYear &&
                                  errors.currentjoiningYear
                                  ? "input-error"
                                  : ""
                                  }`}
                              >
                                <option value="" label="Joining year" />
                                {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              {errors.currentjoiningYear && (
                                <span className="error-text">
                                  {errors.currentjoiningYear}
                                </span>
                              )}
                            </div>

                            <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                              <select
                                id="currentjoiningMonth"
                                name="currentjoiningMonth"
                                value={formData.currentjoiningMonth}
                                onChange={handleInputChange}
                                className={`epf-input-field ${errors.currentjoiningMonth &&
                                  errors.currentjoiningMonth
                                  ? "input-error"
                                  : ""
                                  }`}
                              >
                                <option value="">Joining Month</option>
                                {months.map((month, index) => (
                                  <option key={index} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}

                      {isCurrentEmployment === "No" && (
                        <div className="epf-input-row">
                          <div className="epf-input-wrapper epf-input-gap">
                            <input
                              type="text"
                              id="previousCompanyName"
                              name="previousCompanyName"
                              placeholder="Previous Company Name"
                              value={formData.previousCompanyName}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.previousCompanyName &&
                                errors.previousCompanyName
                                ? "input-error"
                                : ""
                                }`}
                            />
                            {errors.previousCompanyName && (
                              <span className="error-text">
                                {errors.previousCompanyName}
                              </span>
                            )}
                          </div>

                          <div className="d-flex justify-content-between align-items-center gap-2">
                            <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                              <select
                                id="previousjoiningYear"
                                name="previousjoiningYear"
                                value={formData.previousjoiningYear}
                                onChange={handleInputChange}
                                className={`epf-input-field ${errors.previousjoiningYear
                                  ? "input-error"
                                  : ""
                                  }`}
                              >
                                <option value="" label="Joining year" />
                                {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              {errors.previousCompanyName && (
                                <span className="error-text">
                                  {errors.previousCompanyName}
                                </span>
                              )}
                            </div>

                            <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                              <select
                                id="previousjoiningMonth"
                                name="previousjoiningMonth"
                                value={formData.previousjoiningMonth}
                                onChange={handleInputChange}
                                className={`epf-input-field ${errors.previousjoiningMonth
                                  ? "input-error"
                                  : ""
                                  }`}
                              >
                                <option value="">Joining Month</option>
                                {months.map((month, index) => (
                                  <option key={index} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}

                      {isCurrentEmployment === "No" && (
                        <div className="epf-input-row">
                          <div className="epf-input-wrapper epf-input-gap">
                            <input
                              type="text"
                              id="previousjobTitle"
                              name="previousjobTitle"
                              placeholder="Previous Job Title"
                              value={formData.previousjobTitle}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.previousjobTitle ? "input-error" : ""
                                }`}
                            />
                            {errors.previousjobTitle && (
                              <span className="error-text">
                                {errors.previousjobTitle}
                              </span>
                            )}
                          </div>

                          <div className="d-flex justify-content-between align-items-center gap-2">
                            <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                              <select
                                id="previousTillYear"
                                name="previousTillYear"
                                value={formData.previousTillYear}
                                onChange={handleInputChange}
                                className={`epf-input-field ${errors.previousTillYear ? "input-error" : ""
                                  }`}
                              >
                                <option value="" label="Worked Till Year" />
                                {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              {errors.previousTillYear && (
                                <span className="error-text">
                                  {errors.previousTillYear}
                                </span>
                              )}
                            </div>

                            <div className="epf-input-wrapper epf-input-gap" style={{ width: "49%" }}>
                              <select
                                id="previousTillMonth"
                                name="previousTillMonth"
                                value={formData.previousTillMonth}
                                onChange={handleInputChange}
                                className={`epf-input-field ${errors.previousTillMonth
                                  ? "input-error"
                                  : ""
                                  }`}
                              >
                                <option value="">Worked Till Month</option>
                                {months.map((month, index) => (
                                  <option key={index} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                              {errors.previousTillMonth && (
                                <span className="error-text">
                                  {errors.previousTillMonth}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {isCurrentEmployment === "Yes" && (
                        <div className="epf-input-row">
                          <div className="epf-input-wrapper epf-input-gap">
                            <input
                              type="text"
                              id="currentjobTitle"
                              name="currentjobTitle"
                              placeholder="Current Job Title"
                              value={formData.currentjobTitle}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.currentjobTitle ? "input-error" : ""
                                }`}
                            />
                            {errors.currentjobTitle && (
                              <span className="error-text">
                                {errors.currentjobTitle}
                              </span>
                            )}
                          </div>

                          <div className="epf-input-wrapper epf-input-gap d-flex justify-content-between align-items-center gap-2 currency_main_container">

                            <div
                              className="epf-input-wrapper epf-input-gap pb-0"
                              style={{ width: "100%" }}
                            >
                              <input
                                type="text"
                                id="currentSalary"
                                name="currentSalary"
                                placeholder="Current Salary In Rupees"
                                value={formData.currentSalary}
                                onChange={handleInputChange}
                                className={`epf-input-field ${errors.currentSalary ? "input-error" : ""
                                  }`}
                              />
                              {errors.currentSalary && (
                                <span className="error-text">
                                  {errors.currentSalary}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* <button type="submit" className="epf-submit-btn">
                        {isLoadingEmployement ? <span className="spinner"></span> : `Save`}
                      </button> */}
                      <div className="d-flex gap-4">
                        <button type="submit" className="epf-submit-btn mt-0">
                          {isLoadingEmployement ? <span className="spinner"></span> : `Save`}
                        </button>
                        {isEmployementCancel && (<button
                          onClick={handleEditEmploymentCancel}
                          className="epf-submit-btn mt-0"
                          style={{
                            background: "transparent",
                            border: "1px solid rgba(243, 141, 0, 1)",
                            color: "rgba(243, 141, 0, 1)",
                          }}
                        >
                          Cancel
                        </button>)}
                      </div>

                    </div>
                  </form>
                </div>
              )}
          </div>
        </div>
      </div>

      <div className="pt-4 pb-4">
        <div
          className="epf-form-container"
        >
          <div className="qualification-form-container new-qualification-form-container">
            <div className="epf_form_name_heading">
              <h2 className="epf-form-heading">
                Skills Details
              </h2>
              <hr />
            </div>

            <div className="epf-skill-form">
              <form >
                <div className="epf-input-wrapper" style={{ position: "relative" }}>
                  <input
                    type="text"
                    id="skills"
                    name="skills"
                    placeholder="Add your skills"
                    value={formData.skills}
                    onChange={handleSkillInputChange}
                    className="epf-input-field"
                  />

                  {filteredSkills?.length > 0 && (
                    <ul className="suggestions-list student_suggestions_list" ref={suggestionsRef}>
                      {filteredSkills?.filter(skill => !selectedSkills.some(s => s.skill_name === skill))?.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSkillClick(suggestion)}>
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="epf-suggested-skills">
                  <div className="epf-skill-options">

                    {suggestedSkills &&
                      suggestedSkills
                        .filter(skill => !selectedSkills.some(s => s.skill_name === skill))
                        .slice(0, 10)
                        .map((skill) => {
                          return (
                            <button
                              type="button"
                              key={skill}
                              className="epf-skill-button"
                              onClick={() => handleSkillClick(skill)}
                            >
                              {skill} <span className="ps-2">✛</span>
                            </button>
                          );
                        })
                    }

                    {selectedSkills.length > 0 && (
                      selectedSkills.map((skill, index) => (
                        <button
                          type="button"
                          key={skill || index}
                          className="epf-skill-button selected"
                          onClick={() => handleDeleteSkills(skill.id)}
                        >
                          {skill.skill_name} <span className="ps-2">✖</span>
                        </button>
                      ))
                    )}

                  </div>
                </div>

              </form>
            </div>

          </div>
        </div>
      </div>

      <div className="pt-4 pb-4">
        <div
          className="epf-form-container"
          style={
            isSubmittedCertification
              ? { position: "relative" }
              : { display: "block" }
          }
        >
          <div className="qualification-form-container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="epf_form_name_heading pb-0">
                <h2 className="epf-form-heading">
                  {isSubmittedCertification
                    ? "Certifications Details"
                    : "Edit Certifications Details"}
                </h2>
                <hr />
              </div>

              {isSubmittedCertification === true && (
                <div
                  className="epf_qualification_edit_btn pt-0"
                  onClick={handleAddNewCertification}
                  style={{ marginTop: "-22px" }}
                >
                  <b
                    className="d-flex align-items-center gap-2"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    +<span className="pl-4"> Add Certificate</span>
                  </b>
                </div>
              )}
            </div>

            {isEditModelOpenCertification ? (
              <>
                <form
                  onSubmit={(event) =>
                    handleEditCertificationDeatils(editingValue, event)
                  }
                  className="epf-form-layout"
                >
                  <div className="epf-input-row">
                    <div className="epf-input-wrapper epf-input-gap">
                      <input
                        type="text"
                        id="certificateCourseName"
                        name="certificateCourseName"
                        placeholder="Course Name"
                        // value={
                        //   formData.certificateCourseName ||
                        //   editingValue.course_name
                        // }
                        // onChange={handleInputChange}
                        value={
                          !initialValuesSet.certificateCourseName
                            ? editingValue.course_name || ""
                            : formData.certificateCourseName
                        }
                        onChange={(e) => handleInputEditChange("certificateCourseName", e.target.value)}
                        className="epf-input-field"
                      />
                    </div>

                    <div className="epf-input-wrapper epf-input-gap">
                      <select
                        id="certificateType"
                        name="certificateType"
                        value={
                          formData.certificateType || editingValue.course_type
                        }
                        onChange={handleInputChange}
                        className="epf-input-field"
                      >
                        <option value="">Course Type</option>
                        <option value="fulltime">Full Time</option>
                        <option value="parttime">Part Time</option>
                        <option value="correspondence/distance">
                          Correspondence/Distance learning
                        </option>
                      </select>
                    </div>

                    <div className="epf-input-wrapper epf-input-gap">
                      <input
                        type="text"
                        id="certificateValidity"
                        name="certificateValidity"
                        placeholder="Valid Till"
                        value={
                          formData.certificateValidity ||
                          editingValue.valid_till.slice(0, 4)
                        }
                        onChange={handleDateInputChange}
                        className="epf-input-field"
                      />
                    </div>
                  </div>

                  <div className="epf-input-row">
                    <div className="epf-input-wrapper epf-input-gap">
                      <input
                        type="text"
                        id="certificateInstitute"
                        name="certificateInstitute"
                        placeholder="Institute Name"
                        // value={
                        //   formData.certificateInstitute ||
                        //   editingValue.course_institute
                        // }
                        // onChange={handleInputChange}
                        value={
                          !initialValuesSet.certificateInstitute
                            ? editingValue.course_institute || ""
                            : formData.certificateInstitute
                        }
                        onChange={(e) => handleInputEditChange("certificateInstitute", e.target.value)}
                        className="epf-input-field"
                      />
                    </div>

                    <div className="d-flex gap-4">
                      {/* <div className="epf-input-wrapper epf-input-gap">
                        <input
                          type="number"
                          id="certificateStartingYear"
                          name="certificateStartingYear"
                          placeholder="Starting Year"
                          value={
                            formData.certificateStartingYear ||
                            editingValue.course_from.slice(0, 4)
                          }
                          onChange={handleDateInputChange}
                          className="epf-input-field"
                        />
                      </div> */}
                      <div className="epf-input-wrapper epf-input-gap">
                        <DatePicker
                          selected={
                            formData.certificateStartingYear
                              ? new Date(formData.certificateStartingYear)
                              : editingValue.course_from
                                ? new Date(editingValue.course_from)
                                : null
                          }
                          onChange={(date) =>
                            handleDateEditInputChange({
                              target: { name: "certificateStartingYear", value: date },
                            })
                          }
                          dateFormat="yyyy-MM-dd"
                          placeholderText="Select Starting Date"
                          className="epf-input-field"
                          showMonthDropdown
                          showYearDropdown
                          maxDate={new Date()}
                          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                          dropdownMode="select"
                        />
                      </div>

                      <div className="epf-input-wrapper epf-input-gap">
                        <DatePicker
                          selected={
                            formData.certificateEndingYear
                              ? new Date(formData.certificateEndingYear)
                              : editingValue.course_to
                                ? new Date(editingValue.course_to)
                                : null
                          }
                          onChange={(date) =>
                            handleDateEditInputChange({
                              target: { name: "certificateEndingYear", value: date },
                            })
                          }
                          dateFormat="yyyy-MM-dd"
                          placeholderText="Select Ending Date"
                          className="epf-input-field"
                          showMonthDropdown
                          showYearDropdown
                          maxDate={new Date()}
                          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                          dropdownMode="select"
                        />
                      </div>

                      {/* <div className="epf-input-wrapper epf-input-gap">
                        <input
                          type="number"
                          id="certificateEndingYear"
                          name="certificateEndingYear"
                          placeholder="Ending Year"
                          value={
                            formData.certificateEndingYear ||
                            editingValue.course_to.slice(0, 4)
                          }
                          onChange={handleDateInputChange}
                          className="epf-input-field"
                        />
                      </div> */}
                    </div>
                  </div>

                  <div className="d-flex gap-4">
                    <button type="submit" className="epf-submit-btn mt-0">
                      {isLoadingCertification ? <span className="spinner"></span> : `Save`}
                    </button>
                    <button
                      onClick={handleEditCertificationCancel}
                      className="epf-submit-btn mt-0"
                      style={{
                        background: "transparent",
                        border: "1px solid rgba(243, 141, 0, 1)",
                        color: "rgba(243, 141, 0, 1)",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </>
            ) : null}

            {isSubmittedCertification == true &&
              isEditModelOpenCertification == false ? (
              <>
                {updatedData &&
                  updatedData?.certificates?.map((value, index) => (
                    <div key={index} className="d-flex gap-2 profilepage_editdetails">
                      <div className="submitted-data-container">
                        <div className="submitted-data-column">
                          <p>
                            <b>Course Name:</b> {value.course_name}
                          </p>
                          <p>
                            <b>Course Type:</b> {value.course_type}
                          </p>
                          <p>
                            <b>Valid Till:</b> {value.valid_till.slice(0, 4)}
                          </p>
                        </div>
                        <div className="submitted-data-column">
                          <p>
                            <b>Institute:</b> {value.course_institute}
                          </p>
                          <p>
                            <b>Sarting Date:</b> {`${value.course_from}`}
                          </p>
                          <p>
                            <b>Ending Date:</b> {`${value.course_to}`}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-4">
                        <div
                          className="epf_qualification_edit_btn"
                          onClick={() => handleEditCertification(value)}
                        >
                          <b
                            className="d-flex align-items-center gap-2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                                fill="#F38D00"
                              />
                            </svg>
                            <span className="pl-4"> Edit Details</span>
                          </b>
                        </div>
                        <div
                          className="epf_qualification_edit_btn"
                          onClick={() => handleDeleteCertification(value.id)}
                        >
                          <b
                            className="d-flex align-items-center gap-2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z" fill="#F38D00" />
                              <path d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z" fill="#F38D00" />
                              <path d="M9.64788 16H4.87022C2.27302 16 2.16884 14.5637 2.08698 13.4028L1.60326 5.90889C1.58093 5.60378 1.81907 5.33587 2.12419 5.31355C2.43674 5.29866 2.69721 5.52936 2.71953 5.83448L3.20325 13.3284C3.28511 14.4596 3.31488 14.8837 4.87022 14.8837H9.64788C11.2107 14.8837 11.2404 14.4596 11.3149 13.3284L11.7986 5.83448C11.8209 5.52936 12.0888 5.29866 12.3939 5.31355C12.699 5.33587 12.9372 5.59634 12.9148 5.90889L12.4311 13.4028C12.3493 14.5637 12.2451 16 9.64788 16Z" fill="#F38D00" />
                              <path d="M8.49403 11.9069H6.0159C5.71079 11.9069 5.45776 11.6539 5.45776 11.3488C5.45776 11.0437 5.71079 10.7906 6.0159 10.7906H8.49403C8.79915 10.7906 9.05217 11.0437 9.05217 11.3488C9.05217 11.6539 8.79915 11.9069 8.49403 11.9069Z" fill="#F38D00" />
                              <path d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z" fill="#F38D00" />
                            </svg>
                          </b>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              isEditModelOpenCertification == false && (
                <form
                  onSubmit={handleAddCertificationDetails}
                  className="epf-form-layout"
                >
                  <div className="epf-input-row">
                    <div className="epf-input-wrapper epf-input-gap">
                      <input
                        type="text"
                        id="certificateCourseName"
                        name="certificateCourseName"
                        placeholder="Course Name"
                        value={formData.certificateCourseName}
                        onChange={handleInputChange}
                        className={`epf-input-field ${errors.certificateCourseName ? "input-error" : ""
                          }`}
                      />
                      {errors.certificateCourseName && (
                        <span className="error-text">
                          {errors.certificateCourseName}
                        </span>
                      )}
                    </div>

                    <div className="epf-input-wrapper epf-input-gap">
                      <select
                        id="certificateType"
                        name="certificateType"
                        value={formData.certificateType}
                        onChange={handleInputChange}
                        className={`epf-input-field ${errors.certificateType ? "input-error" : ""
                          }`}
                      >
                        <option value="">Course Type</option>
                        <option value="fulltime">Full Time</option>
                        <option value="parttime">Part Time</option>
                        <option value="correspondence/distance">
                          Correspondence/Distance learning
                        </option>
                      </select>
                      {errors.certificateType && (
                        <span className="error-text">
                          {errors.certificateType}
                        </span>
                      )}
                    </div>

                    <div className="epf-input-wrapper epf-input-gap">
                      <input
                        type="number"
                        id="certificateValidity"
                        name="certificateValidity"
                        placeholder="Valid Till"
                        value={formData.certificateValidity}
                        onChange={handleDateInputChange}
                        className={`epf-input-field ${errors.certificateValidity ? "input-error" : ""
                          }`}
                      />
                      {errors.certificateValidity && (
                        <span className="error-text">
                          {errors.certificateValidity}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="epf-input-row">
                    <div className="epf-input-wrapper epf-input-gap">
                      <input
                        type="text"
                        id="certificateInstitute"
                        name="certificateInstitute"
                        placeholder="Institute Name"
                        value={formData.certificateInstitute}
                        onChange={handleInputChange}
                        className={`epf-input-field ${errors.certificateInstitute ? "input-error" : ""
                          }`}
                      />
                      {errors.certificateInstitute && (
                        <span className="error-text">
                          {errors.certificateInstitute}
                        </span>
                      )}
                    </div>

                    <div className="d-flex gap-4">
                      {/* <div className="epf-input-wrapper epf-input-gap">
                        <input
                          type="number"
                          id="certificateStartingYear"
                          name="certificateStartingYear"
                          placeholder="Starting Year"
                          value={formData.certificateStartingYear}
                          onChange={handleDateInputChange}
                          className={`epf-input-field ${errors.certificateStartingYear ? "input-error" : ""
                            }`}
                        />
                        {errors.certificateStartingYear && (
                          <span className="error-text">
                            {errors.certificateStartingYear}
                          </span>
                        )}
                      </div> */}
                      <div className="epf-input-wrapper epf-input-gap d-grid">
                        <DatePicker
                          selected={formData.certificateStartingYear ? new Date(formData.certificateStartingYear) : null}
                          onChange={handleCertificateStartDateChange}
                          dateFormat="yyyy-MM-dd"
                          // showMonthYearPicker
                          placeholderText="Starting Year and Month"
                          maxDate={new Date()}
                          className={`epf-input-field ${errors.certificateStartingYear ? "input-error" : ""}`}
                        />
                        {errors.certificateStartingYear && (
                          <span className="error-text">
                            {errors.certificateStartingYear}
                          </span>
                        )}
                      </div>

                      <div className="epf-input-wrapper epf-input-gap d-grid">
                        <DatePicker
                          selected={formData.certificateEndingYear ? new Date(formData.certificateEndingYear) : null}
                          onChange={handleCertificateEndDateChange}
                          dateFormat="yyyy-MM-dd"
                          // showMonthYearPicker
                          placeholderText="Ending Year and Month"
                          maxDate={new Date()}
                          className={`epf-input-field ${errors.certificateEndingYear ? "input-error" : ""}`}
                        />
                        {errors.certificateEndingYear && (
                          <span className="error-text">
                            {errors.certificateEndingYear}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <button type="submit" className="epf-submit-btn mt-0">
                    Save
                  </button> */}
                  <div className="d-flex gap-4">
                    <button type="submit" className="epf-submit-btn mt-0">
                      {isLoadingCertification ? <span className="spinner"></span> : `Save`}
                    </button>
                    {isCertifiationCancel && (<button
                      onClick={handleEditCertificationCancel}
                      className="epf-submit-btn mt-0"
                      style={{
                        background: "transparent",
                        border: "1px solid rgba(243, 141, 0, 1)",
                        color: "rgba(243, 141, 0, 1)",
                      }}
                    >
                      Cancel
                    </button>)}
                  </div>
                </form>
              )
            )}
          </div>
        </div>
      </div>

      {updatedData && (
        <div className="pt-4 pb-4" style={{ display: "none" }}>
          <div className="pdf_gen_main_container" id="pdf-container">
            <div className="pdf_gen_sidebar">
              <div className="pdf_gen_contact">
                <h3 className="pdf_gen_section_title">Contact</h3>
                <p className="pdf_gen_email">{updatedData?.email}</p>
                <p className="pdf_gen_linkedin">{updatedData?.phone_no}</p>
              </div>
              <div className="pdf_gen_top_skills">
                <h3 className="pdf_gen_section_title">Top Skills</h3>
                <ul className="pdf_gen_skills_list">
                  {updatedData?.skill_name?.map((val, idx) => (
                    <li key={idx}>{val.skill_name}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="pdf_gen_main_content">
              <div className="pb-4">
                {updatedData?.employments?.find(
                  (employment) => employment.is_current_employment === true
                ) ? (
                  <>
                    <h1 className="pdf_gen_name">
                      {updatedData?.first_name} {updatedData?.last_name}
                    </h1>
                    <p className="pdf_gen_title">
                      {
                        updatedData?.employments.find(
                          (employment) =>
                            employment.is_current_employment === true
                        ).current_job_title
                      }{" "}
                      at{" "}
                      {
                        updatedData?.employments.find(
                          (employment) =>
                            employment.is_current_employment === true
                        ).current_company_name
                      }
                    </p>
                    <p className="pdf_gen_location">
                      {updatedData?.current_location}
                    </p>
                  </>
                ) : (
                  <p>No current employment found</p>
                )}
              </div>

              <div className="pdf_amoha_watermark">
                <h1>Amoha</h1>
              </div>

              <div className="pdf_gen_summary">
                <h2 className="pdf_gen_section_title">Summary</h2>
                <p>{updatedData?.bio || "Not Mentioned"}</p>
              </div>

              <div className="pdf_gen_experience">
                <h2 className="pdf_gen_section_title pb-2">Experience</h2>
                {updatedData?.employments?.length > 0 ? (
                  updatedData.employments.map((employment, idx) => (
                    <div key={idx} className="pdf_gen_job">
                      <h3>{employment.current_company_name}</h3>
                      <p className="pdf_gen_job_title">
                        {employment.current_job_title}
                      </p>
                      <p className="pdf_gen_job_duration">
                        {employment.joining_month} {employment.joining_year} -
                        {employment.worked_till_year
                          ? `${employment.worked_till_month} ${employment.worked_till_year}`
                          : "Present"}
                      </p>
                      {/* <p className="pdf_gen_job_location">{employment.location || "Location not mentioned"}</p> */}
                    </div>
                  ))
                ) : (
                  <p>No experience found</p>
                )}
              </div>

              <div className="pdf_gen_education">
                <h2 className="pdf_gen_section_title pb-2">Education</h2>
                {updatedData?.qualifications?.map((qualification, idx) => (
                  <div key={idx} className="pdf_gen_job">
                    <h3>{qualification.institute}</h3>
                    <p className="pdf_gen_job_title">
                      {qualification.degree === "12" ||
                        qualification.degree === "10"
                        ? qualification.board
                        : qualification.course_name}{" "}
                      | {qualification.degree}
                    </p>
                    {qualification.degree === "12" ||
                      qualification.degree === "10" ? (
                      <p className="pdf_gen_job_duration">
                        {new Date(qualification.passing_year).getFullYear() - 1}{" "}
                        -
                        {new Date(qualification.passing_year).toLocaleString(
                          "en-US",
                          { year: "numeric" }
                        )}
                      </p>
                    ) : (
                      <p className="pdf_gen_job_duration">
                        {new Date(qualification.from_date).toLocaleString(
                          "en-US",
                          { year: "numeric" }
                        )}{" "}
                        -
                        {new Date(qualification.to_date).toLocaleString(
                          "en-US",
                          { year: "numeric" }
                        )}
                      </p>
                    )}
                  </div>
                ))}
              </div>

              <div className="page-break"></div>

              <div className="pdf_gen_education">
                <h2 className="pdf_gen_section_title pb-2">Certification</h2>
                {updatedData?.certificates?.map((certificates, idx) => (
                  <div key={idx} className="pdf_gen_job">
                    <h3>{certificates.course_institute}</h3>
                    <p className="pdf_gen_job_title">
                      {certificates.course_name}
                    </p>
                    <p className="pdf_gen_job_duration">
                      {new Date(certificates.course_from).toLocaleString(
                        "en-US",
                        { year: "numeric" }
                      )}{" "}
                      -
                      {new Date(certificates.course_to).toLocaleString(
                        "en-US",
                        { year: "numeric" }
                      )}{" "}
                      -
                    </p>
                    <p className="pdf_gen_job_duration">
                      Validity -
                      {new Date(certificates.valid_till).toLocaleString(
                        "en-US",
                        { year: "numeric" }
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>)
  );
};

export default LetralMyProfile;