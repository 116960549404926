import React, { useEffect, useState } from "react";
import "../../../styles/dashboards/dashboard.css";
import "../../../styles/allJobs/allJobs.css";
import "../../../styles/myApplication/myapplication.css";
import "../../../styles/dashboards/clgdashboard/campusHome.css";
import "chart.js/auto";
import applicationIcon from "../../../utils/dashboards/applicationIcon.svg";
import inReview from "../../../utils/dashboards/inReviewIcon.svg";
import shortListed from "../../../utils/dashboards/shortlistedIcon.svg";
import PlacementTrendGraph from "./placementTrendGraph";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

function CampusManagerHome() {
  const [dataDashboard, setDataDashboard] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const FetchDataHome = async () => {
      try {
        setLoading(true);
        setError(null);
        const accessToken = sessionStorage.getItem('accessToken');

        const headers = {
          Authorization: `Bearer ${accessToken}`
        };

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/collogedashboardmultiple/`,
          { headers }
        );
        setDataDashboard(response.data)

      } catch (error) {
        toast.error(error.message || "An error occurred");
      } finally {
        setLoading(false)
      }
    };

    FetchDataHome();
  }, []);

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-IN', options);
  };

  const calculateRemainingDays = (endDate) => {
    const currentDate = new Date();
    const end = new Date(endDate);
    const timeDiff = end - currentDate;
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysRemaining;
  };

  const filterUpcomingPlacements = (placements) => {
    const currentDate = new Date();
    return placements?.filter((placement) => new Date(placement.end_date) >= currentDate);
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          {loading ? (
            <div className="custom_loader">
              <ClipLoader color="#F38D00" size={50} />
            </div>
          ) : (<>
            <div className="collage_stat_container stat-container">
              <div className="stat-card">
                <div className="stat-icon">
                  <img src={applicationIcon} alt="..." />
                </div>
                <div className="stat-info">
                  <h3>Students</h3>
                  <p>{dataDashboard?.total_students || 0}</p>
                </div>
              </div>

              <div className="stat-card">
                <div className="stat-icon">
                  <img src={inReview} alt="..." />
                </div>
                <div className="stat-info">
                  <h3>Shortlisted</h3>
                  <p>{dataDashboard?.total_shortlisted || 0}</p>
                </div>
              </div>

              {/* <div className="stat-card">
              <div className="stat-icon">
                <img src={rejectedIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Placed</h3>
                <p>{dataDashboard?.total_offered || 0}</p>
              </div>
            </div> */}

              <div className="stat-card">
                <div className="stat-icon">
                  <img src={shortListed} alt="..." />
                </div>
                <div className="stat-info">
                  <h3>Offered</h3>
                  <p>{dataDashboard?.total_offered || 0}</p>
                </div>
              </div>

              <div className="stat-card">
                <div className="stat-icon">
                  <img src={shortListed} alt="..." />
                </div>
                <div className="stat-info">
                  <h3>Interviewed</h3>
                  <p>{dataDashboard?.total_interviewed || 0}</p>
                </div>
              </div>
            </div>
            <div className="_main_content">
              <div className="_main_first_content">
                <div className="campus_manager_chart_section">
                  <div className="campus_manager_chart_header">
                    <b className="campus_manager_chart_title">Department Wise Placement</b>
                  </div>
                  <div className="campus_manager_chart_container">
                    {(() => {
                      const placementValues = Object.values(
                        dataDashboard?.department_wise_placement || {}
                      );

                      const maxCount =
                        placementValues?.length > 0 ? Math.max(...placementValues) : 0;

                      const scale =
                        maxCount === 0
                          ? Array.from({ length: 11 }, (_, index) => (index / 10).toFixed(1))
                          : Array.from({ length: 11 }, (_, index) =>
                            ((maxCount / 10) * index).toFixed(1)
                          );

                      return (
                        <>
                          <div className="campus_manager_scale_markers row mx-0">
                            {scale.map((num, index) => (
                              <div
                                key={index}
                                className="campus_manager_scale_number col text-center"
                              >
                                {num}
                              </div>
                            ))}
                          </div>

                          <div className="campus_manager_department_wrapper">
                            {dataDashboard &&
                              dataDashboard?.department_wise_placement &&
                              Object.entries(dataDashboard?.department_wise_placement).map(
                                ([key, value], index) => (
                                  <div
                                    key={index}
                                    className="campus_manager_department_row row align-items-center mb-4 mx-0"
                                  >
                                    <div className="campus_manager_department_label col-3">
                                      {key}
                                    </div>
                                    <div className="campus_manager_progress_container col px-0">
                                      <div className="campus_manager_progress">
                                        <div
                                          className="campus_manager_progress_bar"
                                          role="progressbar"
                                          style={{
                                            width: `${(value / maxCount) * 100}%`,
                                          }}
                                          aria-valuenow={value}
                                          aria-valuemin="0"
                                          aria-valuemax={maxCount}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}

                            {Object.keys(dataDashboard?.department_wise_placement || {}).length === 0 && (
                              <b className="no_data_found">No Department Wise Placement</b>
                            )}
                          </div>
                        </>
                      );
                    })()}
                  </div>

                </div>

                <div className="d-flex justify-content-between">
                  <div className="_applications_history collag_campus_dashboard_div mt-4">
                    <section className="_campus_dashboard_application_history">
                      <div>
                        <div className="d-flex justify-content-between align-items-center myApplication_top_header">
                          <b>Upcoming Placements</b>
                        </div>

                        <div className="pt-4 pb-4 _application_history_collage_scrollbar">
                          {filterUpcomingPlacements(dataDashboard?.upcoming_placements)?.map((placement, index) => {
                            const daysRemaining = calculateRemainingDays(placement?.end_date);

                            return (
                              <div key={index} className="d-flex align-items-center gap-4 ps-4">
                                <div className="collage_campus_upcoming_date_callande">
                                  <b>{formatDate(placement.end_date)?.split(' ')[0]}</b>
                                  <p>{formatDate(placement.end_date)?.split(' ')[1]}</p>
                                </div>
                                <div className="job-item" style={{ boxShadow: 'none', margin: '0px', padding: '0' }}>
                                  <div className="job-left" style={{cursor:"default"}}>
                                    <img
                                      src={placement.company_logo_url || 'default_logo_url'}
                                      alt="company-logo"
                                      className="company-logo"
                                    />
                                    <div className="job-info">
                                      <h6>{placement?.company_name}</h6>
                                      <p>{daysRemaining} day's Remaining</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        {dataDashboard.upcoming_placements?.length < 1 && (<> <b className="no_data_found">No Upcoming Placements </b> </>)}

                      </div>
                    </section>
                  </div>
                  <div className="_applications_history collag_campus_dashboard_div mt-4">
                    <section className="_campus_dashboard_application_history">
                      <div className="">
                        <div className="d-flex justify-content-between align-items-center myApplication_top_header">
                          <b>CTC Status</b>
                        </div>

                        <div className="pt-4 pb-4 _application_history_collage_scrollbar">
                          {filterUpcomingPlacements(dataDashboard.upcoming_placements)?.map((placement, index) => {
                            const daysRemaining = calculateRemainingDays(placement?.end_date);

                            return (
                              <div key={index} className="d-flex align-items-center gap-4 ps-4 pe-4 pb-2">
                                <div className="job-item" style={{ boxShadow: 'none', margin: '0px', padding: '0' }}>
                                  <div className="job-left" style={{cursor:"default"}}>
                                    <img
                                      src={placement.company_logo_url || 'default_logo_url'}
                                      alt="company-logo"
                                      className="company-logo"
                                    />
                                    <div className="job-info">
                                      <h6>{placement?.company_name?.length > 20 ? `${placement?.company_name?.slice(0, 20)}...` : placement?.company_name}</h6>
                                      <p>{placement?.job_title?.length > 20 ? `${placement?.job_title?.slice(0, 20)}...` : placement?.job_title}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="collage_manager_campus_packages">
                                  <b> {placement?.min_salary_package && placement?.max_salary_package
                                    ? `${parseInt(placement.min_salary_package)} - ${parseInt(placement.max_salary_package)} LPA`
                                    : "N/A"}</b>
                                  {/* <p>Per Annum</p> */}
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        {dataDashboard.upcoming_placements?.length < 1 && (<> <b className="no_data_found">No CTC Status </b> </>)}

                      </div>
                    </section>
                  </div>
                </div>
              </div>

              <div className="d-grid gap-4 _recent_jobs_head">
                <div className="_recent_jobs">
                  <div className="_recentadded_container" style={{ height: "100%" }}>
                    <div className="_popularjobs_section">
                      <div className="_popularjobs_header">
                        <b>Placement Trend</b>
                        {/* <a href="#" className="_popularjobs_seeAllLink">See All</a> */}
                      </div>
                      <hr />
                      <PlacementTrendGraph data={dataDashboard}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>)}
        </div>
      </div>
    </>
  );
}

export default CampusManagerHome;
