import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../../../styles/allJobs/allJobs.css";
import "../../../styles/savedJobs/savedJobs.css";
import experienceIcon from "../../../utils/landingpage/images/experienceIcon.svg";
import dollerIcon from "../../../utils/landingpage/images/dollerIcon.svg";
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png";
import saveIcon from "../../../utils/landingpage/images/saveIcon.svg";
import savedIcon from "../../../utils/landingpage/images/savedIocn.svg";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import Pagination from "@mui/material/Pagination";
import dummy_logo from '../../../utils/landingpage/images/dummy_logo.png'
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const SavedJobs = () => {
  const [experience, setExperience] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [save, setSave] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(null)
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const sliderRef = useRef(null);
  const progressRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const accessToken = sessionStorage.getItem('accessToken');

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/job/saved/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setData(response.data);
      } catch (error) {
        setError("Failed to fetch data");
      }
      finally {
        setLoading(false)
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    if (sliderRef.current && progressRef.current) {
      const percentage = (experience / 30) * 100;
      progressRef.current.style.width = `${percentage}%`;
    }
  }, [experience]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleSave(index) {
    const userDetails = {
      job_id: index,
    };

    const getToken = sessionStorage.getItem('accessToken');

    axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/job/save/`,
      userDetails,
      {
        headers: {
          Authorization: `Bearer ${getToken}`,
        }
      }
    )
      .then(response => {
        console.log('Save successful', response.data);
        setSave(response.data.detail)
        toast.success('Job successfully unsaved!');
      })
      .catch(error => {
        console.log('Error occurred', error);
        toast.error('Failed to unsave the job. Please try again.');
      });
  }

  function handleUnSave(index) {
    const getToken = sessionStorage.getItem('accessToken');

    setButtonLoading(index)
    axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/job/unsave/`,
      {
        headers: {
          Authorization: `Bearer ${getToken}`,
        },
        data: {
          job_id: index,
        }
      }
    )
      .then(response => {
        console.log('UnSave successful', response);
        toast.success(`UnSave successful`);
        setButtonLoading(null)
        const fetchData = async () => {
          try {
            const accessToken = sessionStorage.getItem('accessToken');

            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/job/saved/`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            setData(response.data);
          } catch (error) {
            setError("Failed to fetch data");
          }
        };

        fetchData();
      })
      .catch(error => {
        console.log('Error occurred', error);
        // Show error toast notification
        toast.success('Something Getting Wrong');
      });
  }

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const currentItems = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (error) return <p>{error}</p>;

  const formatSalary = (salary) => {
    if (salary) {
      const floatSalary = parseFloat(salary);
      return floatSalary % 1 === 0 ? floatSalary.toFixed(0) : floatSalary.toFixed(2);
    }
    return null;
  };

  const getPostedDate = (createdAt) => {
    return formatDistanceToNow(parseISO(createdAt), { addSuffix: true });
  };

  return (
    <div className="allJobs_main_container">
      {loading ?
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ClipLoader color="#FFA500" size={60} />
        </div>
        : (<div className="allJobs_container">
          <section className="contact_header alljobs_header savedJobs_header">
            <h2 className="d-grid align-items-center">
              <span>Saved Jobs By You</span>
              <span className="allJobs_savedJobs_numbers">{`(${data.length})`}</span>
            </h2>
          </section>

          <section className="alljobs_jobs_section_head savedJobs_section_head">
            <div className="alljobs_content_head_main">
              <div className="alljobs_content_inner_second savedJobs_content_inner_second">
                <div>
                  <div className="job-header">
                    <h2>{`${data.length} jobs found`}</h2>
                    <div className="sort-dropdown">
                    </div>
                  </div>

                  <div className="job-listings">
                    {currentItems && currentItems.map((jobData, index) => (jobData?.is_saved === true &&
                      <div key={index} className="job-listing">
                        <div className="company-info">
                          <Link to={`/lateral/jobDetails/${jobData.job.id}`}>
                            <img
                              src={jobData?.job?.company_profile?.company_logo_url || dummy_logo}
                              alt={` logo`}
                              className="company-logo"
                            />
                          </Link>
                          <div>
                            <Link to={`/lateral/jobDetails/${jobData.job.id}`}>
                              <h2>{jobData.job.title}</h2>
                            </Link>
                            <div className="d-flex gap-2 saved-jobs-company-review">
                              <div className="company-name">
                                {jobData.job.company || "Not Mentioned"}
                              </div>
                              <div className="rating">
                                <span className="star">★</span> {jobData?.job?.company_profile?.company_rating || '0'}
                                <span
                                  style={{
                                    fontWeight: "200",
                                    color: "#3c3c3c69",
                                  }}
                                >
                                  |
                                </span>{" "}
                                {jobData?.job?.company_profile?.company_review || "0"} Reviews
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="job-details">
                          <span className="detail">
                            {" "}
                            <span className="details_job_icon">
                              <img src={experienceIcon} alt="..." />{" "}
                            </span>{" "}
                            {`${jobData.job.min_experience || "0"} - ${jobData.job.max_experience || "0"} yrs`}
                            <span className="stand_icon">|</span>{" "}
                          </span>
                          <span className="detail">
                            {" "}
                            <span className="details_job_icon">
                              <img src={dollerIcon} alt="..." />{" "}
                            </span>{" "}
                            {jobData?.job.min_salary || jobData?.job?.max_salary
                              ? `${formatSalary(jobData?.job.min_salary || '0')} - ${formatSalary(jobData?.job.max_salary)} LPA`
                              : 'Salary not specified'}
                            <span className='stand_icon'>|</span>
                          </span>
                          <span className="detail">
                            {" "}
                            <span className="details_job_icon">
                              <img src={locationIcon} alt="..." />{" "}
                            </span>{" "}
                            {jobData.job.job_location || 'Not Mentioned'}
                          </span>
                        </div>

                        <p className="job-description">{jobData?.job?.description.length > 150 ? `${jobData.job?.description.slice(0, 150)}...` : jobData.job?.description}</p>
                        <div className="listing-footer">
                          <span className="posted-date">
                            {jobData.job?.created_at ? getPostedDate(jobData.job?.created_at) : 'Date not available'}
                          </span>

                          {jobData?.is_saved === true && (<button
                            className="save_button_letral save-button"
                            onClick={() => handleUnSave(jobData?.job?.id)}
                          >
                            {" "}
                            <span className="details_job_icon">
                              <img src={saveIcon} alt="..." />
                            </span>{" "}
                            <span>{buttonLoading === jobData?.job?.id ? <span className="spinner"></span> : `UnSave`}</span>{" "}
                          </button>)}

                          {jobData?.is_saved === false && (<button
                            className="save_button_letral save-button"
                            onClick={() => handleSave(jobData?.job?.id)}
                          >
                            {" "}
                            <span className="details_job_icon">
                              <img src={saveIcon} alt="..." />
                            </span>{" "}
                            <span>Save</span>{" "}
                          </button>)}

                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="pagination-controls">
                    <Stack spacing={2} alignItems="center">
                      <Pagination
                        className="custom-pagination"
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        renderItem={(item) => (
                          <PaginationItem
                            components={{
                              previous: () => <img src={leftArrowIcon} alt="Previous" />,
                              next: () => <img src={rightIconArrow} alt="Next" />,
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>)}
    </div>
  );
};

export default SavedJobs;