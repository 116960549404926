import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../../styles/settings/setting.css";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';

const Setting = () => {

  const [notificationToggle, setNotificationToggle] = useState(false);
  const [inputFields, setInputFields] = useState({
    old_password: "",
    password: "",
    password2: "",
  });
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [enterOTP, setEnterOTP] = useState(false)
  const [otp, setOtp] = useState('')

  const isSocialLogin = sessionStorage.getItem("login_type");

  const handleToggleChange = () => {
    setNotificationToggle(!notificationToggle);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setInputFields({ ...inputFields, [name]: value });
  };

  const validate = () => {
    let errors = {};

    if (!inputFields.old_password) {
      errors.old_password = "Old password is required";
    }
    if (!inputFields.password) {
      errors.password = "New password is required";
    } else if (inputFields.password.length < 5) {
      errors.password = "Password must be at least 5 characters";
    }
    if (inputFields.password2 !== inputFields.password) {
      errors.password2 = "Passwords must match";
    }
    if (inputFields.old_password === inputFields.password) {
      errors.password = "Old password and new password should be different";
    }

    if (
      !inputFields.old_password ||
      !inputFields.password ||
      !inputFields.password2
    ) {
      errors.generic = "All fields are required";
    }

    return errors;
  };

  const handleCancel = () => {
    setInputFields({
      old_password: "",
      password: "",
      password2: "",
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();

    if (inputFields.old_password === "" || inputFields.password === "" || inputFields.password2 === "") {
      toast.error("All Fields Are Required")
      return;
    }

    if (inputFields.old_password === inputFields.password) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Old Password and New Password should be different",
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }

    const accessToken = sessionStorage.getItem('accessToken');

    if (Object.keys(validationErrors).length === 0) {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      setPasswordLoading(true)

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/changepassword/`, inputFields, config)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message || "Password changed successfully!",
            timer: 3500,
            timerProgressBar: true,
            showConfirmButton: true,
            customClass: {
              confirmButton: "custom-swal-confirm-button",
            },
          });
          setError({});
          setPasswordLoading(false)
          setInputFields({
            old_password: "",
            password: "",
            password2: "",
          });
        })
        .catch((error) => {
          if (error.response?.data?.non_field_errors) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: error.response.data.non_field_errors[0] || "An error occurred.",
            });
            setError({});
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: error.response?.data?.message || "Failed to change password.",
            });
            setError({});
          }

          setPasswordLoading(false)
        }
        );
    } else {
      setError(validationErrors);
      setPasswordLoading(false)
    }
  };

  const handleChangeOTP = (event) => {
    setOtp(event.target.value)
  }

  const handleSubmitOTP = async () => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');

      if (!accessToken) {
        console.error('No access token found');
        return;
      }

      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/change-email-verify-otp/`,
        { otp },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
        }
      );

      Swal.fire({
        title: "OTP Verified!",
        text: "Your email change has been verified successfully.",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      setEnterOTP(false);
      setOtp('')
    } catch (error) {
      let errorMessage = "Something went wrong!";
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      setOtp('')
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = async () => {
    setLoading(true)

    try {
      const accessToken = sessionStorage.getItem('accessToken');

      if (!accessToken) {
        console.error('No access token found');
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/update-email/`,
        { email },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );

      let scuccesMessage = "OTP has been sent to your register email."

      if (response && response.data && response.data.msg) {
        scuccesMessage = response.data.msg;
      }

      setEnterOTP(true)

      Swal.fire({
        title: "OTP Send!",
        text: scuccesMessage,
        icon: "success",
        timer: 3500,
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      });

      setLoading(false)

    } catch (error) {
      let errorMessage = "Something went wrong!";

      if (error.response && error.response.data && error.response.data.email) {
        errorMessage = error.response.data.email[0]; // Accessing the error message from the response
      }

      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      });

      setLoading(false)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="setting_sec">
      <div className="setting_act-container">
        <div className="setting_act_set">
          <h5>Account Setting</h5>
        </div>

        <div className="setting_main_div">
          <div className="setting_Bas_info">
            {isSocialLogin == "manual" && (<div className="setting_Bas_Div">
              <h6>Basic Information</h6>
              <span>Change your email and password.</span>
            </div>)}
            <div className="setting_sqbox">

              {isSocialLogin == "manual" &&
                (<div>
                  {enterOTP === false ? (
                    <div className="setting_sqbox-1">
                      <div className="setting_info">
                        <h6>Change Email</h6>
                        <span>
                          We will send you a verification email on this email ID.
                        </span>
                      </div>
                      <div className="setting_Input">
                        <input
                          type="email"
                          className="setting_textarea"
                          placeholder="Enter Email"
                          value={email}
                          autoComplete="off"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="setting_btn">
                        <div>
                          <div onClick={handleEmailChange} className="setting_custom-btn setting_btn-1 setting_email_register" >{loading ? <div className="spinner"></div> : `Send`}</div>
                        </div>
                        <div>
                          <button onClick={() => setEmail('')} className="setting_custom-btn setting_btn-2">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>) :
                    (<div className="setting_sqbox-1">
                      <div className="setting_info">
                        <h6>Enter OTP</h6>
                      </div>
                      <div className="setting_Input">
                        <input
                          type="number"
                          className="setting_textarea"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={handleChangeOTP}
                        />
                      </div>
                      <div className="setting_btn">
                        <div>
                          {loading ? <div className="spinner"></div> : <div onClick={handleSubmitOTP} className="setting_custom-btn setting_btn-1 setting_email_register" >Send</div>}
                        </div>
                        <div>
                          <button onClick={() => setEnterOTP(false)} className="setting_custom-btn setting_btn-2">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>)
                  }
                </div>)
              }

              {/* <div className="setting_sqbox-1">
                <div className="setting_info">
                  <span>Mobile Number</span>
                </div>
                <div className="setting_Input">
                  <input
                    type="tel"
                    className="setting_textarea"
                    placeholder="Enter Mobile Number"
                    value={inputFeild}
                      autoComplete="off"
                    onChange={handleChange}
                  />
                </div>
                <div className="setting_btn">
                  <div>
                    <Link>
                      {" "}
                      <button className="setting_custom-btn setting_btn-1 setting_otp_btn">
                        Get OTP
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link>
                      {" "}
                      <button className="setting_custom-btn setting_btn-2">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </div> */}

              {/* Squre box3 */}
              {isSocialLogin == "manual" &&
                (<div className="setting_sqbox-1">
                  <div className="setting_info">
                    <h6>Password</h6>
                    <span>
                      {`Your password should be at least 5 characters.`}
                    </span>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="setting_Input">

                      <div>
                        <input
                          type="password"
                          name="old_password"
                          className="setting_textarea"
                          placeholder="Enter your current password"
                          autoComplete="off"
                          value={inputFields.old_password}
                          onChange={handlePasswordChange}
                        />
                        {error.old_password && <div className="error p-0 m-0">{error.old_password}</div>}
                      </div>

                      <div className="setting_new_pass">

                        <div className="setting_new_pass_inner_div">
                          <input
                            type="password"
                            name="password"
                            className="setting_pass"
                            placeholder="Enter a new password"
                            value={inputFields.password}
                            onChange={handlePasswordChange}
                          />
                          {error.password && <div className="error p-0 m-0">{error.password}</div>}
                        </div>

                        <div className="setting_new_pass_inner_div">
                          <input
                            type="password"
                            name="password2"
                            className="setting_pass"
                            placeholder="Confirm password"
                            value={inputFields.password2}
                            onChange={handlePasswordChange}
                          />
                          {error.password2 && <div className="error p-0 m-0">{error.password2}</div>}
                        </div>
                      </div>
                    </div>

                    <div className="setting_btn">
                      <div>
                        <button
                          type="submit"
                          className="setting_custom-btn setting_btn-1 setting_otp_btn"
                        >
                          {passwordLoading ? <div className="spinner"></div> : 'Submit'}
                        </button>
                      </div>
                      <div>
                        <button onClick={handleCancel} className="setting_custom-btn setting_btn-2">Cancel</button>
                      </div>
                    </div>

                    {message && <p>{message}</p>}
                  </form>
                </div>)
              }

              {/* Squre box4 */}
              <div className="setting_sqbox-1 setting_Notification">
                <div className="setting_info">
                  <h6>Notification</h6>
                  <span>
                    This is your personal information that you can update anytime
                  </span>
                </div>
                <div className="setting_toggle">
                  <input
                    type="checkbox"
                    id="mode-toggle"
                    className="setting_toggle__input"
                    checked={notificationToggle}
                    onChange={handleToggleChange}
                  />
                  <label
                    htmlFor="mode-toggle"
                    className="setting_toggle__label"
                  ></label>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Setting;