import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSuperAdminDashboardData } from "../../../redux/actions/super_admin_action";
import "../../../styles/admin/adminhome.css";
import { formatDistanceToNow, parseISO } from "date-fns";
import companyIcon from "../../../utils/dashboards/companies.svg";
import candidatesIcon from "../../../utils/dashboards/Candidates.svg";
import inInterviewIcon from "../../../utils/dashboards/inInterview.svg";
import "../../../styles/dashboards/hrdashboard/hrjoblisting.css";
import { Tooltip } from "@mui/material";
import CountUp from "react-countup";
import ApexCharts from "apexcharts";
import { useNavigate } from "react-router-dom";
import { setActiveApplication } from "../../../redux/reducers/super_admin_reducer";

const formatKey = (key) => {
  return key
    .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/\sCount$/, "");
};

function CampusSection({
  campusHiring,
  upcomingDrives,
  error,
  getCount,
  defaultCountStatus,
  navigate,
}) {
  const [chartData, setChartData] = useState({
    categories: Object.keys(campusHiring),
    series: Object.values(campusHiring),
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    setChartData({
      categories: Object.keys(campusHiring),
      series: Object.values(campusHiring),
    });

    setLoading(false);
  }, [campusHiring]);

  useEffect(() => {
    const chartElement = document.querySelector(".campus_count_bar_chart");

    if (chartElement) {
      const options = {
        series: [
          {
            // data: chartData.series,
            data: [10, 20, 30, 40, 100],
          },
        ],
        chart: {
          height: 330,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: chartData?.categories?.map((category) =>
            formatKey(category)
          ),
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      };

      const chart = new ApexCharts(chartElement, options);
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [chartData]);

  if (loading) {
    return (
      <div className="">
        <p>Loading...</p>
      </div>
    );
  }

  const handleCampusApplication = (filter) => {
    const path = `/dashboard/sidebar-page/admin/all-applications/${"campus"}/${filter}`;
    navigate(path);
  };

  const handleCompany = () => {
    const path = `/dashboard/sidebar-page/admin/companies`;
    navigate(path);
  };

  const handleCandidate = () => {
    const path = `/dashboard/sidebar-page/admin/students`;
    navigate(path);
  };

  const handleCollage = () => {
    const path = `/dashboard/sidebar-page/admin/colleges`;
    navigate(path);
  };

  return (
    <>
      <div className="admin-stat-container">
        <div className="admin-stat-card" onClick={handleCompany}>
          <div className="stat-icon">
            <img src={companyIcon} alt="..." />
          </div>
          <div className="stat-info">
            <h3>Number Of Companies</h3>
            <p>
              <CountUp
                start={0}
                end={getCount(defaultCountStatus?.totalCompanies)}
                duration={2.5}
              />
            </p>
          </div>
        </div>

        <div className="admin-stat-card" onClick={handleCandidate}>
          <div className="stat-icon">
            <img src={candidatesIcon} alt="..." />
          </div>
          <div className="stat-info">
            <h3>Number Of Candidates</h3>
            <p>
              <CountUp
                start={0}
                end={getCount(campusHiring?.studentsCount)}
                duration={2.5}
              />
            </p>
          </div>
        </div>

        <div className="admin-stat-card">
          <div className="stat-icon">
            <img src={inInterviewIcon} alt="..." />
          </div>
          <div className="stat-info">
            <h3>Total Jobs</h3>
            <p>
              <CountUp
                start={0}
                end={getCount(campusHiring?.jobsCount)}
                duration={2.5}
              />
            </p>
          </div>
        </div>

        <div className="admin-stat-card">
          <div className="stat-icon">
            <img src={inInterviewIcon} alt="..." />
          </div>
          <div className="stat-info">
            <h3>Total Applications</h3>
            <p>
              <CountUp
                start={0}
                end={getCount(defaultCountStatus?.totalColleges)}
                duration={2.5}
              />
            </p>
          </div>
        </div>

        <div
          className="admin-stat-card"
          onClick={() => handleCampusApplication("null")}
        >
          <div className="stat-icon">
            <img src={inInterviewIcon} alt="..." />
          </div>
          <div className="stat-info">
            <h3>Total Applications</h3>
            <p>
              <CountUp
                start={0}
                end={getCount(campusHiring?.applicationsCount)}
                duration={2.5}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="mt-3 campus_section_container">
        <div className="campus_section_one">
          <div className="campus_counts">
            <button
              className="admin_status in-review"
              onClick={() => {
                handleCampusApplication("in_review");
              }}
            >
              In Review
            </button>
            <button
              className="admin_status shortlist"
              onClick={() => {
                handleCampusApplication("shortlisted");
              }}
            >
              Shortlisted
            </button>
            <button
              className="admin_status interview"
              onClick={() => {
                handleCampusApplication("interviewed");
              }}
            >
              Interview
            </button>
            <button
              className="admin_status decline"
              onClick={() => {
                handleCampusApplication("discarded");
              }}
            >
              Decline
            </button>
            <button
              className="admin_status offered"
              onClick={() => {
                handleCampusApplication("offered");
              }}
            >
              Offered
            </button>
          </div>
          <div className="campus_count_bar_chart"></div>
        </div>
        <div className="campus_section_two">
          <p className="up_coming_drive_heading">Up coming Drive's</p>
          <div className="campus_up_coming_drives">
            {upcomingDrives?.slice(0, 5).map((drive, index) => {
              const startDate = parseISO(drive.job__start_date);
              const timeToGo = formatDistanceToNow(startDate, {
                addSuffix: true,
              });

              const companyName = drive.company__company_name
                ? drive.company__company_name.charAt(0).toUpperCase() +
                drive.company__company_name.slice(1)
                : "No company name provided";

              const collegeName =
                drive.college__collegename || "No college name provided";

              return (
                <div key={index} className="drive_card">
                  <p className="college_name">{companyName}</p>
                  <p className="company_name">{collegeName}</p>
                  <p className="time_to_go">{timeToGo}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

// LateralSection Component
function LateralSection({
  defaultCountStatus,
  getCount,
  recentJobs,
  lateralHiring,
  navigate,
  application
}) {
  const [chartData, setChartData] = useState({
    categories: Object.keys(lateralHiring),
    series: Object.values(lateralHiring),
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    setChartData({
      categories: Object.keys(lateralHiring),
      series: Object.values(lateralHiring),
    });

    setLoading(false);
  }, [lateralHiring]);

  useEffect(() => {
    const options = {
      series: [
        {
          data: chartData.series,
        },
      ],
      chart: {
        height: 330,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: chartData.categories.map((category) => formatKey(category)),
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
    };

    const chart = new ApexCharts(
      document.querySelector(".lateral_count_bar_chart"),
      options
    );
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [chartData]);

  const handleLateralApplication = (filter) => {
    const path = `/dashboard/sidebar-page/admin/all-applications/${"lateral"}/${filter}`;
    navigate(path);
  };

  const handleCompany = () => {
    const path = `/dashboard/sidebar-page/admin/companies`;
    navigate(path);
  };

  const handleCandidate = () => {
    const path = `/dashboard/sidebar-page/admin/students`;
    navigate(path);
  };

  const handleJob = () => {
    const path = `/dashboard/sidebar-page/admin/admin-jobs`;
    navigate(path);
  };

  return (
    <>
      {" "}
      <div className="admin-stat-container">
        <div className="admin-stat-card" onClick={handleCompany}>
          <div className="stat-icon">
            <img src={companyIcon} alt="..." />
          </div>
          <div className="stat-info">
            <h3>Number Of Companies</h3>
            <p>
              <CountUp
                start={0}
                end={getCount(defaultCountStatus?.totalCompanies)}
                duration={2.5}
              />
            </p>
          </div>
        </div>

        <div className="admin-stat-card" onClick={handleCandidate}>
          <div className="stat-icon">
            <img src={candidatesIcon} alt="..." />
          </div>
          <div className="stat-info">
            <h3>Number Of Candidates</h3>
            <p>
              <CountUp
                start={0}
                end={getCount(lateralHiring?.studentsCount)}
                duration={2.5}
              />
            </p>
          </div>
        </div>

        <div className="admin-stat-card" onClick={handleJob}>
          <div className="stat-icon">
            <img src={inInterviewIcon} alt="..." />
          </div>
          <div className="stat-info">
            <h3>Total Jobs</h3>
            <p>
              <CountUp
                start={0}
                end={getCount(lateralHiring?.jobsCount)}
                duration={2.5}
              />
            </p>
          </div>
        </div>

        <div
          className="admin-stat-card"
          onClick={() => handleLateralApplication("null")}
        >
          <div className="stat-icon">
            <img src={inInterviewIcon} alt="..." />
          </div>
          <div className="stat-info">
            <h3>Total Applications</h3>
            <p>
              <CountUp
                start={0}
                end={getCount(lateralHiring?.applicationsCount)}
                duration={2.5}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="mt-3 lateral_section_container">
        <div className="lateral_section_one">
          <div className="campus_counts">
            <button
              className="admin_status in-review"
              onClick={() => {
                handleLateralApplication("in_review");
              }}
            >
              In Review
            </button>
            <button
              className="admin_status shortlist"
              onClick={() => {
                handleLateralApplication("shortlisted");
              }}
            >
              Shortlisted
            </button>
            <button
              className="admin_status interview"
              onClick={() => {
                handleLateralApplication("interviewed");
              }}
            >
              Interview
            </button>
            <button
              className="admin_status decline"
              onClick={() => {
                handleLateralApplication("discarded");
              }}
            >
              Decline
            </button>
            <button
              className="admin_status offered"
              onClick={() => {
                handleLateralApplication("offered");
              }}
            >
              Offered
            </button>
          </div>
          <div className="lateral_count_bar_chart"></div>
        </div>

        <div className="lateral_section_two">
          <div className="recent_jobs_table">
            <div className="table_header">
              <div className="table_cell">Job Title</div>
              <div className="table_cell">Location</div>
              <div className="table_cell">Experience</div>
              <div className="table_cell">Company</div>
            </div>
            {recentJobs?.slice(0, 10).map((job) => (
              <div key={job.id} className="table_row">
                <div className="table_cell">
                  {job.title.length > 12
                    ? `${job.title.slice(0, 12)}...`
                    : job.title}
                </div>

                <div className="table_cell">
                  {job.job_location.charAt(0).toUpperCase() +
                    job.job_location.slice(1)}
                </div>

                <div className="table_cell">
                  {job.min_experience} - {job.max_experience} years
                </div>
                <div className="table_cell">
                  <Tooltip
                    title={job.company__company_name || "Not Available"}
                    arrow
                  >
                    <span className="company_name">
                      {job.company__company_name
                        ? `${job.company__company_name.charAt(0).toUpperCase() +
                        job.company__company_name.slice(1, 15)
                        }...`
                        : "Not Available"}
                    </span>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function AdminHome() {
  const [applicantType, setApplicantType] = useState("Lateral");
  const getCount = (count) => (count !== undefined ? count : 0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    lateralHiring,
    defaultCountStatus,
    upcomingDrives,
    campusHiring,
    recentJobs,
    loading,
    error,
  } = useSelector((state) => state.superAdmin);

  useEffect(() => {
    dispatch(fetchSuperAdminDashboardData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setActiveApplication(applicantType))
  }, [applicantType, dispatch]);

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="d-flex">
          <button
            onClick={() => setApplicantType("Campus")}
            className={`all_applicants_toggle_btn ${applicantType === "Campus" ? "active" : ""
              }`}
          >
            Campus
          </button>
          <button
            onClick={() => setApplicantType("Lateral")}
            className={`all_applicants_toggle_btn ${applicantType === "Lateral" ? "active" : ""
              }`}
          >
            Lateral
          </button>
        </div>

        {applicantType === "Lateral" ? (
          <LateralSection
            defaultCountStatus={defaultCountStatus}
            getCount={getCount}
            lateralHiring={lateralHiring}
            recentJobs={recentJobs}
            loading={loading}
            error={error}
            navigate={navigate}
            application={'Lateral'}
          />
        ) : (
          <CampusSection
            defaultCountStatus={defaultCountStatus}
            getCount={getCount}
            campusHiring={campusHiring}
            upcomingDrives={upcomingDrives}
            loading={loading}
            error={error}
            navigate={navigate}
            application={'Campus'}
          />
        )}
      </div>
    </div>
  );
}

export default AdminHome;
