import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import "../../../styles/allJobs/allJobs.css";
import "../../../styles/assignmentModel/assignmentModel.css"
import microsoftIMG from "../../../utils/landingpage/images/microsoft-logo.svg"
import experienceIcon from "../../../utils/landingpage/images/experienceIcon.svg"
import dollerIcon from "../../../utils/landingpage/images/dollerIcon.svg"
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png"
import dummy_logo from '../../../utils/landingpage/images/dummy_logo.png'
import saveIcon from "../../../utils/landingpage/images/saveIcon.svg"
import savedIcon from "../../../utils/landingpage/images/savedIocn.svg"
import rightArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import logofacebook from "../../../utils/landingpage/images/logo_facebook.svg";
import logotwitter from "../../../utils/landingpage/images/logo_twitter.svg";
import logoyoutube from "../../../utils/landingpage/images/logo_youtube.svg";
import logoinsta from "../../../utils/landingpage/images/logo_insta.svg";
import googleicon from "../../../utils/auth/images/googleicon.svg";
import axios from 'axios';
import { formatDistanceToNow, parseISO } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-spinners/ClipLoader";

const PublicJobDetails = () => {

    const [experience, setExperience] = useState(0);
    const [token, setToken] = useState(null)
    const [isLogin, setIsLogin] = useState(null);
    const [isLoding, setIsLoading] = useState(false)
    const [jobDetails, setDetails] = useState([]);
    const [jobId, setJobId] = useState(null);
    const { id } = useParams();

    const sliderRef = useRef(null);
    const progressRef = useRef(null);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation()


    useEffect(() => {
        if (sliderRef.current && progressRef.current) {
            const percentage = (experience / 30) * 100;
            progressRef.current.style.width = `${percentage}%`;
        }
    }, [experience]);

    const getPostedDate = (createdAt) => {
        return formatDistanceToNow(parseISO(createdAt), { addSuffix: true });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        setJobId(id);
    }, [id, isLogin]);

    useEffect(() => {
        const jobIdPath = location.pathname; 
        console.log("---job id", jobIdPath);

        const jobIdMatch = jobIdPath.match(/\/(\d+)$/);
        if (jobIdMatch) {
            const jobId = jobIdMatch[1]; 
            console.log("Extracted Job ID:", jobId);

            sessionStorage.setItem("PublicJobId", jobId);
        }
    }, [location]);

    useEffect(() => {
        if (jobId) {
            setIsLoading(true)
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/campus-jobs/${jobId}/`)
                .then(response => {
                    setDetails(response.data);
                    setIsLoading(false)
                })
                .catch(error => {
                    console.error('Error occurred', error);
                    setIsLoading(false)
                });
        } else {
            console.error('Job ID is not available, skipping API call.');
            setIsLoading(false)
        }
    }, [jobId]);

    function handleLogin() {
        navigate('/login');
    }

    const formatSalary = (salary) => {
        if (salary) {
            const floatSalary = parseFloat(salary);
            return floatSalary % 1 === 0 ? floatSalary.toFixed(0) : floatSalary.toFixed(2);
        }
        return null;
    };

    const handleClickRedirect = () => {
        navigate('/register');
    }

    return (<>
        {/* <ToastContainer /> */}
        <div className='allJobs_main_container'>
            <div className='allJobs_container'>
                {isLoding ? (
                    <div className="custom_loader">
                        <Loader color="#F38D00" size={50} />
                    </div>
                ) : (<>
                    <section className="contact_header alljobs_header jobDetails_header">
                        <h2 className='d-grid align-items-center'>
                            <span>Job Details</span>
                        </h2>
                        <h6>Full Details About Job and Company.</h6>
                    </section>

                    <section className='jobDetails_main_container'>
                        <div className='w-100'>
                            <b className='jobDetails_b'>Job Details</b>

                            <div className='pt-4 pb-2'>
                                <div className="job-listings">
                                    <div className="job-listing">
                                        <div className="company-info">
                                            <img src={jobDetails.company_profile?.company_logo_url || dummy_logo} alt={`logo`} className="company-logo" />
                                            <div>
                                                <h2>{jobDetails.title}</h2>
                                                <div className='d-flex gap-2 jdrc_class'>
                                                    <div className="company-name">{jobDetails?.company || 'Not Mentioned'}</div>
                                                    <div className="rating">
                                                        <span className="star">★</span> {jobDetails.company_profile?.company_rating || '0'} <span style={{ fontWeight: "300", color: "#3c3c3c69" }}>|</span> {jobDetails?.company_profile?.company_review || '0'} Reviews
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="job-details job_details_new">
                                            <span className="detail"> <span className='details_job_icon'><img src={experienceIcon} alt='...' /> </span> {jobDetails?.min_experience || '0'}-{jobDetails?.max_experience || '0'} yrs <span className='stand_icon'>|</span> </span>
                                            <span className="detail">
                                                <span className='details_job_icon'><img src={dollerIcon} alt='...' /></span>
                                                {jobDetails?.min_salary && jobDetails?.max_salary
                                                    ? `${formatSalary(jobDetails.min_salary)}-${formatSalary(jobDetails.max_salary)} LPA`
                                                    : 'Not specified'}
                                                <span className='stand_icon'>|</span>
                                            </span>
                                            <span className="detail"> <span className='details_job_icon'><img src={locationIcon} alt='...' /> </span> {jobDetails?.job_location || 'Not Mentioned'} </span>
                                        </div>
                                        <div className="listing-footer">
                                            <div className="job-details">
                                                <span className="detail">
                                                    Posted:
                                                    <span className='custom_fonts'>
                                                        {jobDetails?.created_at ? getPostedDate(jobDetails?.created_at) : 'Date not available'}
                                                    </span>
                                                    <span className='stand_icon'>|</span>
                                                </span>
                                                <span className="detail"> Openings: <span className='custom_fonts'>{jobDetails?.vacancy || '0'}</span> <span className='stand_icon'>|</span> </span>
                                                <span className="detail"> Applicants: <span className='custom_fonts'>{jobDetails?.total_applicants | '0'}</span> </span>
                                            </div>

                                            <div className='d-flex gap-2'>
                                                <button onClick={handleClickRedirect} className="rounded-3 btn jobDetails_register_to_apply_btn ">
                                                    Register Now
                                                </button>
                                                <button onClick={handleLogin} className="jobDetails_button">Login for Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='jobDatails_description'>
                                <h2>Job Description</h2>
                                <p>{jobDetails?.description || 'Not Mentioned'}</p> <br />
                                <p><span>Role:</span> {jobDetails?.title || 'Not Mentioned'}</p>
                                <p><span>Industry Type:</span> {jobDetails?.company_profile?.industry || 'Not Mentioned'}</p>
                                <p><span>Employment Type:</span> {jobDetails?.type || 'Not Mentioned'}</p>
                                <p><span>Education:</span> {jobDetails?.qualification || 'Not Mentioned'}</p>
                                <p><span>Key Skills:</span>
                                    {jobDetails?.job_skills?.map((skill, index) => (
                                        <span style={{ fontWeight: '400' }} key={index}> {skill.skill_name}{index !== jobDetails.job_skills.length - 1 ? ', ' : ''}</span>
                                    ))}
                                </p>
                                {jobDetails?.perks_benefits && (<p><span>Benefits:</span> {jobDetails?.perks_benefits || 'Not Mentioned'}</p>)}
                            </div>

                            <div className="about-company">
                                <h2>About Company</h2>
                                <p>
                                    {jobDetails?.company_profile?.company_desc || 'Not Mentioned'}
                                </p>
                                <div className="social-icons">
                                    <a href={jobDetails?.company_profile?.facebook} className="social-icon">
                                        <img src={logofacebook} alt="Group-img" />
                                    </a>
                                    <a href={jobDetails?.company_profile?.twitter} className="social-icon">
                                        <img src={logotwitter} alt="Group-img" />
                                    </a>
                                    <a href={jobDetails?.company_profile?.linked_in} className="social-icon">
                                        <img src={logoyoutube} alt="Group-img" />
                                    </a>
                                    <a href={jobDetails?.company_profile?.instagram} className="social-icon">
                                        <img src={logoinsta} alt="Group-img" />
                                    </a>
                                </div>
                            </div>

                        </div>

                    </section>
                </>)}

            </div>
        </div>

    </>);
};

export default PublicJobDetails;