import React from 'react'

const TransactionHistory = () => {
    return (
        <div className="_main_content_inner_screen">
            <div className="_main_content_inner_screen_conatiner">

                <div>

                    <h1>Transiotion histoy</h1>
                </div>

            </div>
        </div>
    )
}

export default TransactionHistory;