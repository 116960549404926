import "../../../styles/dashboards/campusecandidaterequest.css";
import "../../../styles/admin/adminRequest.css";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Pagination } from "@mui/material";
import searchLogo from "../../../utils/dashboards/searchIconDash.svg";
import RightArrow from "../../../utils/landingpage/images/rightarroworange.svg";
// import CognizantLogo from "../../../utils/landingpage/images/Cognizant.svg"
import { toast } from "react-toastify";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

function Colleges() {
  const [finalCardData, setFinalCardData] = useState([]);
  const [filterCardData, setFilterCardData] = useState([]);
  const [currentItem, setCurrentItem] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [collegeOptions, setCollegeOptions] = useState(false);
  const [filteredColleges, setFilteredColleges] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  const itemPerPage = 10;

  const initialValue = {
    firstName: "",
    lastName: "",
    collegeName: "",
    email: "",
    password: "",
    password2: "",
    phoneNumber: "",
  };
  // Handle form input changes
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    collegeName: "",
    email: "",
    password: "",
    password2: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    collegeName: "",
    email: "",
    password: "",
    password2: "",
    phoneNumber: "",
  });

  const fetchCollegeData = async () => {
    setLoading(true);

    try {
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/approved-colleges-list/`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const result = await response.json();
      setFilterCardData(result);
      setFinalCardData(result);
    } catch (error) {
      console.error("Failed to fetch college data:", error);
      toast.error("Failed to fetch requests.");
    } finally {
      setLoading(false);
    }
  };

  const fetchColleges = async (searchQuery) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/college-data/?search=${searchQuery}`
      );
      const colleges = response.data.results || [];
      setFilteredColleges(colleges);
    } catch (error) {
      console.error("Error fetching colleges:", error);
    }
  };

  useEffect(() => {
    fetchCollegeData();
    fetchColleges("");
  }, []);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    setCurrentItem(finalCardData.slice(startIndex, endIndex));
  }, [currentPage, finalCardData]);

  const filteredRequests = (searchQuery) => {
    return filterCardData.filter((user) =>
      user?.college_name.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearchQuery(value);
    setFinalCardData(filteredRequests(value));
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "collegeName") {
      setCollegeOptions(false);
    }

    switch (name) {
      case "firstName":
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: value.trim() === "" ? "First Name is required" : "",
        }));
        break;

      case "lastName":
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: value.trim() === "" ? "Last Name is required" : "",
        }));
        break;

      case "email":
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: !emailRegex.test(value) ? "Invalid email address" : "",
        }));
        break;

      case "password":
        setErrors((prevErrors) => ({
          ...prevErrors,
          password:
            value.length < 6 ? "Password must be at least 6 characters" : "",
        }));
        break;

      case "password2":
        setErrors((prevErrors) => ({
          ...prevErrors,
          password2:
            value !== formData.password ? "Passwords do not match" : "",
        }));
        break;

      case "phoneNumber":
        const phoneRegex = /^[0-9]{10}$/;
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: !phoneRegex.test(value) ? "Invalid phone number" : "",
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeCollegeData = (e) => {
    const { name, value } = e.target;
    fetchColleges(value);
    if (!!value) {
      setCollegeOptions(true);
    }
    switch (name) {
      case "collegeName":
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: value.trim() === "" ? "College Name is required" : "",
        }));
        break;
      default:
        break;
    }

    setFormData({ ...formData, collegeName: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCollegeOptions(false);

    // Reset errors
    setErrors({
      firstName: "",
      lastName: "",
      collegeName: "",
      email: "",
      password: "",
      password2: "",
      phoneNumber: "",
    });

    let isValid = true;
    const newErrors = {};

    // Validate fields
    for (const [key, value] of Object.entries(formData)) {
      if (!value) {
        newErrors[key] = `${key} is required`;
        isValid = false;
      }
    }

    if (formData.password !== formData.password2) {
      newErrors.password2 = "Passwords do not match";
      isValid = false;
    }

    if (!isValid) {
      setErrors(newErrors);
      return;
    }
    const formDataValue = new FormData();

    for (const key in formData) {
      setIsLoader(true);
      if (Object.prototype.hasOwnProperty.call(formData, key)) {
        formDataValue.append(key, formData[key]);
      }
    }
    const apiURL = `${process.env.REACT_APP_BASE_URL}/api/collegeregister/`;
    axios
      .post(apiURL, formDataValue, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast.success(response.data.msg);
        handleClose();
      })
      .catch((error) => {
        console.error("Error submitting data", error);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const handleSelectCollege = (college) => {
    setFormData({ ...formData, collegeName: college });
    setErrors({ ...errors, collegeName: "" });
    setCollegeOptions(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setFormData(initialValue);
    setErrors(initialValue);
    setCollegeOptions(false);
  };
  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="campuse_student_request_container">
            <div className="campuse_student_request_header">
              <h3>College Management</h3>
              <div className="search_input_container">
                <div className="campuse_student_request_search_bar">
                  <img src={searchLogo} alt="search" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <button
                  className="add_college_managment"
                  onClick={() => setShowModal(true)}
                >
                  Add College
                </button>
              </div>
            </div>
            <div className="campuse_student_request_body">
              <div
                className="campuse_student_request_sub_container"
                style={{ marginTop: "13px" }}
              >
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    {finalCardData.length > 0 ? (
                      currentItem?.map((item, key) => (
                        <div key={key} className="dashboard_request_list">
                          <div className="dashboard_request_list_card">
                            <div className="dashborad_request_logo_title_container">
                              <img
                                src={"https://via.placeholder.com/100"}
                                alt="college_logo"
                                className="dashboard_request_company_logo"
                              />
                              <div>
                                <h4>{item?.college_name}</h4>
                                <p>{item?.email}</p>
                              </div>
                            </div>
                            {/* <a href="#" style={{ color: "#f38d00", textDecoration: "none" }}>View Details <img src={RightArrow} alt="arrow" /></a> */}

                            <p>
                              <Link
                                to={`/dashboard/sidebar-page/admin/colleges/${item.id}`}
                                style={{
                                  color: "#f38d00",
                                  textDecoration: "none",
                                }}
                              >
                                View Details{" "}
                                <img src={RightArrow} alt="arrow" />
                              </Link>
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no-requests-message">
                        No requests found.
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {finalCardData.length > 10 && (
          <div className="d-flex justify-content-center pagination_custom">
            <Pagination
              count={Math.ceil(finalCardData.length / itemPerPage)}
              page={currentPage}
              color="primary"
              className="mt-3"
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
        className="modal_admin_request"
      >
        <div className="popup_form_container">
          <Modal.Body>
            <Form autoComplete="off" className="admin_dashboard_modal_form">
              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicName"
              >
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                  autoComplete="new-firstName"
                />
                {errors.firstName && (
                  <span className="error_message_form">{errors.firstName}</span>
                )}
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicLastName"
              >
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                  autoComplete="new-lastName"
                />
                {errors.lastName && (
                  <span className="error_message_form">{errors.lastName}</span>
                )}
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicEntityName"
              >
                <Form.Control
                  type="text"
                  name="collegeName"
                  value={formData.collegeName}
                  onChange={handleChangeCollegeData}
                  placeholder="College Name"
                  autoComplete="new-collegeName"
                />
                {errors.collegeName && (
                  <div className="error_message_form">{errors.collegeName}</div>
                )}

                {/* Show filtered results as dropdown */}
                {collegeOptions && filteredColleges.length > 0 && (
                  <ul className="dropdown-list">
                    {filteredColleges.slice(0, 10).map((college, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSelectCollege(college?.college_name)
                        }
                      >
                        {college?.college_name}
                      </li>
                    ))}
                  </ul>
                )}
              </Form.Group>
              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicEmail"
              >
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email Address"
                  autoComplete="new-email"
                />
                {errors.email && (
                  <span className="error_message_form">{errors.email}</span>
                )}
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicPassword"
              >
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                  autoComplete="new-password"
                />
                {errors.password && (
                  <span className="error_message_form">{errors.password}</span>
                )}
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicConfirmPassword"
              >
                <Form.Control
                  type="password"
                  name="password2"
                  value={formData.password2}
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  autoComplete="new-password2"
                />
                {errors.password2 && (
                  <span className="error_message_form">{errors.password2}</span>
                )}
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicPhoneNumber"
              >
                <div></div>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  className="input_field"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  pattern="[0-9]{10}"
                  inputMode="numeric"
                  autoComplete="new-phoneNumber"
                />
                {errors.phoneNumber && (
                  <span className="error_message_form">
                    {errors.phoneNumber}
                  </span>
                )}
              </Form.Group>

              {/* <Button variant="primary" type="submit">
              Submit
            </Button> */}
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <div className="dashboard_request_actions_button_modal">
              <button
                className="dashborad_request_approve_button"
                onClick={handleSubmit}
              >
                Add
              </button>
              <button
                className="dashboard_request_reject_button"
                onClick={() => handleClose()}
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </div>
        {isLoader && (
          <div className="college_popup_loader">
            <span className="loader"></span>
          </div>
        )}
      </Modal>
    </>
  );
}

export default Colleges;
