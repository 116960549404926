import axios from "axios";
import {
  setLetralHiringJobsData,
  setLetralHiringUserData,
  setLandingPageData,
  setFaqsRedux,
  setAlltestimonialsRedux,
  setCountersDataRedux,
  setPopularJobsRedux,
  setTopcompanyRedux,
  setLoadingLetralLanding
} from "../../reducers/letralHiringReducer";
import { toast } from "react-toastify";

export const fetchLetralHiringJobs = () => async (dispatch) => {
  const accessToken = sessionStorage.getItem("accessToken");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/`,
      {
        headers: {
          Authorization: accessToken && `Bearer ${accessToken}`,
        },
      }
    );
    dispatch(setLetralHiringJobsData(response.data));
  } catch (error) {
    console.log("Failed to fetch lateral hiring jobs data:", error);
  }
};

export const fetchLoginUserDetailas = () => async (dispatch) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const userSessionData = sessionStorage.getItem("userDetails");

  const parsedData = JSON.parse(userSessionData);
  const userId = parsedData?.id;

  if (!userId) {
    return;
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
      {
        headers: {
          Authorization: accessToken && `Bearer ${accessToken}`,
        },
      }
    );
    dispatch(setLetralHiringUserData(response.data));
  } catch (error) {
    toast.error("Failed to fetch User details Please Login Again");
  }
};

export const fetchLetralHiringLandingPage = () => async (dispatch) => {
  try {

    dispatch(setLoadingLetralLanding(true))

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/landing_page/`,
    );

    const sortedCompanies = response.data?.main_entities[0]?.top_companies?.sort((a, b) =>
      a.company_name.localeCompare(b.company_name)
    );

    dispatch(setLandingPageData(response.data));
    dispatch(setCountersDataRedux(response.data?.counters));
    dispatch(setAlltestimonialsRedux(response.data?.main_entities[0].user_reviews));
    dispatch(setFaqsRedux(response.data?.main_entities[0].questions));
    dispatch(setPopularJobsRedux(response.data?.main_entities[0].popular_jobs));
    dispatch(setTopcompanyRedux(sortedCompanies))

  } catch (error) {
    console.error("Failed to fetch lateral hiring jobs data:", error);
  }
  finally {
    dispatch(setLoadingLetralLanding(false))
  }
};