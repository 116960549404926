import React, { useState, useEffect } from "react";
import arrowImg from "../../../../../utils/landingpage/images/navigate.svg";
import { Link, useNavigate } from "react-router-dom";
import "../../../../../styles/admin/contentmanagement.css";
import rightArrow from "../../../../../utils/dashboards/right-arrow.svg";
import TestimonialCard from "./TestimonialCard";
import TestimonialForm from "./TestimonialForm";
import axios from "axios";
import { toast } from "react-toastify";

function ClientTestimonial() {
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingTestimonial, setEditingTestimonial] = useState(null);

  const fetchTestimonials = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/testimonials/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTestimonials(response.data);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
      toast.error("Failed to load testimonials");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const handleTestimonialAdded = () => {
    fetchTestimonials();
    setEditingTestimonial(null); // Clear editing state after successful submission
  };

  const handleEdit = (testimonial) => {
    setEditingTestimonial(testimonial);
    // Scroll to form
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const seeAllTestimonials = (e) => {
    navigate(`/dashboard/sidebar-page/${"admin"}/all-testimonials`);
  };

  const goToBack = () => {
    navigate(-1);
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="clientTestimonial_header">
          <h6>
            <img
              onClick={goToBack}
              className="arrow_icon"
              src={arrowImg}
              alt=".."
            />
            &nbsp; {editingTestimonial ? "Edit" : "Add"} Client Testimonial{" "}
            <br />
            &nbsp;{" "}
            <span className="sub_heading">
              {editingTestimonial ? "Update" : "Fill out"} the testimonial
              details
            </span>
          </h6>
        </div>
        {/* ------------------- Testimonials From Start ----------------- */}
        <TestimonialForm
          onSuccess={handleTestimonialAdded}
          editData={editingTestimonial}
        />
        {/* -----------------  Testimonials From End -----------------  */}
        <div className="clientTestimonial_header mt-4">
          <div className="d-flex justify-content-between">
            <h6>Client Testimonials</h6>
            <button
              onClick={seeAllTestimonials}
              className="view_all_testimonials_btn"
            >
              view <img src={rightArrow} alt="." />
            </button>
          </div>
          <div className="client_testimonial_card_containers">
            {loading ? (
              <div>Loading testimonials...</div>
            ) : (
              <TestimonialCard
                testimonials={testimonials}
                limit={2}
                onDelete={handleTestimonialAdded}
                onEdit={handleEdit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientTestimonial;
