import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import Counter from "../common_component/counter/counter";
import Faq from "../common_component/faq/faq";
import Services from "../common_component/services/services";
import Testimonial from "../common_component/testimonial/testimonial";
import "../../styles/landing_page/landingpage.css";
import naukriimg from "../../utils/landingpage/images/heroSectonIMG.svg";
import profile from "../../utils/landingpage/images/profile.svg";
import infograins from "../../utils/landingpage/images/infograinsOrange.svg";
import UnicspacesLogo from "../../utils/landingpage/images/UnicspacesLogo.jpg";
import pranjyanaInfremovebg from "../../utils/landingpage/images/pranjyanaInfremovebg.png";
import AmohaLogoNew from "../../utils/landingpage/images/AmohaLogoNew.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const calendlyRef = useRef(null);
  const [showCalendly, setShowCalendly] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const ReduxLoading = useSelector(
    (state) => state.digitalCampusHiring?.loadingCampusLanding
  );

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,

    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          dots: true,
          fade: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          waitForAnimate: false,
        },
      },
    ],
  };

  const slides = [
    { id: 1, title: "Infograins", img: infograins },
    { id: 2, title: "Unicspaces Logo", img: UnicspacesLogo },
    { id: 3, title: "PranjyanaInfra", img: pranjyanaInfremovebg },
    { id: 4, titile: "Amoha", img: AmohaLogoNew }
  ];

  const handleGetStarted = () => {
    navigate("/register")
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (showCalendly) {
      const timer = setTimeout(() => {
        setShowCloseButton(true);
      }, 4000);

      return () => clearTimeout(timer); // Cleanup function
    }
  }, [showCalendly]);

  const handleClick = () => {
    setIsLoading(true);
    setShowCalendly(true);
  };

  const handleClose = () => {
    setShowCalendly(false);
    setIsLoading(false);
  };

  // useEffect(() => {
  //   if (showCalendly && calendlyRef.current) {
  //     const script = document.createElement("script");
  //     script.src = "https://assets.calendly.com/assets/external/widget.js";
  //     script.async = true;
  //     calendlyRef.current.appendChild(script);
  //   }
  // }, [showCalendly]);
  useEffect(() => {
    if (showCalendly && calendlyRef.current) {
      const script = document.createElement("script");
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      script.onload = () => setIsLoading(false);
      calendlyRef.current.appendChild(script);
    }
  }, [showCalendly]);

  return (
    <>
      {ReduxLoading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ClipLoader color="#FFA500" size={60} />
        </div>
      ) : (<>

        <section className="hero_main">
          <div className="hero_container">
            <div className="hero_row">
              <motion.div
                className="hero_content student_hero_content"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <h6>Your job finding struggles end here</h6>
                <h1>
                  <span className="hero_content_title">
                    <span className="hero_content_find_your_dream">
                      Find Your Dream Job
                    </span>
                    <span>
                      {" "}
                      With{" "}
                      <span className="hero_content_start_naukari">
                        startnaukri.com
                      </span>{" "}
                    </span>
                  </span>
                </h1>
                <p>
                  <span>
                    Find your first job with startnaukri.com a Megamall of jobs {" "}
                  </span>
                  <span> india for candaidates, collages and corporates.</span>
                </p>
                <div className="hero_content_btn">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={handleClick}
                  >
                    Book a Demo
                  </motion.button>

                  {/* {showCalendly && (
                    <div className="calendly-inline-widget-main">
                      <div
                        ref={calendlyRef}
                        className="calendly-inline-widget"
                        data-url="https://calendly.com/megupta1234567/start-naukri-demo"
                      ></div>
                    </div>
                  )} */}

                  {showCalendly && (
                    <div className="calendly-inline-widget-main">
                      {showCloseButton && (
                        <button className="calande_popup_close_button" onClick={handleClose}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="40"
                            height="40"
                            viewBox="0 0 48 48"
                          >
                            <path
                              fill="#f44336"
                              d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                            ></path>
                            <path
                              fill="#fff"
                              d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"
                            ></path>
                            <path
                              fill="#fff"
                              d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"
                            ></path>
                          </svg>
                          Close
                        </button>
                      )}
                      <div
                        ref={calendlyRef}
                        className="calendly-inline-widget"
                        data-url="https://calendly.com/megupta1234567/start-naukri-demo"
                      >
                      </div>
                    </div>
                  )}

                </div>
              </motion.div>
              <motion.div
                className="naukri_image"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <img src={naukriimg} alt="Naukri_Image" />
              </motion.div>
            </div>
          </div>
        </section>
        {/* Hero Section Ends */}
        <Counter />
        {/* Register section Start */}
        <section className="profile_main">
          <div className="profile_container">
            <div className="profile_row">
              <div className="profile_start">
                <motion.div
                  className="profile_image"
                  initial={{ y: 0, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 2 }}
                >
                  <img src={profile} alt="logo" />
                </motion.div>

                <motion.div
                  className="profile_content"
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                >
                  <h3>
                    Getting started is <br /> easy.
                  </h3>
                  <p>
                    With the best job opportunities across
                    <br /> corporates in India.
                  </p>
                  <div className="profile_btn">
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      onClick={handleGetStarted}
                    >
                      Get Started
                    </motion.button>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>

          <div className="register_main">
            <div className="register_container">
              <div className="register_row">
                <div className="register_start row gap-5 gap-sm-5 gap-md-0  ">
                  <motion.div
                    className="register_resume col-12 col-sm-5 col-md-3"
                    initial={{ opacity: 0, y: 150 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <motion.div
                      className="hr"
                      initial={{ opacity: 0, y: -100 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.4 }}
                    ></motion.div>
                    <span>01</span>
                    <h5>Login or Register</h5>
                    <p>
                      Follow the whole step and you're just ready to get started
                    </p>
                  </motion.div>

                  <motion.div
                    className="register_resume col-12 col-sm-5 col-md-3"
                    initial={{ opacity: 0, y: 180 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <motion.div
                      className="hr"
                      initial={{ opacity: 0, y: -100 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    ></motion.div>
                    <span>02</span>
                    <h5>Fill your Personal Data</h5>
                    <p>Finish your register and complete your personal data</p>
                  </motion.div>

                  <motion.div
                    className="register_resume col-12 col-sm-5 col-md-3"
                    initial={{ opacity: 0, y: 210 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.7 }}
                  >
                    <motion.div
                      className="hr"
                      initial={{ opacity: 0, y: -100 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.8 }}
                    ></motion.div>
                    <span>03</span>
                    <h5>Upload your Resume</h5>
                    <p>
                      Upload your latest resume that match with your background
                    </p>
                  </motion.div>

                  <motion.div
                    className="register_resume col-12 col-sm-5 col-md-3 "
                    initial={{ opacity: 0, y: 230 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.9 }}
                  >
                    <motion.div
                      className="hr"
                      initial={{ opacity: 0, y: -100 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 1.1 }}
                    ></motion.div>
                    <span>04</span>
                    <h5>Find the Match job</h5>
                    <p>
                      {" "}
                      Look for job vacancy and immediately get your dream job.
                    </p>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Register section Ends */}
        {/* Employee section Start */}
        <section className="employee_main">
          <div className="employee_container">
            <div className="employee_row">
              <motion.div
                className="employee_content"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <h6>
                  Top Hiring Employers Of
                  <br />
                  Start Naukri
                </h6>
                <p>
                  Start your career journey by Connecting with Top employers like
                  Amoha Group, Infograins, Pranjay Infrastructure, Unicspaces ensuring you
                  find opportunities in your dream companies.
                </p>
              </motion.div>

              <div className="employee_company">
                <div className="slider-container">
                  <Slider {...settings}>
                    {slides?.map((slide) => (
                      <div key={slide.id} className="employee_inner">
                        <div>
                          <img src={slide.img} alt={`${slide.title} logo`} />
                          {/* <h6 className="employee_content_title">
                          {slide.title.split(",")[0]}
                          <br />
                          {slide.title.split(",")[1]}
                        </h6>
                        <button className="employee_content_button">
                          35 open positions
                        </button> */}
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>{" "}
        {/* Employee section Ends */}
        <Faq />
        <Services />
        <Testimonial />

      </>)}
    </>
  );
};

export default LandingPage; 