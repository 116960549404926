// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/loginReducer";
import letralHiringReducer from "../reducers/letralHiringReducer";
import studentProfileReducer from "../reducers/student_profile_reducer";
import studentProfilePercentageReducer from "../reducers/student_profile_percentage";
import hrProfileReducer from "../reducers/hrProfileReducer";
import collage_dashboard_reducer from "../reducers/collage_dashboard_reducer";
import super_admin_reducer from "../reducers/super_admin_reducer";
import digital_campus_hiring from "../reducers/digitalCampusReducer"

const store = configureStore({
  reducer: {
    auth: authReducer,
    letralHiring: letralHiringReducer,
    studentProfile: studentProfileReducer,
    studentProfilePercentage: studentProfilePercentageReducer,
    hrProfile: hrProfileReducer,
    CollageDashboard: collage_dashboard_reducer,
    superAdmin: super_admin_reducer,
    digitalCampusHiring: digital_campus_hiring,
  },
});

export default store;