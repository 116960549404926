import React, { useState, useEffect } from "react";
import "../../../../../styles/admin/contentmanagement.css";
import { Rating } from "@mui/material";
import imgUpload from "../../../../../utils/dashboards/img-upload.svg";
import videoupload from "../../../../../utils/dashboards/videoupload.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

function TestimonialForm({ onSuccess, editData }) {
  const [imagePreview, setImagePreview] = useState("https://via.placeholder.com/100");
  const [videoPreview, setVideoPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      client_name: "",
      company_name: "",
      testimonial_description: "",
      rating: 0,
      client_photo: null,
      testimonial_video: null
    },
    validationSchema: Yup.object({
      client_name: Yup.string().required("Client name is required"),
      company_name: Yup.string().required("Company name is required"),
      testimonial_description: Yup.string().required("Testimonial description is required"),
      rating: Yup.number().required("Rating is required")
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!values.client_photo && !values.testimonial_video && !editData?.client_photo && !editData?.testimonial_video) {
        toast.error("Please upload either an image or video");
        return;
      }

      try {
        setLoading(true);
        const token = sessionStorage.getItem("accessToken");
        const formData = new FormData();
        
        formData.append("client_name", values.client_name);
        formData.append("company_name", values.company_name);
        formData.append("testimonial_description", values.testimonial_description);
        formData.append("rating", parseInt(values.rating));
        
        if (values.client_photo) {
          formData.append("client_photo", values.client_photo);
        }
        if (values.testimonial_video) {
          formData.append("testimonial_video", values.testimonial_video);
        }

        const url = editData 
          ? `${process.env.REACT_APP_BASE_URL}/api/testimonials/${editData.id}/`
          : `${process.env.REACT_APP_BASE_URL}/api/testimonials/`;

        const method = editData ? 'put' : 'post';

        await axios({
          method,
          url,
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success(`Testimonial ${editData ? 'updated' : 'added'} successfully!`);
        resetForm();
        setImagePreview("https://via.placeholder.com/100");
        setVideoPreview(null);
        
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        console.error("API Error:", error);
        toast.error(error.response?.data?.message || `Error ${editData ? 'updating' : 'adding'} testimonial`);
      } finally {
        setLoading(false);
      }
    },
    validateOnMount: true
  });

  // Set form values when editData changes
  useEffect(() => {
    if (editData) {
      formik.setValues({
        client_name: editData.client_name || "",
        company_name: editData.company_name || "",
        testimonial_description: editData.testimonial_description || "",
        rating: editData.rating || 0,
        client_photo: null,
        testimonial_video: null
      });

      if (editData.client_photo) {
        setImagePreview(editData.client_photo);
      }
      if (editData.testimonial_video) {
        setVideoPreview(editData.testimonial_video);
      }
    }
  }, [editData]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (formik.values.testimonial_video) {
        toast.error("Please remove video before uploading image");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
        formik.setFieldValue("client_photo", file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (formik.values.client_photo) {
        toast.error("Please remove image before uploading video");
        return;
      }
      const videoUrl = URL.createObjectURL(file);
      setVideoPreview(videoUrl);
      formik.setFieldValue("testimonial_video", file);
    }
  };

  const handleRatingChange = (event, newValue) => {
    formik.setFieldValue("rating", newValue);
  };

  const handleReset = () => {
    formik.resetForm();
    setImagePreview("https://via.placeholder.com/100");
    setVideoPreview(null);
  };

  return (
    <div className="clientTestimonial_main_container p-4">
      <form className="clientTestimonial_form" onSubmit={formik.handleSubmit}>
        <div className="clientTestimonial_upload_section">
          <div className="image_upload_ratings_container">
            <div className="image_upload">
              <div>
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ width: "100px", borderRadius: "50%" }}
                />
              </div>

              <div>
                <label className="content_upload_img_label_heading">
                  Upload Profile Photo
                </label>
                <label
                  htmlFor="content_upload_img"
                  className="content_upload_img_label"
                >
                  <img src={imgUpload} alt=".." /> <p> Upload Image</p>
                </label>
                <input
                  id="content_upload_img"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </div>
            </div>
            <div className="ratings">
              <div style={{ paddingTop: "0.5rem" }}>
                <p className="add_rating">Add Rating</p>
                <Rating
                  name="rating"
                  value={formik.values.rating}
                  precision={0.5}
                  onChange={handleRatingChange}
                  sx={{
                    fontSize: "36px",
                  }}
                />
              </div>
            </div>
          </div>
          <h6>or</h6>
          <div className="video_upload_container">
            <label className="content_upload_img_label_heading">
              Upload Video &nbsp;
            </label>

            <label
              className="content_upload_img_label_heading_btn"
              htmlFor="video_upload_label"
            >
              Change
            </label>
            <label htmlFor="video_upload_label" className="video_upload_label">
              <div className="video_upload_box">
                {videoPreview ? (
                  <video
                    src={videoPreview}
                    controls
                    className="video_preview"
                    alt="Video Preview"
                  />
                ) : (
                  <img
                    src={videoupload}
                    className="img_upload_icon"
                    alt="Upload Icon"
                  />
                )}
              </div>
            </label>
            <input
              id="video_upload_label"
              type="file"
              accept="video/*"
              style={{ display: "none" }}
              onChange={handleVideoUpload}
            />
          </div>
        </div>
        <div className="clientTestimonial_upload_section_input_fields">
          <div className="clientTestimonial_input">
            <input
              type="text"
              name="client_name"
              placeholder="Enter Client Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.client_name}
            />
            {formik.touched.client_name && formik.errors.client_name && (
              <div className="error-message">{formik.errors.client_name}</div>
            )}
          </div>
          <div className="clientTestimonial_input">
            <input
              type="text"
              name="company_name"
              placeholder="Enter Company Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.company_name}
            />
            {formik.touched.company_name && formik.errors.company_name && (
              <div className="error-message">{formik.errors.company_name}</div>
            )}
          </div>
          <div className="clientTestimonial_textarea">
            <textarea
              name="testimonial_description"
              cols={20}
              rows={6}
              placeholder="Enter testimonial description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.testimonial_description}
            />
            {formik.touched.testimonial_description && formik.errors.testimonial_description && (
              <div className="error-message">{formik.errors.testimonial_description}</div>
            )}
          </div>
        </div>
        <div className="clientTestimonial_btn">
          <button
            type="submit"
            className="clientTestimonial_submit_btn"
            disabled={loading || !formik.isValid}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : (editData ? "Update" : "Submit")}
          </button>
          <button 
            type="button" 
            className="clientTestimonial_cancel_btn"
            onClick={handleReset}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default TestimonialForm;
